import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ImageUploadTask, sortByCreatedDesc } from '@insite-group-ltd/insite-teams-model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ImageUploadStore, ImageUploadTaskState } from './image-upload.store';

@Injectable({ providedIn: 'root' })
export class ImageUploadQuery extends QueryEntity<ImageUploadTaskState> {
    constructor(protected store: ImageUploadStore) {
        super(store);
    }

    getImageUploadTaskIdsWithDocPath$(docPath: string): Observable<string[]> {
        return this.selectAll({ filterBy: (task) => task.docPath === docPath }).pipe(
            map((tasks) => tasks.map((task) => task.id))
        );
    }

    getPendingImageUploadTasks$(docPath: string): Observable<ImageUploadTask[]> {
        return this.selectAll({
            filterBy: (task) => task.status === 'PENDING' && task.docPath === docPath,
            sortBy: 'uploadAttempts',
        });
    }

    getUploadedImageUploadTasks$(docPath: string): Observable<ImageUploadTask[]> {
        return this.selectAll({
            filterBy: (task) => task.status === 'UPLOADED' && task.docPath === docPath,
            sortBy: 'dataUpdateAttempts',
        });
    }

    getNotCompleteImageUploadTasks$(): Observable<ImageUploadTask[]> {
        return this.selectAll({ sortBy: sortByCreatedDesc });
    }

    getNotCompleteImageUploadTaskDocPaths$(): Observable<string[]> {
        return this.selectAll({ sortBy: sortByCreatedDesc }).pipe(
            map((tasks) => tasks.map((task) => task.docPath)),
            map((docPaths) => [...new Set(docPaths)])
        );
    }
}
