import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { filterNilValue } from '@datorama/akita';
import { CollectionGuard } from 'akita-ng-fire';
import uniq from 'lodash/uniq';
import { combineLatest } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { LoggerService } from '../../services/logger/logger.service';
import { UtilService } from '../../services/util/util.service';
import { ProjectQuery } from '../../state/projects/project.query';
import { ProjectService } from '../../state/projects/project.service';
import { InsiteUserService } from '../../state/user/insite-user.service';
import { catchGuardError } from '../../utils/guard';
import { ProjectState } from './project.store';

@Injectable({ providedIn: 'root' })
export class ActiveProjectAndPlanUsersGuard extends CollectionGuard<ProjectState> {
    constructor(
        protected service: ProjectService,
        private insiteUserService: InsiteUserService,
        private projectQuery: ProjectQuery,
        private utilService: UtilService,
        private loggerService: LoggerService
    ) {
        super(service);
    }

    protected sync(next: ActivatedRouteSnapshot) {
        return combineLatest([
            this.projectQuery.getProject$(next.params.projectId),
            this.projectQuery.getProjectPlan$(next.params.projectId),
        ]).pipe(
            filterNilValue(),
            tap(() =>
                this.loggerService.debug(
                    'active project and plan users guard',
                    'syncing active project and plan users'
                )
            ),
            switchMap(([project, plan]) => {
                const userIds = [];
                userIds.push(...Object.keys(project?.users || {}));
                userIds.push(...Object.keys(plan?.users || {}));
                return this.insiteUserService.syncManyDocs(uniq(userIds));
            }),
            catchError((err) => catchGuardError(err, 'users', this.utilService, this.loggerService))
        );
    }
}
