<div class="vertical-align-content plan-quantity-container" [ngClass]="{ 'size-small': sizeSmall }">
    <div class="quantity-information flex-grow-1">
        <ion-text color="primary">
            <p class="font-14 font-weight-500 mt-8 mb-8">
                <span data-testid="quantity.count">{{ (quantity$ | async)}}</span>&nbsp;<span data-testid="quantity.unit">{{ quantityUnit | makePluralString: (quantity$ | async) }}</span>
            </p>
        </ion-text>
    </div>
    <ion-button
        (click)="decrement()"
        color="light-blue-bg"
        class="ion-no-margin ml-6 button-padding-2 button-34-34"
        data-testid="quantity.decrease">
        <ion-icon name="remove" color="primary" slot="icon-only"></ion-icon>
    </ion-button>
    <ion-button
        (click)="increment()"
        color="light-blue-bg"
        class="ion-no-margin ml-6 button-padding-2 button-34-34"
        data-testid="quantity.increase">
        <ion-icon name="add" color="primary" slot="icon-only"></ion-icon>
    </ion-button>
</div>
