import { Injectable } from '@angular/core';
import { where } from '@angular/fire/firestore';
import { CollectionGuard } from 'akita-ng-fire';
import { of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { LoggerService } from '../../services/logger/logger.service';
import { UtilService } from '../../services/util/util.service';
import { catchGuardError } from '../../utils/guard';
import { PlanQuery } from '../plan/plan.query';
import { ProjectService } from './project.service';
import { ProjectState, ProjectStore } from './project.store';

@Injectable({ providedIn: 'root' })
export class PlanProjectsGuard extends CollectionGuard<ProjectState> {
    constructor(
        protected service: ProjectService,
        private projectStore: ProjectStore,
        private planQuery: PlanQuery,
        private utilService: UtilService,
        private loggerService: LoggerService
    ) {
        super(service);
    }

    protected sync() {
        return this.planQuery.currentPlanId$.pipe(
            tap((planId) =>
                this.loggerService.debug('plan projects guard', 'syncing plan projects', { planId })
            ),
            tap(() => this.projectStore.reset()),
            switchMap((planId) => {
                if (planId) {
                    return this.service.syncCollection([where('planId', '==', planId)], {
                        loading: true,
                    });
                } else {
                    return of([]);
                }
            }),
            catchError((err) =>
                catchGuardError(err, 'projects', this.utilService, this.loggerService)
            )
        );
    }
}
