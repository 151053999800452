import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { InsiteUserState, InsiteUserStore } from './insite-user.store';

@Injectable({ providedIn: 'root' })
export class InsiteUserQuery extends QueryEntity<InsiteUserState> {
    constructor(protected store: InsiteUserStore) {
        super(store);
    }
}
