import { Injectable } from '@angular/core';
import { filterNilValue, QueryEntity } from '@datorama/akita';
import { getInsiteUserName, InsiteUser } from '@insite-group-ltd/insite-teams-model';
import cloneDeep from 'lodash/cloneDeep';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, take } from 'rxjs/operators';
import { AuthState, AuthStore } from './auth.store';

@Injectable({ providedIn: 'root' })
export class AuthQuery extends QueryEntity<AuthState> {
    constructor(protected store: AuthStore) {
        super(store);
    }

    get userId(): Promise<string> {
        return this.select('uid').pipe(filterNilValue(), take(1)).toPromise();
    }

    get userId$(): Observable<string> {
        return this.select('uid').pipe(filterNilValue(), distinctUntilChanged());
    }

    get insiteUserName(): Promise<string> {
        return this.user$
            .pipe(
                take(1),
                map((user) => getInsiteUserName(user))
            )
            .toPromise();
    }

    get insiteUserName$(): Observable<string> {
        return this.user$.pipe(map((user) => getInsiteUserName(user)));
    }

    get email(): Promise<string> {
        return this.user$
            .pipe(
                take(1),
                map((user) => user?.email)
            )
            .toPromise();
    }

    get email$(): Observable<string> {
        return this.user$.pipe(map((user) => user?.email));
    }

    get user$(): Observable<InsiteUser> {
        return this.select('profile').pipe(
            filterNilValue(),
            map((user) => cloneDeep(user))
        );
    }

    get userPrivate$(): Observable<boolean> {
        return this.user$.pipe(map((user) => user.privateMode));
    }
}
