import camelCase from 'lodash/camelCase';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import merge from 'lodash/merge';
import uniq from 'lodash/uniq';
import { BaseList } from './list';
import { Project } from './project';
import { SelectOption } from './select-option';
import { enTranslations } from './translations';

export type ItemFieldId =
    | 'TITLE'
    | 'DESCRIPTION'
    | 'DUE_DATE'
    | 'IMAGES'
    | 'ASSIGNEES'
    | 'LOCATIONS'
    | 'STATUS'
    | 'STATUS_REQUESTS'
    | 'PRIORITY'
    | 'FILES'
    | 'COMMENTS';

export interface ItemField {
    id: ItemFieldId;
    enabled: boolean;
    label?: string;
}

export interface ItemFieldSet extends SelectOption {
    fields: Record<ItemFieldId, ItemField>;
    readOnly?: boolean;
}

export const DEFAULT_ITEM_FIELD_SET: ItemFieldSet = {
    id: 'DEFAULT',
    name: 'System default',
    readOnly: true,
    fields: {
        TITLE: {
            id: 'TITLE',
            enabled: true,
        },
        DESCRIPTION: {
            id: 'DESCRIPTION',
            enabled: true,
        },
        DUE_DATE: {
            id: 'DUE_DATE',
            enabled: true,
        },
        IMAGES: {
            id: 'IMAGES',
            enabled: true,
        },
        ASSIGNEES: {
            id: 'ASSIGNEES',
            enabled: true,
        },
        LOCATIONS: {
            id: 'LOCATIONS',
            enabled: true,
        },
        STATUS: {
            id: 'STATUS',
            enabled: true,
        },
        STATUS_REQUESTS: {
            id: 'STATUS_REQUESTS',
            enabled: true,
        },
        PRIORITY: {
            id: 'PRIORITY',
            enabled: true,
        },
        FILES: {
            id: 'FILES',
            enabled: true,
        },
        COMMENTS: {
            id: 'COMMENTS',
            enabled: true,
        },
    },
};

export function emptyItemFieldSet() {
    return Object.assign({}, cloneDeep(DEFAULT_ITEM_FIELD_SET), {
        id: null,
        name: null,
        readOnly: false,
    });
}

export function getItemFieldSet(project: Project, list: BaseList) {
    const itemFieldSetId = list?.itemFieldSet?.id;
    if (itemFieldSetId) {
        return merge(
            cloneDeep(DEFAULT_ITEM_FIELD_SET),
            project?.itemFieldSets?.[itemFieldSetId] || {}
        );
    } else {
        return DEFAULT_ITEM_FIELD_SET;
    }
}

export function getMergedItemFieldSet(project: Project, lists: BaseList[]) {
    const itemFieldSets = uniq(lists.map((list) => getItemFieldSet(project, list)));
    const mergedItemFieldSet = cloneDeep(DEFAULT_ITEM_FIELD_SET);
    for (const itemFieldId of Object.keys(mergedItemFieldSet.fields)) {
        const field = itemFieldId as ItemFieldId;
        const mergedItemField = mergedItemFieldSet.fields[field];
        mergedItemField.enabled = itemFieldSets.some(
            (itemFieldSet) => itemFieldSet.fields[field].enabled
        );
        mergedItemField.label = uniq(
            itemFieldSets
                .filter(
                    (itemFieldSet) =>
                        itemFieldSet.fields[field].enabled && itemFieldSet.fields[field].label
                )
                .map((itemFieldSet) => itemFieldSet.fields[field].label)
        ).join('/');
        if (
            mergedItemField.label?.length &&
            itemFieldSets.find((itemFieldSet) => itemFieldSet.id === 'DEFAULT')
        ) {
            const defaultLabel = get(enTranslations, `item.${camelCase(field)}`);
            mergedItemField.label = `${defaultLabel}/${mergedItemField.label}`;
        }
    }
    return mergedItemFieldSet;
}

export function isListItemFieldEnabled(project: Project, list: BaseList, ...fields: ItemFieldId[]) {
    const itemFieldSet = getItemFieldSet(project, list);
    return isItemFieldEnabled(itemFieldSet, ...fields);
}

export function isItemFieldEnabled(itemFieldSet: ItemFieldSet, ...fields: ItemFieldId[]) {
    return fields.every((field) => itemFieldSet.fields[field].enabled);
}
