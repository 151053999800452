import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { map, tap } from 'rxjs/operators';

export type KeyboardStatus = 'SHOWN' | 'HIDDEN';

@Injectable({
    providedIn: 'root',
})
export class KeyboardService implements OnDestroy {
    private _keyboardStatus$ = new BehaviorSubject<KeyboardStatus>('HIDDEN');

    constructor() {
        if (Capacitor.isNativePlatform()) {
            Keyboard.addListener('keyboardWillShow', () => this._keyboardStatus$.next('SHOWN'));
            Keyboard.addListener('keyboardDidHide', () => this._keyboardStatus$.next('HIDDEN'));
        }
    }

    ngOnDestroy() {
        if (Capacitor.isNativePlatform()) {
            Keyboard.removeAllListeners();
        }
    }

    get isKeyboardClosed$(): Observable<boolean> {
        if (Capacitor.isNativePlatform()) {
            return this.keyboardStatus$.pipe(map((status) => status === 'HIDDEN'));
        } else {
            return of(true);
        }
    }

    private get keyboardStatus$(): Observable<KeyboardStatus> {
        return this._keyboardStatus$.asObservable().pipe(
            tap(() => {
                if (!Capacitor.isNativePlatform()) {
                    throw Error('keyboard not supported');
                }
            })
        );
    }
}
