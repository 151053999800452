import { Injectable } from '@angular/core';
import {
    Camera,
    CameraDirection,
    CameraResultType,
    CameraSource,
    GalleryImageOptions,
    ImageOptions,
} from '@capacitor/camera';
import { Filesystem } from '@capacitor/filesystem';
import {
    defaultImageSettings,
    GpsCoordinates,
    Image as InsiteImage,
    ImageDataUrl,
    ImageSettings,
    isBase64Image,
} from '@insite-group-ltd/insite-teams-model';
import { decode } from 'base64-arraybuffer';
import { parse } from 'date-fns';
import * as ExifReader from 'exifreader';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isString from 'lodash/isString';
import { nanoid } from 'nanoid';
import { ViewImageModalPageInputs } from '../../components/view-image-modal/view-image-modal';
import { ViewImageModalPage } from '../../components/view-image-modal/view-image-modal.page';
import { InsiteUserPrivateQuery } from '../../state/user/insite-user-private.query';
import { AnalyticsService } from '../analytics/analytics.service';
import { AuditedService } from '../audited/audited.service';
import { FileService } from '../file/file.service';
import { GpsService } from '../gps/gps.service';
import { LoggerService } from '../logger/logger.service';
import { SentryService } from '../sentry/sentry.service';
import { UtilService } from '../util/util.service';

const IGNORE_ERRORS = [
    'User cancelled photos app',
    'User denied access to photos',
    'User denied access to camera',
    'No image picked',
    'No images picked',
];

@Injectable({
    providedIn: 'root',
})
export class ImageService {
    private readonly LOGGER_NAME = 'image-service';

    constructor(
        private fileService: FileService,
        private analyticsService: AnalyticsService,
        private auditedService: AuditedService,
        private utilService: UtilService,
        private sentryService: SentryService,
        private loggerService: LoggerService,
        private insiteUserPrivateQuery: InsiteUserPrivateQuery,
        private gpsService: GpsService
    ) {}

    public async choosePhoto(): Promise<InsiteImage> {
        const loading = await this.utilService.presentLoadingSpinner();
        try {
            const image = await Camera.getPhoto(this.choosePictureOptions);
            return this.toImage(ImageDataUrl.fromBase64(image.base64String));
        } catch (err) {
            if (!includes(IGNORE_ERRORS, err?.message)) {
                this.sentryService.captureException(err);
            }
            return null;
        } finally {
            await loading.dismiss();
            this.analyticsService.event('choose_photo');
        }
    }

    public async takePhoto(captureGpsCoordinates = false): Promise<InsiteImage> {
        const loading = await this.utilService.presentLoadingSpinner();
        try {
            const userPrivate = await this.insiteUserPrivateQuery.userPrivate;
            const cameraOptions = this.getTakePictureOptions(
                userPrivate.imageSettings || defaultImageSettings
            );
            const image = await Camera.getPhoto(cameraOptions);
            const insiteImage = await this.fromDataUrl(image.dataUrl);
            if (
                captureGpsCoordinates ||
                (await this.insiteUserPrivateQuery.saveImageGpsCoordinates)
            ) {
                loading.message = 'Capturing GPS coordinates...';
                insiteImage.gpsCoordinates = await this.gpsService.getGpsCoordinates();
            }
            insiteImage.takenDate = this.getImageTakenAt(image.dataUrl);
            return insiteImage;
        } catch (err) {
            if (!includes(IGNORE_ERRORS, err?.message)) {
                this.sentryService.captureException(err);
            }
            return null;
        } finally {
            await loading.dismiss();
            this.analyticsService.event('take_photo');
        }
    }

    public async chooseMultiplePhotos(): Promise<InsiteImage[]> {
        const loading = await this.utilService.presentLoadingSpinner();
        try {
            const { photos } = await Camera.pickImages(this.chooseMultiplePictureOptions);
            if (!photos?.length) {
                return [];
            }
            const unsavedImagesPromises: Promise<InsiteImage>[] = [];
            for (const photo of photos) {
                if (!photo.path) {
                    continue;
                }
                unsavedImagesPromises.push(
                    (async () => {
                        const imageBase64 = await Filesystem.readFile({
                            path: photo.path,
                        });
                        return this.toImage(
                            ImageDataUrl.fromBase64WithFilePath(
                                imageBase64.data as string,
                                photo.path
                            )
                        );
                    })()
                );
            }
            return Promise.all(unsavedImagesPromises);
        } catch (err) {
            this.loggerService.error(this.LOGGER_NAME, err.message);
            if (!includes(IGNORE_ERRORS, err?.message)) {
                this.sentryService.captureException(err);
            }
            return [];
        } finally {
            await loading.dismiss();
            this.analyticsService.event('choose_photo_multiple');
        }
    }

    public async viewImage(images: InsiteImage[], index = 0) {
        const viewImageModalInputs: ViewImageModalPageInputs = {
            images: cloneDeep(images),
            index,
        };
        await this.utilService.presentModal<ViewImageModalPageInputs>(
            ViewImageModalPage,
            viewImageModalInputs,
            {
                cssClass: 'ion-img-viewer view-image-modal',
                keyboardClose: true,
                showBackdrop: true,
            }
        );
        this.analyticsService.event('view_image');
    }

    public imageToBase64(image: InsiteImage | string): Promise<any> {
        if (isString(image)) {
            return this.fileService.fileToBase64(image);
        } else if (isBase64Image(image)) {
            return Promise.resolve(image.url);
        } else {
            return this.fileService.fileToBase64(image.url);
        }
    }

    public async getImageDimensions(
        imageSrc: string
    ): Promise<{ width: number; height: number; aspectRatio: number }> {
        const imageElement = await this.loadHTMLImageElement(imageSrc);
        return {
            width: imageElement.width,
            height: imageElement.height,
            aspectRatio: imageElement.width / imageElement.height,
        };
    }

    public loadHTMLImageElement(imageSrc: string): Promise<HTMLImageElement> {
        const image = new Image();
        image.setAttribute('crossOrigin', 'anonymous');
        return new Promise((resolve) => {
            // we need to get real image size and make sure it fits on screen
            image.onload = () => {
                resolve(image);
            };
            image.src = imageSrc;

            // make sure the load event fires for cached images too
            if (image.complete || image.complete === undefined) {
                image.src =
                    'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==';
                image.src = imageSrc;
            }
        });
    }

    public async fromDataUrl(dataUrl: string): Promise<InsiteImage> {
        const unsavedImage: Partial<InsiteImage> = {
            url: dataUrl,
            id: nanoid(),
        };
        await this.auditedService.markCreated(unsavedImage as InsiteImage, true);
        return unsavedImage as InsiteImage;
    }

    public async fromFileList(files: FileList): Promise<InsiteImage[]> {
        const imageDataUrls = await this.fileService.filesToDataUrls(files);
        const unsavedImagesPromises: Promise<InsiteImage>[] = [];
        for (const imageDataUrl of imageDataUrls) {
            unsavedImagesPromises.push(this.toImage(ImageDataUrl.fromDataUrl(imageDataUrl)));
        }
        return Promise.all(unsavedImagesPromises);
    }

    public displayInvalidImagesDetectedAlert(invalidImageCount: number) {
        let header = 'Invalid image detected';
        if (invalidImageCount > 1) {
            header = `${invalidImageCount} invalid images detected`;
        }
        this.utilService.errorAlert(
            header,
            `All images in this list must have GPS coordinates. <br><br>
                           The images without GPS coordinates are highlighted in orange and indicated with &#33; <br><br>
                           Please replace or remove these images before trying to save.`
        );
    }

    private async toImage(imageDataUrl: ImageDataUrl) {
        const saveImageGpsCoordinates = await this.insiteUserPrivateQuery.saveImageGpsCoordinates;
        const insiteImage = await this.fromDataUrl(imageDataUrl.dataUrl);
        if (saveImageGpsCoordinates) {
            insiteImage.gpsCoordinates = this.getImageGpsCoordinates(imageDataUrl.base64);
        }
        insiteImage.takenDate = this.getImageTakenAt(imageDataUrl.base64);
        return insiteImage;
    }

    private get choosePictureOptions(): ImageOptions {
        return {
            width: 1200,
            height: 1200,
            quality: 50,
            source: CameraSource.Photos,
            resultType: CameraResultType.Base64,
            correctOrientation: true,
        };
    }

    private getTakePictureOptions(imageSettings: ImageSettings): ImageOptions {
        return {
            width: 1200,
            height: 1200,
            quality: 50,
            allowEditing: false,
            direction: CameraDirection.Rear,
            source: CameraSource.Camera,
            resultType: CameraResultType.DataUrl,
            saveToGallery: imageSettings.saveToCameraRoll,
            correctOrientation: true,
        };
    }

    private get chooseMultiplePictureOptions(): GalleryImageOptions {
        return {
            width: 1200,
            height: 1200,
            quality: 50,
        };
    }

    private getImageTakenAt(base64: string): number | null {
        try {
            const tags = ExifReader.load(decode(base64), { expanded: true });
            const dateTime = get(tags, 'exif.DateTimeOriginal.description');
            return dateTime ? parse(dateTime, 'yyyy:MM:dd HH:mm:ss', new Date()).getTime() : null;
        } catch (err) {
            this.loggerService.error(
                this.LOGGER_NAME,
                `${base64.substring(0, base64.indexOf(';base64'))} - ${err.message}`
            );
            this.sentryService.captureException(err);
            return null;
        }
    }

    private getImageGpsCoordinates(base64: string): GpsCoordinates | null {
        try {
            const tags = ExifReader.load(decode(base64), { expanded: true });
            const gps = tags['gps'];
            if (gps?.Latitude && gps?.Longitude) {
                return {
                    latitude: gps.Latitude,
                    longitude: gps.Longitude,
                };
            }
            return null;
        } catch (err) {
            this.loggerService.error(
                this.LOGGER_NAME,
                `${base64.substring(0, base64.indexOf(';base64'))} - ${err.message}`
            );
            this.sentryService.captureException(err);
            return null;
        }
    }
}
