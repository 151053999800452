import { Directive, OnInit } from '@angular/core';
import { IonTextarea } from '@ionic/angular';

@Directive({
    selector: '[libTextAreaClearIcon]',
})
export class TextAreaClearIconDirective implements OnInit {
    constructor(private el: IonTextarea) {}

    async ngOnInit() {
        const inputEl = await this.el.getInputElement();
        const clearIcon = document.createElement('ion-icon');
        clearIcon.setAttribute('name', 'close-sharp');
        clearIcon.classList.add('cursor-pointer', 'textarea-clear-icon');
        clearIcon.addEventListener('click', () => (this.el.value = ''));
        inputEl.parentElement.appendChild(clearIcon);
    }
}
