import { Injectable } from '@angular/core';
import { InAppBrowser, InAppBrowserEvent } from '@ionic-native/in-app-browser/ngx';

@Injectable({
    providedIn: 'root',
})
export class InAppBrowserService {
    constructor(private iab: InAppBrowser) {}

    openInAppBrowser(
        url: string,
        messageCallback: (data: InAppBrowserEvent['data']) => Promise<void>
    ) {
        const browser = this.iab.create(url, '_blank', {
            location: 'no',
            clearcache: 'yes',
            clearsessioncache: 'yes',
            cleardata: 'yes',
        });
        browser.on('message').subscribe((message: InAppBrowserEvent) => {
            browser.close();
            messageCallback(message.data);
        });
    }

    postMessage(data: any) {
        if (!this.isWithinInAppBrowser) {
            throw Error('unable to post message: client not running within in app browser');
        }
        this.window.webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify(data));
    }

    get isWithinInAppBrowser() {
        return !!this.window?.webkit?.messageHandlers?.cordova_iab?.postMessage;
    }

    private get window(): any {
        return window;
    }
}
