import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Plan, ProjectRole, UserInProject } from '@insite-group-ltd/insite-teams-model';
import { RadioGroupChangeEventDetail } from '@ionic/core';
import { combineLatest, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { UtilService } from '../../services/util/util.service';
import { AuthService } from '../../state/auth/auth.service';
import { PlanService } from '../../state/plan/plan.service';
import { ProjectQuery } from '../../state/projects/project.query';
import { ProjectService } from '../../state/projects/project.service';

@Component({
    selector: 'app-manage-project-user-modal',
    templateUrl: './manage-project-user-modal.page.html',
    styleUrls: ['./manage-project-user-modal.page.scss'],
})
export class ManageProjectUserModalPage implements OnInit, OnDestroy {
    @Input() projectId: string;
    @Input() userId: string;
    @Input() canManageUsers: any;
    plan: Plan;
    user: UserInProject;
    roleOptions: ProjectRole[] = [];
    currentRole: ProjectRole;
    private subs = new SubSink();

    constructor(
        private planService: PlanService,
        private projectService: ProjectService,
        private projectQuery: ProjectQuery,
        private utilService: UtilService,
        private authService: AuthService
    ) {}

    ngOnInit() {
        this.projectQuery.getUserInProject$(this.projectId, this.userId);
        this.subs.sink = combineLatest([
            this.projectQuery.getUserInProject$(this.projectId, this.userId),
            // if the current user is external they will not be able to read the plan, so catch and return null
            this.projectQuery.getProjectPlan$(this.projectId).pipe(catchError(() => of(null))),
        ]).subscribe(([userInProject, plan]) => {
            this.currentRole = userInProject.role;
            this.user = userInProject;
            if (plan) {
                this.plan = plan;
                const planRole = plan.users[this.userId];
                const currentUsersPlanRole = plan.users[this.authService.userId];
                if (planRole === 'ADMIN') {
                    this.roleOptions = ['ADMIN'];
                } else if (planRole === 'INTERNAL') {
                    this.roleOptions = ['ADMIN', 'INTERNAL'];
                } else if (currentUsersPlanRole === 'ADMIN') {
                    this.roleOptions = ['INTERNAL', 'EXTERNAL', 'READ_ONLY'];
                } else {
                    this.roleOptions = ['EXTERNAL', 'READ_ONLY'];
                }
            }
        });
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    close() {
        this.utilService.dismissModal();
    }

    done(chosenRole: ProjectRole) {
        this.utilService.dismissModal({ currentRole: this.currentRole, chosenRole });
    }

    async updateRole(event: Event) {
        const chosenRole = (event as CustomEvent<RadioGroupChangeEventDetail>).detail.value;
        if (chosenRole === this.currentRole) {
            return;
        }
        if (
            this.currentRole === 'ADMIN' ||
            this.currentRole === 'INTERNAL' ||
            chosenRole === 'READ_ONLY'
        ) {
            this.done(chosenRole);
        } else if (chosenRole === 'INTERNAL') {
            if (
                this.currentRole === 'READ_ONLY' &&
                this.projectService.hasReachedProjectUserLimit(this.projectId)
            ) {
                await this.projectService.displayProjectUserLimitReached(this.projectId);
                this.user.role = this.currentRole;
            } else if (
                await this.projectService.hasReachedProjectUserRoleLimit(
                    this.projectId,
                    this.userId,
                    'INTERNAL'
                )
            ) {
                await this.projectService.displayProjectUserRoleLimitReached(
                    this.projectId,
                    'INTERNAL'
                );
                this.user.role = this.currentRole;
            } else {
                let promoted = false;
                if (this.planService.hasReachedMaxPlanMemberLimit()) {
                    await this.utilService.errorAlert(
                        'No room on plan!',
                        'You are unable to promote this use to the Internal project role as there is not enough room on your plan. Please contact us to upgrade your plan member limit.'
                    );
                } else {
                    const planName = this.plan.company;
                    const insiteUserName = `${this.user.insiteUser.firstName} ${this.user.insiteUser.lastName}`;
                    promoted = await this.utilService.confirmAlert(
                        'Add user to plan',
                        `Are you sure you want to make <b>${insiteUserName}</b> an internal user? This will add them to your plan and promote them to an internal user on all projects under <b>${planName}</b>.`,
                        'Add'
                    );
                }
                if (promoted) {
                    this.done('INTERNAL');
                } else {
                    this.user.role = this.currentRole;
                }
            }
        } else if (chosenRole === 'EXTERNAL') {
            if (this.projectService.hasReachedProjectUserLimit(this.projectId)) {
                await this.projectService.displayProjectUserLimitReached(this.projectId);
                this.user.role = this.currentRole;
            } else if (
                await this.projectService.hasReachedProjectUserRoleLimit(
                    this.projectId,
                    this.userId,
                    'EXTERNAL'
                )
            ) {
                await this.projectService.displayProjectUserRoleLimitReached(
                    this.projectId,
                    'EXTERNAL'
                );
                this.user.role = this.currentRole;
            } else {
                this.done('EXTERNAL');
            }
        }
    }

    removeUser() {
        this.utilService.removeAlert(
            'Remove user',
            `Are you sure you want to remove ${this.user.insiteUser.firstName} ${this.user.insiteUser.lastName} from this project?`,
            () => {
                this.projectService.removeProjectUser(this.projectId, this.user.userId);
                this.utilService.successToast(
                    `${this.user.insiteUser.firstName} ${this.user.insiteUser.lastName} removed from project`
                );
                this.close();
            }
        );
    }
}
