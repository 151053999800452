import { Component, Input, OnInit } from '@angular/core';
import { WindowService } from '@insite-group-ltd/angular';
import { NavController } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { AlertHeaderService } from '../../services/alert-header/alert-header.service';
import { UtilService } from '../../services/util/util.service';

@Component({
    selector: 'lib-info-alert-header',
    templateUrl: './info-alert-header.component.html',
    styleUrls: ['./info-alert-header.component.scss'],
})
export class InfoAlertHeaderComponent implements OnInit {
    @Input() title: string;
    @Input() message: string;
    @Input() btnText: string;
    @Input() btnAction: () => void;
    @Input() projectId: string;
    @Input() type: InfoAlertType;
    @Input() marginBottom: string;
    @Input() marginTop: string;
    @Input() marginHorizontal: boolean;
    @Input() url: string;
    showAction: Observable<boolean> = of(true);
    windowSize$ = this.windowService.windowSize$;

    constructor(
        public alertHeaderService: AlertHeaderService,
        private utilService: UtilService,
        private windowService: WindowService,
        private navCtrl: NavController
    ) {}

    ngOnInit() {
        switch (this.type) {
            case 'EXISTING_PLAN_AS_ADMIN': {
                this.title = 'You are buying a new plan';
                this.message =
                    'You are already part of an existing plan. Continue below to purchase a new plan, if you are looking to upgrade your existing plan go to plan and billing and choose upgrade.';
                this.btnText = 'Manage plan';
                this.btnAction = () => this.navCtrl.navigateRoot('/app/manage');
                break;
            }
            case 'EXISTING_PLAN_AS_USER': {
                this.title = 'You are buying a new plan';
                this.message =
                    'You are already part of an existing plan. Continue below to purchase a new plan, if you are looking to upgrade your current plan then please contact a plan admin.';
                this.btnText = 'Go to Teams';
                this.btnAction = () => this.utilService.openUrl('https://teams.insiteapp.co.uk');
                break;
            }
            case 'EDITING_LIST_TEMPLATE': {
                this.title = 'You are now editing a template';
                this.message = 'Press <b>Use template</b> to create a list using this template';
                break;
            }
            case 'VIEWING_LIST_TEMPLATE': {
                this.title = 'You are now viewing a template';
                this.message = 'Press <b>Use template</b> to create a list using this template';
                break;
            }
            case 'LIST_READ_ONLY': {
                this.message = `To be able to edit the data, change the list status back to 'Active'`;
                break;
            }
            default: {
                return;
            }
        }
    }

    action() {
        if (this.btnAction) {
            this.btnAction();
        }
    }

    toggleMinimise() {
        this.alertHeaderService.toggleMinimised();
    }
}

export type InfoAlertType =
    | 'EXISTING_PLAN_AS_ADMIN'
    | 'EXISTING_PLAN_AS_USER'
    | 'EDITING_LIST_TEMPLATE'
    | 'LIST_READ_ONLY'
    | 'VIEWING_LIST_TEMPLATE';
