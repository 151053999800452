import head from 'lodash/head';
import isArray from 'lodash/isArray';
import isBoolean from 'lodash/isBoolean';
import startCase from 'lodash/startCase';
import toString from 'lodash/toString';
import { Audited } from './audited';
import { SelectOption } from './select-option';

export interface Pdf extends Audited {
    id?: string;
    url: string;
    thumbUrl?: string;
    filename: string;
    directory: string;
    path: string;
}

export function isSavedPdf(pdf: Pdf): boolean {
    return !isUnsavedPdf(pdf);
}

export function isUnsavedPdf(pdf: Pdf): boolean {
    return !!pdf && isBase64Pdf(pdf);
}

export function isBase64Pdf(pdf: Pdf): boolean {
    return pdf?.url?.indexOf('data:application/pdf') !== -1;
}

export type PdfFormData = Record<string, string | number | boolean | string[]>;

export type PdfRawFormFields = Array<PdfRawFormField>;

export interface PdfRawFormField {
    type: 'text' | 'checkbox' | 'select' | 'radio';
    fieldName: string;
    fieldLabel: string;
    multiLine: boolean;
    multiSelect?: boolean;
    options?: SelectOption[];
}

export function getPdfFormField(formFields: PdfRawFormFields, fieldName: string) {
    return formFields.find((field) => field.fieldName === fieldName);
}

export function hasPdfFormField(formFields: PdfRawFormFields, fieldName: string) {
    return !!getPdfFormField(formFields, fieldName);
}

export function getStringFormFieldValue(formField: PdfRawFormField, formData: PdfFormData): string {
    const fieldValue = formData?.[formField.fieldName];
    if (formField.type === 'checkbox') {
        const values = [];
        const options = formField.options || [];
        // if there are multiple options print the names comma separated
        if (options.length > 1) {
            for (const option of formField.options || []) {
                const optionValue = formData?.[option.id];
                if (optionValue === true || optionValue === 'Yes') {
                    values.push(option.name);
                }
            }
        } else {
            const option = head(options) as SelectOption;
            const optionValue = formData?.[option.id];
            values.push(optionValue === true || optionValue === 'Yes' ? 'Yes' : 'No');
        }
        return values.join(', ');
    } else if (formField.type === 'select' && isArray(fieldValue) && formField.multiSelect) {
        return fieldValue.join(', ');
    } else if (isBoolean(fieldValue)) {
        return fieldValue ? 'Yes' : 'No';
    } else {
        return toString(fieldValue);
    }
}

export function parsePdfRawFormFields(rawFormData: Array<any>): PdfRawFormFields {
    const pdfRawFormFields: PdfRawFormFields = [];

    for (const field of rawFormData) {
        const rawFormField = field.fieldAnnotation;
        if (isSupportedFormFieldType(rawFormField)) {
            const rawFormFieldType = parsePdfRawFormFieldType(rawFormField);

            if (!rawFormFieldType) {
                continue;
            }

            const options = (rawFormField.options || []).map(
                (option: { exportValue: string; displayValue: string }) => {
                    return {
                        id: option.exportValue,
                        name: option.displayValue,
                    };
                }
            );

            // radios need special handling as each option is included as a separate field
            // here we flatten them into a single set of options
            if (rawFormFieldType === 'radio') {
                const radioField = pdfRawFormFields.find(
                    (pdfRawFormField) => pdfRawFormField.fieldName === rawFormField.fieldName
                );

                // field already exists, add to the available options
                const radioOption: SelectOption = {
                    id: rawFormField.buttonValue,
                    name: startCase(rawFormField.buttonValue),
                };
                if (radioField?.options) {
                    radioField.options.push(radioOption);
                    continue;
                } else {
                    options.push(radioOption);
                }
            }

            // checkboxes need special handling as each option is included as a separate field
            // here we flatten them into a single set of options
            if (rawFormFieldType === 'checkbox') {
                const checkboxField = pdfRawFormFields.find(
                    (pdfRawFormField) => pdfRawFormField.fieldLabel === rawFormField.alternativeText
                );

                // field already exists, add to the available options
                const checkboxOption: SelectOption = {
                    id: rawFormField.fieldName,
                    name: startCase(rawFormField.fieldName),
                };
                if (checkboxField?.options) {
                    checkboxField.options.push(checkboxOption);
                    continue;
                } else {
                    options.push(checkboxOption);
                }
            }

            pdfRawFormFields.push({
                type: rawFormFieldType,
                fieldName: rawFormField.fieldName,
                fieldLabel: rawFormField.alternativeText || startCase(rawFormField.fieldName),
                multiLine: rawFormField.multiLine || false,
                multiSelect: rawFormField.multiSelect || false,
                options,
            });
        }
    }

    return pdfRawFormFields;
}

export function parsePdfRawFormFieldType(rawFormField: any): PdfRawFormField['type'] | null {
    const { fieldType } = rawFormField;
    if (fieldType === 'Tx') {
        return 'text';
    } else if (fieldType === 'Ch') {
        return 'select';
    } else if (fieldType === 'Btn' && rawFormField.radioButton) {
        return 'radio';
    } else if (
        fieldType === 'Btn' &&
        !rawFormField.pushButton &&
        rawFormField.alternativeText?.length
    ) {
        return 'checkbox';
    } else {
        return null;
    }
}

export function isSupportedFormFieldType(fieldType: string) {
    return !!parsePdfRawFormFieldType(fieldType);
}
