import { AuthTenants } from './auth-tenant';
import { LeadMagnets } from './lead-magnet';
import { PlanType } from './plan';

export interface SystemPrivateConfig {
    apolloConfig: ApolloConfig;
    authTenants: AuthTenants;
    leadMagnets: LeadMagnets;
    paddleConfig: PaddleConfig;
    hubSpotConfig: HubSpotConfig;
    internalEmailConfig: InternalEmailConfig;
    emailBlacklist: EmailBlacklistConfig;
    legacyInsiteMigrationOffer?: LegacyInsiteMigrationOffer;
    legacyInsiteFeatureFlags?: LegacyInsiteFeatureFlags;
    legacyInsiteFeatureConfig?: LegacyInsiteFeatureConfig;
}

export interface LegacyInsiteMigrationOffer {
    productIds: string[];
    discountPercentage: number;
    recurring: boolean;
    expiryDays?: number;
}

export interface LegacyInsiteFeatureFlags {
    migrationOfferEnabled: boolean;
    bookDemoEnabled: boolean;
}

export interface LegacyInsiteFeatureConfig {
    bookDemoMeetingLink: string;
}

export type PaddlePlansConfig = Record<PlanType, string[]>;

export interface PaddleConfig {
    publicKey: string;
    vendorId: string;
    apiHost: string;
    checkoutApiHost: string;
}

export interface HubSpotConfig {
    utmSourceDealSourceMapping: Record<string, string>;
    trialExpiredInActiveDiscussionTaskOwnerId: string;
}

export const UTM_SOURCE_DEAL_SOURCE_MAPPING = {
    OLD_APP: 'old_app',
};

export const hubSpotConfig: HubSpotConfig = {
    utmSourceDealSourceMapping: UTM_SOURCE_DEAL_SOURCE_MAPPING,
    trialExpiredInActiveDiscussionTaskOwnerId: '383836225', // dean,
};

export interface ApolloConfig {
    apiBaseUrl: string;
    lowAdoptersSequence: ApolloSequenceConfig;
    mediumAdoptersSequence: ApolloSequenceConfig;
    orphanedUserSequence: ApolloSequenceConfig;
    unverifiedEmailSequence: ApolloSequenceConfig;
    nonOrphanedUserUnverifiedEmailSequence: ApolloSequenceConfig;
}

export interface ApolloSequenceConfig {
    name: string;
    sequenceId: string;
    contactStageId: string;
    sendEmailFromEmailAccountId: string;
}

export interface EmailBlacklistConfig {
    blacklist: string[];
}

export const APOLLO_EMAIL_FROM_ACCOUNT_ID = '643d150815268600a3aba46c';

export const APOLLO_COLD_CONTACT_STAGE_ID = '60d3745cc1f602011262baa6';

export const apolloConfig: ApolloConfig = {
    apiBaseUrl: 'https://api.apollo.io/v1',
    lowAdoptersSequence: {
        name: 'Trial expired: Low adopters follow up',
        sequenceId: '60e6d06eb7385800dada95cf',
        contactStageId: APOLLO_COLD_CONTACT_STAGE_ID,
        sendEmailFromEmailAccountId: APOLLO_EMAIL_FROM_ACCOUNT_ID,
    },
    mediumAdoptersSequence: {
        name: 'Trial expired: Medium adopters follow up',
        sequenceId: '60f154361add93008cdd7741',
        contactStageId: APOLLO_COLD_CONTACT_STAGE_ID,
        sendEmailFromEmailAccountId: APOLLO_EMAIL_FROM_ACCOUNT_ID,
    },
    orphanedUserSequence: {
        name: 'Orphan users: Immediate follow up',
        sequenceId: '61016506af0a2800dad743d2',
        contactStageId: APOLLO_COLD_CONTACT_STAGE_ID,
        sendEmailFromEmailAccountId: APOLLO_EMAIL_FROM_ACCOUNT_ID,
    },
    unverifiedEmailSequence: {
        name: 'Orphan users: Unverified email follow up',
        sequenceId: '6123aa91807be2014c3320f9',
        contactStageId: APOLLO_COLD_CONTACT_STAGE_ID,
        sendEmailFromEmailAccountId: APOLLO_EMAIL_FROM_ACCOUNT_ID,
    },
    nonOrphanedUserUnverifiedEmailSequence: {
        name: 'Active user: Unverified email follow up',
        sequenceId: '637f566b03d5d600a3293e88',
        contactStageId: APOLLO_COLD_CONTACT_STAGE_ID,
        sendEmailFromEmailAccountId: APOLLO_EMAIL_FROM_ACCOUNT_ID,
    },
};

export type InternalEmail =
    | 'newUser'
    | 'cardlessTrial'
    | 'newUserNotAutoAddedToPlan'
    | 'newUsersAddedToSequences'
    | 'leadMagnetDownload'
    | 'postmarkEvent'
    | 'insiteProjectMigration'
    | 'meteredBillingReport'
    | 'userAddedPhoneNumber'
    | 'accountDeleted'
    | 'contactForm'
    | 'enquiry'
    | 'trialsExpired'
    | 'blockedSignUp'
    | 'legacyAppMigrationOffer';

export type InternalEmailConfig = Record<InternalEmail, string[]>;
