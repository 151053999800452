import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';

@Directive({
    selector: '[libRemoveTabIndex]',
})
export class RemoveTabIndexDirective implements OnInit, OnDestroy {
    observer: MutationObserver;

    constructor(private el: ElementRef) {}

    ngOnInit() {
        const observerOptions = {
            childList: true,
            attributes: true,
        };
        this.observer = new MutationObserver(this.callback.bind(this));
        this.observer.observe(this.el.nativeElement, observerOptions);
    }

    private callback(mutationList) {
        mutationList
            .filter((mutation) => mutation.type === 'childList')
            .forEach(() => {
                const clearButton = this.el.nativeElement.children[1];
                if (clearButton) {
                    clearButton.setAttribute('tabIndex', '-1');
                }
            });
    }

    ngOnDestroy() {
        if (this.observer) {
            this.observer.disconnect();
        }
    }
}
