import { Injectable } from '@angular/core';
import { toPromise } from '@insite-group-ltd/insite-teams-shared';
import { PaddleService } from '../../services/paddle/paddle.service';
import { PlanOptionQuery } from './plan-option.query';

@Injectable({ providedIn: 'root' })
export class PlanOptionService {
    constructor(private planOptionQuery: PlanOptionQuery, private paddleService: PaddleService) {}

    async checkout(
        company: string,
        existingPlanId: string,
        loadCallback: (loading: boolean) => void,
        successCallback: (data) => void,
        closeCallback?: () => void
    ) {
        const selectedPlanOption = await toPromise(this.planOptionQuery.selectedPlanOption$);
        return this.paddleService.checkout(
            this.planOptionQuery.planType$,
            this.planOptionQuery.quantity$,
            this.planOptionQuery.billingInterval$,
            company,
            existingPlanId,
            selectedPlanOption,
            loadCallback,
            successCallback,
            closeCallback,
            this.planOptionQuery.coupon
        );
    }
}
