import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { ResizeObserver } from '@juggle/resize-observer';
import { BehaviorSubject, Observable } from 'rxjs';
import { auditTime, map } from 'rxjs/operators';
import { SubSink } from 'subsink';

export type WindowSizeOption = keyof WindowSize & ('smallSize' | 'tabletSize' | 'desktopSize');

export class WindowSize {
    readonly height: number;
    readonly width: number;
    public readonly desktopSize: boolean;
    public readonly tabletSize: boolean;
    public readonly smallSize: boolean;
    public readonly isMobile: boolean;

    constructor(height: number, width: number) {
        this.height = height;
        this.width = width;
        this.desktopSize = this.width >= 992;
        this.tabletSize = this.width >= 768;
        this.smallSize = this.width >= 576;
        this.isMobile = !this.tabletSize;
    }
}

@Injectable({
    providedIn: 'root',
})
export class WindowService implements OnDestroy {
    private readonly windowSizeChanged = new BehaviorSubject<WindowSize>(
        new WindowSize(document.body.clientHeight, document.body.clientWidth)
    );
    private subs = new SubSink();
    public windowSize$ = this.windowSizeChanged.asObservable();

    constructor(private zone: NgZone) {
        this.subs.sink = this.resizeObservable(document.body)
            .pipe(
                auditTime(200),
                map(() => new WindowSize(window.innerHeight, window.innerWidth))
            )
            .subscribe((val) => {
                this.zone.run(() => {
                    this.windowSizeChanged.next(val);
                });
            });
    }

    get currentWindowSize(): WindowSize {
        return this.windowSizeChanged.value;
    }

    private resizeObservable(elem: any) {
        return new Observable((subscriber) => {
            const ro = new ResizeObserver((entries) => {
                subscriber.next(entries);
            });
            ro.observe(elem);
            return function unsubscribe() {
                ro.unobserve(elem);
            };
        });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
