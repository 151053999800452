import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { PlanQuery } from '../state/plan/plan.query';

@Injectable({
    providedIn: 'root',
})
export class HasActivePlansAsAdminGuard implements CanActivate {
    constructor(private planQuery: PlanQuery) {}

    canActivate(): Observable<boolean | UrlTree> {
        const TRUE = true;
        return this.planQuery.currentUserNotDeletedPlansAsAdmin$.pipe(
            filter((plans) => plans?.length > 0),
            map(() => TRUE)
        );
    }
}
