import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import {
    DictionaryEntriesByAvailability,
    DictionaryPhrase,
    EMPTY_DICTIONARY_ENTRIES_BY_AVAILABILITY,
    sortByAlpha,
} from '@insite-group-ltd/insite-teams-model';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { FeatureService } from '../../../services/feature/feature.service';
import {
    PlanDictionaryPhrasesState,
    PlanDictionaryPhrasesStore,
} from './plan-dictionary-phrases.store';

@Injectable({ providedIn: 'root' })
export class PlanDictionaryPhrasesQuery extends QueryEntity<PlanDictionaryPhrasesState> {
    constructor(
        protected store: PlanDictionaryPhrasesStore,
        private featureService: FeatureService
    ) {
        super(store);
    }

    getDictionaryPhrases$(planId: string): Observable<DictionaryPhrase[]> {
        return this.selectAll({
            filterBy: (phrase) => phrase.planId === planId,
            sortBy: (a, b) => sortByAlpha(a.phrase, b.phrase),
        });
    }

    getDictionaryEntriesByAvailability$(
        planId: string
    ): Observable<DictionaryEntriesByAvailability> {
        return this.featureService.canAccessFeature$('dictionaryPhrases').pipe(
            switchMap((canAccessDictionaryPhrases) => {
                if (canAccessDictionaryPhrases) {
                    return this.getDictionaryPhrases$(planId).pipe(
                        map((phrases) => this.mapDictionaryEntriesByAvailability(phrases))
                    );
                } else {
                    return of(EMPTY_DICTIONARY_ENTRIES_BY_AVAILABILITY);
                }
            })
        );
    }

    private mapDictionaryEntriesByAvailability(phrases: DictionaryPhrase[]) {
        return {
            ALL: phrases,
            TITLE: phrases?.filter((phrase) => phrase.title),
            DESCRIPTION: phrases?.filter((phrase) => phrase.description),
            INACTIVE: phrases?.filter((phrase) => !phrase.title && !phrase.description),
        };
    }
}
