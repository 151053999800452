import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TippyModule } from '@ngneat/helipopper';
import { NgStringPipesModule } from 'ngx-pipes';
import { SharedDirectivesModule } from '../../directives/shared-directives.module';
import { SharedPipesModule } from '../../pipes/shared-pipes.module';
import { SharedComponentsModule } from '../shared-components.module';
import { StatusIndicatorModule } from '../status-indicator/status-indicator.module';
import { AddOrInviteProjectUsers } from './add-or-invite-project-users.page';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        SharedDirectivesModule,
        SharedComponentsModule,
        NgStringPipesModule,
        SharedPipesModule,
        ReactiveFormsModule,
        TippyModule,
        StatusIndicatorModule,
    ],
    declarations: [AddOrInviteProjectUsers],
    exports: [AddOrInviteProjectUsers],
})
export class AddOrInviteProjectUsersModule {}
