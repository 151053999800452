import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PlanOptionQuery, PlanOptionStore } from '../../state/plan-option';

@Component({
    selector: 'app-plan-quantity',
    templateUrl: './plan-quantity.component.html',
    styleUrls: ['./plan-quantity.component.scss'],
})
export class PlanQuantityComponent implements OnInit {
    @Input() minQuantity = 1;
    @Input() maxQuantity?: number;
    @Input() sizeSmall: boolean;
    @Input() quantityUnit: string;
    @Output() quantityError = new EventEmitter<boolean>();
    quantity$ = this.planOptionQuery.quantity$;

    constructor(
        private planOptionQuery: PlanOptionQuery,
        private planOptionStore: PlanOptionStore
    ) {}

    ngOnInit() {
        if (this.minQuantity < 1) {
            this.minQuantity = 1;
        }
        this.planOptionStore.incrementQuantityToMinimum(this.minQuantity);
    }

    decrement() {
        const { quantity } = this.planOptionStore.getValue();
        if (quantity > this.minQuantity && quantity > 1) {
            this.planOptionStore.decrementQuantity();
            this.success();
        } else {
            this.error();
        }
    }

    increment() {
        const { quantity } = this.planOptionStore.getValue();
        if (!this.maxQuantity || quantity < this.maxQuantity) {
            this.planOptionStore.incrementQuantity();
            this.success();
        }
    }

    private error() {
        this.quantityError.emit(true);
    }

    private success() {
        this.quantityError.emit(false);
    }
}
