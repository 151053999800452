import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Geolocation } from '@capacitor/geolocation';
import { GpsCoordinates } from '@insite-group-ltd/insite-teams-model';
import pTimeout from 'p-timeout';
import { InsiteUserPrivateQuery } from '../../state/user/insite-user-private.query';
import { toPromise } from '../../utils/pipe';
import { LoggerService } from '../logger/logger.service';
import { SentryService } from '../sentry/sentry.service';
import { UtilService } from '../util/util.service';

@Injectable({
    providedIn: 'root',
})
export class GpsService {
    constructor(
        private loggerService: LoggerService,
        private sentryService: SentryService,
        private insiteUserPrivateQuery: InsiteUserPrivateQuery,
        private utilService: UtilService
    ) {}

    public async getGpsCoordinates(): Promise<GpsCoordinates | null> {
        if (Capacitor.isNativePlatform() && Capacitor.isPluginAvailable('Geolocation')) {
            let timeout: number;
            try {
                if (await this.hasLocationPermission()) {
                    const gpsCoordinatesHighAccuracy = await toPromise(
                        this.insiteUserPrivateQuery.imageGpsCoordinatesHighAccuracy$
                    );
                    this.loggerService.error(
                        'gps-service',
                        'capturing gps coordinates with high accuracy: ' +
                            gpsCoordinatesHighAccuracy
                    );
                    timeout = gpsCoordinatesHighAccuracy ? 20_000 : 5_000;
                    const coordinates = await pTimeout(
                        Geolocation.getCurrentPosition({
                            enableHighAccuracy: gpsCoordinatesHighAccuracy,
                        }),
                        {
                            milliseconds: timeout,
                        }
                    );
                    if (coordinates) {
                        return {
                            latitude: coordinates.coords.latitude,
                            longitude: coordinates.coords.longitude,
                        };
                    }
                }
                return null;
            } catch (err) {
                const { message } = err;
                this.loggerService.error('gps-service', err.message);
                // don't report to sentry if timeout or location is disabled on device
                if (
                    !message?.startsWith('Promise timed out') &&
                    !message?.includes('location disabled')
                ) {
                    this.sentryService.captureException(err);
                }
                if (message?.startsWith('Promise timed out')) {
                    this.utilService.errorToast(
                        `Failed to capture image gps location within ${timeout / 1000} second limit`
                    );
                }
                return null;
            }
        }
    }

    private async hasLocationPermission(): Promise<boolean> {
        let permissions = await Geolocation.checkPermissions();
        if (permissions.location === 'prompt' || permissions.location === 'prompt-with-rationale') {
            permissions = await Geolocation.requestPermissions({
                permissions: ['location'],
            });
        }
        return permissions.location === 'granted';
    }
}
