import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableAkitaProdMode } from '@datorama/akita';
import { init as sentryAngularInit } from '@sentry/angular-ivy';
import * as Sentry from '@sentry/capacitor';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

Sentry.init(
    {
        enabled: environment.production,
        dsn: 'https://e9b332bd911744f2b6b6d692ceec23e4@o213828.ingest.sentry.io/5514826',
        environment: environment.env,
        release: environment.version,
    },
    sentryAngularInit
);

if (environment.production) {
    enableProdMode();
    enableAkitaProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    // eslint-disable-next-line no-console
    .catch((err) => console.log(err));
