import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileOpener } from '@capacitor-community/file-opener';
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem, StatOptions } from '@capacitor/filesystem';
import {
    InsiteFileVersion,
    UnsavedInsiteFileVersion,
    isSavedFileVersion,
} from '@insite-group-ltd/insite-teams-model';
import * as fileExtension from 'file-extension';
import saveAs from 'file-saver';
import { nanoid } from 'nanoid';
import { firstValueFrom } from 'rxjs';
import { SystemQuery } from '../../state/system/system.query';
import { toPromise } from '../../utils/pipe';
import { AnalyticsService } from '../analytics/analytics.service';
import { AuditedService } from '../audited/audited.service';
import { FeatureService } from '../feature/feature.service';
import { LoggerService } from '../logger/logger.service';
import { SentryService } from '../sentry/sentry.service';
import { UtilService } from '../util/util.service';

const LOGGER_NAME = 'file-service';

export type ImportValue = {
    value: string;
};

@Injectable({
    providedIn: 'root',
})
export class FileService {
    constructor(
        private analyticsService: AnalyticsService,
        private sentryService: SentryService,
        private httpClient: HttpClient,
        private logger: LoggerService,
        private auditedService: AuditedService,
        private featureService: FeatureService,
        private utilService: UtilService,
        private systemQuery: SystemQuery
    ) {}

    async fileListToInsiteFiles(files: FileList): Promise<UnsavedInsiteFileVersion[]> {
        const insiteFileVersions: UnsavedInsiteFileVersion[] = [];
        if (files.length > 0) {
            const audited = await this.auditedService.getAudited();
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const dataUrl = await this.readFileAsDataUrl(files[i]);
                if (dataUrl) {
                    insiteFileVersions.push({
                        id: nanoid(),
                        url: dataUrl,
                        originalFilename: file.name,
                        extension: fileExtension(file.name),
                        size: file.size,
                        type: file.type,
                        formData: {},
                        formFields: [],
                        ...audited,
                    });
                }
            }
        }
        return insiteFileVersions;
    }

    async filesToDataUrls(files: FileList) {
        const fileUrls = [];
        if (files.length > 0) {
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < files.length; i++) {
                const dataUrl = await this.readFileAsDataUrl(files[i]);
                if (dataUrl) {
                    fileUrls.push(dataUrl);
                }
            }
            this.analyticsService.event('file_to_data_url', { fileCount: files.length });
        }
        return fileUrls;
    }

    public readFileAsDataUrl(inputFile: File | Blob): Promise<string> {
        let temporaryFileReader = new FileReader();
        // workaround for https://github.com/ionic-team/ionic-native/issues/505
        if (Capacitor.isNativePlatform() && inputFile instanceof Blob) {
            const realFileReader = (temporaryFileReader as any)._realReader;
            if (realFileReader) {
                temporaryFileReader = realFileReader;
            }
        }
        return new Promise((resolve, reject) => {
            temporaryFileReader.onerror = (err) => {
                this.handleFileReaderError(temporaryFileReader, reject, err);
            };
            temporaryFileReader.onload = () => {
                resolve(temporaryFileReader.result as string);
            };
            temporaryFileReader.readAsDataURL(inputFile);
        });
    }

    async filesToContents(files: FileList): Promise<string[]> {
        const fileUrls: string[] = [];
        if (files.length > 0) {
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < files.length; i++) {
                const dataUrl = await this.readFileAsContent(files[i]);
                if (dataUrl) {
                    fileUrls.push(dataUrl);
                }
            }
            this.analyticsService.event('file_to_contents', { fileCount: files.length });
        }
        return fileUrls;
    }

    public readFileAsContent(inputFile): Promise<string> {
        const temporaryFileReader = new FileReader();
        return new Promise((resolve, reject) => {
            temporaryFileReader.onerror = (err) => {
                this.handleFileReaderError(temporaryFileReader, reject, err);
            };
            temporaryFileReader.onload = () => {
                resolve(temporaryFileReader.result as string);
            };
            temporaryFileReader.readAsText(inputFile, 'UTF-8');
        });
    }

    public async downloadFile(
        url: string,
        directory: Directory,
        path: string,
        forceDownload = false
    ): Promise<string> {
        const fileOptions = {
            directory,
            path,
        };
        const targetPath = await Filesystem.getUri(fileOptions);
        const fileExists = await this.fileExists(fileOptions);
        if (forceDownload && fileExists) {
            await this.deleteFile(path, directory);
        }
        if (forceDownload || !fileExists) {
            this.makeDirectory(directory, path.substring(0, path.lastIndexOf('/')));
            this.logger.info(LOGGER_NAME, `file doesn't exists, downloading...`);
            await Filesystem.downloadFile({
                url,
                directory: directory,
                path: path,
                method: 'GET',
            });
        }
        return targetPath.uri;
    }

    public async copyFile(
        fromPath: string,
        toPath: string,
        fromDirectory?: Directory,
        toDirectory?: Directory
    ): Promise<string> {
        const fileExists = await this.fileExists({
            path: toPath,
            directory: toDirectory,
        });
        if (fileExists) {
            await this.deleteFile(toPath, toDirectory);
        }
        await Filesystem.copy({
            directory: fromDirectory,
            toDirectory,
            from: fromPath,
            to: toPath,
        });
        return (
            await Filesystem.getUri({
                directory: toDirectory,
                path: toPath,
            })
        ).uri;
    }

    public async writeFile(data: string, directory: Directory, path: string): Promise<string> {
        const fileOptions = {
            directory,
            path,
            data,
            recursive: true,
        };
        const fileExists = await this.fileExists(fileOptions);
        if (fileExists) {
            await this.deleteFile(path, directory);
        }
        const result = await Filesystem.writeFile(fileOptions);
        return result.uri;
    }

    public openInsiteFile(insiteFileVersion: InsiteFileVersion) {
        if (Capacitor.isNativePlatform()) {
            //TODO
            return;
        } else if (isSavedFileVersion(insiteFileVersion)) {
            window.open(insiteFileVersion.url, '_blank');
        } else {
            saveAs(insiteFileVersion.url, insiteFileVersion.originalFilename);
        }
    }

    public async openFile(directory: Directory, path: string, mimeType: string) {
        const fileOptions = {
            directory,
            path,
        };
        const targetPath = await Filesystem.getUri(fileOptions);
        FileOpener.open({
            filePath: targetPath.uri,
            contentType: mimeType,
        });
    }

    public async deleteFile(path: string, directory?: Directory) {
        const fileOptions = {
            directory,
            path,
        };
        await Filesystem.deleteFile(fileOptions);
    }

    public async fileToBase64(url: string): Promise<string> {
        const blob = await toPromise(this.httpClient.get(url, { responseType: 'blob' }));
        return this.readFileAsDataUrl(blob);
    }

    public async makeDirectory(directory: Directory, path: string) {
        try {
            this.logger.info(LOGGER_NAME, `making directory: ${directory}/${path}`);
            await Filesystem.mkdir({
                directory,
                path,
                recursive: true,
            });
        } catch (err) {
            if (err.message !== 'Directory exists') {
                this.logger.info(LOGGER_NAME, `error making directory: ${err.message}`);
                throw err;
            } else {
                this.logger.info(LOGGER_NAME, `directory exists: ${directory}/${path}`);
            }
        }
    }

    public async validateFiles(files: FileList): Promise<boolean> {
        const maxFileUploadSizeInMB = await this.featureService.getFeatureConfig(
            'maxFileUploadSizeInMB'
        );
        const fileBlacklistPattern = new RegExp(
            `^${await firstValueFrom(this.systemQuery.fileBlacklistPattern)}$`
        );
        const filesArray = Array.from(files);
        for (const file of filesArray) {
            if (file.size > maxFileUploadSizeInMB * 1024 * 1024) {
                await this.utilService.messageAlert(
                    'Maximum size exceeded!',
                    `Files can have a maximum size of ${maxFileUploadSizeInMB}MB.<br><br><a target="_blank" href="https://insiteapp.zendesk.com/hc/en-gb/requests/new">Contact us</a> to discuss increasing this limit.`
                );
                return false;
            }
            if (fileBlacklistPattern.test(file.name)) {
                await this.utilService.messageAlert(
                    'Permission denied!',
                    `.${fileExtension(file.name)} files are not supported`
                );
                return false;
            }
        }
        return true;
    }

    private handleFileReaderError(
        temporaryFileReader: FileReader,
        reject: any,
        err: ProgressEvent<FileReader>
    ) {
        this.sentryService.captureException(err);
        temporaryFileReader.abort();
        reject(new DOMException('Problem parsing input file.'));
    }

    private async fileExists(statOptions: StatOptions): Promise<boolean> {
        try {
            await Filesystem.stat(statOptions);
            this.logger.info(
                LOGGER_NAME,
                `file found at path ${statOptions.directory}/${statOptions.path}`
            );
            return true;
        } catch (e) {
            this.logger.info(
                LOGGER_NAME,
                `file not found at path ${statOptions.directory}/${statOptions.path}`
            );
            return false;
        }
    }
}
