import { NgModule } from '@angular/core';
import { ClickStopPropagationDirective } from './click-stop-propagation/click-stop-propagation.directive';
import { DropzoneDirective } from './dropzone/dropzone.directive';
import { OpenUrlDirective } from './open-url/open-url.directive';
import { PasswordPeepDirective } from './password-peep/password-peep.directive';
import { RemoveIoniconTitleDirective } from './remove-ionicon-title/remove-ionicon-title.directive';
import { RemoveTabIndexDirective } from './remove-tab-index/remove-tab-index.directive';
import { TextareaAutogrowDirective } from './textarea-autogrow/textarea-autogrow.directive';
import { TextAreaClearIconDirective } from './textarea-clear-icon/textarea-clear-icon.directive';

@NgModule({
    declarations: [
        RemoveTabIndexDirective,
        DropzoneDirective,
        OpenUrlDirective,
        RemoveIoniconTitleDirective,
        ClickStopPropagationDirective,
        TextareaAutogrowDirective,
        TextAreaClearIconDirective,
        PasswordPeepDirective,
    ],
    exports: [
        RemoveTabIndexDirective,
        DropzoneDirective,
        OpenUrlDirective,
        RemoveIoniconTitleDirective,
        ClickStopPropagationDirective,
        TextareaAutogrowDirective,
        TextAreaClearIconDirective,
        PasswordPeepDirective,
    ],
})
export class SharedDirectivesModule {}
