import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProjectQuery } from '../state/projects/project.query';

@Injectable({
    providedIn: 'root',
})
export class ProjectNotArchivedGuard implements CanActivate {
    constructor(private projectQuery: ProjectQuery, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
        const projectId = route.paramMap.get('projectId');
        return this.projectQuery.isProjectActive$(projectId).pipe(
            map((isActive) => {
                if (isActive) {
                    return true;
                } else {
                    return this.router.createUrlTree(['/app', 'projects', projectId, 'manage']);
                }
            })
        );
    }
}
