<ng-container *ngIf="{
    options: options$ | async
} as state">
    <div>
        <div class="ion-text-center ion-padding-vertical">
            <ion-text><h4 class="ion-no-margin">{{title}}</h4></ion-text>
        </div>
        <ion-item class="rounded-item white round-no-label ion-no-padding ion-margin-horizontal">
            <ion-input data-testid="select.popover.search"
                       [formControl]="searchControl"
                       clear-input="true"
                       mode="md"
                       name="title"
                       [autocapitalize]="autoCapitalizeInput"
                       #filterInput
                       type="text"
                       [placeholder]="allowAdd ? 'Filter or add new' : 'Filter'"></ion-input>
        </ion-item>
        <ion-item lines="none">
            <ion-label color="admin-dark" class="font-weight-500 ml-6">
                Choose
                <span *ngIf="allowAdd">existing </span>
                ({{state.options.length}} {{'option' | makePluralString: state.options.length}})
            </ion-label>
        </ion-item>
    </div>

    <ion-list *ngIf="state.options?.length; else noOptions"
              [ngStyle]="{'height': 'calc(2px + (44px * ' + state.options.length + '))'}"
              class="overflow-scroll-y mb-0">
        <ion-radio-group [(ngModel)]="valueId">
            <virtual-scroller #scroll [items]="state.options" [compareItems]="compareById">
                <ion-item *ngFor="let item of scroll.viewPortItems" class="border-999 option checkbox-item" mode="md" data-testid="select.popover.option">
                    <ion-label data-testid="select.popover.option.label">{{item.name}}</ion-label>
                    <ion-radio class="ion-margin-end" slot="start" [value]="item.id"></ion-radio>
                </ion-item>
            </virtual-scroller>
        </ion-radio-group>
    </ion-list>

    <ng-template #noOptions>
        <div *ngIf="!searchControl.value" class="ion-padding-bottom ion-padding-horizontal">
            <ion-label color="admin-dark">
                <p class="ml-16 mr-16 mb-8">
                    There are no {{entityName}}s set up for this {{scope}} yet.
                    <ng-container *ngIf="allowAdd">Start typing above to add one.</ng-container>
                </p>
            </ion-label>
        </div>
    </ng-template>

    <div *ngIf="searchControl.value">
        <div class="ion-padding-horizontal" *ngIf="state.options.length === 0">
            <ion-label class="ion-text-left" color="admin-dark">
                <p class="ml-16 mr-16 mt-6 mb-14">
                    No <span *ngIf="allowAdd">existing </span>matches.
                </p>
            </ion-label>
        </div>
        <ng-container *ngIf="allowAdd">
            <ion-item lines="none">
                <ion-label color="admin-dark" class="font-weight-500 ml-6">Create new</ion-label>
            </ion-item>
            <ion-button data-testid="select.popover.create" 
                        (click)="add()"
                        class="button-border-2 hover-border-solid button-38 mr-20 ml-20 mb-20 mt-0 flex-shrink-0"
                        color="tertiary"
                        expand="block">
                <ion-text class="width-100 overflow-ellipsis line-height-22">
                    Create {{entityName}}: {{searchControl.value}}</ion-text>
            </ion-button>
        </ng-container>
    </div>

    <div class="display-flex border-top-light-med pt-4 pb-4 pr-14">
        <div class="margin-left-auto">
            <ion-button (click)="cancel()" fill="clear" class="mr-8 button-34 button-padding-20" data-testid="select.popover.cancel">
                Cancel
            </ion-button>
            <ion-button data-testid="select.popover.done" (click)="finish()" class="ml-6 button-34 button-padding-20">Done</ion-button>
        </div>
    </div>
</ng-container>
