import { Audited } from './audited';
import { WithComments } from './comments';
import { WithGpsCoordinates } from './gps';
import { isCapacitorUrl } from './url';

export interface Image extends Audited, WithComments, WithGpsCoordinates {
    id: string;
    url: string;
    thumbUrl?: string;
    filename?: string;
    directory?: string;
    primary?: boolean;
    fromTemplate?: boolean;
    takenDate?: any;
}

export function isSavedImage(image: Image): boolean {
    return !isUnsavedImage(image);
}

export function isUnsavedImage(image: Image | undefined): boolean {
    return !!image && isBase64Image(image);
}

export function isBase64Image(image: Image): boolean {
    return image?.url?.indexOf('data:image') !== -1;
}

export function isLocalImage(image: Image): boolean {
    return isCapacitorUrl(image?.url);
}

export function wasLocalImageUploaded(before: Image, after: Image): boolean {
    return isLocalImage(before) && !isLocalImage(after);
}

export function getImageUrl(image: Image): string {
    return image?.url;
}

export class ImageDataUrl {
    private constructor(private url: string) {}

    static fromBase64WithFilePath(base64: string, filePath: string) {
        return new ImageDataUrl(`data:image/${filePath.split('.').pop()};base64,${base64}`);
    }

    static fromBase64(base64: string, mimeType = 'image/jpeg') {
        return new ImageDataUrl(`data:${mimeType};base64,${base64}`);
    }

    static fromDataUrl(dataUrl: string) {
        return new ImageDataUrl(dataUrl);
    }

    get base64(): string {
        return this.url.replace(/^data:image\/(.*);base64,/, '');
    }

    get dataUrl() {
        return this.url;
    }
}
