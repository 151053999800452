import { Pipe, PipeTransform } from '@angular/core';
import { Image, isLocalImage } from '@insite-group-ltd/insite-teams-model';

@Pipe({ name: 'isLocalImage' })
export class IsLocalImage implements PipeTransform {
    transform(image: Image): boolean {
        if (!image) {
            return false;
        } else {
            return isLocalImage(image);
        }
    }
}
