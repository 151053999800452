import { Injectable, OnDestroy } from '@angular/core';
import {
    Transaction,
    arrayRemove,
    arrayUnion,
    query,
    serverTimestamp,
    where,
} from '@angular/fire/firestore';
import { getEntityType } from '@datorama/akita';
import {
    SubscriptionChangeQuantityDto,
    SubscriptionUpgradeDto,
} from '@insite-group-ltd/insite-teams-api-model';
import {
    Audited,
    EMAIL_PATTERN,
    Plan,
    PlanRole,
    UserTrialState,
    getInsiteUserName,
    getPlanMemberCount,
    hasReachedMaxPlanMemberLimit,
} from '@insite-group-ltd/insite-teams-model';
import { asArray } from '@insite-group-ltd/utils';
import { CollectionConfig, NoPathParams } from 'akita-ng-fire';
import { AtomicWrite } from 'akita-ng-fire/lib/utils/types';
import cloneDeep from 'lodash/cloneDeep';
import isString from 'lodash/isString';
import keys from 'lodash/keys';
import set from 'lodash/set';
import { Observable, combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { ApiService } from '../../services/api/api.service';
import { AuditedService } from '../../services/audited/audited.service';
import { TelemetryService } from '../../services/telemetry/telemetry.service';
import { UtilService } from '../../services/util/util.service';
import { AuthQuery } from '../auth/auth.query';
import { AuthService } from '../auth/auth.service';
import { BaseCollectionService } from '../collection-service/base-collection.service';
import { InsiteUserPrivateQuery } from '../user/insite-user-private.query';
import { PlanQuery } from './plan.query';
import { PlanState, PlanStore } from './plan.store';

const COLLECTION_PATH = 'plans';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: COLLECTION_PATH, idKey: 'id' })
export class PlanService
    extends BaseCollectionService<PlanState, PlanStore, NoPathParams>
    implements OnDestroy
{
    private subs = new SubSink();

    constructor(
        store: PlanStore,
        private analyticsService: AnalyticsService,
        private planQuery: PlanQuery,
        private authService: AuthService,
        private authQuery: AuthQuery,
        private utilService: UtilService,
        protected auditedService: AuditedService,
        protected telemetryService: TelemetryService,
        private insiteUserPrivateQuery: InsiteUserPrivateQuery,
        private apiService: ApiService
    ) {
        super(COLLECTION_PATH, store, auditedService, telemetryService);
        this.subs.sink = this.planQuery.currentPlanId$.subscribe((planId) => {
            this.analyticsService.setUserProperties({
                plan_id: planId,
            });
        });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    formatFromFirestore(plan: getEntityType<PlanState>) {
        if (!plan?.settings?.templateManagement) {
            plan = set(plan, 'settings.templateManagement', 'ADMINS_INTERNALS');
        }
        return plan;
    }

    async updatePlan(plan: Plan | any) {
        const toSave = cloneDeep(plan);
        const planId = this.planQuery.getActiveId();
        await this.auditedService.markUpdated(toSave);
        await this.update(planId, toSave);
        this.analyticsService.event('update_plan', { planId });
    }

    hasReachedProjectLimit(planId: string = this.planQuery.getActiveId()): boolean {
        const plan = this.planQuery.getEntity(planId);
        return plan.maxProjectCount && plan.maxProjectCount <= plan.projectCount;
    }

    hasReachedCreateProjectAllowanceForPeriod(
        planId: string = this.planQuery.getActiveId()
    ): boolean {
        const plan = this.planQuery.getEntity(planId);
        return (
            plan.maxProjectsCreatedInBillingPeriodCount &&
            plan.maxProjectsCreatedInBillingPeriodCount <= plan.projectsCreatedInBillingPeriodCount
        );
    }

    displayProjectsCreatedInBillingPeriodLimitExceeded(
        planId: string = this.planQuery.getActiveId()
    ) {
        const plan = this.planQuery.getEntity(planId);
        return this.utilService.errorAlert(
            'Max project limit reached',
            `You have reached the max number of projects for the billing period of ${plan.maxProjectsCreatedInBillingPeriodCount}. Please contact us to increase the limit.`
        );
    }

    displayProjectLimitExceeded(planId: string = this.planQuery.getActiveId()) {
        const plan = this.planQuery.getEntity(planId);
        return this.utilService.errorAlert(
            'Max active project limit reached',
            `You have reached your max project limit of ${plan.maxProjectCount}, either increase your project limit or archive some other projects. If you are unsure, contact us to find out how.`
        );
    }

    hasReachedMaxPlanMemberLimit(planId: string = this.planQuery.getActiveId()): boolean {
        const plan = this.planQuery.getEntity(planId);
        return hasReachedMaxPlanMemberLimit(plan);
    }

    presentMemberLimitReachedAlert(planOrPlanId: Plan | string = this.planQuery.getActiveId()) {
        const plan = isString(planOrPlanId) ? this.planQuery.getEntity(planOrPlanId) : planOrPlanId;
        this.utilService.errorAlert(
            'Max plan member limit reached',
            `You have reached your max plan member limit of ${plan.maxPlanMemberCount}.<br><br>Please either:<br>• remove some existing users and/or invitees and try again, or<br>• contact us to upgrade your limit.`
        );
    }

    public async inviteUsersToPlan(
        emails: string[],
        planId = this.planQuery.getActiveId(),
        write?: AtomicWrite
    ) {
        await this.updateField(
            planId,
            'invitedUsers',
            arrayUnion(...emails),
            this.getWriteOptions({}, write)
        );
        this.analyticsService.event('invite_users_to_plan', { planId, emails });
    }

    public async revokeInvitedUsersFromPlan(email: string) {
        const planId = this.planQuery.getActiveId();
        await this.updateField(planId, 'invitedUsers', arrayRemove(email), this.getWriteOptions());
        this.analyticsService.event('revoke_invite_user_from_plan', { planId, email });
    }

    public async setPlanUserRole(
        userId: string | string[],
        role: PlanRole,
        planId = this.planQuery.getActiveId(),
        write?: AtomicWrite
    ) {
        const updated = {};
        asArray(userId).forEach((id) => {
            updated[`users.${id}`] = role;
        });
        await this.auditedService.markUpdated(updated as Audited);
        await this.update(planId, updated, this.getWriteOptions({}, write));
        this.analyticsService.event('update_plan_user_role', { role, userId });
    }

    public async removePlanUser(userId: string) {
        const planId = this.planQuery.getActiveId();
        await this.removeField(planId, `users.${userId}`, this.getWriteOptions());
        this.analyticsService.event('delete_plan_user', { planId, userId });
    }

    public async multiAddUsersByEmail(emailsToAdd: string, modalPage?: any): Promise<boolean> {
        const emails: string[] | Set<string> = emailsToAdd
            .split(/[,;]/)
            .map((email) => email.trim().toLocaleLowerCase());
        let validEmails: string[] | Set<string> = emails.filter((email) =>
            EMAIL_PATTERN.test(email)
        );
        validEmails = new Set(validEmails);
        const invalidEmails: string[] = emails.filter(
            (email) => !EMAIL_PATTERN.test(email) && email.length > 0
        );
        const existingUsers = [];
        const usersToAdd = [];
        const usersToInvite = [];
        const activePlan = this.planQuery.getActive();
        for (const validEmail of validEmails) {
            const foundUser = await this.authService.searchByEmail(validEmail);
            if (foundUser) {
                if (keys(activePlan.users).some((userId) => userId === foundUser.id)) {
                    existingUsers.push(foundUser.id);
                } else {
                    usersToAdd.push(foundUser);
                }
            } else {
                if ((activePlan.invitedUsers || []).some((email) => email === validEmail)) {
                    existingUsers.push(validEmail);
                } else {
                    usersToInvite.push(validEmail);
                }
            }
        }
        if (activePlan.maxPlanMemberCount) {
            const currentPlanMemberCount = getPlanMemberCount(activePlan);
            if (
                usersToAdd.length + usersToInvite.length + currentPlanMemberCount >
                activePlan.maxPlanMemberCount
            ) {
                const availableSeats = activePlan.maxPlanMemberCount - currentPlanMemberCount;
                this.utilService.messageAlert(
                    'Too many users!',
                    `You can have up to ${
                        activePlan.maxPlanMemberCount
                    } members on this plan. There ${
                        availableSeats > 1
                            ? 'are currently ' + availableSeats + ' seats'
                            : 'is currently 1 seat'
                    } available.<br><br>Please either:<br>• try to add/invite less users, or<br>• remove some existing users and/or invitees, or<br>• contact us to upgrade your limit.`
                );
                return false;
            }
        }

        if (modalPage) {
            const existingUserCount = existingUsers.length;
            const modal = await this.utilService.presentModal<any>(
                modalPage,
                { invalidEmails, existingUserCount, usersToAdd, usersToInvite, type: 'PLAN' },
                { cssClass: 'users-to-invite-modal' }
            );
            const { data } = await modal.onDidDismiss();
            if (!data) {
                return false;
            }
        }
        const batch = this.batch();
        if (usersToAdd.length > 0) {
            await this.setPlanUserRole(
                usersToAdd.map((user) => user.id),
                'INTERNAL',
                this.planQuery.getActiveId(),
                batch
            );
        }
        if (usersToInvite.length > 0) {
            await this.inviteUsersToPlan(usersToInvite, this.planQuery.getActiveId(), batch);
        }
        try {
            await batch.commit();
            if (modalPage) {
                this.utilService.successToast(
                    `Added ${usersToAdd.length} user(s), and invited ${usersToInvite.length} user(s) to the plan`
                );
                return true;
            }
        } catch {
            this.utilService.errorAlert();
            return false;
        }
    }

    async createTrialPlan(company: string) {
        const user = await this.authQuery.user$.pipe(take(1)).toPromise();
        await this.runTransaction((transaction: Transaction) => {
            const planRef = this.collectionDoc();
            transaction.set(planRef, {
                id: planRef.id,
                createdAt: serverTimestamp(),
                createdBy: user.uid,
                createdByName: getInsiteUserName(user),
                lastUpdated: serverTimestamp(),
                lastUpdatedBy: user.uid,
                lastUpdatedByName: getInsiteUserName(user),
                status: 'trialing',
                type: 'ENTERPRISE',
                company,
                projectCount: 0,
                users: {
                    [user.uid]: 'ADMIN',
                },
            } as Plan);
            const userPrivateRef = this.doc(`users/${user.uid}/private/${user.uid}`);
            transaction.set(
                userPrivateRef,
                {
                    trialed: true,
                    defaultPlan: planRef.id,
                },
                { merge: true }
            );
            return Promise.resolve();
        });
        // this.urlService.trackConversion();
    }

    get userTrialState$(): Observable<UserTrialState> {
        return combineLatest([
            this.insiteUserPrivateQuery.userPrivate$,
            this.planQuery.isUserOnAnyPlan$,
        ]).pipe(
            map(([userPrivate, isUserOnAnyPlan]) => {
                return {
                    isEligible: userPrivate && !userPrivate.trialed && !isUserOnAnyPlan,
                    hasTrialed: userPrivate && userPrivate.trialed,
                };
            })
        );
    }

    changeQuantity(quantity: number) {
        return this.apiService.post<SubscriptionChangeQuantityDto>(
            `/plan/${this.planQuery.getActiveId()}/subscription/quantity`,
            {
                quantity,
            }
        );
    }

    upgrade(quantity: number) {
        return this.apiService.post<SubscriptionUpgradeDto>(
            `/plan/${this.planQuery.getActiveId()}/subscription/upgrade`,
            {
                quantity,
            }
        );
    }

    getUserPlansCount(userId: string) {
        return this.getCount(
            query(this.collection, where(`users.${userId}`, 'in', ['ADMIN', 'INTERNAL']))
        );
    }
}
