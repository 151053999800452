import { Injectable } from '@angular/core';
import { CollectionGuard } from 'akita-ng-fire';
import head from 'lodash/head';
import { combineLatest, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { LoggerService } from '../../services/logger/logger.service';
import { UtilService } from '../../services/util/util.service';
import { catchGuardError } from '../../utils/guard';
import { InsiteUserPrivateQuery } from '../user/insite-user-private.query';
import { PlanQuery } from './plan.query';
import { PlanService } from './plan.service';
import { PlanState, PlanStore } from './plan.store';

@Injectable({ providedIn: 'root' })
export class ActivePlanGuard extends CollectionGuard<PlanState> {
    constructor(
        protected service: PlanService,
        private planStore: PlanStore,
        private planQuery: PlanQuery,
        private insiteUserPrivateQuery: InsiteUserPrivateQuery,
        private utilService: UtilService,
        private loggerService: LoggerService
    ) {
        super(service);
    }

    protected sync() {
        return combineLatest([
            this.planQuery.currentUserPlansIdsAsAdmin$,
            this.insiteUserPrivateQuery.defaultPlanId$,
        ]).pipe(
            tap(([currentUserPlansIdsAsAdmin$, defaultPlanId$]) =>
                this.loggerService.debug('active plan guard', 'syncing active plan', {
                    currentUserPlansIdsAsAdmin$,
                    defaultPlanId$,
                })
            ),
            switchMap(([planIdsAsAdmin, defaultPlanId]) => {
                if (planIdsAsAdmin?.length > 0) {
                    if (
                        defaultPlanId &&
                        planIdsAsAdmin.find((planId) => planId === defaultPlanId)
                    ) {
                        this.planStore.setActive(defaultPlanId);
                        return this.planQuery.selectActive();
                    } else {
                        this.planStore.setActive(head(planIdsAsAdmin));
                        return this.planQuery.selectActive();
                    }
                } else {
                    const activeId = this.planQuery.getActiveId();
                    if (activeId) {
                        this.planStore.removeActive(activeId);
                    }
                    return of(null);
                }
            }),
            catchError((err) => catchGuardError(err, 'plans', this.utilService, this.loggerService))
        );
    }
}
