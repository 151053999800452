export const environment = {
    appName: 'insite-teams-admin',
    production: true,
    firebase: {
        apiKey: 'AIzaSyAR0QeMRFR3pcInAGQ8J3GjP1ispEF1yPI',
        authDomain: 'admin.insiteapp.co.uk',
        databaseURL: 'https://insite-teams.firebaseio.com',
        projectId: 'insite-teams',
        storageBucket: 'insite-teams.appspot.com',
        messagingSenderId: '858500834910',
        appId: '1:858500834910:web:5c4945e5d00d162527b6cf',
        measurementId: 'G-92C785NR35',
    },
    env: 'production',
    functionDomain: 'https://europe-west1-insite-teams.cloudfunctions.net',
    paddleApi: 'https://checkout.paddle.com/api',
    version: '1.22.1',
    apiBaseUrl: 'https://api.insiteapp.co.uk',
};
