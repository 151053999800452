<ng-container *ngIf="{
    showUserIsPlanAdminMessage: roleOptions.length === 1,
    showHowToDemoteMessage: (currentRole === 'ADMIN' || currentRole === 'INTERNAL') && roleOptions.length === 2 && plan.type === 'ENTERPRISE'
} as state">
    <ion-header class="ion-no-border">
        <ion-toolbar mode="md" color="white" class="pt-0">
            <ion-title class="font-22" color="admin-dark"><b>{{canManageUsers ? 'Manage user' : 'User details'}}</b></ion-title>
            <ion-buttons slot="end">
                <ion-button class="button-padding-4 mr-6" (click)="close()">
                    <ion-icon color="admin-dark" name="close-sharp"></ion-icon>
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>

    <div *ngIf="user" class="modal-content overflow-scroll-y height-100">
        <div class="vertical-align-content mt-10 ion-margin-horizontal">
            <ion-avatar class="initials-avatar avatar-light size-60 flex-shrink-0 vertical-align-content">
                <ion-img *ngIf="user.insiteUser.image"
                         [src]="user.insiteUser.image.thumbUrl || user.insiteUser.image.url"></ion-img>
                <ion-text *ngIf="!user.insiteUser.image" class="margin-auto">
                    <ion-icon name="person" color="med-dark"></ion-icon>
                </ion-text>
            </ion-avatar>
            <div class="overflow-hidden">
                <ion-text color="admin-dark">
                    <p class="mb-0 mt-0 font-weight-500 overflow-ellipsis">{{user.insiteUser.firstName + ' ' + user.insiteUser.lastName}}</p>
                </ion-text>
                <ion-text color="medium">
                    <p *ngIf="user.insiteUser.position" class="mb-0 mt-0 font-14 overflow-ellipsis">
                        {{user.insiteUser.position}}
                    </p>
                    <p *ngIf="user.insiteUser.company" class="mb-0 mt-0 font-14 overflow-ellipsis">
                        {{user.insiteUser.company}}
                    </p>
                </ion-text>
            </div>
        </div>

        <p class="vertical-align-content mt-20 border-bottom-light-med pb-12 pl-22 mb-0 overflow-ellipsis">
            <ion-icon name="mail" color="med-dark" class="font-22 mr-6"></ion-icon>
            <a href="{{'mailto:' + user.insiteUser.email}}" target="_blank" class="font-14 external-link hover-underline">
                {{user.insiteUser.email || '-'}}
            </a>
        </p>

        <ng-container *ngIf="canManageUsers; else roleDescription">
            <ion-list class="mr-0 ml-0 mb-0">
                <ion-radio-group [(ngModel)]="user.role" (ionChange)="updateRole($event)">
                    <ion-item *ngFor="let role of roleOptions" lines="inset" [ngClass]="{'maintain-border': state.showUserIsPlanAdminMessage || state.showHowToDemoteMessage}">
                        <div>
                            <div>
                                <ion-badge [color]="(role | projectRoleDetail).colour"
                                           class="font-weight-500 font-14 inline-vertical-align ion-justify-content-center badge-bordered-contrast">
                                    <ion-icon [name]="(role | projectRoleDetail).icon" class="font-16 mr-4 ml-2"></ion-icon>
                                    {{(role | projectRoleDetail).label}}
                                </ion-badge>
                            </div>
                            <p class="ion-text-wrap font-13 color-dark-tint mt-4 pl-6 mb-0">{{(role | projectRoleDetail).description}}</p>
                        </div>
                        <ion-radio *ngIf="roleOptions.length > 1" [value]="role" slot="end" mode="md"></ion-radio>
                    </ion-item>
                </ion-radio-group>
            </ion-list>

            <div *ngIf="state.showUserIsPlanAdminMessage" class="display-flex font-13 ion-margin-vertical ml-22 mr-24">
                <lib-status-indicator [disabled]="true" icon="information" colour="primary" padding="padding-1" fontSize="font-14" class="mt-2 mr-6"></lib-status-indicator>
                <ion-text color="admin-dark">
                    <p class="mt-0 mb-0">
                        This user is an Admin on the plan and so can only be an Admin on the project.
                    </p>
                </ion-text>
            </div>

            <div *ngIf="state.showHowToDemoteMessage" class="display-flex font-13 ion-margin-vertical ml-22 mr-24">
                <lib-status-indicator [disabled]="true" icon="information" colour="primary" padding="padding-1" fontSize="font-14" class="mt-2 mr-6"></lib-status-indicator>
                <ion-text color="admin-dark">
                    <p class="mt-0 mb-0">
                        To give this user External or Read Only role you will need to remove them from your plan.
                    </p>
                </ion-text>
            </div>

            <ion-button (click)="removeUser()"
                        fill="clear"
                        class="button-34 button-padding-10 ml-12 ion-margin-bottom mt-8">
                Remove user
            </ion-button>
        </ng-container>

        <ng-template #roleDescription>
            <ion-list class="mr-0 ml-0 mb-0">
                <ion-item lines="inset">
                    <div>
                        <div>
                            <ion-badge [color]="(user.role | projectRoleDetail).colour"
                                       class="font-weight-500 font-14 inline-vertical-align ion-justify-content-center badge-bordered-contrast">
                                <ion-icon [name]="(user.role | projectRoleDetail).icon" class="font-16 mr-4 ml-2"></ion-icon>
                                {{(user.role | projectRoleDetail).label}}
                            </ion-badge>
                        </div>
                        <ion-label class="mt-4 pl-6">
                            <p class="ion-text-wrap mb-0">{{(user.role | projectRoleDetail).description}}</p>
                        </ion-label>
                    </div>
                </ion-item>
            </ion-list>
        </ng-template>

    </div>
</ng-container>

