import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { PlanTransaction } from '@insite-group-ltd/insite-teams-model';
import { CollectionState } from 'akita-ng-fire';

export type PlanTransactionState = CollectionState<PlanTransaction>;

@Injectable({ providedIn: 'root' })
@StoreConfig({
    name: 'plan-transaction',
    idKey: 'id',
    resettable: true,
})
export class PlanTransactionStore extends EntityStore<PlanTransactionState> {
    constructor() {
        super();
    }
}
