<ng-container *ngIf="{
    windowSize: windowSize$ | async
} as state">
    <div [ngClass]="{ 'ion-margin-horizontal': marginHorizontal }"
         [ngStyle]="{ 'margin-top': marginTop, 'margin-bottom': marginBottom }"
         class="alert-header info-alert">
        <div class="display-flex-column flex-row-small">
            <div class="display-flex">
                <ion-icon [ngStyle]="{'font-size': state.windowSize.smallSize && !alertHeaderService.minimised ? '50px' : '22px'}"
                          class="alert-icon"
                          name="information-circle-outline"
                          color="secondary"></ion-icon>
                <ng-container *ngIf="!state.windowSize.smallSize">
                    <ion-text class="hide-small alert-title" color="secondary">
                        <p class="font-15 font-weight-500 mt-0 mb-0">{{ title }}</p>
                    </ion-text>
                    <div class="flex-fill"></div>
                    <ng-container [ngTemplateOutlet]="minimiseButton"></ng-container>
                </ng-container>

            </div>
            <div class="flex-grow-10">
                <div style="max-width: 900px">
                    <ion-text color="secondary">
                        <p *ngIf="state.windowSize.smallSize" class="font-15 font-weight-500 mb-8 mt-10">{{ title }}</p>
                        <p *ngIf="!alertHeaderService.minimised" class="font-14 mt-neg-2 message-text" [innerHTML]="message"></p>
                    </ion-text>
                </div>
            </div>
            <div class="display-flex-column">
                <ion-button mode="md"
                            class="alert-action-button ion-align-self-end margin-auto-vertical ion-no-margin"
                            (click)="action()"
                            *ngIf="btnText && (showAction | async)"
                            color="secondary"
                            shape="outline">
                    {{ btnText }}
                </ion-button>
                <ng-container *ngIf="state.windowSize.smallSize && !(btnText && (showAction | async))" [ngTemplateOutlet]="minimiseButton"></ng-container>
                <ng-template #minimiseButton>
                    <ion-button (click)="toggleMinimise()" size="small" fill="clear" class="button-padding-5 minimise-button">
                        <ion-icon [name]="alertHeaderService.minimised ? 'chevron-down' : 'chevron-up'"></ion-icon>
                    </ion-button>
                </ng-template>
            </div>
        </div>
    </div>
</ng-container>
