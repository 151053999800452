import * as Sentry from '@sentry/capacitor';
import { EMPTY, Observable } from 'rxjs';
import { LoggerService } from '../services/logger/logger.service';
import { UtilService } from '../services/util/util.service';

export function catchGuardError(
    err: any,
    path: string,
    utilService: UtilService,
    loggerService: LoggerService
): Observable<any> {
    if (err.code === 'permission-denied') {
        loggerService.error('guard-permission-denied', path + ' ' + err.message);
        utilService.errorAlert(
            'Permission denied',
            'You do not have access to this resource',
            true
        );
    } else {
        loggerService.error('guard', err);
        Sentry.captureException(err);
        utilService.errorAlert(
            'Something went wrong!',
            "This error has been reported. We'll get right on it!",
            true
        );
    }
    return EMPTY;
}
