import { gt } from 'semver';
import { Audited } from './audited';
import { FeatureAccess, FeatureConfig, FeatureFlags } from './feature';
import { Image } from './image';
import { PinturaEditorState } from './image-editor';
import { ItemFilterProfile } from './item-filter';
import { defaultItemSortOption } from './item-sort';
import { ListContentViewSettings, ListGroupBy } from './list';
import { NotificationSettings } from './notification';
import { UserInPlan } from './plan';
import { UserInProject } from './project';
import { SelectOption, SelectOptions } from './select-option';
import { SystemWhatsNewDetail } from './system';
import { getUpdateGuideStepIds } from './user-guide';

export const EMAIL_PATTERN = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const PASSWORD_PATTERN = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
export const PASSWORD_STRENGTH_ERROR =
    'Password does not meet the strength requirements. Must be more than 8 characters long, containing a minimum of 1 uppercase, 1 lowercase and 1 number.';

export type InsiteUserWarningMessage = 'SHARE_PUBLIC_LINK';

export interface PhoneNumber {
    internationalNumber: string;
    nationalNumber: string;
    isoCode: string;
    dialCode: string;
}

export function sortInsiteUserByName<T extends UserInProject | UserInPlan>(users: T[]) {
    if (!users) {
        return [];
    }
    return users.sort((a: T, b: T) => {
        return getInsiteUserName(a.insiteUser).toLowerCase() <
            getInsiteUserName(b.insiteUser).toLowerCase()
            ? -1
            : 1;
    });
}

export function getInsiteUserName(user?: InsiteUser) {
    if (!user) {
        return '';
    }
    return [user.firstName, user.lastName].filter((val) => !!val).join(' ');
}

export interface InsiteUser extends Audited {
    id: string;
    uid: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneCode?: string;
    phoneNumber?: string;
    image?: Image;
    position?: string;
    company: string;
    privateMode: boolean;
}

export interface InsiteUserPrivate extends Audited {
    companyLogo?: Image;
    signature?: string | Image;
    imageSettings?: ImageSettings;
    reportSettings?: ReportSettings;
    customisationSettings?: CustomisationSettings;
    subscribeToMailingList: boolean;
    privacyAccepted: boolean;
    termsAccepted: boolean;
    walkthroughComplete: boolean;
    emailNotifications?: boolean;
    notificationTokens?: string[];
    notificationSettings?: NotificationSettings;
    listContentViewSettings?: ListContentViewSettings;
    listGroupBy?: ListGroupBy;
    defaultPlan?: string;
    phoneNumber?: PhoneNumber;
    trialed: boolean;
    pendingWelcomePopup: boolean;
    utmSource?: string;
    hasConsented?: boolean;
    imageEditingSettings?: ImageEditingSettings;
    savedListFilters?: Record<string, ItemFilterProfile>;
    completedUserGuideSteps: string[];
    countryCode?: string;
    warningMessage?: Record<InsiteUserWarningMessage, string>;
    pinturaImageEditorState?: PinturaEditorState;
    featureFlags?: FeatureFlags;
    featureConfig?: FeatureConfig;
    featureAccess?: FeatureAccess;
    viewedWhatsNewVersion?: string;
}

export interface UserMapping {
    hubSpotContactId?: string;
    hubSpotDealId?: string;
    apolloContactId?: string;
}

export const defaultCompletedUserGuideSteps: string[] = getUpdateGuideStepIds();

export interface ImageEditingSettings {
    brushColor: string;
    brushWidth: number;
    isDrawingMode: boolean;
    activeShapeId: string;
}

export interface ImageSettings {
    saveToCameraRoll: boolean;
    saveGpsCoordinates: boolean;
    gpsCoordinatesHighAccuracy: boolean;
    offlineUpload: boolean;
    onlyUploadOnWifi: boolean;
}

export interface UserTrialState {
    isEligible: boolean;
    hasTrialed: boolean;
}

export const defaultImageSettings: ImageSettings = {
    saveToCameraRoll: true,
    saveGpsCoordinates: false,
    gpsCoordinatesHighAccuracy: false,
    offlineUpload: false,
    onlyUploadOnWifi: false,
};

export type ReportItemGrouping = 'NONE' | 'LIST' | 'LIST_TAGS';

export type ReportItemGraphsBy = 'STATUS' | 'STATUS_SCORE' | 'PRIORITY';

export type ReportItemGraphsPrimary = 'ASSIGNEES' | 'LOCATIONS';

export type ReportItemSummaryTableTitleColumn = 'title' | 'description' | 'title-description';

export type ReportItemSummaryTableDetailColumn =
    | 'hidden'
    | 'status'
    | 'status-score'
    | 'due-date'
    | 'priority'
    | 'created-date'
    | 'completed-date'
    | 'assignees'
    | 'locations'
    | 'time-open'
    | 'files';

export type ReportItemSummaryTableColumn =
    | ReportItemSummaryTableTitleColumn
    | ReportItemSummaryTableDetailColumn;

export const reportItemSummaryTableTitleColumnOptions: SelectOptions = [
    { id: 'title', name: 'Title' },
    { id: 'description', name: 'Description' },
    { id: 'title-description', name: 'Title & Description' },
];

export const reportItemSummaryTableDetailColumnOptions: SelectOptions = [
    { id: 'hidden', name: 'Hidden' },
    { id: 'status', name: 'Status' },
    { id: 'status-score', name: 'Score' },
    { id: 'due-date', name: 'Due date' },
    { id: 'priority', name: 'Priority' },
    { id: 'assignees', name: 'Assignees' },
    { id: 'locations', name: 'Locations' },
    { id: 'files', name: 'Files' },
    { id: 'created-date', name: 'Created date' },
    { id: 'completed-date', name: 'Completed date' },
    { id: 'time-open', name: 'Time open' },
];

export interface ReportSettings {
    auditorName: boolean;
    auditorCompany: boolean;
    auditorSignature: boolean;
    auditorPosition: boolean;
    auditorEmail: boolean;
    companyLogo: boolean;
    companyAddress: boolean;
    additionalCompanyDetails: boolean;
    projectNumber: boolean;
    projectClient: boolean;
    projectArchitect: boolean;
    projectLocation: boolean;
    itemDescription: boolean;
    itemDueDate: boolean;
    itemStatus: boolean;
    itemStatusScore: boolean;
    itemStatusRequests: boolean;
    itemPriority: boolean;
    itemAssignees: boolean;
    itemLocations: boolean;
    itemImages: boolean;
    itemImageComments: boolean;
    itemImageIndex: boolean;
    itemDrawings: boolean;
    itemComments: boolean;
    itemCreatedAt: boolean;
    itemCreatedBy: boolean;
    itemCode: boolean;
    itemQrCode: boolean;
    itemFiles: boolean;
    itemActivityLog: boolean;
    imageQuality: number;
    imageGpsCoordinates: boolean;
    imagesPerRow: number;
    squareImages: boolean;
    reportColour: string;
    pageBreakAfterEachItem: boolean;
    allowItemPageOverflow: boolean;
    showIntermediateHeaders: boolean;
    reportDateIncludeTime: boolean;
    dateStampOnPhotos: boolean;
    dateStampSource: 'DATE_UPLOADED' | 'DATE_TAKEN';
    dateStampOnStatus: boolean;
    dateStampOnItemComments: boolean;
    dateStampOnItemImageComments: boolean;
    showCommentsCreatedBy: boolean;
    displayLeaderPage: boolean;
    displayGraphs: boolean;
    displayItemsSummaryTable: boolean;
    applyFiltersToItemsSummaryTable: boolean;
    itemGrouping: ReportItemGrouping;
    itemGraphs: ReportItemGraphsBy;
    applyFiltersToItemGraphs: boolean;
    primaryBarGraph: ReportItemGraphsPrimary;
    summary?: string;
    itemsSortOrder: string;
    coverTheme: SelectOption['id'];
    displayPageHeader: boolean;
    displayPageFooter: boolean;
    summaryTableColumns: {
        column1: ReportItemSummaryTableTitleColumn;
        column2: ReportItemSummaryTableDetailColumn;
        column3: ReportItemSummaryTableDetailColumn;
        column4: ReportItemSummaryTableDetailColumn;
    };
}

export const defaultReportSettings: ReportSettings = {
    itemAssignees: true,
    itemDescription: true,
    itemDueDate: true,
    itemImages: true,
    itemImageComments: true,
    itemImageIndex: true,
    itemDrawings: true,
    itemLocations: true,
    itemStatus: true,
    itemStatusScore: true,
    itemStatusRequests: true,
    itemPriority: true,
    itemCreatedAt: true,
    itemCreatedBy: true,
    itemCode: true,
    itemFiles: true,
    itemActivityLog: false,
    auditorName: true,
    auditorCompany: true,
    auditorSignature: true,
    auditorPosition: true,
    auditorEmail: true,
    companyAddress: true,
    companyLogo: true,
    additionalCompanyDetails: true,
    projectNumber: true,
    projectClient: true,
    projectArchitect: true,
    projectLocation: true,
    itemComments: true,
    itemQrCode: true,
    imageQuality: 50,
    imageGpsCoordinates: true,
    imagesPerRow: 3,
    squareImages: false,
    reportColour: '#3498db',
    pageBreakAfterEachItem: true,
    allowItemPageOverflow: true,
    showIntermediateHeaders: true,
    reportDateIncludeTime: false,
    dateStampOnPhotos: true,
    dateStampSource: 'DATE_UPLOADED',
    dateStampOnStatus: true,
    dateStampOnItemComments: true,
    dateStampOnItemImageComments: true,
    showCommentsCreatedBy: false,
    displayLeaderPage: true,
    displayGraphs: true,
    applyFiltersToItemGraphs: true,
    displayItemsSummaryTable: true,
    applyFiltersToItemsSummaryTable: true,
    itemGrouping: 'LIST',
    itemGraphs: 'STATUS',
    primaryBarGraph: 'ASSIGNEES',
    itemsSortOrder: defaultItemSortOption.code,
    coverTheme: 'COMPREHENSIVE_LEFT',
    displayPageHeader: true,
    displayPageFooter: true,
    summaryTableColumns: {
        column1: 'title',
        column2: 'status',
        column3: 'due-date',
        column4: 'priority',
    },
};

export interface CustomisationSettings {
    projectTitle: string;
    projectNumber: string;
    projectClient: string;
    projectArchitect: string;
    projectLocation: string;
    listTitle: string;
    listReference: string;
    itemTitle: string;
    itemDescription: string;
    itemAssignedTo: string;
    itemLocation: string;
    itemDueDate: string;
    itemComments: string;
    itemStatus: string;
}

export const defaultCustomisationSettings: CustomisationSettings = {
    projectTitle: 'Name',
    projectNumber: 'Project number',
    projectClient: 'Client',
    projectArchitect: 'Architect',
    projectLocation: 'Location',
    listTitle: 'Name',
    listReference: 'Reference',
    itemTitle: 'Title',
    itemDescription: 'Description',
    itemAssignedTo: 'Assigned To',
    itemLocation: 'Location',
    itemDueDate: 'Due Date',
    itemComments: 'Comments',
    itemStatus: 'Status',
};

export function hasSeenWhatsNew(
    user: InsiteUser,
    userPrivate: InsiteUserPrivate,
    whatsNew: SystemWhatsNewDetail
) {
    const { createdAt } = user;
    const { viewedWhatsNewVersion } = userPrivate;
    const userCreatedAfterWhatsNew = createdAt && createdAt >= whatsNew.date;
    const userHasNotSeenWhatsNew =
        !viewedWhatsNewVersion || gt(whatsNew.version, viewedWhatsNewVersion);
    return userCreatedAfterWhatsNew || !userHasNotSeenWhatsNew;
}
