import { Component, Input, OnInit } from '@angular/core';
import { marked } from 'marked';
import { firstValueFrom } from 'rxjs';
import { SystemQuery } from '../../state/system/system.query';
import { UtilService } from '../../services/util/util.service';

@Component({
    selector: 'lib-saas-agreement',
    templateUrl: './saas-agreement.page.html',
    styleUrls: ['./saas-agreement.page.scss'],
})
export class SaasAgreementPage implements OnInit {
    saasAgreement: string;
    loaded = false;
    @Input() adminConsole: boolean;

    constructor(private systemQuery: SystemQuery, private utilService: UtilService) {}

    async ngOnInit() {
        const saasAgreement = await firstValueFrom(this.systemQuery.saasAgreement);
        this.saasAgreement = marked.parse(saasAgreement);
        this.loaded = true;
    }

    close() {
        this.utilService.dismissModal();
    }
}
