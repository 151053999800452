import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    arrayRemove,
    deleteField,
    getDocsFromServer,
    increment,
    query,
    Timestamp,
    Transaction,
    where,
} from '@angular/fire/firestore';
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem, ReadFileOptions, WriteFileOptions } from '@capacitor/filesystem';
import { ConnectionStatus } from '@capacitor/network';
import {
    DrawingPin,
    Image,
    ImageType,
    ImageUploadTask,
    isLocalImage,
    Item,
    ItemDetails,
    Project,
} from '@insite-group-ltd/insite-teams-model';
import * as Sentry from '@sentry/capacitor';
import { CollectionConfig, NoPathParams } from 'akita-ng-fire';
import { subDays } from 'date-fns';
import includes from 'lodash/includes';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import keys from 'lodash/keys';
import { nanoid } from 'nanoid';
import PQueue from 'p-queue';
import { debounceTime, filter, switchMap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { AuditedService } from '../../services/audited/audited.service';
import { AuthService } from '../../state/auth/auth.service';
import { BaseCollectionService } from '../../state/collection-service/base-collection.service';
import { InsiteUserPrivateQuery } from '../../state/user/insite-user-private.query';
import { toPromise } from '../../utils/pipe';
import { DeviceService } from '../device/device.service';
import { FileService } from '../file/file.service';
import { LoggerService } from '../logger/logger.service';
import { NetworkService } from '../network/network.service';
import { StorageService } from '../storage/storage.service';
import { TelemetryService } from '../telemetry/telemetry.service';
import { UtilService } from '../util/util.service';
import { ImageUploadQuery } from './image-upload.query';
import { ImageUploadStore, ImageUploadTaskState } from './image-upload.store';

const LOGGER_NAME = 'image-upload';
const COLLECTION_PATH = 'image-uploads';

@Injectable({
    providedIn: 'root',
})
@CollectionConfig({ path: COLLECTION_PATH, idKey: 'id' })
export class ImageUploadService extends BaseCollectionService<
    ImageUploadTaskState,
    ImageUploadStore,
    NoPathParams
> {
    private queue = new PQueue({ concurrency: 1 });

    constructor(
        store: ImageUploadStore,
        private imageUploadQuery: ImageUploadQuery,
        private storageService: StorageService,
        private analyticsService: AnalyticsService,
        protected auditedService: AuditedService,
        protected telemetryService: TelemetryService,
        private loggerService: LoggerService,
        private insiteUserPrivateQuery: InsiteUserPrivateQuery,
        private networkService: NetworkService,
        private utilService: UtilService,
        private authService: AuthService,
        private deviceService: DeviceService,
        private fileService: FileService,
        private httpClient: HttpClient
    ) {
        super(COLLECTION_PATH, store, auditedService, telemetryService);
    }

    public init() {
        if (Capacitor.isNativePlatform()) {
            this.insiteUserPrivateQuery.offlineImageEnabled$
                .pipe(
                    filter((offlineImageEnabled) => !!offlineImageEnabled),
                    switchMap(() => this.networkService.networkStatus$),
                    debounceTime(5000)
                )
                .subscribe((networkStatus) => this.syncAllImages(networkStatus));
            setTimeout(async () => {
                const offlineImageEnabled = await this.insiteUserPrivateQuery.offlineImageEnabled;
                if (offlineImageEnabled) {
                    this.syncAllImages(await this.networkService.networkStatus);
                }
            }, 5000);
            this.clearFilesPendingDeletion();
        }
    }

    private async syncAllImages(networkStatus: ConnectionStatus) {
        if (
            networkStatus.connected &&
            (networkStatus.connectionType === 'wifi' ||
                !(await this.insiteUserPrivateQuery.onlyUploadOnWifi))
        ) {
            this.syncAll();
            this.loggerService.info(LOGGER_NAME, `network connection active syncing all images`);
        }
    }

    public async uploadImage(imageType: ImageType, toSave: Image | string): Promise<Image> {
        const imageBase64 = isString(toSave) ? toSave : toSave.url;
        const filename = `${uuidv4()}.${imageBase64.substring(
            'data:image/'.length,
            imageBase64.indexOf(';base64')
        )}`;
        const path = `${imageType.directory}/${filename}`;
        const savedImage: Partial<Image> = {
            id: isString(toSave) ? nanoid() : toSave.id,
            filename,
            directory: imageType.directory,
            url: await this.saveImageToCloudStorage(path, imageBase64),
            gpsCoordinates: isString(toSave) ? null : toSave.gpsCoordinates || null,
        };
        await this.auditedService.markCreated(savedImage as Image, true);
        return savedImage as Image;
    }

    public async uploadImageOfflineSupport(
        docPath: string,
        imageType: ImageType,
        toSave: Image
    ): Promise<Image> {
        const imageBase64 = toSave.url;
        const filename = `${uuidv4()}.${imageBase64.substring(
            'data:image/'.length,
            imageBase64.indexOf(';base64')
        )}`;
        const path = `${imageType.directory}/${filename}`;
        const savedImage: Partial<Image> = {
            id: toSave.id,
            filename,
            directory: imageType.directory,
            url: await this.saveImage(imageType.directory, path, imageBase64),
            comments: toSave.comments || [],
            primary: !!toSave.primary,
            gpsCoordinates: toSave.gpsCoordinates || null,
            takenDate: toSave.takenDate,
        };
        if (isLocalImage(savedImage as Image)) {
            await this.addImageUploadTask(docPath, imageType, path, savedImage.id, savedImage.url);
        }
        await this.auditedService.markCreated(savedImage as Image, true);
        return savedImage as Image;
    }

    public async deleteTask(imageUploadTask: ImageUploadTask) {
        const imageTaskDoc = this.imageUploadQuery.getEntity(imageUploadTask.id);
        if (imageTaskDoc) {
            await this.updateTaskToDeleted([imageUploadTask.id]);
            const docRef = this.doc(imageUploadTask.docPath);
            await this.runTransaction(async (transaction: Transaction) => {
                // This code may get re-run multiple times if there are conflicts.
                const docSnapshot = await transaction.get(docRef);
                if (!docSnapshot.exists()) {
                    this.loggerService.info(
                        LOGGER_NAME,
                        `doc does not exist at path: ${imageUploadTask.docPath}`
                    );
                    throw Error('Document does not exist!');
                }
                const data = docSnapshot.data();
                const updateData: any = {};
                const target = imageUploadTask.target;
                if (target === 'ITEM') {
                    this.removeItemImage(imageUploadTask, data as Item, updateData);
                } else if (target === 'PROJECT') {
                    updateData.image = deleteField();
                } else if (target === 'ITEM_IMAGE_EDIT') {
                    updateData[`imageCanvas.${imageUploadTask.imageId}`] = deleteField();
                } else if (target === 'ITEM_DRAWING_PIN') {
                    updateData.thumbnail = deleteField();
                } else {
                    throw Error(`unsupported image upload task target: ${target}`);
                }
                const updated = keys(updateData).length;
                if (updated) {
                    transaction.update(docRef, updateData);
                    this.loggerService.info(
                        LOGGER_NAME,
                        `successfully removed pending upload image urls for doc at path: ${imageUploadTask.docPath}`
                    );
                }
            });
            this.loggerService.info(
                LOGGER_NAME,
                `cleared task with id ${imageUploadTask.id} for doc path: ${imageUploadTask.docPath}`
            );
        }
    }

    private removeItemImage(uploadedImageUploadTask: ImageUploadTask, item: Item, updateData: any) {
        const docImages = item.images || [];
        for (const docImage of docImages) {
            if (docImage.id === uploadedImageUploadTask.imageId) {
                updateData.images = arrayRemove(docImage);
                return;
            }
        }
    }

    private async saveImage(directory: string, path: string, base64Image: string) {
        if (await this.canSaveImageOffline()) {
            const url = await this.saveImageToLocalFileSystem(directory, path, base64Image);
            this.loggerService.info(LOGGER_NAME, `saving image to local file system: ${url}`);
            return url;
        } else {
            this.loggerService.info(LOGGER_NAME, `saving image to cloud storage`);
            return await this.saveImageToCloudStorage(path, base64Image);
        }
    }

    private async saveImageToCloudStorage(path: string, base64Image: string) {
        const customMetadata = {
            resizedImage: Capacitor.isNativePlatform().toString(),
        };
        this.analyticsService.event('upload_image');
        return await this.storageService.uploadDataUrl(path, base64Image, { customMetadata });
    }

    private async saveImageToLocalFileSystem(directory: string, path: string, base64Image: string) {
        try {
            await this.fileService.makeDirectory(Directory.Data, directory);
        } catch (err) {
            this.loggerService.error(
                'image-service',
                `failed to create directory: ${directory}, error: ${err.message}`
            );
            const deviceId = await this.deviceService.getDeviceId();
            Sentry.captureException(err, (scope) => {
                scope.setTag('service', LOGGER_NAME);
                scope.setExtra('directory', directory);
                scope.setExtra('path', path);
                scope.setExtra('deviceId', deviceId);
                return scope;
            });
        }
        const fileOptions: WriteFileOptions = {
            directory: Directory.Data,
            path,
            data: base64Image,
        };
        const { uri } = await Filesystem.writeFile(fileOptions);
        this.analyticsService.event('local_image_save');
        return Capacitor.convertFileSrc(uri);
    }

    public async clearImageUploadTasks(docPath: string): Promise<void> {
        if (!Capacitor.isNativePlatform()) {
            return Promise.resolve();
        }
        const pendingImageUploadTasks = await toPromise(
            this.imageUploadQuery.getPendingImageUploadTasks$(docPath)
        );
        if (pendingImageUploadTasks?.length) {
            for (const imageUploadTask of pendingImageUploadTasks) {
                this.deleteImageFromFileSystem(imageUploadTask);
            }
        }
        const imageUploadTaskIdsWithDocPath = await toPromise(
            this.imageUploadQuery.getImageUploadTaskIdsWithDocPath$(docPath)
        );
        await this.updateTaskToDeleted(imageUploadTaskIdsWithDocPath);
        this.loggerService.info(
            LOGGER_NAME,
            `cleared ${imageUploadTaskIdsWithDocPath.length} images for doc path: ${docPath}`
        );
    }

    public async syncAll(bypassWifiOnlyCheck = false) {
        const docPaths = await toPromise(
            this.imageUploadQuery.getNotCompleteImageUploadTaskDocPaths$()
        );
        this.loggerService.info(LOGGER_NAME, `found ${docPaths?.length || 0} doc paths to sync`);
        const imageCount = docPaths?.length;
        if (imageCount) {
            this.utilService.successToast(`Syncing images`);
            this.runImageUploadTasks(docPaths, bypassWifiOnlyCheck);
        }
    }

    public async runImageUploadTasks(
        docPath: string | string[],
        bypassWifiOnlyCheck = false
    ): Promise<void> {
        const message = isArray(docPath)
            ? `skipping run image upload task for ${docPath?.length} docs`
            : `skipping run image upload task for doc at path: ${docPath}`;
        if (!Capacitor.isNativePlatform()) {
            this.loggerService.info(LOGGER_NAME, `skipping run image upload not native`);
            return;
        } else if (!(await this.insiteUserPrivateQuery.offlineImageEnabled)) {
            this.loggerService.info(LOGGER_NAME, `${message} - offline image not enabled`);
            return;
        }
        const networkStatus = await this.networkService.networkStatus;
        if (!networkStatus.connected) {
            this.loggerService.info(LOGGER_NAME, `${message} - network not connected`);
            return;
        } else if (
            !bypassWifiOnlyCheck &&
            networkStatus.connectionType !== 'wifi' &&
            (await this.insiteUserPrivateQuery.onlyUploadOnWifi)
        ) {
            this.loggerService.info(LOGGER_NAME, `${message} - network not on wifi`);
            return;
        }
        if (isArray(docPath)) {
            docPath.forEach((path) => this.addImageUploadTaskToQueue(path));
        } else {
            this.addImageUploadTaskToQueue(docPath);
        }
    }

    private addImageUploadTaskToQueue(docPath: string) {
        this.queue.add(async () => {
            try {
                this.loggerService.info(
                    LOGGER_NAME,
                    `running image upload task for doc at path: ${docPath}`
                );
                await this.uploadLocalImagesToStorage(docPath);
                await this.updateImageUrlsForDoc(docPath);
            } catch (e) {
                this.loggerService.info(
                    LOGGER_NAME,
                    `failed to run image upload task for doc at path: ${docPath} - error ${e.message}`
                );
                const deviceId = await this.deviceService.getDeviceId();
                Sentry.captureException(e, (scope) => {
                    scope.setTag('service', LOGGER_NAME);
                    scope.setExtra('docPath', docPath);
                    scope.setExtra('deviceId', deviceId);
                    return scope;
                });
            }
        });
    }

    private async uploadLocalImagesToStorage(docPath: string) {
        const pendingImageUploadTasks: ImageUploadTask[] = await toPromise(
            this.imageUploadQuery.getPendingImageUploadTasks$(docPath)
        );
        if (pendingImageUploadTasks?.length) {
            return Promise.all(
                pendingImageUploadTasks.map((imageUploadTask) =>
                    this.uploadImageToStorage(docPath, imageUploadTask)
                )
            );
        }
        return Promise.resolve();
    }

    private async uploadImageToStorage(docPath: string, imageUploadTask: ImageUploadTask) {
        try {
            const fileOptions: ReadFileOptions = {
                directory: Directory.Data,
                path: imageUploadTask.filePath,
            };
            const { data } = await Filesystem.readFile(fileOptions);
            const imageUrl = await this.saveImageToCloudStorage(
                imageUploadTask.filePath,
                `data:image/${imageUploadTask.filePath.split('.').pop()};base64,${data}`
            );
            await this.update(imageUploadTask.id, {
                url: imageUrl,
                status: 'UPLOADED',
                uploadAttempts: increment(1) as any,
                lastModified: Timestamp.fromDate(new Date()),
            });
            this.loadImageIntoBrowserCache(imageUrl);
            this.loggerService.info(
                LOGGER_NAME,
                `uploaded image to cloud storage with doc at path: ${docPath} (${imageUploadTask.id})`
            );
        } catch (e) {
            this.loggerService.info(
                LOGGER_NAME,
                `failed uploading image to cloud storage with doc at path: ${docPath} (${imageUploadTask.id}) - error: ${e.message}`
            );
            // don't report error to sentry where user signal causes upload failure
            if (!includes(e.message, 'storage/retry-limit-exceeded')) {
                const deviceId = await this.deviceService.getDeviceId();
                Sentry.captureException(e, (scope) => {
                    scope.setTag('service', LOGGER_NAME);
                    scope.setExtra('docPath', docPath);
                    scope.setExtra('imageUploadTaskId', imageUploadTask.id);
                    scope.setExtra('deviceId', deviceId);
                    return scope;
                });
            }
            await this.update(imageUploadTask.id, {
                uploadAttempts: increment(1) as any,
                lastModified: Timestamp.fromDate(new Date()),
            });
        }
    }

    private async updateImageUrlsForDoc(docPath: string) {
        const uploadedImageUploadTasks: ImageUploadTask[] = await toPromise(
            this.imageUploadQuery.getUploadedImageUploadTasks$(docPath)
        );
        if (uploadedImageUploadTasks?.length) {
            try {
                const docRef = this.doc(docPath);
                await this.runTransaction(async (transaction: Transaction) => {
                    // This code may get re-run multiple times if there are conflicts.
                    const docSnapshot = await transaction.get(docRef);
                    if (docSnapshot.exists()) {
                        const data = docSnapshot.data();
                        const updateData = {};
                        for (const uploadedImageUploadTask of uploadedImageUploadTasks) {
                            const target = uploadedImageUploadTask.target;
                            if (target === 'ITEM') {
                                this.updateItemImages(
                                    uploadedImageUploadTask,
                                    data as Item,
                                    updateData
                                );
                            } else if (target === 'PROJECT') {
                                this.updateProjectImage(uploadedImageUploadTask, updateData);
                            } else if (target === 'ITEM_IMAGE_EDIT') {
                                this.updateItemDetailsImage(
                                    uploadedImageUploadTask,
                                    data as ItemDetails,
                                    updateData
                                );
                            } else if (target === 'ITEM_DRAWING_PIN') {
                                this.updateDrawingPinImage(uploadedImageUploadTask, updateData);
                            } else {
                                throw Error(`unsupported image upload task target: ${target}`);
                            }
                        }
                        const updated = keys(updateData).length;
                        if (updated) {
                            transaction.update(docRef, updateData);
                            this.loggerService.info(
                                LOGGER_NAME,
                                `successfully updated uploaded image urls for doc at path: ${docPath}`
                            );
                        }
                    } else {
                        this.loggerService.info(
                            LOGGER_NAME,
                            `doc does not exist at path: ${docPath}`
                        );
                    }
                });
                await this.update(
                    uploadedImageUploadTasks.map((task) => task.id),
                    {
                        status: 'COMPLETE',
                        dataUpdateAttempts: increment(1) as any,
                        lastModified: Timestamp.fromDate(new Date()),
                        pendingFileDeletion: true,
                    }
                );
                this.loggerService.info(
                    LOGGER_NAME,
                    `successfully updated ${uploadedImageUploadTasks.length} uploaded image urls for doc at path: ${docPath}`
                );
            } catch (e) {
                this.loggerService.info(
                    LOGGER_NAME,
                    `failed updating doc image with url at path: ${docPath} - error: ${e.message}`
                );
                const deviceId = await this.deviceService.getDeviceId();
                Sentry.captureException(e, (scope) => {
                    scope.setTag('service', LOGGER_NAME);
                    scope.setExtra('docPath', docPath);
                    scope.setExtra(
                        'uploadedImageUploadTasks',
                        uploadedImageUploadTasks?.length || 0
                    );
                    scope.setExtra('deviceId', deviceId);
                    return scope;
                });
                await this.update(
                    uploadedImageUploadTasks.map((task) => task.id),
                    {
                        dataUpdateAttempts: increment(1) as any,
                        lastModified: Timestamp.fromDate(new Date()),
                    }
                );
            }
        }
    }

    private updateProjectImage(
        uploadedImageUploadTask: ImageUploadTask,
        updateData: Partial<Project>
    ) {
        updateData['image.url'] = uploadedImageUploadTask.url;
    }

    private updateItemImages(
        uploadedImageUploadTask: ImageUploadTask,
        item: Item,
        updateData: Partial<Item>
    ) {
        const docImages = item.images || [];
        for (const docImage of docImages) {
            if (
                docImage.id === uploadedImageUploadTask.imageId &&
                uploadedImageUploadTask.filePath === `${docImage.directory}/${docImage.filename}` &&
                isLocalImage(docImage)
            ) {
                if (!updateData.images) {
                    updateData.images = docImages;
                }
                docImage.url = uploadedImageUploadTask.url;
            }
        }
    }

    private updateItemDetailsImage(
        uploadedImageUploadTask: ImageUploadTask,
        itemDetails: ItemDetails,
        updateData: Partial<ItemDetails>
    ) {
        if (itemDetails?.imageCanvas[uploadedImageUploadTask.imageId]) {
            updateData[`imageCanvas.${uploadedImageUploadTask.imageId}.image.url`] =
                uploadedImageUploadTask.url;
        }
    }

    private updateDrawingPinImage(
        uploadedImageUploadTask: ImageUploadTask,
        updateData: Partial<DrawingPin>
    ) {
        updateData['thumbnail.url'] = uploadedImageUploadTask.url;
    }

    private async addImageUploadTask(
        docPath: string,
        imageType: ImageType,
        filePath: string,
        imageId: string,
        imageUrl: string
    ) {
        // make image task id composite of image id and image type target as item and item details images share the same id
        const id = `${imageId}-${imageType.target}`;
        const imageUploadTask: ImageUploadTask = {
            id,
            imageId,
            url: imageUrl,
            docPath,
            target: imageType.target,
            filePath,
            status: 'PENDING',
            uploadAttempts: 0,
            dataUpdateAttempts: 0,
            createdAt: Timestamp.fromDate(new Date()),
            lastModified: Timestamp.fromDate(new Date()),
            userId: this.authService.userId,
            deviceId: await this.deviceService.getDeviceId(),
            pendingFileDeletion: false,
        };
        this.setDoc(`image-uploads/${id}`, imageUploadTask).catch(async (e) => {
            this.loggerService.info(
                LOGGER_NAME,
                `failed to add image upload task for doc at path: ${docPath} - error ${e.message}`
            );
            const deviceId = await this.deviceService.getDeviceId();
            Sentry.captureException(e, (scope) => {
                scope.setTag('service', LOGGER_NAME);
                scope.setExtra('imageUploadTask', JSON.stringify(imageUploadTask));
                scope.setExtra('deviceId', deviceId);
                return scope;
            });
        });
        this.loggerService.info(
            LOGGER_NAME,
            `added image upload task doc path: ${docPath} (${id})`
        );
    }

    private async deleteImageFromFileSystem(imageUploadTask: ImageUploadTask) {
        const fileOptions = {
            directory: Directory.Data,
            path: imageUploadTask.filePath,
        };
        try {
            await Filesystem.deleteFile(fileOptions);
            this.loggerService.info(
                LOGGER_NAME,
                `successfully deleted file system image: ${JSON.stringify(fileOptions)}`
            );
        } catch (e) {
            this.loggerService.info(
                LOGGER_NAME,
                `failed deleting file system image: ${JSON.stringify(fileOptions)} - error ${
                    e.message
                }`
            );
            const deviceId = await this.deviceService.getDeviceId();
            Sentry.captureException(e, (scope) => {
                scope.setTag('service', LOGGER_NAME);
                scope.setExtra('imageUploadTaskFilePath', imageUploadTask.filePath);
                scope.setExtra('imageUploadTaskId', imageUploadTask.id);
                scope.setExtra('deviceId', deviceId);
                return scope;
            });
        }
    }

    private updateTaskToDeleted(ids: string[]) {
        return this.update(ids, {
            status: 'DELETED',
            lastModified: Timestamp.fromDate(new Date()),
        });
    }

    private async canSaveImageOffline(): Promise<boolean> {
        return (
            Capacitor.isNativePlatform() && (await this.insiteUserPrivateQuery.offlineImageEnabled)
        );
    }

    private async loadImageIntoBrowserCache(imageUrl: string) {
        try {
            await this.httpClient.get(imageUrl).toPromise();
        } catch (e) {
            this.loggerService.info(
                LOGGER_NAME,
                `failed to load image into browser cache: ${imageUrl} - error ${e.message}`
            );
        }
    }

    private async clearFilesPendingDeletion() {
        let userId: string;
        let deviceId: string;
        try {
            userId = await toPromise(this.authService.userId$);
            deviceId = await this.deviceService.getDeviceId();
            const pendingDeletionFilesQuery = await getDocsFromServer(
                query(
                    this.collection,
                    where('pendingFileDeletion', '==', true),
                    where('userId', '==', userId),
                    where('deviceId', '==', deviceId),
                    where('lastModified', '<=', subDays(new Date(), 30))
                )
            );
            const pendingDeletionFiles = pendingDeletionFilesQuery.docs;
            this.loggerService.info(
                LOGGER_NAME,
                `found ${pendingDeletionFiles.length} files pending deletion`
            );
            if (pendingDeletionFiles.length) {
                await Promise.all(
                    pendingDeletionFiles.map(async (pendingDeletion) => {
                        await this.deleteImageFromFileSystem(pendingDeletion.data());
                        await this.update(pendingDeletion.id, { pendingFileDeletion: false });
                    })
                );
            }
        } catch (e) {
            this.loggerService.info(
                LOGGER_NAME,
                `failed to list local file pending deletion - error ${e.message}`
            );
            Sentry.captureException(e, (scope) => {
                scope.setTag('service', LOGGER_NAME);
                scope.setExtra('deviceId', deviceId);
                scope.setExtra('userId', userId);
                return scope;
            });
        }
    }
}
