import { Injectable } from '@angular/core';
import { CollectionGuard } from 'akita-ng-fire';
import { SystemService } from './system.service';
import { SystemState } from './system.store';

@Injectable({ providedIn: 'root' })
export class SystemGuard extends CollectionGuard<SystemState> {
    constructor(protected service: SystemService) {
        super(service);
    }
}
