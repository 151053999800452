import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedComponentsModule } from '@insite-group-ltd/insite-teams-shared';
import { IonicModule } from '@ionic/angular';
import { UsersToInviteModalPage } from './users-to-invite-modal.page';

@NgModule({
    imports: [CommonModule, FormsModule, IonicModule, SharedComponentsModule],
    declarations: [UsersToInviteModalPage],
})
export class UsersToInviteModalPageModule {}
