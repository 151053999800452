import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { WindowService } from '@insite-group-ltd/angular';
import { IonicModule } from '@ionic/angular';
import { AlertHeaderService } from './alert-header/alert-header.service';
import { AnalyticsService } from './analytics/analytics.service';
import { AuditedService } from './audited/audited.service';
import { CsvService } from './csv/csv.service';
import { FileService } from './file/file.service';
import { GpsService } from './gps/gps.service';
import { ImageUploadService } from './image-upload/image-upload.service';
import { ImageService } from './image/image.service';
import { KeyboardService } from './keyboard/keyboard.service';
import { LoggerService } from './logger/logger.service';
import { MapService } from './map/map.service';
import { NetworkService } from './network/network.service';
import { SentryService } from './sentry/sentry.service';
import { StorageService } from './storage/storage.service';
import { TelemetryService } from './telemetry/telemetry.service';
import { TranslationService } from './translation/translation.service';
import { UrlService } from './url/url.service';
import { UserDeviceService } from './user-device/user-device.service';
import { UtilService } from './util/util.service';
import { ZendeskService } from './zendesk/zendesk.service';

@NgModule({
    declarations: [],
    imports: [CommonModule, IonicModule],
    exports: [],
})
export class SharedServicesModule {
    public static forRoot(environment: any): ModuleWithProviders<SharedServicesModule> {
        return {
            ngModule: SharedServicesModule,
            providers: [
                AlertHeaderService,
                AnalyticsService,
                AuditedService,
                CsvService,
                FileService,
                GpsService,
                ImageService,
                ImageUploadService,
                KeyboardService,
                LoggerService,
                MapService,
                NetworkService,
                SentryService,
                StorageService,
                TelemetryService,
                TranslationService,
                UrlService,
                UserDeviceService,
                UtilService,
                WindowService,
                ZendeskService,
                {
                    provide: 'environment',
                    useValue: environment,
                },
            ],
        };
    }
}
