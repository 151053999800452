<ng-container
    *ngIf="{
    planOverview: planOverview$ | async
} as state"
>
    <ion-header class="ion-no-border admin-modal-header">
        <ion-toolbar mode="md" color="white">
            <ion-title class="font-22" color="admin-dark"
                ><b>{{state.planOverview?.type | titlecase}} plan trial</b>
            </ion-title>
            <ion-buttons slot="end">
                <ion-button (click)="close()" data-testid="trial.learn.more.close">
                    <ion-icon color="admin-dark" name="close-sharp"></ion-icon>
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>

    <div class="modal-content overflow-scroll-y ion-padding">
        <div class="margin-small">
            <ion-text color="admin-dark">
                <p class="font-weight-500 mt-0 font-15">
                    You are trialling {{state.planOverview?.type === 'ENTERPRISE' ? 'an' : 'a'}}
                    {{state.planOverview?.type | titlecase}} plan which gets you access to the
                    following features of Insite:
                </p>
            </ion-text>

            <ion-grid class="ion-no-padding">
                <ion-row>
                    <ion-col size="12" sizeMd="6">
                        <div
                            *ngFor="let feature of state.planOverview?.featureSet1"
                            class="vertical-align-content mb-4"
                        >
                            <ion-icon
                                class="font-26 mr-10 color-primary-tint flex-shrink-0"
                                name="checkmark-circle-sharp"
                            ></ion-icon>
                            <ion-text color="admin-dark"
                                ><span class="font-15 font-weight-500">{{feature}}</span>
                            </ion-text>
                        </div>
                    </ion-col>
                    <ion-col size="12" sizeMd="6">
                        <div
                            *ngFor="let feature of state.planOverview?.featureSet2"
                            class="vertical-align-content mb-4"
                        >
                            <ion-icon
                                class="font-26 mr-10 color-primary-tint flex-shrink-0"
                                name="checkmark-circle-sharp"
                            ></ion-icon>
                            <ion-text color="admin-dark"
                                ><span class="font-15 font-weight-500">{{feature}}</span>
                            </ion-text>
                        </div>
                    </ion-col>
                </ion-row>
            </ion-grid>

            <div class="vertical-align-content date-containers">
                <ion-text color="dark">
                    <p class="font-14 mb-4">
                        <b>{{state.planOverview?.createdAt?.toDate() | date: 'mediumDate'}}</b>
                    </p>
                </ion-text>
                <div class="flex-fill"></div>
                <ion-text color="dark">
                    <p class="font-14 mb-4">
                        <b>{{state.planOverview?.trialEndDate?.toDate() | date: 'mediumDate'}}</b>
                    </p>
                </ion-text>
            </div>

            <div class="trial-progress-bg mt-6">
                <div
                    [ngStyle]="{'width': state.planOverview?.trialPercentageComplete + '%'}"
                    class="trial-progress"
                ></div>
            </div>
            <div class="ion-justify-content-center vertical-align-content mt-8 width-100 mb-32">
                <ion-icon color="dark" name="time-outline"></ion-icon>
                <ion-text color="dark">
                    <p class="font-14 mt-0 mb-0 ml-6">
                        <span data-testid="trial.days.remaining">{{state.planOverview?.remainingTrialDays}}</span> days remaining
                    </p>
                </ion-text>
            </div>

            <ion-text color="admin-dark">
                <p class="font-14 mb-8">
                    At the end of the trial period your subscription will automatically commence,
                    unless you cancel during the trial period.
                </p>
            </ion-text>
        </div>
    </div>
</ng-container>
