<ion-list class="popover-list position-relative z-1000 overflow-hidden">
    <ng-container *ngFor="let group of popoverGroups">
        <ion-list-header *ngIf="group.header"
                         class="mb-neg-10 pb-0 pl-16 font-weight-500" mode="md">
            <ion-text color="primary">{{group.header}}</ion-text>
        </ion-list-header>

        <ion-radio-group *ngIf="group.radioGroup; else items" [value]="group.selected">
            <ion-item *ngFor="let option of group.options" (click)="optionFunction(option.handler)">
                <ion-label>{{option.text}}</ion-label>
                <ion-radio mode="md" slot="start" class="mr-18" [value]="option"></ion-radio>
            </ion-item>
        </ion-radio-group>

        <ng-template #items>
            <ion-item *ngFor="let option of group.options" (click)="optionFunction(option.handler)"
                      [button]="option.handler"
                      class="font-14 position-relative z-10"
                      mode="md">
                <ion-icon *ngIf="option.icon" [color]="option.selected ? 'primary' : 'dark'" [name]="option.icon" slot="start" size="small"></ion-icon>
                <ion-label *ngIf="translateLabels; else noTranslate" color="dark">
                    {{option.text | translate}}
                    <p *ngIf="option.subtitle" class="font-12" color="med-dark">{{option.subtitle}}</p>
                </ion-label>
                <ng-template #noTranslate>
                    <ion-label color="dark">
                        {{option.text}}
                        <p *ngIf="option.subtitle" class="font-12" color="med-dark">{{option.subtitle}}</p>
                    </ion-label>
                </ng-template>
            </ion-item>
        </ng-template>
    </ng-container>
</ion-list>
