import { NgModule } from '@angular/core';
import { CanAccessFeature } from './CanAccessFeature';
import { GpsCoordinatesLabel } from './GpsCoordinatesLabel';
import { InsiteUserLabel } from './InsiteUserLabel';
import { IsLocalImage } from './IsLocalImage';
import { ItemAccessDetail } from './ItemAccessDetail';
import { ProjectRoleDetail } from './ProjectRoleDetail';
import { ResolveImageUrl } from './ResolveImageUrl';
import { ResolveUnsanitizedImageUrl } from './ResolveUnsanitizedImageUrl';
import { SelectOptionLabel } from './SelectOptionLabel';
import { ShortNamePipe } from './ShortName';
import { TrueValuesPipe } from './TrueValues';

@NgModule({
    declarations: [
        InsiteUserLabel,
        ResolveImageUrl,
        ResolveUnsanitizedImageUrl,
        ShortNamePipe,
        IsLocalImage,
        ProjectRoleDetail,
        ItemAccessDetail,
        TrueValuesPipe,
        SelectOptionLabel,
        GpsCoordinatesLabel,
        CanAccessFeature,
    ],
    imports: [],
    exports: [
        InsiteUserLabel,
        ResolveImageUrl,
        ResolveUnsanitizedImageUrl,
        ShortNamePipe,
        IsLocalImage,
        ProjectRoleDetail,
        ItemAccessDetail,
        TrueValuesPipe,
        SelectOptionLabel,
        GpsCoordinatesLabel,
        CanAccessFeature,
    ],
})
export class SharedPipesModule {}
