<svg xmlns="http://www.w3.org/2000/svg"
     class="auth-bg"
     width="4645"
     height="3028"
     viewBox="0 0 4645 3028">
    <g id="Group_3771" data-name="Group 3771" transform="translate(1412 349)">
        <g id="Ellipse_302"
           data-name="Ellipse 302"
           transform="translate(1274 720)"
           fill="none"
           stroke="#f5fbfe"
           stroke-width="200"
           opacity="0.75">
            <circle cx="979.5" cy="979.5" r="979.5" stroke="none"/>
            <circle cx="979.5" cy="979.5" r="879.5" fill="none"/>
        </g>
        <g id="Ellipse_303"
           data-name="Ellipse 303"
           transform="translate(-1412 -349)"
           fill="none"
           stroke="#f5fbfe"
           stroke-width="200"
           opacity="0.75">
            <circle cx="979.5" cy="979.5" r="979.5" stroke="none"/>
            <circle cx="979.5" cy="979.5" r="879.5" fill="none"/>
        </g>
    </g>
</svg>
<div class="modal-content position-relative overflow-scroll-y">
    <div class="auth-logo vertical-align-content ion-justify-content-center">
        <img class="logo-img" src="./assets/imgs/logos/auth-logo.svg"/>
        <div class="auth-logo-text font-18 font-weight-500 ml-18 ion-padding-start">
            Choose a plan
        </div>
    </div>
    <div *ngIf="billingInterval$ | async as billingInterval" class="billing-interval-buttons mb-24">
        <ion-button (click)="setBillingInterval('monthly')"
                    [color]="billingInterval === 'monthly' ? 'primary' : 'white'"
                    class="button-32">Pay monthly
        </ion-button>
        <ion-button (click)="setBillingInterval('annual')"
                    [color]="billingInterval === 'annual' ? 'primary' : 'white'"
                    class="button-32">Pay annually
        </ion-button>
    </div>
    <ion-grid class="pt-0">
        <ion-row [ngClass]="(windowSize$ | async).desktopSize ? 'card-padding-16' : 'card-padding-10'">
            <ion-col *ngFor="let planOption of planOptions$ | async; trackBy: trackByFn"
                     class="mb-12"
                     size="12"
                     sizeMd="6"
                     sizeLg="3"
                     sizeXl="3">
                <app-plan-overview (choosePlan)="choosePlan(planOption)"
                                   [planOption]="planOption"
                                   [buyButton]="true"
                                   [allowBuyBespokePlans]="true"
                                   class="display-contents"></app-plan-overview>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="showCloseButton" class="ion-justify-content-center">
            <ion-button (click)="close()" fill="clear" class="hover-underline cursor-pointer">
                Back
            </ion-button>
        </ion-row>
    </ion-grid>
</div>
