import { Injectable } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';
import { Audited, CreatedBy, UpdatedBy } from '@insite-group-ltd/insite-teams-model';
import { AuthQuery } from '../../state/auth/auth.query';
import { DeviceService } from '../device/device.service';

@Injectable({
    providedIn: 'root',
})
export class AuditedService {
    constructor(private authQuery: AuthQuery, private deviceService: DeviceService) {}

    async markCreated<T extends Audited>(audited: T, useDateObject = false): Promise<T> {
        this.markCreatedSync(
            audited,
            useDateObject,
            await this.authQuery.userId,
            await this.authQuery.insiteUserName,
            await this.deviceService.getDeviceId()
        );
        return audited;
    }

    private markCreatedSync<T extends Audited>(
        audited: T,
        useDateObject: boolean,
        userId: string,
        insiteUserName: string,
        deviceId: string
    ): T {
        if (!audited) {
            return;
        }
        if (useDateObject) {
            const now = Date.now();
            audited.createdAt = now;
            audited.lastUpdated = now;
        } else {
            audited.createdAt = Timestamp.fromDate(new Date());
            audited.lastUpdated = Timestamp.fromDate(new Date());
        }
        audited.createdBy = userId;
        audited.createdByName = insiteUserName;
        audited.createdByDeviceId = deviceId;
        audited.lastUpdatedBy = userId;
        audited.lastUpdatedByName = insiteUserName;
        audited.lastUpdatedByDeviceId = deviceId;
        return audited;
    }

    async markUpdated<T extends UpdatedBy>(audited: T, useDateObject = false): Promise<T> {
        this.markUpdatedSync(
            audited,
            useDateObject,
            await this.authQuery.userId,
            await this.authQuery.insiteUserName,
            await this.deviceService.getDeviceId()
        );
        return audited;
    }

    private markUpdatedSync<T extends UpdatedBy>(
        audited: T,
        useDateObject: boolean,
        userId: string,
        insiteUserName: string,
        deviceId: string
    ): T {
        if (!audited) {
            return;
        }
        if (useDateObject) {
            audited.lastUpdated = Date.now();
        } else {
            audited.lastUpdated = Timestamp.fromDate(new Date());
        }
        audited.lastUpdatedBy = userId;
        audited.lastUpdatedByName = insiteUserName;
        audited.lastUpdatedByDeviceId = deviceId;
        return audited;
    }

    async getUpdated(): Promise<UpdatedBy> {
        return {
            lastUpdated: Timestamp.fromDate(new Date()),
            lastUpdatedBy: await this.authQuery.userId,
            lastUpdatedByName: await this.authQuery.insiteUserName,
            lastUpdatedByDeviceId: await this.deviceService.getDeviceId(),
        };
    }

    async getCreated(): Promise<CreatedBy> {
        return {
            createdAt: Timestamp.fromDate(new Date()),
            createdBy: await this.authQuery.userId,
            createdByName: await this.authQuery.insiteUserName,
            createdByDeviceId: await this.deviceService.getDeviceId(),
        };
    }

    async getAudited(): Promise<Audited> {
        const timestamp = Timestamp.fromDate(new Date());
        const userId = await this.authQuery.userId;
        const userName = await this.authQuery.insiteUserName;
        const deviceId = await this.deviceService.getDeviceId();
        return {
            createdAt: timestamp,
            createdBy: userId,
            createdByName: userName,
            createdByDeviceId: deviceId,
            lastUpdated: timestamp,
            lastUpdatedBy: userId,
            lastUpdatedByName: userName,
            lastUpdatedByDeviceId: deviceId,
        };
    }
}
