import { Injectable } from '@angular/core';
import { CollectionGuard } from 'akita-ng-fire';
import { catchError } from 'rxjs/operators';
import { LoggerService } from '../../services/logger/logger.service';
import { UtilService } from '../../services/util/util.service';
import { catchGuardError } from '../../utils/guard';
import { SystemService } from './system.service';
import { SystemState } from './system.store';

@Injectable({ providedIn: 'root' })
export class SystemTermsGuard extends CollectionGuard<SystemState> {
    constructor(
        protected service: SystemService,
        private utilService: UtilService,
        private loggerService: LoggerService
    ) {
        super(service);
    }

    protected sync() {
        this.loggerService.debug('system terms guard', 'syncing system terms');
        return this.service
            .syncDoc({ id: 'terms' })
            .pipe(
                catchError((err) =>
                    catchGuardError(err, 'system/terms', this.utilService, this.loggerService)
                )
            );
    }
}
