import { Component, Input, OnInit } from '@angular/core';
import { Plan } from '@insite-group-ltd/insite-teams-model';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { AuthService } from '../../state/auth/auth.service';
import { PlanQuery } from '../../state/plan/plan.query';

@Component({
    selector: 'lib-switch-plan',
    templateUrl: './switch-plan.component.html',
    styleUrls: ['./switch-plan.component.scss'],
})
export class SwitchPlanComponent implements OnInit {
    plans$: Observable<Plan[]>;
    @Input() buyAnother: boolean;

    constructor(
        private planQuery: PlanQuery,
        private authService: AuthService,
        private navController: NavController
    ) {}

    ngOnInit() {
        this.plans$ = this.planQuery.currentUserPlansAsAdmin$;
    }

    selectPlan(id: string) {
        this.authService.updateUserPrivate({ defaultPlan: id });
    }

    buyAnotherPlan() {
        this.navController.navigateForward('/app/buy');
    }

    trackByFn(_: number, plan: Plan) {
        return plan?.id;
    }
}
