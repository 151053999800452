import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { Features } from '@insite-group-ltd/insite-teams-model';
import { CollectionState } from 'akita-ng-fire';

export type PlanFeaturesState = CollectionState<Features>;

@Injectable({ providedIn: 'root' })
@StoreConfig({
    name: 'plan-features',
    idKey: 'id',
    resettable: true,
})
export class PlanFeaturesStore extends EntityStore<PlanFeaturesState> {
    constructor() {
        super();
    }
}
