import { Injectable } from '@angular/core';
import { QueryConstraint, where } from '@angular/fire/firestore';
import { CollectionConfig } from 'akita-ng-fire';
import { switchMap, tap } from 'rxjs/operators';
import { AuditedService } from '../../../services/audited/audited.service';
import { TelemetryService } from '../../../services/telemetry/telemetry.service';
import {
    BaseCollectionService,
    PlanIdPathParam,
} from '../../collection-service/base-collection.service';
import { PlanQuery } from '../plan.query';
import { PlanMeteredBillingState, PlanMeteredBillingStore } from './plan-metered-billing.store';

const COLLECTION_PATH = 'plans/:planId/metered-billing';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: COLLECTION_PATH, idKey: 'id' })
export class PlanMeteredBillingService extends BaseCollectionService<
    PlanMeteredBillingState,
    PlanMeteredBillingStore,
    PlanIdPathParam
> {
    constructor(
        protected store: PlanMeteredBillingStore,
        private planQuery: PlanQuery,
        auditedService: AuditedService,
        telemetryService: TelemetryService
    ) {
        super(COLLECTION_PATH, store, auditedService, telemetryService);
    }

    get path() {
        const parentId = this.planQuery.getActiveId();
        return `plans/${parentId}/metered-billing`;
    }

    getTransactionsForPeriod(transactionTimestamp: any, previousTransactionTimestamp: any) {
        return this.planQuery.selectActiveId().pipe(
            tap(() => this.store.reset()),
            switchMap((planId) => {
                const constraints: QueryConstraint[] = [
                    where('timestamp', '<=', transactionTimestamp),
                ];
                if (previousTransactionTimestamp) {
                    constraints.push(where('timestamp', '>=', previousTransactionTimestamp));
                }
                return this.syncCollection(`plans/${planId}/metered-billing`, constraints);
            })
        );
    }
}
