import { ElementRef, Injectable } from '@angular/core';
import { Browser, OpenOptions } from '@capacitor/browser';
import { WindowService } from '@insite-group-ltd/angular';
import {
    SelectOption,
    SortingBy,
    sortingByCreatedDesc,
    sortingByName,
} from '@insite-group-ltd/insite-teams-model';
import {
    ActionSheetController,
    AlertController,
    LoadingController,
    ModalController,
    PopoverController,
} from '@ionic/angular';
import { ComponentProps, ComponentRef, ModalOptions } from '@ionic/core';
import { AlertButton, AlertInput } from '@ionic/core/dist/types/components/alert/alert-interface';
import { HotToastService } from '@ngneat/hot-toast';
import { ToastOptions } from '@ngneat/hot-toast/lib/hot-toast.model';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { PopoverOptionGroup } from '../../components/popovers/grouped-popover/grouped-popover.component';
import { RadioGroup } from '../../components/popovers/radio-group-popover/radio-group-popover.component';
import { PopoverOption } from '../../components/popovers/select-popover/select-popover.component';
import {
    MultiSelectPopoverPageInputs,
    MultiSelectPopoverPageResult,
} from '../../components/select/multi-select-popover/multi-select-popover';
import { MultiSelectPopoverPage } from '../../components/select/multi-select-popover/multi-select-popover.page';
import { SelectPopoverPageInputs } from '../../components/select/select-popover/select-popover';
import { SelectPopoverPage } from '../../components/select/select-popover/select-popover.page';
import { isTestMode } from '../../utils/test';
// noinspection ES6PreferShortImport
import { LoggerService } from '../logger/logger.service';
import {
    ActionSheetBuilder,
    GenericPopoverBuilder,
    GroupedPopoverBuilder,
    InputPopoverBuilder,
    ModalBuilder,
    RadioGroupPopoverBuilder,
    SelectPopoverBuilder,
} from './util-builders';

@Injectable({
    providedIn: 'root',
})
export class UtilService {
    private errorShown = false;

    constructor(
        private alertController: AlertController,
        private actionSheetController: ActionSheetController,
        private loadingController: LoadingController,
        private modalController: ModalController,
        private popoverController: PopoverController,
        private translateService: TranslateService,
        private logger: LoggerService,
        private toastService: HotToastService,
        private windowService: WindowService
    ) {}

    deleteAlert(
        header: string,
        message: string,
        handler?: () => Promise<void> | void
    ): Promise<boolean> {
        return this.confirmAlert(header, message, 'Yes, delete', handler);
    }

    cancelAlert(
        header: string,
        message: string,
        handler?: () => Promise<void> | void
    ): Promise<boolean> {
        return this.confirmAlert(header, message, 'Yes, cancel', handler, 'Back');
    }

    removeAlert(
        header: string,
        message: string,
        handler?: () => Promise<void> | void
    ): Promise<boolean> {
        return this.confirmAlert(header, message, 'Yes, remove', handler);
    }

    leaveAlert(
        header: string,
        message: string,
        handler?: () => Promise<void> | void
    ): Promise<boolean> {
        return this.confirmAlert(header, message, 'Yes, leave', handler);
    }

    async confirmAlert(
        header: string,
        message: string,
        confirmMessage: string,
        handler?: (data: any) => Promise<void | boolean> | void | boolean,
        cancelMessage?: string,
        inputs?: AlertInput[],
        cssClass?: string
    ): Promise<boolean> {
        let confirmed = false;
        const buttons = [
            {
                text: cancelMessage || 'Cancel',
                cssClass: ['secondary', 'data-testid-secondary'],
            },
            {
                text: confirmMessage,
                role: 'confirm',
                handler: async (data: any) => {
                    if (handler) {
                        const result = await handler(data);
                        // allow the handler to block the closing of the alert
                        if (result === false) {
                            return false;
                        }
                    }
                    confirmed = true;
                },
                cssClass: ['data-testid-primary'],
            },
        ];
        const alert = await this.presentAlert(header, message, buttons, inputs, cssClass);
        await alert.onDidDismiss();
        return confirmed;
    }

    errorAlert(header = 'Something went wrong!', message = 'Please try again', hardReload = false) {
        if (!this.errorShown) {
            this.errorShown = true;
            return new Promise<void>((resolve) => {
                this.messageAlert(header, message, () => {
                    if (hardReload) {
                        window.location.replace('app/projects');
                    } else {
                        this.errorShown = false;
                        resolve();
                    }
                });
            });
        }
    }

    messageAlert(
        header: string,
        message?: string,
        handler?: any,
        buttonText?: string,
        cssClass?: string | string[]
    ) {
        return this.presentAlert(header, message, [
            {
                text: buttonText || 'Ok',
                role: 'cancel',
                handler,
                cssClass: cssClass,
            },
        ]);
    }

    sortAction(sortBy$: BehaviorSubject<SortingBy>) {
        return this.actionSheet
            .header('Sort')
            .button([
                {
                    text: 'Sort by A-Z',
                    icon: 'text',
                    selected: sortBy$.value?.code === sortingByName.code,
                    handler: () => {
                        sortBy$.next(sortingByName);
                        this.logger.info('helper-service', 'sort alpha');
                    },
                },
                {
                    text: 'Sort by newest first',
                    icon: 'calendar',
                    selected: sortBy$.value?.code === sortingByCreatedDesc.code,
                    handler: () => {
                        sortBy$.next(sortingByCreatedDesc);
                        this.logger.info('helper-service', 'sort created');
                    },
                },
            ])
            .present();
    }

    sortPopover(event: MouseEvent, sortBy$: BehaviorSubject<SortingBy>) {
        const sortOptions: RadioGroup[] = [
            {
                header: 'Sort by',
                values: [
                    {
                        name: 'Sort by A-Z',
                        icon: 'text',
                        id: 'A_Z',
                    },
                    {
                        name: 'Sort by newest first',
                        icon: 'calendar',
                        id: 'CREATED_DESC',
                    },
                ],
                currentValue: sortBy$.value?.code,
                handler: (sortBy) => {
                    sortBy$.next(sortBy === 'A_Z' ? sortingByName : sortingByCreatedDesc);
                },
            },
        ];
        return this.radioGroupPopover(event, sortOptions).present();
    }

    presentSavingSpinner(message = 'Saving...') {
        return this.presentLoadingSpinner(message);
    }

    async presentLoadingSpinner(message?: string) {
        const loading = await this.loadingController.create({ message, mode: 'md' });
        await loading.present();
        return loading;
    }

    async withLoadingSpinner<T>(fn: () => Promise<T>, message?: string): Promise<T> {
        const spinner = await this.presentLoadingSpinner(message);
        try {
            return await fn();
        } finally {
            spinner?.dismiss();
        }
    }

    successToast(message: string, toastOptions: ToastOptions<any> = {}): void {
        this.presentToast(message, 'success', toastOptions);
    }

    errorToast(message: string, toastOptions: ToastOptions<any> = {}): void {
        this.presentToast(message, 'error', toastOptions);
    }

    warningToast(message: string, toastOptions: ToastOptions<any> = {}): void {
        this.presentToast(message, 'warning', toastOptions);
    }

    toast(message: string, toastOptions: ToastOptions<any> = {}): void {
        this.presentToast(message, 'show', toastOptions);
    }

    private presentToast(
        message: string,
        toastFn: 'show' | 'success' | 'error' | 'warning',
        toastOptions: ToastOptions<any> = {}
    ): void {
        this.windowService.windowSize$.pipe(take(1)).subscribe((windowSize) => {
            const options: ToastOptions<any> = {
                position: windowSize.tabletSize ? 'top-right' : 'top-center',
                dismissible: true,
                duration: 3000,
                className: 'insite-hot-toast',
                autoClose: true,
                ...toastOptions,
            };
            if (toastFn === 'success') {
                this.toastService.success(message, options);
            } else if (toastFn === 'error') {
                this.toastService.error(message, options);
            } else if (toastFn === 'warning') {
                this.toastService.warning(message, options);
            } else {
                this.toastService.show(message, options);
            }
        });
    }

    async presentAlert(
        header: string,
        message: string,
        buttons: (AlertButton | string)[] = [],
        inputs: AlertInput[] = [],
        cssClass?: string | string[]
    ) {
        const alert = await this.alertController.create({
            header,
            message,
            buttons,
            inputs,
            mode: 'md',
            cssClass: cssClass || '',
        });
        await alert.present();
        return alert;
    }

    async presentAlertAwaitData(
        header: string,
        message: string,
        buttons: (AlertButton | string)[] = [],
        inputs: AlertInput[] = [],
        cssClass?: string | string[]
    ) {
        const alert = await this.presentAlert(header, message, buttons, inputs, cssClass);
        const { data } = await alert.onDidDismiss();
        return data;
    }

    async presentModal<T = never, U extends T = T>(
        component: any,
        componentProps?: U,
        options: Partial<ModalOptions> = {}
    ): Promise<HTMLIonModalElement> {
        options.component = component;
        options.componentProps = componentProps;
        const modal = await this.modalController.create(options as ModalOptions);
        await modal.present();
        return modal;
    }

    async presentModalAwaitData<T, R>(
        component: any,
        componentProps?: T,
        options: Partial<ModalOptions> = {}
    ): Promise<R> {
        const modal = await this.presentModal<T>(component, componentProps, options);
        const { data } = await modal.onDidDismiss<R>();
        return data;
    }

    dismissModal(data?: any) {
        return this.modalController.dismiss(data);
    }

    openUrl(url: string) {
        const openOptions: OpenOptions = {
            url,
            windowName: isTestMode() ? '_self' : '_blank',
        };
        Browser.open(openOptions);
    }

    closeDropdown(dropdown: ElementRef, bordered?: boolean) {
        dropdown.nativeElement.style.maxHeight = '0';
        dropdown.nativeElement.style.boxShadow = 'none';
        dropdown.nativeElement.style.overflow = 'hidden';
        if (bordered) {
            dropdown.nativeElement.style.border = 'none';
        }
    }

    openDropdown(dropdown: ElementRef, maxHeight: string, bordered?: boolean) {
        dropdown.nativeElement.style.maxHeight = maxHeight;
        dropdown.nativeElement.style.boxShadow = '0 2px 10px rgba(0,0,0,0.2)';
        dropdown.nativeElement.style.overflow = 'scroll';
        if (bordered) {
            dropdown.nativeElement.style.border = '1px solid var(--ion-color-light-med)';
        }
    }

    presentAssigneesLocationsPopover<T extends SelectOption>(
        type: 'assignees' | 'locations',
        options: T[],
        values: T[],
        endSlotTemplateRef?: any
    ): Promise<MultiSelectPopoverPageResult<T>> {
        const typeTrans = this.translateService.instant('item');
        return this.presentMultiSelectPopover({
            title: typeTrans[type],
            options,
            values,
            endSlotTemplateRef,
            autoCapitalizeInput: type === 'assignees' ? 'words' : 'sentences',
        });
    }

    presentTagsPopover<T extends SelectOption>(
        options: T[],
        values: T[]
    ): Promise<MultiSelectPopoverPageResult<T>> {
        return this.presentMultiSelectPopover({
            title: this.translateService.instant('project.tags'),
            options,
            values,
            withColours: true,
        });
    }

    async presentSelectPopover<T extends SelectOption>(
        properties: Partial<SelectPopoverPageInputs<T>>
    ): Promise<T | null> {
        const modal = await this.presentModal<Partial<SelectPopoverPageInputs<T>>>(
            SelectPopoverPage,
            properties,
            {
                cssClass: 'multi-select-popover-modal',
            }
        );
        const { data } = await modal.onDidDismiss();
        return data;
    }

    async presentMultiSelectPopover<T extends SelectOption>(
        properties: Partial<MultiSelectPopoverPageInputs<T>>
    ): Promise<MultiSelectPopoverPageResult<T>> {
        const modal = await this.presentModal<Partial<MultiSelectPopoverPageInputs<T>>>(
            MultiSelectPopoverPage,
            properties,
            {
                cssClass: 'multi-select-popover-modal',
            }
        );
        const { data } = await modal.onDidDismiss();
        return data;
    }

    get actionSheet(): ActionSheetBuilder {
        return new ActionSheetBuilder(this.actionSheetController);
    }

    modal<U, R = any, T extends ComponentRef = any>(
        component: T,
        componentProps?: U
    ): ModalBuilder<U, R, T> {
        return new ModalBuilder<U, R, T>(this.modalController, component, componentProps);
    }

    radioGroupPopover(
        event: Event,
        radioGroups?: RadioGroup[],
        cssClass?: string | string[]
    ): RadioGroupPopoverBuilder {
        return new RadioGroupPopoverBuilder(this.popoverController, event, radioGroups, cssClass);
    }

    groupedPopover(
        event: Event,
        optionGroups?: PopoverOptionGroup[],
        cssClass?: string | string[]
    ): GroupedPopoverBuilder {
        return new GroupedPopoverBuilder(this.popoverController, event, optionGroups, cssClass);
    }

    selectPopover(
        event: Event,
        options?: PopoverOption[],
        title?: string,
        cssClass?: string | string[]
    ): SelectPopoverBuilder {
        return new SelectPopoverBuilder(this.popoverController, event, options, title, cssClass);
    }

    inputPopover(
        event: Event,
        value?: string,
        title?: string,
        cssClass?: string | string[]
    ): InputPopoverBuilder {
        return new InputPopoverBuilder(this.popoverController, event, value, title, cssClass);
    }

    popover<T extends ComponentRef>(
        component: T,
        componentProps: ComponentProps<T>,
        event: Event,
        cssClass?: string | string[]
    ): GenericPopoverBuilder<T> {
        return new GenericPopoverBuilder<T>(
            this.popoverController,
            component,
            componentProps,
            event,
            cssClass
        );
    }
}
