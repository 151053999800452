import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { PopoverOption } from '../select-popover/select-popover.component';

export interface PopoverOptionGroup {
    header: string;
    options: PopoverOption[];
    radioGroup?: boolean;
    selected?: PopoverOption;
}

@Component({
    selector: 'lib-grouped-popover',
    templateUrl: './grouped-popover.component.html',
    styleUrls: ['./grouped-popover.component.scss'],
})
export class GroupedPopoverComponent implements OnInit {
    @Input() popoverGroups: PopoverOptionGroup[];
    @Input() translateLabels: boolean;

    constructor(private popoverController: PopoverController) {}

    ngOnInit() {
        for (const group of this.popoverGroups) {
            if (group.radioGroup) {
                group.selected = group.options.find((option) => option.selected);
            }
        }
    }

    optionFunction(fn: () => void) {
        if (fn) {
            fn();
            this.popoverController.dismiss();
        }
    }
}
