import round from 'lodash/round';

export interface GpsCoordinates {
    latitude: number;
    longitude: number;
}

export interface WithGpsCoordinates {
    gpsCoordinates?: GpsCoordinates;
}

export function getGpsCoordinatesLabel(gpsCoordinates: GpsCoordinates) {
    return `${round(gpsCoordinates.latitude, 5)},${round(gpsCoordinates.longitude, 5)}`;
}

export function getGpsCoordinatesGoogleMapsUrl(
    gpsCoordinates: GpsCoordinates,
    googleMapsUrlSchema = 'https://maps.google.com/?q=<lat>,<lng>'
) {
    return googleMapsUrlSchema
        .replace('<lat>', gpsCoordinates.latitude.toString())
        .replace('<lng>', gpsCoordinates.longitude.toString());
}
