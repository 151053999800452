import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { DictionaryPhrase } from '@insite-group-ltd/insite-teams-model';
import { CollectionState } from 'akita-ng-fire';

export type PlanDictionaryPhrasesState = CollectionState<DictionaryPhrase>;

@Injectable({ providedIn: 'root' })
@StoreConfig({
    name: 'plan-dictionary-phrases',
    idKey: 'id',
    resettable: true,
})
export class PlanDictionaryPhrasesStore extends EntityStore<PlanDictionaryPhrasesState> {
    constructor() {
        super();
    }
}
