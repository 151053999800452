import {
    deleteField,
    doc as docRef,
    docData,
    DocumentChange,
    DocumentReference,
    getCountFromServer,
    getDoc as getDocRef,
    getDocFromServer as getDocRefFromServer,
    Query,
    QueryConstraint,
    setDoc as setDocRef,
    SetOptions,
    WriteBatch,
} from '@angular/fire/firestore';
import { EntityStore, getEntityType } from '@datorama/akita';
import { Audited } from '@insite-group-ltd/insite-teams-model';
import {
    CollectionService,
    NoPathParams,
    pathWithParams,
    WriteOptionsWithPathParams,
} from 'akita-ng-fire';
import { AtomicWrite, PathParams, SyncOptions } from 'akita-ng-fire/lib/utils/types';
import cloneDeep from 'lodash/cloneDeep';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuditedService } from '../../services/audited/audited.service';
import { TelemetryService } from '../../services/telemetry/telemetry.service';

export interface UserIdPathParam extends Record<string, string> {
    userId: Required<string>;
}

export interface PlanIdPathParam extends Record<string, string> {
    planId: Required<string>;
}

export interface ProjectIdPathParam extends Record<string, string> {
    projectId: Required<string>;
}
export interface ProjectIdListIdPathParams extends ProjectIdPathParam {
    listId: Required<string>;
}

export interface ProjectIdDrawingIdPathParams extends ProjectIdPathParam {
    drawingId: Required<string>;
}

export interface ProjectIdListIdItemIdPathParams extends ProjectIdListIdPathParams {
    itemId: Required<string>;
}

export interface ProjectIdListTemplateIdPathParams extends ProjectIdPathParam {
    templateId: Required<string>;
}

export abstract class BaseCollectionService<
    T,
    S extends EntityStore<T>,
    U extends Record<string, string>
> extends CollectionService<T, U> {
    protected constructor(
        protected parametrisedCollectionPath: string,
        protected store: S,
        protected auditedService: AuditedService,
        protected telemetryService: TelemetryService
    ) {
        super(store);
    }

    getPath(options: PathParams) {
        return options && options.params
            ? pathWithParams(this.parametrisedCollectionPath, options.params)
            : this.currentPath;
    }

    doc(path: string) {
        return docRef(this.db, path);
    }

    collectionDoc() {
        return docRef(this.collection);
    }

    docData$(path: string) {
        const doc = docRef(this.db, path);
        return docData(doc, { idField: 'id' });
    }

    async getDocData(path: string) {
        const doc = await getDocRef(docRef(this.db, path));
        return { id: doc.id, ...doc?.data() } as getEntityType<T>;
    }

    async getDocDataFromServer(path: string) {
        const doc = await getDocRefFromServer(docRef(this.db, path));
        return { id: doc.id, ...doc?.data() } as getEntityType<T>;
    }

    async getCount(query: Query<any>) {
        const countFromServer = await getCountFromServer(query);
        return countFromServer?.data()?.count || 0;
    }

    setDoc(path: string, data: getEntityType<T>) {
        return setDocRef(this.doc(path), data);
    }

    async updateField(
        id: string,
        key: string,
        value: any,
        writeOptions: WriteOptionsWithPathParams<U>,
        markUpdated?: boolean
    ) {
        const update = {
            [key]: value,
        };
        if (markUpdated !== false) {
            await this.auditedService.markUpdated(update as Audited);
        }
        // @ts-ignore
        await this.update(id, update, writeOptions);
    }

    async updateObject(id: string, update: any, writeOptions: WriteOptionsWithPathParams<U>) {
        await this.auditedService.markUpdated(update);
        await this.update(id, update, writeOptions);
    }

    removeField(
        id: string,
        key: string,
        writeOptions: WriteOptionsWithPathParams<U>,
        markUpdated?: boolean
    ) {
        return this.updateField(id, key, deleteField(), writeOptions, markUpdated);
    }

    batch(): WriteBatch {
        return new WriteBatchExt(() => super.batch());
    }

    getWriteOptions(params?: U & NoPathParams, write?: AtomicWrite): WriteOptionsWithPathParams<U>;
    getWriteOptions(params: U, write?: AtomicWrite): WriteOptionsWithPathParams<U> {
        return {
            write,
            params,
            ctx: params,
        };
    }

    syncCollection(
        syncOptions?: Partial<SyncOptions>
    ): Observable<DocumentChange<getEntityType<T>>[]>;
    syncCollection(
        path: string,
        syncOptions?: Partial<SyncOptions>
    ): Observable<DocumentChange<getEntityType<T>>[]>;
    syncCollection(
        query: QueryConstraint[],
        syncOptions?: Partial<SyncOptions>
    ): Observable<DocumentChange<getEntityType<T>>[]>;
    syncCollection(
        path: string,
        queryFn?: QueryConstraint[],
        syncOptions?: Partial<SyncOptions>
    ): Observable<DocumentChange<getEntityType<T>>[]>;
    syncCollection(
        pathOrQuery: string | QueryConstraint[] | Partial<SyncOptions> = this.currentPath,
        queryOrOptions?: QueryConstraint[] | Partial<SyncOptions>,
        syncOptions: Partial<SyncOptions> = { loading: true }
    ): Observable<DocumentChange<getEntityType<T>>[]> {
        return (
            super
                // @ts-ignore
                .syncCollection(pathOrQuery, queryOrOptions, syncOptions)
                .pipe(
                    tap((documentChangeAction) =>
                        this.telemetryService.addFirestoreReadCollectionTelemetryData(
                            documentChangeAction
                        )
                    )
                )
        );
    }

    formatToFirestore(entity: Partial<any>) {
        if (entity['modType']) {
            const cloned = cloneDeep(entity);
            delete cloned['modType'];
            return cloned;
        }
        return entity;
    }
}

class WriteBatchExt implements WriteBatch {
    private readonly maxBatchSize = 500;
    private batches: WriteBatch[] = [];
    private currentBatch: WriteBatch;
    private currentBatchSize = 0;

    constructor(private getNewBatch: () => WriteBatch) {
        this.createNewBatch();
    }

    set<T>(documentRef: DocumentReference<T>, data: Partial<T>, options?: SetOptions) {
        this.checkBatchSize();
        this.currentBatchSize++;
        return this.currentBatch.set(documentRef, data, options);
    }

    update(documentRef: DocumentReference<any>, data: any): WriteBatch {
        this.checkBatchSize();
        this.currentBatchSize++;
        return this.currentBatch.update(documentRef, data);
    }

    delete(documentRef: DocumentReference<any>): WriteBatch {
        this.checkBatchSize();
        this.currentBatchSize++;
        return this.currentBatch.delete(documentRef);
    }

    async commit(): Promise<void> {
        for (const batch of this.batches) {
            await batch.commit();
        }
    }

    private checkBatchSize() {
        if (this.currentBatchSize >= this.maxBatchSize) {
            this.createNewBatch();
        }
    }

    private createNewBatch() {
        const batch = this.getNewBatch();
        this.batches.push(batch);
        this.currentBatch = batch;
        this.currentBatchSize = 0;
    }
}
