import { Inject, Injectable, OnDestroy } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import * as Sentry from '@sentry/capacitor';
import includes from 'lodash/includes';
import { parse } from 'query-string';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { LoggerService } from '../logger/logger.service';

@Injectable({
    providedIn: 'root',
})
export class UrlService implements OnDestroy {
    private subs = new SubSink();
    private _viewingProjectDashboard$ = new BehaviorSubject<boolean>(false);
    private _trackConversion$ = new Subject<void>();
    private _utmParams: any;

    constructor(
        private router: Router,
        private menuCtrl: MenuController,
        private loggerService: LoggerService,
        @Inject('environment') private environment
    ) {
        this.subs.sink = this.router.events
            .pipe(filter((event) => event instanceof NavigationStart))
            .subscribe((event: NavigationStart) => {
                this._viewingProjectDashboard$.next(
                    /^\/app\/project\/[a-zA-Z0-9]+(\/manage)?$/.test(event.url)
                );
                this.setMenuAccess(event.url);
                this.parseUtmParams(event.url);
            });
    }

    appProjectUrl(projectId: string) {
        return `${this.environment.appUrl}/app/project/${projectId}`;
    }

    appListUrl(projectId: string, listId: string) {
        return `${this.environment.appUrl}/app/project/${projectId}/list/${listId}/view`;
    }

    appListItemsUrl(projectId: string, listId: string) {
        return `${this.environment.appUrl}/app/project/${projectId}/list/${listId}/view/items`;
    }

    trackConversion() {
        this._trackConversion$.next();
    }

    get utmSource(): string {
        return this._utmParams?.utm_source || null;
    }

    get utmParams() {
        return this._utmParams || {};
    }

    get viewingProjectDashboard$() {
        return this._viewingProjectDashboard$.asObservable();
    }

    get trackConversion$() {
        return this._trackConversion$.asObservable();
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    private setMenuAccess(url: string) {
        if (url.startsWith('/auth') || url.startsWith('/trial')) {
            this.menuCtrl.enable(false);
        } else {
            this.menuCtrl.enable(true);
        }
    }

    private parseUtmParams(url: string) {
        if (!this._utmParams) {
            this._utmParams = {};
            try {
                const parsedParams = parse(url.substring(url.indexOf('?')));
                for (const [key, value] of Object.entries(parsedParams)) {
                    if (includes(key, 'utm_')) {
                        if (Array.isArray(value)) {
                            this._utmParams[key] = value[0];
                        } else {
                            this._utmParams[key] = value;
                        }
                    }
                }
            } catch (err) {
                this.loggerService.error('url', 'failed to parse utm params', err);
                Sentry.captureException(err);
            }
        }
    }
}
