import { Injectable } from '@angular/core';
import { NgxZendeskWebwidgetConfig, NgxZendeskWebwidgetService } from 'ngx-zendesk-webwidget';
import { AuthQuery } from '../../state/auth/auth.query';
import { AuthService } from '../../state/auth/auth.service';
import { NetworkService } from '../network/network.service';
import { UtilService } from '../util/util.service';

export class ZendeskConfig extends NgxZendeskWebwidgetConfig {
    lazyLoad = true;
    timeOut = 60000;
    injectionTag = 'body';
    accountUrl = 'insiteapp.zendesk.com';

    callback(zE) {
        zE('webWidget', 'hide');
    }
}

@Injectable({
    providedIn: 'root',
})
export class ZendeskService {
    constructor(
        private authQuery: AuthQuery,
        private authService: AuthService,
        private networkService: NetworkService,
        private zendeskWidget: NgxZendeskWebwidgetService,
        private utilService: UtilService
    ) {}

    async contactSupport() {
        await this.init();
        await this.prefill();
        this.zendeskWidget.zE('webWidget', 'updateSettings', {
            helpCenter: {
                suppress: true,
            },
        });
        this.zendeskWidget.zE('webWidget', 'show');
        this.zendeskWidget.zE('webWidget', 'open');
        this.zendeskWidget.zE('webWidget:on', 'close', () => {
            this.zendeskWidget.zE('webWidget', 'hide');
            this.zendeskWidget.zE('webWidget', 'updateSettings', {
                helpCenter: {
                    suppress: false,
                },
            });
        });
    }

    async openHelp() {
        await this.init();
        await this.prefill();
        this.zendeskWidget.zE('webWidget', 'show');
        this.zendeskWidget.zE('webWidget', 'open');
        this.zendeskWidget.zE('webWidget:on', 'close', () => {
            this.zendeskWidget.zE('webWidget', 'hide');
        });
    }

    private async prefill() {
        const isAuthenticated = this.authService.authenticated;
        if (isAuthenticated) {
            this.zendeskWidget.zE('webWidget', 'prefill', {
                name: {
                    value: (await this.authQuery.insiteUserName) || '',
                    readOnly: true,
                },
                email: {
                    value: (await this.authQuery.email) || '',
                    readOnly: true,
                },
            });
        }
    }

    private async init() {
        if (!(await this.networkService.connected)) {
            return this.utilService.messageAlert(
                `You're offline`,
                'Support is only available whilst online. Please check your connection and try again.'
            );
        }
        if (!this.zendeskWidget.isInitialized) {
            let loading: HTMLIonLoadingElement;
            try {
                loading = await this.utilService.presentLoadingSpinner();
                await this.zendeskWidget.initZendesk();
            } finally {
                await loading.dismiss();
            }
        }
    }
}
