/* eslint-disable sonarjs/no-duplicate-string */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { map, switchMap, tap } from 'rxjs/operators';
import { LoggerService } from '../../services/logger/logger.service';
import { AuthQuery } from '../auth/auth.query';
import { PlanService } from './plan.service';

@Injectable({ providedIn: 'root' })
export class AdminPlansAccessGuard implements CanActivate {
    constructor(
        private authQuery: AuthQuery,
        private planService: PlanService,
        private loggerService: LoggerService,
        private router: Router
    ) {}

    canActivate(next: ActivatedRouteSnapshot) {
        return this.authQuery.userId$.pipe(
            tap((userId$) =>
                this.loggerService.debug('admin plans access guard', 'checking admin plans', {
                    userId$,
                })
            ),
            switchMap((userId) => this.planService.getUserPlansCount(userId)),
            map((userPlansCount) => {
                this.loggerService.debug(
                    'admin plans access guard',
                    'user plans: ' + userPlansCount
                );
                if (!userPlansCount) {
                    if (next?.data?.path === 'get-started' || next.url[0]?.path === 'checkout') {
                        this.loggerService.debug(
                            'admin plans access guard',
                            'no plans as admin but going to checkout so allow'
                        );
                        return true;
                    } else {
                        let path = `/app/get-started`;
                        if (next.queryParams.plan) {
                            path += `?plan=${next.queryParams.plan}`;
                            if (next.queryParams.quantity) {
                                path += `&quantity=${next.queryParams.quantity}`;
                            }
                        }
                        this.loggerService.debug(
                            'admin plans guard',
                            'redirect to get started as not allowed elsewhere'
                        );
                        return this.router.parseUrl(path);
                    }
                } else if (next?.data?.path === 'get-started' || next.url[0]?.path === 'checkout') {
                    this.loggerService.debug(
                        'admin plans guard',
                        'redirect to buy as user already has an existing plan'
                    );
                    return this.router.parseUrl('/app/buy');
                } else {
                    return true;
                }
            })
        );
    }
}
