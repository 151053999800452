/* eslint-disable no-console */
import { Inject, Injectable } from '@angular/core';
import * as Sentry from '@sentry/capacitor';

@Injectable({
    providedIn: 'root',
})
export class LoggerService {
    constructor(@Inject('environment') private environment) {}

    public debug(category: string, message: any, data?: any) {
        this.log(console.log, 'debug', category, message, data);
    }

    public info(category: string, message: any, data?: any) {
        this.log(console.log, 'info', category, message, data);
    }

    public warn(category: string, message: any, data?: any) {
        this.log(console.warn, 'warning', category, message, data);
    }

    public error(category: string, message: any, data?: any) {
        this.log(console.error, 'error', category, message, data);
    }

    private log(
        logFunction: any,
        severity: Sentry.SeverityLevel,
        category: string,
        message: any,
        data?: any
    ) {
        if (this.environment.testMode && severity !== 'error') {
            return;
        }
        const statement = `[${category}] - ${message}`;
        Sentry.addBreadcrumb({ message: statement, level: severity, data });
        if (data) {
            if (!this.environment.production) {
                logFunction(statement, data);
            }
        } else if (!this.environment.production) {
            logFunction(statement);
        }
    }
}
