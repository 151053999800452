import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { Project } from '@insite-group-ltd/insite-teams-model';
import { CollectionState } from 'akita-ng-fire';

export type ProjectState = CollectionState<Project>;

@Injectable({ providedIn: 'root' })
@StoreConfig({
    name: 'project',
    idKey: 'id',
    resettable: true,
})
export class ProjectStore extends EntityStore<ProjectState> {
    constructor() {
        super();
    }
}
