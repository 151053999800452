import includes from 'lodash/includes';
import isArray from 'lodash/isArray';
import { PlanType } from './plan';

export interface FeatureFlags {
    pinturaImageEditor?: boolean;
    resolveUserCountryCode?: boolean;
    cardlessTrial?: boolean;
    captureTelemetry?: boolean;
    whatsNew?: boolean;
    bookDemoEnabled?: boolean;
}

export const DEFAULT_FEATURE_FLAGS: Required<FeatureFlags> = {
    pinturaImageEditor: true,
    resolveUserCountryCode: true,
    cardlessTrial: true,
    captureTelemetry: false,
    whatsNew: true,
    bookDemoEnabled: true,
};

export interface FeatureConfig {
    maxFileUploadSizeInMB?: number;
    maxPdfUploadSizeInMB?: number;
    maxImageUploadSizeInMB?: number;
    flushTelemetryIntervalSeconds?: number;
    maxListItemCount?: number;
    scheduledEmailNotificationFrequencyMinutes?: number;
    bookDemoMeetingLink?: string;
}

export const DEFAULT_FEATURE_CONFIG: Required<FeatureConfig> = {
    maxFileUploadSizeInMB: 10,
    maxPdfUploadSizeInMB: 10,
    maxImageUploadSizeInMB: 10,
    flushTelemetryIntervalSeconds: 60,
    maxListItemCount: 750,
    scheduledEmailNotificationFrequencyMinutes: 15,
    bookDemoMeetingLink: 'https://meetings.hubspot.com/dhawtrey/30-min-demo',
};

type Access = PlanType[] | boolean;

export interface FeatureAccess {
    drawings?: Access;
    reportShareViaEmail?: Access;
    dictionaryPhrases?: Access;
    itemFiles?: Access;
    statusRequests?: Access;
    itemSections?: Access;
}

export const DEFAULT_FEATURE_ACCESS: Required<FeatureAccess> = {
    drawings: ['ENTERPRISE'],
    reportShareViaEmail: ['BASIC', 'TEAM', 'ENTERPRISE'],
    dictionaryPhrases: ['ENTERPRISE'],
    itemFiles: ['ENTERPRISE'],
    statusRequests: ['TEAM', 'ENTERPRISE'],
    itemSections: ['ENTERPRISE'],
};

export function canAccessFeature(
    featureAccess: FeatureAccess,
    feature: keyof FeatureAccess,
    planType: PlanType
) {
    const value = featureAccess[feature];
    return isArray(value) ? includes(value, planType) : !!value;
}

export interface Features {
    featureFlags?: FeatureFlags;
    featureConfig?: FeatureConfig;
    featureAccess?: FeatureAccess;
}

export const EMPTY_FEATURES: Features = {
    featureFlags: {},
    featureConfig: {},
    featureAccess: {},
};
