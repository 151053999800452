import { Injectable, OnDestroy } from '@angular/core';
import {
    BaseList,
    enTranslations,
    ItemFieldSet,
    Translations,
} from '@insite-group-ltd/insite-teams-model';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Path } from 'dot-path-value';
import camelCase from 'lodash/camelCase';
import merge from 'lodash/merge';
import set from 'lodash/set';
import trimEnd from 'lodash/trimEnd';
import { SubSink } from 'subsink';

@Injectable({
    providedIn: 'root',
})
export class TranslationService implements OnDestroy {
    private subs = new SubSink();
    private defaultLangTranslations = {};
    private projectTranslations = {};
    private listTranslations: any = {};

    constructor(private translateService: TranslateService) {
        this.subs.sink = translateService.onLangChange.subscribe((params: LangChangeEvent) => {
            this.defaultLangTranslations[params.lang] = params.translations;
        });
    }

    init() {
        this.translateService.setTranslation('en', enTranslations);
        this.translateService.setDefaultLang('en');
        this.translateService.use('en');
    }

    setProjectTranslations(projectTranslations: Translations | undefined) {
        this.projectTranslations = projectTranslations || {};
        this.setMergedTranslations();
    }

    setListTranslations(list?: BaseList, itemFieldSet?: ItemFieldSet) {
        this.listTranslations = {};
        if (list?.statusGroup) {
            const itemStatus = {};
            list.statusGroup.options.forEach((option) => {
                itemStatus[option.id] = option;
            });
            this.listTranslations.itemStatus = itemStatus;
        }
        if (itemFieldSet) {
            Object.values(itemFieldSet.fields)
                .filter((field) => field.enabled && field.label)
                .forEach((field) => {
                    set(this.listTranslations, `item.${camelCase(field.id)}`, field.label);
                });
        }
        this.setMergedTranslations();
    }

    private setMergedTranslations() {
        this.translateService.setTranslation(
            this.translateService.currentLang,
            merge(
                {},
                this.defaultLangTranslations[this.translateService.currentLang],
                this.projectTranslations,
                this.listTranslations
            )
        );
    }

    get defaultTranslations(): Translations {
        return this.defaultLangTranslations[this.translateService.currentLang];
    }

    getLowerSingular(key: Path<Translations>) {
        const trans = this.get(key);
        if (trans) {
            return trimEnd(trans.toLowerCase(), 's');
        }
        return '';
    }

    getSingular(key: Path<Translations>) {
        const trans = this.get(key);
        if (trans) {
            return trimEnd(trans, 's');
        }
        return '';
    }

    getLower(key: Path<Translations>) {
        const trans = this.get(key);
        if (trans) {
            return trans.toLowerCase();
        }
        return '';
    }

    get(key: Path<Translations>) {
        return this.translateService.instant(key);
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}
