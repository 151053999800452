import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { filterNilValue } from '@datorama/akita';
import { Plan } from '@insite-group-ltd/insite-teams-model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PlanQuery } from '../state/plan/plan.query';

@Injectable({
    providedIn: 'root',
})
export class PlanNotBasicGuard implements CanActivate {
    constructor(private planQuery: PlanQuery, private router: Router) {}

    canActivate(): Observable<boolean | UrlTree> {
        return this.planQuery.selectActive().pipe(
            filterNilValue(),
            map((plan: Plan) =>
                plan.type !== 'BASIC' ? true : this.router.createUrlTree(['/app'])
            )
        );
    }
}
