import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { PlanOption, PlanTypeId } from '@insite-group-ltd/insite-teams-model';
import { AuthService, PlanQuery } from '@insite-group-ltd/insite-teams-shared';
import includes from 'lodash/includes';
import { combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { PaddleService } from '../../services/paddle/paddle.service';
import { PlanOptionState, PlanOptionStore } from './plan-option.store';

@Injectable({ providedIn: 'root' })
export class PlanOptionQuery extends QueryEntity<PlanOptionState> {
    billingInterval$ = this.select((state) => state.billingInterval).pipe(distinctUntilChanged());
    planType$ = this.select((state) => state.planType).pipe(distinctUntilChanged());
    quantity$ = this.select((state) => state.quantity || 1).pipe(distinctUntilChanged());

    constructor(
        protected store: PlanOptionStore,
        private planQuery: PlanQuery,
        private authService: AuthService,
        private paddleService: PaddleService
    ) {
        super(store);
    }

    get planType() {
        return this.getValue().planType;
    }

    get billingInterval() {
        return this.getValue().billingInterval;
    }

    get coupon() {
        return this.getValue().coupon;
    }

    get couponPlans() {
        return this.getValue().couponPlans;
    }

    get couponDiscount() {
        return this.getValue().couponDiscount;
    }

    get couponDuration() {
        return this.getValue().couponDuration;
    }

    get quantity() {
        return this.getValue().quantity;
    }

    get canUserTrial$(): Observable<boolean> {
        return this.authService.authenticated$.pipe(
            switchMap((authenticated) => {
                if (authenticated) {
                    return this.planQuery.currentUserPlansAsAdmin$.pipe(
                        map((plans) => !plans?.length)
                    );
                } else {
                    return of(true);
                }
            })
        );
    }

    get selectedPlanOption$(): Observable<PlanOption> {
        return combineLatest([this.select(), this.canUserTrial$]).pipe(
            switchMap(([planOptionState, canUserTrial]) => {
                const {
                    planType,
                    billingInterval,
                    quantity,
                    couponPlans,
                    couponDiscount,
                    couponDuration,
                } = planOptionState;
                if (planType && billingInterval) {
                    return this.paddleService.getPlanOption(planType, billingInterval).pipe(
                        map((planOption) => {
                            if (planOption) {
                                this.enrichPlanOption(
                                    planOption,
                                    quantity,
                                    couponPlans,
                                    couponDiscount,
                                    couponDuration,
                                    canUserTrial
                                );
                                return planOption;
                            } else {
                                return null;
                            }
                        })
                    );
                } else {
                    return of(null);
                }
            })
        );
    }

    get planOptions$(): Observable<PlanOption[]> {
        return combineLatest([this.select(), this.canUserTrial$]).pipe(
            switchMap(([planOptionState, canUserTrial]) => {
                const { billingInterval, quantity, couponPlans, couponDiscount, couponDuration } =
                    planOptionState;
                return this.paddleService.getPlanOptions(billingInterval).pipe(
                    map((planOptions) => {
                        planOptions.forEach((planOption) =>
                            this.enrichPlanOption(
                                planOption,
                                quantity,
                                couponPlans,
                                couponDiscount,
                                couponDuration,
                                canUserTrial
                            )
                        );
                        return planOptions;
                    })
                );
            })
        );
    }

    getPlanOptionOfType$(id: PlanTypeId): Observable<PlanOption> {
        return this.planOptions$.pipe(
            map((planOptions) => planOptions.find((planOption) => planOption.id === id))
        );
    }

    private enrichPlanOption(
        planOption: PlanOption,
        quantity: number,
        couponPlans: number[],
        couponDiscount: number = 0,
        couponDuration: PlanOptionState['couponDuration'],
        canUserTrial: boolean
    ) {
        planOption.withTrial = canUserTrial && !couponPlans.length;
        planOption.discountDuration = couponDuration;
        if (planOption.supportsQuantity) {
            planOption.price = planOption.price * quantity;
        }
        if (
            planOption.paddleIds.some((paddleId) => includes(couponPlans, paddleId)) &&
            couponDiscount
        ) {
            planOption.discountedPrice = planOption.price * ((100 - couponDiscount) / 100);
            planOption.discountPercentage = couponDiscount;
        }
    }
}
