import { Inject, Injectable } from '@angular/core';
import * as Sentry from '@sentry/capacitor';

@Injectable({
    providedIn: 'root',
})
export class SentryService {
    constructor(@Inject('environment') private environment) {}

    captureException(err: any, captureContext?: any) {
        if (this.environment.production) {
            Sentry.captureException(err, captureContext);
        } else {
            // eslint-disable-next-line no-console
            console.error(err, captureContext);
        }
    }
}
