import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { PlanDetails } from '@insite-group-ltd/insite-teams-model';
import { CollectionState } from 'akita-ng-fire';

export type PlanDetailsState = CollectionState<PlanDetails>;

@Injectable({ providedIn: 'root' })
@StoreConfig({
    name: 'plan-details',
    idKey: 'id',
    resettable: true,
})
export class PlanDetailsStore extends EntityStore<PlanDetailsState> {
    constructor() {
        super();
    }
}
