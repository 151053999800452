import { Path } from 'dot-path-value';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import pick from 'lodash/pick';
import unset from 'lodash/unset';

export function fieldsChanged<T>(before: T, after: T, fieldsToIgnore: Path<T>[] = []): (keyof T)[] {
    const differences = [];
    before = cloneDeep(before);
    after = cloneDeep(after);
    if (fieldsToIgnore.length) {
        fieldsToIgnore.forEach((field) => {
            unset(before, field);
            unset(after, field);
        });
    }
    if (before && after) {
        const newFields = difference(Object.keys(after), Object.keys(before));
        newFields.forEach((newField) => {
            const newFieldValue = after[newField];
            if (
                (!newFieldValue && isString(newFieldValue)) ||
                isNil(newFieldValue) ||
                (Array.isArray(newFieldValue) && !newFieldValue.length)
            ) {
                delete after[newField];
            }
        });
    }
    if (after) {
        Object.keys(after)
            .filter((field) => !isEqual((before || {})[field], after[field]))
            .forEach((field) => {
                differences.push(field);
            });
    }
    return differences;
}

export function isFieldsChanged<T>(before: T, after: T, fieldsToIgnore: Path<T>[] = []) {
    if (!isNil(before)) {
        return !!fieldsChanged<T>(before, after, fieldsToIgnore)?.length;
    }
}

export function onlyFieldsChanged<T>(before: T, after: T, fieldsToIgnore: Path<T>[] = []) {
    return pick(after, fieldsChanged<T>(before, after, fieldsToIgnore));
}
