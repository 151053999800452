import { Component, Input } from '@angular/core';
import { WindowService } from '@insite-group-ltd/angular';
import { BillingInterval, PlanOption } from '@insite-group-ltd/insite-teams-model';
import { UtilService } from '@insite-group-ltd/insite-teams-shared';
import { PlanOptionQuery, PlanOptionState, PlanOptionStore } from '../state/plan-option';

@Component({
    selector: 'app-change-plan',
    templateUrl: './change-plan.page.html',
    styleUrls: ['./change-plan.page.scss'],
})
export class ChangePlanPage {
    @Input() showCloseButton: boolean;

    planOptions$ = this.planOptionQuery.planOptions$;
    billingInterval$ = this.planOptionQuery.billingInterval$;
    windowSize$ = this.windowService.windowSize$;

    constructor(
        private utilService: UtilService,
        private windowService: WindowService,
        private planOptionStore: PlanOptionStore,
        private planOptionQuery: PlanOptionQuery
    ) {}

    choosePlan(planOption: PlanOption) {
        this.planOptionStore.updatePlanType(planOption.id as PlanOptionState['planType']);
        this.utilService.dismissModal(true);
    }

    close() {
        this.utilService.dismissModal();
    }

    setBillingInterval(billingInterval: BillingInterval) {
        this.planOptionStore.updateBillingInterval(billingInterval);
    }

    trackByFn(_: number, planOption: PlanOption) {
        return planOption?.id;
    }
}
