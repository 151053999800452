import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { TrialLearnMorePage } from './trial-learn-more.page';
import { ComponentsModule } from '../components/components.module';

@NgModule({
    imports: [CommonModule, FormsModule, IonicModule, ComponentsModule],
    declarations: [TrialLearnMorePage],
})
export class TrialLearnMorePageModule {}
