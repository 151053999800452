import { NgModule } from '@angular/core';
import { User } from '@angular/fire/auth';
import { AuthGuard } from '@angular/fire/auth-guard';
import { ActivatedRouteSnapshot, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {
    ActivePlanDetailsGuard,
    ActivePlanDictionaryPhrasesGuard,
    ActivePlanFeaturesGuard,
    ActivePlanGuard,
    ActivePlanOwnerGuard,
    ActivePlanTransactionsGuard,
    ActivePlanUsersGuard,
    ActiveProjectAndPlanUsersGuard,
    ActiveProjectGuard,
    AdminPlansAccessGuard,
    AdminPlansGuard,
    InsiteUserPrivateGuard,
    PlanNotBasicGuard,
    PlanProjectsGuard,
    SystemGuard,
} from '@insite-group-ltd/insite-teams-shared';
import get from 'lodash/get';
import { map } from 'rxjs/operators';

export const redirectToVerificationOrLogin = (next: ActivatedRouteSnapshot) =>
    map((user: User) => {
        const queryParams = new URLSearchParams(next?.queryParams || {});
        if (user) {
            if (user.emailVerified) {
                return true;
            } else {
                return `/auth/verification${queryParams.toString()}`;
            }
        } else {
            const redirect = get(next, '_routerState.url');
            if (get(window, 'localStorage.setItem')) {
                window.localStorage.setItem('redirectUrl', redirect);
            }
            return `/auth?${queryParams.toString()}`;
        }
    });

const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then((m) => m.AuthPageModule),
    },
    {
        path: 'app',
        canActivate: [AuthGuard, SystemGuard],
        canDeactivate: [SystemGuard],
        data: { authGuardPipe: redirectToVerificationOrLogin },
        children: [
            {
                path: '',
                canActivate: [
                    AdminPlansGuard,
                    ActivePlanGuard,
                    ActivePlanDetailsGuard,
                    InsiteUserPrivateGuard,
                    ActivePlanFeaturesGuard,
                    AdminPlansAccessGuard,
                ],
                canDeactivate: [
                    AdminPlansGuard,
                    ActivePlanGuard,
                    ActivePlanDetailsGuard,
                    InsiteUserPrivateGuard,
                    ActivePlanFeaturesGuard,
                ],
                children: [
                    {
                        path: '',
                        redirectTo: 'projects',
                        pathMatch: 'full',
                    },
                    {
                        path: 'members',
                        loadChildren: () =>
                            import('./plan-members/plan-members.module').then(
                                (m) => m.PlanMembersPageModule
                            ),
                        canActivate: [ActivePlanUsersGuard, PlanNotBasicGuard],
                        canDeactivate: [ActivePlanUsersGuard],
                    },
                    {
                        path: 'manage',
                        canActivate: [ActivePlanOwnerGuard, PlanProjectsGuard],
                        canDeactivate: [ActivePlanOwnerGuard, PlanProjectsGuard],
                        children: [
                            {
                                path: '',
                                loadChildren: () =>
                                    import('./manage-plan/manage-plan.module').then(
                                        (m) => m.ManagePlanPageModule
                                    ),
                            },
                            {
                                path: 'billing-history',
                                canActivate: [ActivePlanTransactionsGuard],
                                canDeactivate: [ActivePlanTransactionsGuard],
                                loadChildren: () =>
                                    import(
                                        './manage-plan/billing-history/billing-history.module'
                                    ).then((m) => m.BillingHistoryPageModule),
                            },
                        ],
                    },
                    {
                        path: 'settings',
                        children: [
                            {
                                path: '',
                                loadChildren: () =>
                                    import('./settings/settings.module').then(
                                        (m) => m.SettingsPageModule
                                    ),
                            },
                            {
                                path: 'item-details-dictionary',
                                canActivate: [ActivePlanDictionaryPhrasesGuard],
                                canDeactivate: [ActivePlanDictionaryPhrasesGuard],
                                loadChildren: () =>
                                    import(
                                        './settings/item-details-dictionary/item-details-dictionary.module'
                                    ).then((m) => m.ItemDetailsDictionaryPageModule),
                            },
                        ],
                    },
                    {
                        path: 'resources',
                        loadChildren: () =>
                            import('./resources/resources.module').then(
                                (m) => m.ResourcesPageModule
                            ),
                    },
                    {
                        path: 'projects',
                        canActivate: [PlanProjectsGuard],
                        canDeactivate: [PlanProjectsGuard],
                        children: [
                            {
                                path: '',
                                loadChildren: () =>
                                    import('./projects/projects.module').then(
                                        (m) => m.ProjectsPageModule
                                    ),
                            },
                            {
                                path: 'new',
                                loadChildren: () =>
                                    import('./projects/manage-project/manage-project.module').then(
                                        (m) => m.ManageProjectPageModule
                                    ),
                            },
                            {
                                path: ':projectId',
                                canActivate: [ActiveProjectGuard, ActiveProjectAndPlanUsersGuard],
                                canDeactivate: [ActiveProjectGuard, ActiveProjectAndPlanUsersGuard],
                                children: [
                                    {
                                        path: 'manage',
                                        // eslint-disable-next-line sonarjs/no-identical-functions
                                        loadChildren: () =>
                                            import(
                                                './projects/manage-project/manage-project.module'
                                            ).then((m) => m.ManageProjectPageModule),
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'buy',
                        loadChildren: () =>
                            import('./get-started/get-started.module').then(
                                (m) => m.GetStartedPageModule
                            ),
                        data: {
                            title: 'Buy another plan',
                        },
                    },
                ],
            },
            {
                path: 'get-started',
                loadChildren: () =>
                    import('./get-started/get-started.module').then((m) => m.GetStartedPageModule),
                canActivate: [AdminPlansGuard],
                canDeactivate: [AdminPlansGuard],
                data: {
                    path: 'get-started',
                    title: 'Get started',
                },
            },
        ],
    },
    {
        path: '**',
        redirectTo: '/app/projects',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
            relativeLinkResolution: 'legacy',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
