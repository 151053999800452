import { Injectable } from '@angular/core';
import { orderBy } from '@angular/fire/firestore';
import { CollectionGuard } from 'akita-ng-fire';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { LoggerService } from '../../../services/logger/logger.service';
import { UtilService } from '../../../services/util/util.service';
import { catchGuardError } from '../../../utils/guard';
import { PlanQuery } from '../plan.query';
import { PlanDictionaryPhrasesService } from './plan-dictionary-phrases.service';
import { PlanDictionaryPhrasesState } from './plan-dictionary-phrases.store';

@Injectable({ providedIn: 'root' })
export class ActivePlanDictionaryPhrasesGuard extends CollectionGuard<PlanDictionaryPhrasesState> {
    constructor(
        protected service: PlanDictionaryPhrasesService,
        private planQuery: PlanQuery,
        private utilService: UtilService,
        private loggerService: LoggerService
    ) {
        super(service);
    }

    protected sync() {
        return this.planQuery.currentPlanId$.pipe(
            tap((currentPlanId$) =>
                this.loggerService.debug(
                    'active plan dictionary phrases guard',
                    'syncing active plan dictionary phrases',
                    {
                        currentPlanId$,
                    }
                )
            ),
            switchMap((planId) =>
                this.service.syncCollection(`plans/${planId}/dictionary-phrases`, [
                    orderBy('phrase', 'asc'),
                ])
            ),
            catchError((err) =>
                catchGuardError(
                    err,
                    `plans/$planId/dictionary-phrases`,
                    this.utilService,
                    this.loggerService
                )
            )
        );
    }
}
