import keys from 'lodash/keys';
import { Audited } from './audited';
import { Image } from './image';
import { InsiteUser } from './insite-user';
import { ItemPermission, ListVisibility } from './list';
import { TemplateManagementOptions } from './settings';
import { PlanTypeId } from './subscription';
import { Translations } from './translations';

export const PLAN_ROLES: PlanRole[] = ['ADMIN', 'INTERNAL'];

export type PlanRole = 'ADMIN' | 'INTERNAL';

export type PlanType = 'BASIC' | 'TEAM' | 'ENTERPRISE';

export type PlanStatus = 'pending' | 'active' | 'deleted' | 'paused' | 'trialing' | 'past_due';

export const PLAN_HIERARCHY: PlanType[] = ['BASIC', 'TEAM', 'ENTERPRISE'];

export interface Plan extends Audited {
    id: string;
    type: PlanType;
    status: PlanStatus;
    users: Record<string, PlanRole>;
    invitedUsers: any;
    company: string;
    address?: string;
    phoneNumber?: string;
    additionalCompanyDetails?: AdditionalCompanyDetail[];
    projectCount: any;
    projectsCreatedInBillingPeriodCount?: number;
    maxProjectCount?: number | null;
    maxProjectsCreatedInBillingPeriodCount?: number;
    maxUserCount?: number;
    maxUserCountBreakdown?: {
        internal?: number;
        external?: number;
    };
    maxPlanMemberCount?: number;
    subscription_id?: string;
    subscription_plan_id?: string;
    subscriptionNameOverride?: string;
    trialEndDate?: any;
    settings?: PlanSettings;
}

export interface PlanDetails {
    id: string;
    update_url: string;
    cancel_url: string;
    next_bill_date?: any;
    next_payment_amount?: number;
    currency: string;
    quantity: string;
    subscription_id: string;
    subscription_plan_id: string;
    cancellation_effective_date: any;
    event_time: any;
    billingType?: 'FLAT' | 'METERED';
    flatRate?: string;
    meteredRates?: MeteredRate[];
    unarchiveCharge: boolean;
}

export interface PlanStorage {
    storageLimit?: number;
    totalStorageUsed?: number;
    storageUsedByUser?: Record<string, number>;
    storageUsedByProject?: Record<string, number>;
}

export interface AdditionalCompanyDetail {
    name: string;
    value: string;
}

export interface PlanSettings {
    companyLogo: Image;
    listPrivacy: ListVisibility;
    reportColour: string;
    translations?: Translations;
    defaultItemAccess?: DefaultItemAccessSetting;
    templateManagement?: TemplateManagementOptions;
}

export interface DefaultItemAccessSetting {
    ADMIN: ItemPermission;
    INTERNAL: ItemPermission;
    EXTERNAL: ItemPermission;
    READ_ONLY: ItemPermission;
}

export interface DictionaryPhrase {
    id: string;
    phrase: string;
    title: boolean;
    description: boolean;
    planId: string;
    useCountTitle: number;
    useCountDescription: number;
}

export type DictionaryPhrasesByAvailability = Record<DictionaryUsage, string[]>;

export type DictionaryEntriesByAvailability = Record<DictionaryUsage, DictionaryPhrase[]>;

export const EMPTY_DICTIONARY_ENTRIES_BY_AVAILABILITY: DictionaryEntriesByAvailability = {
    ALL: [],
    TITLE: [],
    DESCRIPTION: [],
    INACTIVE: [],
};

export type DictionaryUsage = 'ALL' | 'TITLE' | 'DESCRIPTION' | 'INACTIVE';

export interface MeteredRate {
    name: string;
    threshold: number;
    rate: number;
}

export interface MeteredCharge {
    id: string;
    timestamp: any;
    amount: number;
    quantity: number;
    breakdown: MeteredChargeBreakdown[];
}

export interface MeteredChargeBreakdown extends MeteredRate {
    quantity: number;
    amount: number;
    details: {
        projects: {
            id: string;
            name: string;
        }[];
    };
}

export interface PlanTransaction {
    id?: string;
    checkout_id: string;
    type:
        | 'subscription_payment_succeeded'
        | 'subscription_payment_failed'
        | 'subscription_payment_refunded';
    currency: string;
    order_id: string;
    payment_method: string;
    payment_tax: string;
    plan_name: string;
    quantity: string;
    sale_gross: string;
    receipt_url: string;
    subscription_id: string;
    subscription_plan_id: string;
    subscription_payment_id: string;
    unit_price: string;
    event_time: any;
    next_retry_date: any;
    next_bill_date: any;
    attempt_number: string;
    gross_refund: string;
    refund_reason: string;
    refund_type: string;
    tax_refund: string;
    amount: string;
}

export function getPlanTransactionStatus(planTransaction: PlanTransaction): string {
    switch (planTransaction?.type) {
        case 'subscription_payment_succeeded': {
            return 'Success';
        }
        case 'subscription_payment_failed': {
            return 'Failed';
        }
        case 'subscription_payment_refunded': {
            return 'Refunded';
        }
        default: {
            return '';
        }
    }
}

export interface UserInPlan {
    userId: string;
    role: PlanRole;
    insiteUser: InsiteUser;
}

export class CurrentUsersPlan {
    constructor(private userId?: string, public plan?: Plan) {}

    get planId(): string {
        return this.plan ? this.plan.id : '';
    }

    get canCreateProject(): boolean {
        return this.isAdmin;
    }

    get isAdmin(): boolean {
        return !!this.plan && !!this.userId && this.plan.users[this.userId] === 'ADMIN';
    }

    get isInternal(): boolean {
        return !!this.plan && !!this.userId && this.plan.users[this.userId] === 'INTERNAL';
    }

    static none() {
        return new CurrentUsersPlan(undefined, undefined);
    }
}

export function isPlanActive(plan: Plan): boolean {
    const { status } = plan;
    return status === 'active' || status === 'trialing' || status === 'past_due';
}

export function getPlanStatus(plan: Plan): string {
    switch (plan?.status) {
        case 'active': {
            return 'Active';
        }
        case 'deleted': {
            return 'Cancelled';
        }
        case 'trialing': {
            return 'Trialing';
        }
        case 'paused': {
            return 'Paused';
        }
        case 'past_due': {
            return 'Active (Payment failed)';
        }
        default: {
            return '';
        }
    }
}

export function getPlanMemberCount(plan: Plan): number {
    return keys(plan.users).length + (plan.invitedUsers || []).length;
}

export function hasReachedMaxPlanMemberLimit(plan: Plan): boolean {
    return !!plan.maxPlanMemberCount && getPlanMemberCount(plan) >= plan.maxPlanMemberCount;
}

export function isPlanDowngrade(beforeType: PlanType, afterType: PlanType) {
    return PLAN_HIERARCHY.indexOf(afterType) < PLAN_HIERARCHY.indexOf(beforeType);
}

export interface PlanOption {
    id: PlanTypeId;
    name: string;
    paddleIds: number[];
    quantityInfo?: string;
    description: string;
    price?: number;
    discountedPrice?: number;
    discountDuration?: 'single' | 'lifetime';
    currency?: string;
    trialDays?: number;
    rate?: string;
    btnText: string;
    featureHeadline: string;
    featureSet1: string[];
    featureSet2: string[];
    type: PlanType;
    discountPercentage?: number;
    withTrial: boolean;
    billingInterval?: BillingInterval;
    supportsQuantity?: boolean;
    quantityUnit?: string;
    supportsUpgradeTo?: string;
    mostPopular?: boolean;
    maxQuantity?: number;
}

export interface PlanOwner {
    name: string;
    email: string;
}

export interface PlanOverview {
    name: string;
    type: PlanType;
    status: PlanStatus;
    billingWarning: boolean;
    remainingTrialDays?: number;
    trialPercentageComplete: number;
    trialEndDate: any;
    createdAt: any;
    featureSet1: string[];
    featureSet2: string[];
}

export type BillingInterval = 'monthly' | 'annual';

export function isMemberOfPlan(userId: string, plan: Plan): boolean {
    if (!plan?.users) {
        return false;
    }
    return !!plan.users[userId];
}

export function getMembersWithRole(plan: Plan, role: PlanRole) {
    return (
        Object.entries(plan?.users || {})
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .filter(([_, planRole]) => planRole === role)
            .map(([userId]) => userId)
    );
}
