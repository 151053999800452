import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { PlanDetailsState, PlanDetailsStore } from './plan-details.store';

@Injectable({ providedIn: 'root' })
export class PlanDetailsQuery extends QueryEntity<PlanDetailsState> {
    constructor(protected store: PlanDetailsStore) {
        super(store);
    }

    get updateUrl$() {
        return this.selectActive((active) => active.update_url);
    }

    get cancelUrl$() {
        return this.selectActive((active) => active.cancel_url);
    }
}
