import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { WindowService, WindowSize } from '@insite-group-ltd/angular';
import {
    EmailToInviteWithRole,
    getProjectRoleDetails,
    Plan,
    PlanType,
    UserToAddWithRole,
} from '@insite-group-ltd/insite-teams-model';
import { IonCheckbox } from '@ionic/angular';
import { CheckboxChangeEventDetail } from '@ionic/core';
import differenceWith from 'lodash/differenceWith';
import forOwn from 'lodash/forOwn';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, map, startWith, tap } from 'rxjs/operators';
import { UtilService } from '../../services/util/util.service';
import { ProjectQuery } from '../../state/projects/project.query';
import { ProjectService } from '../../state/projects/project.service';

@Component({
    selector: 'lib-add-or-invite-project-users',
    templateUrl: './add-or-invite-project-users.page.html',
    styleUrls: ['./add-or-invite-project-users.page.scss'],
})
export class AddOrInviteProjectUsers implements OnInit {
    @Input() projectId: string;
    windowSize$ = this.windowService.windowSize$;
    planType$: Observable<PlanType>;
    isProjectPlanAdmin$: Observable<boolean>;
    availableInternalUsersToAdd$: Observable<UserToAddWithRole[]>;
    currentNonReadOnlyProjectUserCount$: Observable<number>;
    currentInternalProjectUserCount$: Observable<number>;
    currentAvailableInternalSeatCount$: Observable<number>;
    maxSeatCount$: Observable<number>;
    maxSeatCountBreakdown$: Observable<Plan['maxUserCountBreakdown']>;
    internalUsersToAdd: Record<string, UserToAddWithRole> = {};
    searchControl = new FormControl();
    usersToAdd: UserToAddWithRole[] = [];
    emailsToInvite: EmailToInviteWithRole[];
    invalidEmails: string[];
    usersAlreadyOnProjectCount: number;
    loaded = false;
    searchboxValue = '';

    constructor(
        private utilService: UtilService,
        private projectQuery: ProjectQuery,
        private projectService: ProjectService,
        private windowService: WindowService
    ) {}

    ngOnInit() {
        this.currentNonReadOnlyProjectUserCount$ =
            this.projectQuery.getNonReadOnlyProjectUserCount$(this.projectId);
        this.currentInternalProjectUserCount$ = this.projectQuery.getInternalProjectUserCount$(
            this.projectId
        );
        this.maxSeatCount$ = this.projectQuery.getProjectPlanMaxUserCount$(this.projectId);
        this.maxSeatCountBreakdown$ = this.projectQuery.getProjectPlanMaxUserCountBreakdown$(
            this.projectId
        );
        this.planType$ = this.projectQuery.getProjectPlanType$(this.projectId);
        this.isProjectPlanAdmin$ = this.projectQuery.isProjectPlanAdmin$(this.projectId);
        this.currentAvailableInternalSeatCount$ = combineLatest([
            this.maxSeatCount$,
            this.maxSeatCountBreakdown$,
            this.currentInternalProjectUserCount$,
            this.currentNonReadOnlyProjectUserCount$,
        ]).pipe(
            map(
                ([
                    maxSeatCount,
                    maxSeatCountBreakdown,
                    currentInternalProjectUserCount,
                    currentNonReadOnlyProjectUserCount,
                ]) => {
                    if (!isNil(maxSeatCountBreakdown?.internal)) {
                        return maxSeatCountBreakdown?.internal - currentInternalProjectUserCount;
                    } else if (!isNil(maxSeatCount)) {
                        return maxSeatCount - currentNonReadOnlyProjectUserCount;
                    }
                }
            )
        );

        this.availableInternalUsersToAdd$ = combineLatest([
            this.projectQuery.getUsersInProject$(this.projectId),
            this.projectQuery.getUsersInProjectPlan$(this.projectId),
            this.searchControl.valueChanges.pipe(startWith(''), debounceTime(250)),
        ]).pipe(
            map(([projectUsers, projectPlanUsers, searchValue]) => {
                const filtered = projectPlanUsers.filter(
                    (user) =>
                        !searchValue ||
                        `${user.insiteUser.firstName} ${user.insiteUser.lastName}`
                            .toLowerCase()
                            .includes(searchValue.toLowerCase()) ||
                        user.insiteUser.email.toLowerCase().includes(searchValue.toLowerCase())
                );
                return differenceWith(filtered, projectUsers || [], (arrVal, othVal) =>
                    isEqual(arrVal?.userId, othVal?.userId)
                );
            }),
            map((users) =>
                users.map((user) => {
                    return {
                        user: user.insiteUser,
                        roleOptions: user.role === 'ADMIN' ? ['ADMIN'] : ['ADMIN', 'INTERNAL'],
                        role: user.role === 'ADMIN' ? 'ADMIN' : 'INTERNAL',
                        checked: false,
                    } as UserToAddWithRole;
                })
            ),
            tap(() => (this.loaded = true))
        );
    }

    close() {
        this.utilService.dismissModal();
    }

    updateInternalUsersToAdd(
        event: Event,
        internalUser: UserToAddWithRole,
        maxSeatCount: number,
        maxSeatCountBreakdown: Plan['maxUserCountBreakdown'],
        internalSeatsAvailable: boolean,
        checkboxRef: IonCheckbox
    ) {
        const checked = (event as CustomEvent<CheckboxChangeEventDetail>).detail.checked;
        if ((maxSeatCount || maxSeatCountBreakdown) && checked && !internalSeatsAvailable) {
            checkboxRef.checked = false;
            if (maxSeatCountBreakdown?.internal) {
                this.projectService.displayProjectUserRoleLimitReached(this.projectId, 'INTERNAL');
            } else {
                this.projectService.displayProjectUserLimitReached(this.projectId);
            }
        } else {
            if (this.internalUsersToAdd[internalUser.user.id]) {
                this.internalUsersToAdd[internalUser.user.id].checked = checked;
            } else {
                this.internalUsersToAdd[internalUser.user.id] = {
                    user: internalUser.user,
                    roleOptions: internalUser.roleOptions,
                    role: internalUser.role,
                    checked,
                };
            }
        }
    }

    addInternalUsers() {
        const checkedInternalUsers = this.getCheckedInternalUsersToAdd();
        if (
            this.projectService.seatsAreAvailableForUsersToAdd(this.projectId, checkedInternalUsers)
        ) {
            this.projectService.batchAddInviteUsers(this.projectId, checkedInternalUsers, [], true);
            this.utilService.dismissModal();
        }
    }

    cancel() {
        this.utilService.dismissModal();
    }

    getCheckedInternalUsersToAdd(): UserToAddWithRole[] {
        const toAdd = [];
        forOwn(this.internalUsersToAdd, (internalUserToAdd) => {
            if (internalUserToAdd.checked) {
                toAdd.push(internalUserToAdd);
            }
        });
        return toAdd;
    }

    async search() {
        if (!this.searchboxValue) {
            return this.utilService.messageAlert(
                'No emails provided',
                'Please provide some email addresses in the search box.'
            );
        }
        const evaluatedEmails = await this.projectService.searchInsiteUsersAgainstProject(
            this.projectId,
            this.searchboxValue
        );
        if (evaluatedEmails) {
            this.usersToAdd = evaluatedEmails.usersToAdd || [];
            this.emailsToInvite = evaluatedEmails.emailsToInvite || [];
            this.invalidEmails = evaluatedEmails.invalidEmails || [];
            this.usersAlreadyOnProjectCount = evaluatedEmails.usersAlreadyOnProjectCount || 0;
        }
    }

    back() {
        this.usersToAdd = [];
        this.emailsToInvite = [];
        this.invalidEmails = [];
        this.usersAlreadyOnProjectCount = 0;
        this.searchControl.reset();
    }

    getNonReadOnlyUsersToAddOrInviteCount(): number {
        return (
            (this.usersToAdd?.filter((user) => user.role !== 'READ_ONLY').length || 0) +
                this.emailsToInvite?.filter((email) => email.role !== 'READ_ONLY').length || 0
        );
    }

    addOrInviteUsers() {
        if (
            this.projectService.seatsAreAvailableForUsersToAdd(
                this.projectId,
                this.usersToAdd,
                this.emailsToInvite
            )
        ) {
            this.utilService.dismissModal();
            this.projectService.batchAddInviteUsers(
                this.projectId,
                this.usersToAdd,
                this.emailsToInvite,
                true
            );
        }
    }

    async presentRoleOptions(
        event: MouseEvent,
        windowSize: WindowSize,
        userOrEmail: UserToAddWithRole | EmailToInviteWithRole,
        maxSeatCountReached = false
    ) {
        if ('user' in userOrEmail && userOrEmail.roleOptions.length === 1) {
            return this.utilService.messageAlert(
                'User is plan admin',
                userOrEmail.user.firstName +
                    ' ' +
                    userOrEmail.user.lastName +
                    ' is an Admin on the plan and so can only be added to the project as an Admin.'
            );
        }
        if (maxSeatCountReached) {
            return this.utilService.messageAlert(
                'No seats available',
                'You cannot add any more users with Admin, Internal or External roles as you have reached the maximum number of available seats. Users can still be added and invited with the Read only role.'
            );
        }
        const buttons = userOrEmail.roleOptions.map((role) => {
            const projectRoleLabel = getProjectRoleDetails(role);
            return {
                text: projectRoleLabel.label,
                icon: projectRoleLabel.icon,
                handler: () => {
                    userOrEmail.role = role;
                },
                selected: userOrEmail.role === role,
            };
        });
        if (windowSize.smallSize) {
            this.utilService.selectPopover(event, buttons).present();
        } else {
            await this.utilService.actionSheet.header('Role').button(buttons).present();
        }
    }

    trackByFnUser(_: number, user: UserToAddWithRole) {
        return user?.user.id;
    }

    trackByFnEmail(_: number, user: EmailToInviteWithRole) {
        return user?.email;
    }

    removeUserToAdd(i: number) {
        this.usersToAdd.splice(i, 1);
    }

    removeEmailToInvite(i: number) {
        this.emailsToInvite.splice(i, 1);
    }
}
