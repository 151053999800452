<ion-list class="popover-list position-relative z-1000 overflow-hidden">
    <ion-list-header *ngIf="title" class="pb-0 pl-16 font-weight-500" mode="md">
        <ion-text color="primary">{{ title }}</ion-text>
    </ion-list-header>
    <ion-item *ngFor="let option of options"
              (click)="optionFunction(option.handler)"
              [disabled]="option.disabled"
              button
              class="font-14 position-relative z-10"
              mode="md">
        <ion-icon *ngIf="option.selected && selectedIcon === 'start'" color="primary" name="ellipse"
                  class="mr-8 font-10"></ion-icon>
        <ion-label *ngIf="translateLabels; else noTranslate" color="dark">{{option.text | translate}}</ion-label>
        <ng-template #noTranslate>
            <ion-label color="dark">{{ option.text }}</ion-label>
        </ng-template>
        <ion-icon *ngIf="option.icon"
                  [color]="option.selected ? 'primary' : 'dark'"
                  [name]="option.icon"
                  slot="end"
                  size="small"></ion-icon>
        <ion-icon *ngIf="option.selected && selectedIcon === 'end'"
                  name="checkmark"
                  color="primary"
                  slot="end"
                  class="m-0 font-22"></ion-icon>
    </ion-item>
</ion-list>
