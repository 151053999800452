import { Injectable } from '@angular/core';
import { arrayRemove, arrayUnion, deleteField, increment } from '@angular/fire/firestore';
import { filterNilValue } from '@datorama/akita';
import {
    Audited,
    EMAIL_PATTERN,
    EmailToInviteWithRole,
    InsiteUser,
    ItemFieldSet,
    Plan,
    Project,
    ProjectAssignee,
    ProjectLocation,
    ProjectPermissions,
    ProjectRole,
    ProjectTag,
    StatusGroup,
    StatusOption,
    Translations,
    UserInProject,
    UserToAddWithRole,
    fixTranslations,
    getProjectInvitedUsers,
    getProjectRoleDetails,
    isBase64Image,
    mapArrayToSelectOptionObject,
    sortByAlpha,
    sortByName,
} from '@insite-group-ltd/insite-teams-model';
import { CollectionConfig, NoPathParams } from 'akita-ng-fire';
import { AtomicWrite } from 'akita-ng-fire/lib/utils/types';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import groupBy from 'lodash/groupBy';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import keys from 'lodash/keys';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { ManageProjectUserModalPageInputs } from '../../components/manage-project-user-modal/manage-project-user-modal';
import { ManageProjectUserModalPage } from '../../components/manage-project-user-modal/manage-project-user-modal.page';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { AuditedService } from '../../services/audited/audited.service';
import { ImageUploadService } from '../../services/image-upload/image-upload.service';
import { TelemetryService } from '../../services/telemetry/telemetry.service';
import { UtilService } from '../../services/util/util.service';
import { toPromise } from '../../utils/pipe';
import { getId } from '../../utils/state';
import { AuthService } from '../auth/auth.service';
import { BaseCollectionService } from '../collection-service/base-collection.service';
import { PlanQuery } from '../plan/plan.query';
import { PlanService } from '../plan/plan.service';
import { ProjectQuery } from './project.query';
import { ProjectState, ProjectStore } from './project.store';

const COLLECTION_PATH = 'projects';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: COLLECTION_PATH, idKey: 'id' })
export class ProjectService extends BaseCollectionService<
    ProjectState,
    ProjectStore,
    NoPathParams
> {
    constructor(
        store: ProjectStore,
        private analyticsService: AnalyticsService,
        private projectQuery: ProjectQuery,
        private planQuery: PlanQuery,
        private authService: AuthService,
        private planService: PlanService,
        protected auditedService: AuditedService,
        protected telemetryService: TelemetryService,
        private utilService: UtilService,
        private imageUploadService: ImageUploadService
    ) {
        super(COLLECTION_PATH, store, auditedService, telemetryService);
    }

    createOrUpdate(
        projectId: string,
        project: Project,
        fieldsChanged?: (keyof Project)[]
    ): Promise<string> {
        if (projectId) {
            return this.updateProject(projectId, project, fieldsChanged);
        } else {
            return this.createProject(project);
        }
    }

    async createProject(project: Project): Promise<string> {
        if (!project.planId) {
            throw Error('plan id not set for project');
        }
        const toSave = cloneDeep(project);
        toSave.status = 'ACTIVE';
        if (this.planService.hasReachedProjectLimit(toSave.planId)) {
            await this.planService.displayProjectLimitExceeded(toSave.planId);
            return null;
        } else if (this.planService.hasReachedCreateProjectAllowanceForPeriod(toSave.planId)) {
            await this.planService.displayProjectsCreatedInBillingPeriodLimitExceeded(
                toSave.planId
            );
            return null;
        } else {
            await this.auditedService.markCreated(toSave);
            toSave.users = {};
            toSave.users[this.authService.userId] = 'ADMIN';
            const planTranslations = await this.planQuery
                .selectEntity(toSave.planId)
                .pipe(
                    take(1),
                    map((plan) => plan?.settings?.translations)
                )
                .toPromise();
            if (planTranslations) {
                const translations = cloneDeep(planTranslations);
                fixTranslations(translations);
                toSave.translations = translations;
            }
            const batch = this.batch();
            await this.incrementPlanProjectCount(toSave.planId, 1, batch);
            const id = await this.add(toSave, this.getWriteOptions({}, batch));
            await batch.commit();
            this.analyticsService.event('create_project');
            this.imageUploadService.runImageUploadTasks(`projects/${toSave.id}`);
            return id;
        }
    }

    async updateProject(
        projectId: string,
        project: Project | Partial<Project>,
        fieldsChanged: (keyof Project)[]
    ): Promise<string> {
        if (!project.planId) {
            throw Error('plan id not set for project');
        }
        const toUpdate = pick(cloneDeep(project), [
            'planId',
            'id',
            ...fieldsChanged,
        ]) as Partial<Project>;
        toUpdate.status = 'ACTIVE';
        await this.auditedService.markUpdated(toUpdate as Audited);
        await this.update(projectId, toUpdate);
        this.analyticsService.event('update_project', { projectId });
        this.imageUploadService.runImageUploadTasks(`projects/${projectId}`);
        return projectId;
    }

    async delete(projectId: string) {
        const batch = this.batch();
        await this.incrementPlanProjectCount(
            this.projectQuery.getEntity(projectId).planId,
            -1,
            batch
        );
        await this.remove(projectId, this.getWriteOptions({}, batch));
        await batch.commit();
        this.analyticsService.event('delete_project', { projectId });
        this.imageUploadService.clearImageUploadTasks(`projects/${projectId}`);
    }

    async updateTranslations(projectId: string, translations: Translations) {
        fixTranslations(translations);
        await this.updateField(projectId, 'translations', translations, this.getWriteOptions());
        this.analyticsService.event('update_translations', { projectId });
    }

    async updatePermission<T extends keyof ProjectPermissions>(
        projectId: string,
        permissionKey: T,
        permission: ProjectPermissions[T]
    ) {
        await this.updateField(
            projectId,
            `permissions.${permissionKey}`,
            permission,
            this.getWriteOptions()
        );
        this.analyticsService.event('update_permissions', { projectId });
    }

    async updateTags(projectId: string, tags: ProjectTag[]) {
        tags.filter((tag) => !tag?.id).forEach((tag) => (tag.id = this.createId()));
        await this.updateField(
            projectId,
            'tags',
            mapArrayToSelectOptionObject(tags),
            this.getWriteOptions()
        );
        this.analyticsService.event('update_tags', { projectId });
    }

    async updateStatusGroup(projectId: string, statusGroup: StatusGroup) {
        const project = cloneDeep(this.projectQuery.getEntity(projectId));
        const { statusGroups } = project;
        const statusGroupIndex = statusGroups.findIndex((group) => group.id === statusGroup.id);
        statusGroups[statusGroupIndex] = statusGroup;
        await this.updateField(projectId, 'statusGroups', statusGroups, this.getWriteOptions());
        this.analyticsService.event('update_status_groups', { projectId });
    }

    async addStatusGroup(projectId: string, statusGroup: StatusGroup) {
        await this.updateField(
            projectId,
            'statusGroups',
            arrayUnion(statusGroup),
            this.getWriteOptions()
        );
        this.analyticsService.event('update_status_groups', { projectId });
    }

    async addOptionToStatusGroup(
        projectId: string,
        statusGroupId: string,
        newStatusOption: StatusOption
    ) {
        const project = cloneDeep(this.projectQuery.getEntity(projectId));
        const { statusGroups } = project;
        const statusGroupIndex = statusGroups.findIndex(
            (statusGroup) => statusGroup.id === statusGroupId
        );
        statusGroups[statusGroupIndex].options.push(newStatusOption);
        await this.updateField(projectId, 'statusGroups', statusGroups, this.getWriteOptions());
        this.analyticsService.event('update_status_groups', { projectId });
    }

    async deleteStatusGroup(projectId: string, statusGroups: StatusGroup) {
        await this.updateField(
            projectId,
            'statusGroups',
            arrayRemove(statusGroups),
            this.getWriteOptions()
        );
        this.analyticsService.event('delete_status_group', { projectId });
    }

    async createOrUpdateItemFieldSet(projectId: string, itemFieldSet: ItemFieldSet) {
        if (!itemFieldSet.id) {
            itemFieldSet.id = this.createId();
        }
        // ensure the first letter of the field label is always uppercase
        for (const itemField of Object.values(itemFieldSet.fields)) {
            const { label } = itemField;
            if (label) {
                itemField.label = label.charAt(0).toUpperCase() + label.slice(1);
            }
        }
        await this.updateField(
            projectId,
            `itemFieldSets.${itemFieldSet.id}`,
            itemFieldSet,
            this.getWriteOptions()
        );
        this.analyticsService.event('create_or_update_item_field_set', { projectId });
    }

    async deleteItemFieldSet(projectId: string, itemFieldSetOrId: ItemFieldSet | string) {
        const itemFieldSetId = isString(itemFieldSetOrId) ? itemFieldSetOrId : itemFieldSetOrId.id;
        await this.updateField(
            projectId,
            `itemFieldSets.${itemFieldSetId}`,
            deleteField(),
            this.getWriteOptions()
        );
        this.analyticsService.event('delete_item_field_set', { projectId });
    }

    async addExternalProjectUserToPlan(projectId: string, userId: string) {
        const write = this.planService.batch();
        await this.planService.setPlanUserRole(
            userId,
            'INTERNAL',
            this.projectQuery.getEntity(projectId).planId,
            write
        );
        await this.updateField(
            projectId,
            `users.${userId}`,
            'INTERNAL',
            this.getWriteOptions({}, write)
        );
        await write.commit();
        this.analyticsService.event('add_external_project_user_to_plan', { projectId, userId });
    }

    async setProjectUserRole(
        projectId: string,
        userId: string,
        role: ProjectRole = 'EXTERNAL',
        write?: AtomicWrite
    ) {
        await this.updateField(projectId, `users.${userId}`, role, this.getWriteOptions({}, write));
        this.analyticsService.event('update_project_user_role', { projectId, userId, role });
    }

    async removeProjectUser(projectId: string, userId: string) {
        await this.removeField(projectId, `users.${userId}`, this.getWriteOptions());
        this.analyticsService.event('delete_project_user', { projectId, userId });
    }

    async saveOrUpdateLocation(projectId: string, location: ProjectLocation, write?: AtomicWrite) {
        if (isNil(location.sortIndex)) {
            const nextSortIndex = await toPromise(
                this.projectQuery.getLocationsNextSortIndex$(projectId)
            );
            if (!isNil(nextSortIndex)) {
                location.sortIndex = nextSortIndex;
            }
        }
        const locationId = getId(this.createId(), location);
        await this.updateField(
            projectId,
            `locations.${locationId}`,
            location,
            this.getWriteOptions({}, write)
        );
        this.analyticsService.event('update_project_location', { projectId, locationId });
        return locationId;
    }

    async saveOrUpdateLocationDrawing(
        projectId: string,
        locationId: string,
        drawingId: string,
        write?: AtomicWrite
    ) {
        await this.updateField(
            projectId,
            `locations.${locationId}.drawingId`,
            drawingId,
            this.getWriteOptions({}, write)
        );
        this.analyticsService.event('update_project_location_drawing', { projectId, locationId });
    }

    async removeLocationDrawing(projectId: string, locationId: string, write?: AtomicWrite) {
        await this.updateField(
            projectId,
            `locations.${locationId}.drawingId`,
            deleteField(),
            this.getWriteOptions({}, write)
        );
        this.analyticsService.event('remove_project_location_drawing', { projectId, locationId });
    }

    async saveLocations(projectId: string, locations: string[], maintainImportOrder: boolean) {
        const update = {};
        const existingLocations = await toPromise(this.projectQuery.getLocations$(projectId));
        let sortIndex: number;
        if (maintainImportOrder) {
            if (existingLocations?.length) {
                // existing locations so the sort index starts the current highest
                sortIndex = await toPromise(
                    this.projectQuery.getLocationsNextSortIndex$(projectId)
                );
                // if none of the existing locations have a sort index, sort them by name
                // and set their sort index
                if (
                    existingLocations.every((existingLocation) => isNil(existingLocation.sortIndex))
                ) {
                    existingLocations.sort(sortByName).forEach((existingLocation) => {
                        update[`locations.${existingLocation.id}.sortIndex`] =
                            existingLocations.indexOf(existingLocation);
                    });
                    sortIndex = existingLocations.length;
                }
            } else {
                // no existing locations so the sort index starts from 0
                sortIndex = 0;
            }
        } else if (
            // if some of the existing locations have a sort index but maintain wasnt
            // enabled, sort by alpha and the sort index starts after the existing highest index
            existingLocations?.some((existingLocation) => !isNil(existingLocation.sortIndex))
        ) {
            locations = locations.sort(sortByAlpha);
            sortIndex = await toPromise(this.projectQuery.getLocationsNextSortIndex$(projectId));
        }
        for (const location of locations) {
            const projectLocation = { id: undefined, name: location } as ProjectLocation;
            const locationId = getId(this.createId(), projectLocation);
            if (!isNil(sortIndex)) {
                projectLocation.sortIndex = sortIndex;
                sortIndex++;
            }
            update[`locations.${locationId}`] = projectLocation;
        }
        await this.auditedService.markUpdated(update as Project);
        await this.update(projectId, update);
        this.analyticsService.event('save_project_locations', { projectId });
    }

    async updateLocations(projectId: string, locations: ProjectLocation[]) {
        locations
            .filter((location) => !location?.id)
            .forEach((location) => (location.id = this.createId()));
        await this.updateField(
            projectId,
            'locations',
            mapArrayToSelectOptionObject(locations),
            this.getWriteOptions()
        );
        this.analyticsService.event('update_locations', { projectId });
    }

    async saveAssignees(projectId: string, assignees: string[]) {
        const update = {};
        for (const assignee of assignees) {
            const projectAssignee = { id: undefined, name: assignee, users: [] };
            const assigneeId = getId(this.createId(), projectAssignee);
            update[`assignees.${assigneeId}`] = projectAssignee;
        }
        await this.auditedService.markUpdated(update as Project);
        await this.update(projectId, update);
        this.analyticsService.event('save_project_assignees', { projectId });
    }

    async saveOrUpdateAssignee(projectId: string, assignee: ProjectAssignee) {
        const assigneeId = getId(this.createId(), assignee);
        await this.updateField(
            projectId,
            `assignees.${assigneeId}`,
            assignee,
            this.getWriteOptions()
        );
        this.analyticsService.event('update_project_assignee', { projectId, assigneeId });
    }

    async removeProjectLocation(projectId: string, locationId: string, write?: AtomicWrite) {
        await this.removeField(
            projectId,
            `locations.${locationId}`,
            this.getWriteOptions({}, write)
        );
        this.analyticsService.event('delete_project_location', { projectId, locationId });
    }

    async removeProjectAssignee(projectId: string, assigneeId: string) {
        await this.removeField(projectId, `assignees.${assigneeId}`, this.getWriteOptions());
        this.analyticsService.event('delete_project_assignee', { projectId, assigneeId });
    }

    public async revokeInvitedUserFromProject(projectId: string, email: string) {
        await this.updateObject(
            projectId,
            {
                invitedUsers: arrayRemove(email),
                [`invitedUsersWithRoles.ADMIN`]: arrayRemove(email),
                [`invitedUsersWithRoles.INTERNAL`]: arrayRemove(email),
                [`invitedUsersWithRoles.EXTERNAL`]: arrayRemove(email),
                [`invitedUsersWithRoles.READ_ONLY`]: arrayRemove(email),
            },
            this.getWriteOptions()
        );
        this.analyticsService.event('revoke_invite_user_from_project', { projectId, email });
    }

    async saveOrUpdateTag(projectId: string, tag: ProjectTag) {
        const tagId = getId(this.createId(), tag);
        await this.updateField(projectId, `tags.${tagId}`, tag, this.getWriteOptions());
        this.analyticsService.event('update_project_tag', { projectId, tagId: tag.id });
    }

    async removeTag(projectId: string, tagId: string) {
        await this.removeField(projectId, `tags.${tagId}`, this.getWriteOptions());
        this.analyticsService.event('delete_project_tag', { projectId, tagId });
    }

    public async archiveProject(projectId: string) {
        const batch = this.batch();
        await this.incrementPlanProjectCount(
            this.projectQuery.getEntity(projectId).planId,
            -1,
            batch
        );
        const projectUpdate = { status: 'ARCHIVED' } as Project;
        await this.auditedService.markUpdated(projectUpdate);
        await this.update(projectId, projectUpdate, this.getWriteOptions({}, batch));
        await batch.commit();
    }

    public async unarchiveProject(projectId: string): Promise<boolean> {
        const planId = this.projectQuery.getEntity(projectId).planId;
        if (this.planService.hasReachedProjectLimit(planId)) {
            await this.planService.displayProjectLimitExceeded(planId);
            return false;
        } else {
            const batch = this.batch();
            await this.incrementPlanProjectCount(
                this.projectQuery.getEntity(projectId).planId,
                1,
                batch
            );
            const projectUpdate = { status: 'ACTIVE' } as Project;
            await this.auditedService.markUpdated(projectUpdate);
            await this.update(projectId, projectUpdate, this.getWriteOptions({}, batch));
            await batch.commit();
            return true;
        }
    }

    public async inviteUsersToProject(projectId: string, emails: string[], write?: AtomicWrite) {
        await this.updateField(
            projectId,
            'invitedUsers',
            arrayUnion(...emails),
            this.getWriteOptions({}, write)
        );
        this.analyticsService.event('invite_users_to_project', { projectId, emails });
    }

    public async inviteUsersToProjectWithRole(
        projectId: string,
        emailsToInviteWithRole: EmailToInviteWithRole[],
        write?: AtomicWrite
    ) {
        const groupedEmailsToInvite = groupBy(emailsToInviteWithRole, 'role');
        for (const [role, emailsWithRoles] of Object.entries(groupedEmailsToInvite)) {
            const emails = emailsWithRoles.map((emailWithRole) => emailWithRole.email);
            await this.updateField(
                projectId,
                `invitedUsersWithRoles.${role}`,
                arrayUnion(...emails),
                this.getWriteOptions({}, write)
            );
            this.analyticsService.event('invite_users_to_project_with_role', {
                projectId,
                role,
                emails,
            });
        }
    }

    public async searchInsiteUsersAgainstProject(projectId: string, emailsToAdd: string) {
        const project = this.projectQuery.getEntity(projectId);
        const plan = this.planQuery.getEntity(project.planId);
        const evaluatedEmails = await this.evaluateEmailsToAdd(project, emailsToAdd);
        const mappedUsersToAdd: UserToAddWithRole[] = [];
        const nonReadOnlyUserCount = this.getNonReadOnlyUserCount(project);
        let userIndex = 1;
        for (const userToAdd of evaluatedEmails.usersToAdd) {
            const mappedUserToAdd = {
                user: userToAdd,
                roleOptions: [],
                checked: true,
            } as UserToAddWithRole;
            const planRole = plan.users[userToAdd.id];
            if (planRole === 'ADMIN') {
                mappedUserToAdd.roleOptions.push('ADMIN');
                mappedUserToAdd.role = 'ADMIN';
            } else if (planRole === 'INTERNAL' || plan.type === 'TEAM') {
                mappedUserToAdd.roleOptions.push('ADMIN', 'INTERNAL');
                mappedUserToAdd.role = 'INTERNAL';
            } else {
                mappedUserToAdd.roleOptions.push('EXTERNAL', 'READ_ONLY');
                // if there is no max user count, or we wouldn't have exceeded that with these users we
                // are currently adding then set role to external
                if (!plan.maxUserCount || plan.maxUserCount >= nonReadOnlyUserCount + userIndex) {
                    mappedUserToAdd.role = 'EXTERNAL';
                } else {
                    mappedUserToAdd.role = 'READ_ONLY';
                }
            }
            mappedUsersToAdd.push(mappedUserToAdd);
            userIndex++;
        }
        const mappedEmailsToInvite: EmailToInviteWithRole[] = [];
        for (const emailToInvite of evaluatedEmails.emailsToInvite) {
            const isEnterprise = plan.type === 'ENTERPRISE';
            if (isEnterprise) {
                let role: ProjectRole;
                // if there is no max user count, or we wouldn't have exceeded that with these users we
                // are currently adding then set role to external
                if (!plan.maxUserCount || plan.maxUserCount >= nonReadOnlyUserCount + userIndex) {
                    role = 'EXTERNAL';
                } else {
                    role = 'READ_ONLY';
                }
                mappedEmailsToInvite.push({
                    email: emailToInvite,
                    roleOptions: ['EXTERNAL', 'READ_ONLY'],
                    role,
                });
            } else {
                mappedEmailsToInvite.push({
                    email: emailToInvite,
                    roleOptions: ['ADMIN', 'INTERNAL'],
                    role: 'INTERNAL',
                });
            }
            userIndex++;
        }
        return {
            usersToAdd: mappedUsersToAdd,
            emailsToInvite: mappedEmailsToInvite,
            invalidEmails: evaluatedEmails.invalidEmails,
            usersAlreadyOnProjectCount: evaluatedEmails.usersAlreadyOnProjectCount,
        };
    }

    private async evaluateEmailsToAdd(project: Project, emailsToAdd: string) {
        const emails: string[] = emailsToAdd
            .split(/[,;]/)
            .map((email) => email.trim().toLocaleLowerCase());
        const validEmails = new Set<string>();
        const invalidEmails: string[] = [];
        emails.forEach((email) => {
            if (EMAIL_PATTERN.test(email)) {
                validEmails.add(email);
            } else if (email.length > 0) {
                invalidEmails.push(email);
            }
        });
        let usersAlreadyOnProjectCount = 0;
        const usersToAdd: InsiteUser[] = [];
        const emailsToInvite: string[] = [];
        for (const validEmail of validEmails) {
            const foundUser = await this.authService.searchByEmail(validEmail);
            if (foundUser) {
                if (keys(project?.users).some((userId) => userId === foundUser.id)) {
                    usersAlreadyOnProjectCount++;
                } else {
                    usersToAdd.push(foundUser);
                }
            } else if (getProjectInvitedUsers(project).some((email) => email === validEmail)) {
                usersAlreadyOnProjectCount++;
            } else {
                emailsToInvite.push(validEmail);
            }
        }
        return { usersToAdd, emailsToInvite, invalidEmails, usersAlreadyOnProjectCount };
    }

    seatsAreAvailableForUsersToAdd(
        projectId: string,
        usersToAdd: UserToAddWithRole[],
        emailsToInvite: EmailToInviteWithRole[] = []
    ): boolean {
        const project = this.projectQuery.getEntity(projectId);
        const plan = this.planQuery.getEntity(project.planId);
        let nonReadOnlyUsersToAddCount: number;
        // if the plan is team we count the emails to invite aswell as team
        // plans doesn't have read only users so can't fall back to that
        if (plan.type === 'TEAM') {
            nonReadOnlyUsersToAddCount = usersToAdd.length + emailsToInvite?.length;
        } else {
            nonReadOnlyUsersToAddCount = usersToAdd.filter(
                (userToAdd) => userToAdd.role !== 'READ_ONLY'
            ).length;
        }
        const currentNonReadOnlyProjectUsers = this.getNonReadOnlyUserCount(project);
        if (
            plan.maxUserCount &&
            currentNonReadOnlyProjectUsers + nonReadOnlyUsersToAddCount > plan.maxUserCount
        ) {
            this.displayProjectUserLimitReached(projectId);
            return false;
        }
        if (plan.maxUserCountBreakdown) {
            const currentExternalUserCount = this.getExternalUserCount(project);
            const externalUsersToAdd = [...usersToAdd, ...emailsToInvite].filter(
                (user) => user.role === 'EXTERNAL'
            ).length;
            if (
                !isNil(plan.maxUserCountBreakdown.external) &&
                currentExternalUserCount + externalUsersToAdd > plan.maxUserCountBreakdown.external
            ) {
                this.displayProjectUserRoleLimitReached(projectId, 'EXTERNAL');
                return false;
            }
            const currentInternalUserCount = this.getInternalUserCount(project);
            const internalUsersToAdd = [...usersToAdd, ...emailsToInvite].filter(
                (user) => user.role === 'INTERNAL' || user.role === 'ADMIN'
            ).length;
            if (
                !isNil(plan.maxUserCountBreakdown.internal) &&
                currentInternalUserCount + internalUsersToAdd > plan.maxUserCountBreakdown.internal
            ) {
                this.displayProjectUserRoleLimitReached(projectId, 'INTERNAL');
                return false;
            }
        }
        return true;
    }

    public async batchAddInviteUsers(
        projectId: string,
        usersToAdd: UserToAddWithRole[],
        emailsToInvite: EmailToInviteWithRole[],
        showToast = false
    ) {
        const project = this.projectQuery.getEntity(projectId);
        const projectPlan = this.planQuery.getEntity(project.planId);
        const batch = this.batch();
        if (usersToAdd.length > 0) {
            for (const userToAdd of usersToAdd) {
                await this.setProjectUserRole(
                    project?.id,
                    userToAdd.user.id,
                    userToAdd.role,
                    batch
                );
                if (projectPlan.type === 'TEAM' && !projectPlan.users[userToAdd.user.id]) {
                    await this.planService.setPlanUserRole(
                        userToAdd.user.id,
                        'INTERNAL',
                        projectPlan.id,
                        batch
                    );
                }
            }
        }
        if (emailsToInvite.length > 0) {
            await this.inviteUsersToProjectWithRole(project?.id, emailsToInvite, batch);
            if (projectPlan.type === 'TEAM') {
                await this.planService.inviteUsersToPlan(
                    emailsToInvite.map((emailToInvite) => emailToInvite.email),
                    projectPlan.id,
                    batch
                );
            }
        }
        try {
            await batch.commit();
            if (showToast) {
                let message = '';
                if (usersToAdd.length > 0 && emailsToInvite.length > 0) {
                    message = `Added ${usersToAdd.length} user(s), and invited ${emailsToInvite.length} user(s) to the project`;
                } else if (usersToAdd.length > 0) {
                    message = `Added ${usersToAdd.length} user(s) to the project`;
                } else if (emailsToInvite.length > 0) {
                    message = `Invited ${emailsToInvite.length} user(s) to the project`;
                }
                this.utilService.successToast(message);
            }
        } catch {
            this.utilService.errorAlert();
        }
    }

    public async multiAddUsersByEmail(projectId: string, emailsToAdd: string, modalPage?: any) {
        const emails: string[] | Set<string> = emailsToAdd
            .split(/[,;]/)
            .map((email) => email.trim().toLocaleLowerCase());
        let validEmails: string[] | Set<string> = emails.filter((email) =>
            EMAIL_PATTERN.test(email)
        );
        validEmails = new Set(validEmails);
        const invalidEmails: string[] = emails.filter(
            (email) => !EMAIL_PATTERN.test(email) && email.length > 0
        );
        const existingUsers = [];
        const usersToAdd: InsiteUser[] = [];
        const usersToInvite = [];
        const project = this.projectQuery.getEntity(projectId);
        for (const validEmail of validEmails) {
            const foundUser = await this.authService.searchByEmail(validEmail);
            if (foundUser) {
                if (keys(project.users).some((userId) => userId === foundUser.id)) {
                    existingUsers.push(foundUser.id);
                } else {
                    usersToAdd.push(foundUser);
                }
            } else {
                if ((project.invitedUsers || []).some((email) => email === validEmail)) {
                    existingUsers.push(validEmail);
                } else {
                    usersToInvite.push(validEmail);
                }
            }
        }
        if (modalPage) {
            const existingUserCount = existingUsers.length;
            const modal = await this.utilService.presentModal<any>(
                modalPage,
                { invalidEmails, existingUserCount, usersToAdd, usersToInvite, type: 'PROJECT' },
                { cssClass: 'users-to-invite-modal' }
            );
            const { data } = await modal.onDidDismiss();
            if (!data) {
                return;
            }
        }
        const plan = this.planQuery.getEntity(project.planId);
        const internalUsersToAddCount = usersToAdd.filter((user) =>
            find(plan.users, user.id)
        ).length;
        const currentNonReadOnlyProjectUsers = pickBy(
            project.users,
            (role) => role !== 'READ_ONLY'
        );
        if (
            plan.maxUserCount &&
            keys(currentNonReadOnlyProjectUsers).length + internalUsersToAddCount >
                plan.maxUserCount
        ) {
            return this.displayProjectUserLimitReached(projectId);
        }
        const batch = this.batch();
        if (usersToAdd.length > 0) {
            for (const userToAdd of usersToAdd) {
                await this.setProjectUserRole(
                    projectId,
                    userToAdd.id,
                    plan.users[userToAdd.id] || 'READ_ONLY',
                    batch
                );
            }
        }
        if (usersToInvite.length > 0) {
            await this.inviteUsersToProject(projectId, usersToInvite, batch);
        }
        try {
            await batch.commit();
            if (modalPage) {
                this.utilService.successToast(
                    `Added ${usersToAdd.length} user(s), and invited ${usersToInvite.length} user(s) to the project`
                );
            }
        } catch {
            this.utilService.errorAlert();
        }
    }

    getProjectPlan$(projectId: string): Observable<Plan> {
        return this.projectQuery.selectEntity(projectId).pipe(
            filterNilValue(),
            switchMap((project) => this.planQuery.selectEntity(project.planId))
        );
    }

    getProjectPlanName$(projectId: string): Observable<string> {
        return this.getProjectPlan$(projectId).pipe(map((plan) => plan?.company));
    }

    hasReachedProjectUserLimit(projectId: string): boolean {
        const project = this.projectQuery.getEntity(projectId);
        const plan = this.planQuery.getEntity(project.planId);
        const nonReadOnlyProjectUsers = this.getNonReadOnlyUserCount(project);
        return plan.maxUserCount && nonReadOnlyProjectUsers >= plan.maxUserCount;
    }

    async hasReachedProjectUserRoleLimit(
        projectId: string,
        userId: string,
        targetRole: ProjectRole
    ): Promise<boolean> {
        const userInProject = await toPromise(
            this.projectQuery.getUserInProject$(projectId, userId)
        );
        const plan = await toPromise(this.projectQuery.getProjectPlan$(projectId));
        if (plan.maxUserCountBreakdown) {
            const { external, internal } = plan.maxUserCountBreakdown;
            const hasReachedExternalUserLimit =
                !isNil(external) &&
                targetRole === 'EXTERNAL' &&
                userInProject.role !== 'EXTERNAL' &&
                external <= this.getExternalUserCount(projectId);
            const hasReachedInternalUserLimit =
                !isNil(internal) &&
                (targetRole === 'INTERNAL' || targetRole === 'ADMIN') &&
                userInProject.role !== 'ADMIN' &&
                userInProject.role !== 'INTERNAL' &&
                internal <= this.getInternalUserCount(projectId);
            return hasReachedExternalUserLimit || hasReachedInternalUserLimit;
        }
        return false;
    }

    async displayProjectUserRoleLimitReached(projectId: string, targetRole: ProjectRole) {
        const plan = await toPromise(this.projectQuery.getProjectPlan$(projectId));
        let message: string;
        if (targetRole === 'ADMIN' || targetRole === 'INTERNAL') {
            message = `You have reached your max project internal user limit of ${plan.maxUserCountBreakdown.internal} and so you cannot give any more users Admin or Internal roles. Users can still be added and invited with the External & Read only roles. Please contact us to discuss increasing this limit.`;
        } else {
            message = `You have reached your max project external user limit of ${plan.maxUserCountBreakdown.external}, contact us to discuss increasing this limit.`;
        }
        return this.utilService.errorAlert('Max project user role limit reached', message);
    }

    displayProjectUserLimitReached(projectId: string) {
        const project = this.projectQuery.getEntity(projectId);
        const plan = this.planQuery.getEntity(project.planId);
        let message: string;
        if (plan.type === 'ENTERPRISE') {
            message = `You have reached your max project user limit of ${plan.maxUserCount} and so you cannot give any more users Admin, Internal or External roles. Users can still be added and invited with the Read only role. Please contact us to discuss increasing this limit.`;
        } else {
            message = `You have reached your max project user limit of ${plan.maxUserCount}, contact us to discuss increasing this limit.`;
        }
        return this.utilService.errorAlert('Max project user limit reached', message);
    }

    addOrInviteProjectUsers(projectId: string, modalPage: any) {
        this.utilService.presentModal<any>(
            modalPage,
            { projectId },
            { cssClass: 'add-or-invite-project-users-modal' }
        );
    }

    hasExistingAssigneeWithName(projectId: string, name: string, assigneeId?: string) {
        const assignees = this.projectQuery.getEntity(projectId).assignees || {};
        return Object.values(assignees).some(
            (assignee) =>
                assignee.name.toLowerCase() === name.toLowerCase() &&
                (!assigneeId || assignee.id !== assigneeId)
        );
    }

    hasExistingLocationWithName(projectId: string, name: string, locationId?: string) {
        const locations = this.projectQuery.getEntity(projectId).locations || {};
        return Object.values(locations).some(
            (location) =>
                location.name.toLowerCase() === name.toLowerCase() &&
                (!locationId || location.id !== locationId)
        );
    }

    async manageProjectUser(
        userToManage: UserInProject,
        projectId: string,
        canManageUsers: boolean
    ) {
        const modal = await this.utilService.presentModal<ManageProjectUserModalPageInputs>(
            ManageProjectUserModalPage,
            { userId: userToManage.userId, projectId: projectId, canManageUsers },
            { cssClass: 'manage-project-user-modal' }
        );
        const { data } = await modal.onDidDismiss();
        if (data) {
            const insiteUserName = `${userToManage.insiteUser.firstName} ${userToManage.insiteUser.lastName}`;
            if (
                data.currentRole === 'ADMIN' ||
                data.currentRole === 'INTERNAL' ||
                data.chosenRole === 'READ_ONLY' ||
                data.chosenRole === 'EXTERNAL'
            ) {
                this.setProjectUserRole(projectId, userToManage.userId, data.chosenRole);
                const newRole = getProjectRoleDetails(data.chosenRole).label;
                this.utilService.successToast(
                    `${insiteUserName}'s role changed to ${newRole} on project.`
                );
            } else if (data.chosenRole === 'INTERNAL') {
                this.addExternalProjectUserToPlan(projectId, userToManage.userId);
                this.utilService.successToast(
                    `${insiteUserName} added to plan and role changed to Internal on project.`
                );
            }
        }
    }

    private incrementPlanProjectCount(planId: string, incrementor: number, write: AtomicWrite) {
        return this.planService.update(
            planId,
            { projectCount: increment(incrementor) },
            this.planService.getWriteOptions({}, write)
        );
    }

    private getExternalUserCount(projectIdOrProject: string | Project): number {
        let project: Project;
        if (isString(projectIdOrProject)) {
            project = this.projectQuery.getEntity(projectIdOrProject);
        } else {
            project = projectIdOrProject;
        }
        return Object.values(project.users).filter((role) => role === 'EXTERNAL').length;
    }

    private getInternalUserCount(projectIdOrProject: string | Project): number {
        let project: Project;
        if (isString(projectIdOrProject)) {
            project = this.projectQuery.getEntity(projectIdOrProject);
        } else {
            project = projectIdOrProject;
        }
        return Object.values(project.users).filter(
            (role) => role === 'INTERNAL' || role === 'ADMIN'
        ).length;
    }

    private getNonReadOnlyUserCount(projectIdOrProject: string | Project): number {
        let project: Project;
        if (isString(projectIdOrProject)) {
            project = this.projectQuery.getEntity(projectIdOrProject);
        } else {
            project = projectIdOrProject;
        }
        return Object.keys(pickBy(project.users, (role) => role !== 'READ_ONLY')).length;
    }

    formatToFirestore(project: Partial<Project>): Partial<Project> {
        super.formatToFirestore(project);
        // validate we are not trying to save any base64 data urls
        if (project?.image && isBase64Image(project?.image)) {
            // eslint-disable-next-line no-console
            console.error('ERROR - saving base64 data url for project');
        }
        return project;
    }
}
