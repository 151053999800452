import merge from 'lodash/merge';
import { defaultReportSettings, InsiteUserPrivate, ReportSettings } from './insite-user';
import { Plan } from './plan';

export function getReportSettings(
    userPrivate: InsiteUserPrivate,
    isOnPlan: boolean,
    plan?: Plan,
    reportSettings?: ReportSettings
): ReportSettings {
    return merge(
        {},
        defaultReportSettings,
        userPrivate.reportSettings,
        {
            reportColour: isOnPlan
                ? plan?.settings?.reportColour || userPrivate.reportSettings?.reportColour
                : userPrivate.reportSettings?.reportColour,
        },
        reportSettings
    );
}

export function getCompanyLogoSource(
    userPrivate: InsiteUserPrivate,
    isOnPlan: boolean,
    plan?: Plan
) {
    if (plan?.type === 'BASIC') {
        return userPrivate.companyLogo || plan.settings?.companyLogo;
    } else {
        return isOnPlan
            ? plan?.settings?.companyLogo || userPrivate.companyLogo
            : userPrivate.companyLogo;
    }
}
