import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { serverTimestamp } from '@angular/fire/firestore';
import { WindowService } from '@insite-group-ltd/angular';
import {
    BillingInterval,
    getSubscriptionPlanId,
    getSubscriptionPlanIdsForPlanTypeIdAndInterval,
    Plan,
    PlanOption,
    PlanTypeId,
    SubscriptionPlan,
} from '@insite-group-ltd/insite-teams-model';
import {
    AuthQuery,
    isTestMode,
    PlanDetailsQuery,
    PlanService,
    SystemQuery,
    toPromise,
    UtilService,
} from '@insite-group-ltd/insite-teams-shared';
import { NavController } from '@ionic/angular';
import cloneDeep from 'lodash/cloneDeep';
import head from 'lodash/head';
import isString from 'lodash/isString';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

export interface ProductPriceDetails {
    price: number;
    currency: string;
    trialDays: number;
}

@Injectable({
    providedIn: 'root',
})
export class PaddleService {
    private _monthlyPlanOptions = new BehaviorSubject<PlanOption[]>([]);
    private _annualPlanOptions = new BehaviorSubject<PlanOption[]>([]);

    constructor(
        private authQuery: AuthQuery,
        private windowService: WindowService,
        private planDetailsQuery: PlanDetailsQuery,
        private planService: PlanService,
        private navController: NavController,
        private utilService: UtilService,
        private httpClient: HttpClient,
        private systemQuery: SystemQuery
    ) {
        this.systemQuery.activePlans$.pipe(take(1)).subscribe((activePlans) => {
            this.getProductPrices(activePlans.map((activePlan) => activePlan.id)).then(
                (productPriceDetails: Record<string, ProductPriceDetails>) => {
                    this._monthlyPlanOptions.next([
                        this.mapPersonalPlanOption(
                            getSubscriptionPlanIdsForPlanTypeIdAndInterval(
                                activePlans,
                                'personal',
                                'monthly'
                            ),
                            productPriceDetails,
                            'monthly'
                        ),
                        this.mapStarterPlanOption(
                            getSubscriptionPlanIdsForPlanTypeIdAndInterval(
                                activePlans,
                                'starter',
                                'monthly'
                            ),
                            productPriceDetails,
                            'monthly'
                        ),
                        this.mapPremiumPlanOption(
                            getSubscriptionPlanIdsForPlanTypeIdAndInterval(
                                activePlans,
                                'premium',
                                'monthly'
                            ),
                            productPriceDetails
                        ),
                        this.mapEnterprisePlanOption(
                            getSubscriptionPlanIdsForPlanTypeIdAndInterval(
                                activePlans,
                                'enterprise',
                                'monthly'
                            ),
                            productPriceDetails
                        ),
                    ]);
                    this._annualPlanOptions.next([
                        this.mapPersonalPlanOption(
                            getSubscriptionPlanIdsForPlanTypeIdAndInterval(
                                activePlans,
                                'personal',
                                'annual'
                            ),
                            productPriceDetails,
                            'annual'
                        ),
                        this.mapStarterPlanOption(
                            getSubscriptionPlanIdsForPlanTypeIdAndInterval(
                                activePlans,
                                'starter',
                                'annual'
                            ),
                            productPriceDetails,
                            'annual'
                        ),
                        this.mapPremiumPlanOption(
                            getSubscriptionPlanIdsForPlanTypeIdAndInterval(
                                activePlans,
                                'premium',
                                'annual'
                            ),
                            productPriceDetails
                        ),
                        this.mapEnterprisePlanOption(
                            getSubscriptionPlanIdsForPlanTypeIdAndInterval(
                                activePlans,
                                'enterprise',
                                'annual'
                            ),
                            productPriceDetails
                        ),
                    ]);
                }
            );
        });
    }

    private get paddle() {
        return window.Paddle;
    }

    public async getProductPrice(productId: number): Promise<ProductPriceDetails> {
        const productPrices = await this.getProductPrices([productId]);
        return productPrices[productId];
    }

    private async getProductPrices(
        productIds: number[]
    ): Promise<Record<string, ProductPriceDetails>> {
        const params = new URLSearchParams({
            product_ids: productIds.join(','),
            ...(isTestMode() && { customer_country: 'GB' }),
        });
        const data: any = await toPromise(
            this.httpClient.jsonp(
                `${environment.paddleApi}/2.0/prices?${params.toString()}`,
                'callback'
            )
        );
        const productPrices = {};
        if (data.success) {
            data.response.products.forEach((product) => {
                productPrices[product.product_id] = {
                    price: product.subscription.price.gross,
                    currency: product.currency,
                    trialDays: product.subscription.trial_days,
                };
            });
        }
        return productPrices;
    }

    getPlanOptions(billingInterval: BillingInterval): Observable<PlanOption[]> {
        switch (billingInterval) {
            case 'monthly':
                return this._monthlyPlanOptions.pipe(
                    filter((options) => !!options.length),
                    map((options) => options.map(cloneDeep))
                );
            case 'annual':
                return this._annualPlanOptions.pipe(
                    filter((options) => !!options.length),
                    map((options) => options.map(cloneDeep))
                );
            default:
                throw Error('unsupported billing interval: ' + billingInterval);
        }
    }

    getPlanOption(id: PlanTypeId, billingInterval: BillingInterval): Observable<PlanOption> {
        return this.getPlanOptions(billingInterval).pipe(
            map((options) => options.find((option) => option.id === id))
        );
    }

    getCurrentBillingInterval(plan: Plan): Observable<BillingInterval> {
        return this.getCurrentSubscriptionPlan(plan).pipe(
            map((subscriptionPlan) => {
                return subscriptionPlan.billingInterval;
            })
        );
    }

    getCurrentSubscriptionPlan(plan: Plan): Observable<SubscriptionPlan> {
        if (!plan.subscription_plan_id) {
            throw new Error('plan not active with subscription ' + plan.id);
        }
        return this.systemQuery.allPlans$.pipe(
            map((allPlans) => {
                const subscriptionPlanId = this.toNumber(plan.subscription_plan_id);
                const subscriptionPlan = allPlans.find(
                    (subscriptionPlan) => subscriptionPlan.id === subscriptionPlanId
                );
                if (!subscriptionPlan) {
                    throw new Error('unknown plan id ' + plan.subscription_plan_id);
                }
                return subscriptionPlan;
            })
        );
    }

    async updatePaymentDetails() {
        const updateUrl = await firstValueFrom(this.planDetailsQuery.updateUrl$);
        if (updateUrl) {
            this.paddle.Checkout.open({
                override: updateUrl,
            });
        }
    }

    private toNumber(id: number | string) {
        return isString(id) ? parseInt(id, 10) : id;
    }

    async checkout(
        planType$: Observable<PlanTypeId>,
        quantity$: Observable<number>,
        billingInterval$: Observable<BillingInterval>,
        company: string,
        existingPlanId: string,
        selectedPlanOption: PlanOption,
        loadCallback: (loading: boolean) => void,
        successCallback: (data) => void,
        closeCallback?: () => void,
        coupon?: string
    ) {
        const email = await this.authQuery.email;
        const userId = await this.authQuery.userId;
        const isSmallSize = this.windowService.currentWindowSize.smallSize;
        let planId = existingPlanId;
        if (!planId) {
            planId = await this.planService.add({
                company,
                createdAt: serverTimestamp(),
                createdBy: userId,
                createdByName: await this.authQuery.insiteUserName,
                status: 'pending',
            });
        } else {
            await this.planService.update(planId, {
                company,
            });
        }
        const passthrough = {
            userId,
            planId,
        };
        const planTypeSubscription = combineLatest([
            planType$,
            quantity$,
            billingInterval$,
            this.systemQuery.activePlans$,
        ]).subscribe(([planType, quantity, billingInterval, plans]) => {
            loadCallback(true);
            const subscriptionPlanId = getSubscriptionPlanId(
                plans,
                planType,
                selectedPlanOption.withTrial && !coupon,
                billingInterval
            );
            const checkoutRequest: any = {
                product: subscriptionPlanId,
                quantity: selectedPlanOption.supportsQuantity ? quantity || 1 : 1,
                allowQuantity: selectedPlanOption.supportsQuantity,
                disableLogout: true,
                passthrough: JSON.stringify(passthrough),
                email,
                coupon,
                loadCallback: () => {
                    loadCallback(false);
                },
                closeCallback: () => {
                    closeCallback();
                    planTypeSubscription?.unsubscribe();
                },
                successCallback: async (data: any) => {
                    successCallback(data);
                    planTypeSubscription?.unsubscribe();
                    await firstValueFrom(
                        this.planService
                            .docData$(`plans/${planId}`)
                            .pipe(
                                filter(
                                    (plan) =>
                                        (plan?.status === 'trialing' ||
                                            plan?.status === 'active') &&
                                        plan.subscription_plan_id
                                )
                            )
                    );
                    const navToProjectsIfNewPlan = () => {
                        if (!existingPlanId) {
                            this.navController.navigateRoot('/app/projects', {
                                state: {
                                    welcome: selectedPlanOption.withTrial && !coupon,
                                },
                            });
                        }
                    };
                    // via the auth checkout there is no modal to dismiss so we can just ignore that error
                    this.utilService
                        .dismissModal()
                        .then(navToProjectsIfNewPlan, navToProjectsIfNewPlan);
                },
            };
            if (isSmallSize) {
                checkoutRequest.method = 'inline';
                checkoutRequest.frameTarget = 'checkout-container';
                checkoutRequest.frameInitialHeight = 416;
                checkoutRequest.frameStyle =
                    'width:495px; min-width:495px; background-color: transparent; border: none;';
            }
            // bit of a workaround for the fact paddle will look at the ip to determine location
            // so tests fails when running in a CI environment
            if (isTestMode()) {
                checkoutRequest.country = 'GB';
            }
            this.paddle.Checkout.open(checkoutRequest);
        });
    }

    private mapPersonalPlanOption(
        paddleIds: number[],
        productPriceDetails: Record<string, ProductPriceDetails>,
        billingInterval: BillingInterval
    ): PlanOption {
        const priceDetails = productPriceDetails[head(paddleIds)];
        return {
            id: 'personal',
            name: 'Personal',
            paddleIds,
            quantityInfo: '1 user',
            description: 'Everything necessary for the independent inspector.',
            price: priceDetails.price,
            currency: priceDetails.currency,
            trialDays: priceDetails.trialDays,
            rate: billingInterval === 'monthly' ? 'per month' : 'per year',
            btnText: 'Buy now',
            featureHeadline: `What's included`,
            featureSet1: ['Unlimited projects', 'Mobile & desktop apps', 'Reports (Excel & PDF)'],
            featureSet2: ['Templates', 'Image editor'],
            type: 'BASIC',
            withTrial: true,
            billingInterval,
            supportsUpgradeTo: 'Starter',
        };
    }

    private mapStarterPlanOption(
        paddleIds: number[],
        productPriceDetails: Record<string, ProductPriceDetails>,
        billingInterval: BillingInterval
    ): PlanOption {
        const priceDetails = productPriceDetails[head(paddleIds)];
        return {
            id: 'starter',
            name: 'Starter',
            paddleIds,
            description: 'Inspect, assign and close out with your internal team.',
            price: priceDetails.price,
            currency: priceDetails.currency,
            trialDays: priceDetails.trialDays,
            rate: billingInterval === 'monthly' ? 'per month' : 'per year',
            btnText: 'Buy now',
            featureHeadline: `All of Personal, plus`,
            featureSet1: ['Multiple project users', 'Training packages'],
            featureSet2: [],
            type: 'TEAM',
            withTrial: true,
            billingInterval,
            supportsQuantity: true,
            quantityUnit: 'user',
            maxQuantity: 10,
        };
    }

    private mapPremiumPlanOption(
        paddleIds: number[],
        productPriceDetails: Record<string, ProductPriceDetails>
    ): PlanOption {
        const priceDetails = productPriceDetails[head(paddleIds)];
        return {
            id: 'premium',
            name: 'Premium',
            paddleIds,
            quantityInfo: 'Custom usage limits',
            description: 'Unlock all features & collaborate with everyone.',
            trialDays: priceDetails.trialDays,
            btnText: 'Contact us',
            featureHeadline: `All of Starter, plus`,
            featureSet1: [
                'External collaboration',
                'Pin issues on drawings',
                'Additional core features',
            ],
            featureSet2: ['Free admin training', 'Enhanced support'],
            type: 'ENTERPRISE',
            withTrial: true,
            mostPopular: true,
        };
    }

    private mapEnterprisePlanOption(
        paddleIds: number[],
        productPriceDetails: Record<string, ProductPriceDetails>
    ): PlanOption {
        const priceDetails = productPriceDetails[head(paddleIds)];
        return {
            id: 'enterprise',
            name: 'Enterprise',
            paddleIds,
            quantityInfo: 'Custom usage limits',
            description: 'Advanced customisation and connections. Custom pricing.',
            trialDays: priceDetails.trialDays,
            btnText: 'Contact us',
            featureHeadline: `All of Premium, plus`,
            featureSet1: ['Access to API', 'SSO'],
            featureSet2: ['Analytics & reporting', 'Custom report formats'],
            type: 'ENTERPRISE',
            withTrial: true,
        };
    }
}
