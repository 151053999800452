<ion-list class="popover-list position-relative z-1000 overflow-hidden">
    <ng-container *ngFor="let group of radioGroups">
        <ion-list-header class="pb-0 pl-16 font-weight-500" mode="md">
            <ion-text color="primary">{{ group.header }}</ion-text>
            <ion-icon *ngIf="group.informationMessage"
                      name="information-circle"
                      color="primary"
                      maxWidth="300px"
                      class="font-16 ml-6"
                      [tippy]="group.informationMessage"
                      libRemoveIoniconTitle
            ></ion-icon>
        </ion-list-header>
        <ion-radio-group
            [value]="group.currentValue"
            (ionChange)="selectGroupValue($event, group.handler)">
            <ion-item *ngFor="let value of group.values">
                <ion-label *ngIf="translateLabels; else noTranslate">{{value.name | translate}}</ion-label>
                <ng-template #noTranslate>
                    <ion-label>{{ value.name }}</ion-label>
                </ng-template>
                <ion-radio mode="md" slot="start" [value]="value.id"></ion-radio>
            </ion-item>
        </ion-radio-group>

        <ion-text *ngIf="group.guideLink">
            <p class="mt-12 mr-8 mb-12 ml-22 font-13">
                <span [libOpenUrl]="group.guideLink" class="external-link">Find out more</span>
            </p>
        </ion-text>
    </ng-container>
</ion-list>
