import { Injectable } from '@angular/core';
import { filterNilValue, QueryEntity } from '@datorama/akita';
import {
    defaultImageSettings,
    defaultListContentViewSettings,
    defaultReportSettings,
    FeatureAccess,
    FeatureConfig,
    FeatureFlags,
    Image,
    ImageEditingSettings,
    InsiteUserPrivate,
    InsiteUserWarningMessage,
    ItemFilterProfile,
    ListContentViewSettings,
    ListGroupByProperty,
    PinturaEditorState,
    ReportSettings,
} from '@insite-group-ltd/insite-teams-model';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, take } from 'rxjs/operators';
import { toPromise } from '../../utils/pipe';
import { InsiteUserPrivateState, InsiteUserPrivateStore } from './insite-user-private.store';

@Injectable({ providedIn: 'root' })
export class InsiteUserPrivateQuery extends QueryEntity<InsiteUserPrivateState> {
    constructor(protected store: InsiteUserPrivateStore) {
        super(store);
    }

    get userPrivate(): Promise<InsiteUserPrivate> {
        return this.userPrivate$.pipe(take(1), map(cloneDeep)).toPromise();
    }

    get userPrivate$(): Observable<InsiteUserPrivate> {
        return this.selectActive().pipe(filterNilValue(), map(cloneDeep));
    }

    get userPrivateFromId$(): Observable<InsiteUserPrivate> {
        return this.selectActiveId().pipe(
            filterNilValue(),
            distinctUntilChanged(),
            map(() => cloneDeep(this.getActive()))
        );
    }

    get listContentViewSettings$(): Observable<ListContentViewSettings> {
        return this.selectActive((userPrivate) =>
            Object.assign({}, userPrivate.listContentViewSettings || defaultListContentViewSettings)
        ).pipe(filterNilValue(), distinctUntilChanged(isEqual));
    }

    get reportSettings$(): Observable<ReportSettings> {
        return this.selectActive((userPrivate) =>
            Object.assign({}, userPrivate.reportSettings || defaultReportSettings)
        );
    }

    get listGroupBy$(): Observable<ListGroupByProperty> {
        return this.selectActive(
            (userPrivate) => userPrivate?.listGroupBy?.property || 'TAGS'
        ).pipe(distinctUntilChanged(isEqual));
    }

    getSavedListItemFilterProfile$(listId: string): Observable<ItemFilterProfile> {
        return this.selectActive().pipe(
            filterNilValue(),
            map((userPrivate) => cloneDeep(userPrivate.savedListFilters?.[listId])),
            distinctUntilChanged(isEqual)
        );
    }

    hasSavedListItemFilterProfile$(listId: string): Observable<boolean> {
        return this.getSavedListItemFilterProfile$(listId).pipe(
            map((savedListFilter) => !!savedListFilter)
        );
    }

    getWarningMessage$(warningMessage: InsiteUserWarningMessage): Observable<boolean> {
        return this.selectActive().pipe(
            filterNilValue(),
            map((userPrivate) => get(userPrivate, `warningMessage.${warningMessage}`, false)),
            distinctUntilChanged(isEqual)
        );
    }

    get defaultPlanId$() {
        return this.selectActive().pipe(
            filterNilValue(),
            map((userPrivate) => userPrivate.defaultPlan),
            distinctUntilChanged()
        );
    }

    get offlineImageEnabled$(): Observable<boolean> {
        return this.userPrivate$.pipe(
            map(
                (userPrivate) =>
                    userPrivate.imageSettings?.offlineUpload || defaultImageSettings.offlineUpload
            ),
            distinctUntilChanged()
        );
    }

    get onlyUploadOnWifi$(): Observable<boolean> {
        return this.userPrivate$.pipe(
            map(
                (userPrivate) =>
                    userPrivate.imageSettings?.onlyUploadOnWifi ||
                    defaultImageSettings.onlyUploadOnWifi
            )
        );
    }

    get saveImageGpsCoordinates$(): Observable<boolean> {
        return this.userPrivate$.pipe(
            map((userPrivate) => {
                if (isNil(userPrivate.imageSettings?.saveGpsCoordinates)) {
                    return defaultImageSettings.saveGpsCoordinates;
                } else {
                    return userPrivate.imageSettings?.saveGpsCoordinates;
                }
            })
        );
    }

    get imageGpsCoordinatesHighAccuracy$(): Observable<boolean> {
        return this.userPrivate$.pipe(
            map((userPrivate) => {
                if (isNil(userPrivate.imageSettings?.gpsCoordinatesHighAccuracy)) {
                    return defaultImageSettings.gpsCoordinatesHighAccuracy;
                } else {
                    return userPrivate.imageSettings?.gpsCoordinatesHighAccuracy;
                }
            })
        );
    }

    get offlineImageEnabled(): Promise<boolean> {
        return toPromise(this.offlineImageEnabled$);
    }

    get onlyUploadOnWifi(): Promise<boolean> {
        return toPromise(this.onlyUploadOnWifi$);
    }

    get saveImageGpsCoordinates(): Promise<boolean> {
        return toPromise(this.saveImageGpsCoordinates$);
    }

    get imageEditingSettings$(): Observable<ImageEditingSettings> {
        return this.userPrivate$.pipe(map((userPrivate) => userPrivate.imageEditingSettings));
    }

    get userSignature$(): Observable<string | Image> {
        return this.userPrivate$.pipe(map((userPrivate) => userPrivate.signature));
    }

    get completedUserGuideSteps(): Observable<string[]> {
        return this.userPrivate$.pipe(
            map((userPrivate) => userPrivate.completedUserGuideSteps || []),
            distinctUntilChanged()
        );
    }

    get pinturaImageEditorState$(): Observable<PinturaEditorState> {
        return this.userPrivate$.pipe(map((userPrivate) => userPrivate.pinturaImageEditorState));
    }

    get featureFlags$(): Observable<FeatureFlags> {
        return this.userPrivate$
            .pipe(map((userPrivate) => userPrivate.featureFlags || {}))
            .pipe(distinctUntilChanged(isEqual));
    }

    get featureConfig$(): Observable<FeatureConfig> {
        return this.userPrivate$
            .pipe(map((userPrivate) => userPrivate.featureConfig || {}))
            .pipe(distinctUntilChanged(isEqual));
    }

    get featureAccess$(): Observable<FeatureAccess> {
        return this.userPrivate$
            .pipe(map((userPrivate) => userPrivate.featureAccess || {}))
            .pipe(distinctUntilChanged(isEqual));
    }
}
