import countBy from 'lodash/countBy';
import keys from 'lodash/keys';
import minBy from 'lodash/minBy';
import { SelectOption } from './select-option';

export class ColourOptions {
    private readonly options: Record<string, number> = {
        '#009064': 0,
        '#005fbb': 0,
        '#00c3ff': 0,
        '#5852b3': 0,
        '#cb8a09': 0,
        '#ccb400': 0,
        '#e272e1': 0,
        '#de4443': 0,
        '#787878': 0,
        '#0c0c0c': 0,
    };

    private readonly colourState: Record<string, string> = {};

    get colourOptions() {
        return Object.keys(this.options);
    }

    getColourOption(id?: string) {
        if (id && this.colourState[id]) {
            return this.colourState[id];
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [key, value] = minBy(Object.entries(this.options), ([_, v]) => v) as [string, number];
        this.options[key] = value + 1;
        if (id) {
            this.colourState[id] = key;
        }
        return key;
    }
}

export const tagColours = [
    {
        name: 'Green',
        hex: '#009064',
    },
    {
        name: 'Blue',
        hex: '#005fbb',
    },
    {
        name: 'Turquoise',
        hex: '#00c3ff',
    },
    {
        name: 'Purple',
        hex: '#5852b3',
    },
    {
        name: 'Orange',
        hex: '#cb8a09',
    },
    {
        name: 'Yellow',
        hex: '#ccb400',
    },
    {
        name: 'Pink',
        hex: '#e272e1',
    },
    {
        name: 'Red',
        hex: '#de4443',
    },
    {
        name: 'Light grey',
        hex: '#787878',
    },
    {
        name: 'Dark grey',
        hex: '#0c0c0c',
    },
];

function tagColourKeys() {
    const colours: any = {};
    tagColours.forEach((tagColour) => {
        colours[tagColour.hex] = 0;
    });
    return colours;
}

export function getRandomOptionColour(withColours: SelectOption[]): string {
    const colours = tagColourKeys();
    const colourCount = countBy(withColours, 'colour');
    const mergedColourCount: Record<string, number> = { ...colours, ...colourCount };
    return minBy(keys(mergedColourCount), (key) => mergedColourCount[key]) as string;
}
