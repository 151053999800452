<ion-header>
    <ion-toolbar>
        <ion-buttons slot="primary">
            <ion-button (click)="close()" color="primary" fill="solid" size="small"
                        class="button-32-32 mr-6 button-padding-5">
                <ion-icon color="white" name="close"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<div class="modal-content height-100">
    <ion-slides pager="true" [options]="options" #sliderRef class="view-image-modal-bullets vertical-align-slide-content">
        <ion-slide *ngFor="let image of images" class="position-relative">
            <div class="swiper-zoom-container">
                <img data-testid="view.image" [src]="image.url | resolveImageUrl">
            </div>
            <div class="pagination-container line-height-19">
                <p *ngIf="image.takenDate" class="mt-0 mb-0 vertical-align-content ion-justify-content-center" data-testid="view.image.date.taken">
                    <ion-icon name="camera" class="font-18 mr-3"></ion-icon>
                    <span tippy="Date taken" data-tippy-placement="right">{{image.takenDate | date: 'medium' }}</span>
                </p>
                <p *ngIf="image.lastUpdated || image.createdAt" class="mt-0 mb-0 vertical-align-content ion-justify-content-center" data-testid="view.image.date.uploaded">
                    <ion-icon name="time" class="font-18 mr-3"></ion-icon>
                    <span tippy="Date uploaded" data-tippy-placement="right">{{(image.lastUpdated || image.createdAt) | date: 'medium' }}</span>
                </p>
                <p *ngIf="imagesHaveGps"
                   (click)="image.gpsCoordinates ? openGpsCoordinates(image.gpsCoordinates) : null"
                   [ngClass]="{'cursor-pointer': !!image.gpsCoordinates}"
                   class="mt-0 mb-0 vertical-align-content ion-justify-content-center"
                   data-testid="view.image.gps.coordinates">
                    <ion-icon name="navigate-circle" class="font-18 mr-3"></ion-icon>
                    <span tippy="GPS coordinates" data-tippy-placement="right" data-tippy-touch="false">{{(image.gpsCoordinates | gpsCoordinatesLabel) || 'No GPS coordinates'}}</span>
                </p>
            </div>
        </ion-slide>
    </ion-slides>
</div>
