import { NgModule } from '@angular/core';
import { ProjectNotArchivedGuard } from './project-not-archived.guard';
import { PlanNotBasicGuard } from './plan-not-basic.guard';
import { ProjectPlanNotBasicGuard } from './project-plan-not-basic.guard';
import { HasActivePlansAsAdminGuard } from './has-active-plans-as-admin.guard';

@NgModule({
    imports: [],
    providers: [
        ProjectNotArchivedGuard,
        PlanNotBasicGuard,
        ProjectPlanNotBasicGuard,
        HasActivePlansAsAdminGuard,
    ],
})
export class SharedGuardsModule {}
