<ng-container *ngIf="{
    options: options$ | async
} as state">
    <div>
        <div class="ion-text-center ion-padding-vertical">
            <ion-text><h4 class="ion-no-margin">{{title}}</h4></ion-text>
        </div>
        <ion-item class="rounded-item white round-no-label ion-no-padding ion-margin-horizontal">
            <ion-input data-testid="multi.select.popover.filter" 
                       [formControl]="searchControl"
                       clear-input="true"
                       mode="md"
                       name="title"
                       [autocapitalize]="autoCapitalizeInput"
                       #filterInput
                       type="text"
                       [placeholder]="allowAdd ? 'Filter or add new' : 'Filter'"></ion-input>
        </ion-item>
        <ion-item lines="none">
            <ion-label color="admin-dark" class="font-weight-500 ml-6">
                Choose
                <span *ngIf="allowAdd">existing </span>
                ({{state.options.length}} {{'option' | makePluralString: state.options.length}})
            </ion-label>
        </ion-item>
    </div>

    <ion-list *ngIf="state.options?.length; else noOptions"
              [ngStyle]="{'height': 'calc(2px + (44px * ' + state.options.length + '))'}"
              class="overflow-scroll-y mb-0">
        <virtual-scroller #scroll [items]="state.options">
            <ion-item *ngFor="let item of scroll.viewPortItems"
                      class="border-999 option checkbox-item"
                      mode="md"
                      data-testid="multi.select.popover.option">
                <ion-label data-testid="multi.select.popover.option.label">{{item.name}}</ion-label>
                <ion-checkbox [(ngModel)]="item.checked"
                              mode="ios"
                              slot="start"
                              class="ion-margin-end"
                              data-testid="multi.select.popover.option.checkbox"></ion-checkbox>
                <div *ngIf="withColours && item.colour && !endSlotTemplateRef"
                     [ngStyle]="{'background-color': item.colour + '40', 'border': '1px solid ' + item.colour}"
                     class="tag-indicator mr-neg-6"
                     slot="end"></div>
                <ng-container *ngIf="item.showEndSlotTemplate">
                    <ng-container
                        *ngTemplateOutlet="endSlotTemplateRef; context: { $implicit: item.colour }"></ng-container>
                </ng-container>
            </ion-item>
        </virtual-scroller>
    </ion-list>

    <ng-template #noOptions>
        <div *ngIf="!searchControl.value" class="ion-padding-bottom ion-padding-horizontal">
            <ion-label color="admin-dark">
                <p class="ml-16 mr-16 mb-8">
                    There are no {{title | lowercase}} set up for this project yet.
                    <ng-container *ngIf="allowAdd">Start typing above to add one.</ng-container>
                </p>
            </ion-label>
        </div>
    </ng-template>

    <div *ngIf="searchControl.value">
        <div class="ion-padding-horizontal" *ngIf="state.options.length === 0">
            <ion-label class="ion-text-left" color="admin-dark">
                <p class="ml-16 mr-16 mt-6 mb-14 font-15">
                    No <span *ngIf="allowAdd">existing </span>matches.
                </p>
            </ion-label>
        </div>
        <ng-container *ngIf="allowAdd">
            <ion-item lines="none">
                <ion-label color="admin-dark" class="font-weight-500 ml-6">Create new</ion-label>
            </ion-item>
            <ion-button (click)="add()"
                        class="button-border-2 hover-border-solid button-38 mr-20 ml-20 mb-20 mt-0 flex-shrink-0"
                        color="tertiary"
                        expand="block"
                        data-testid="multi.select.popover.add">
                <ion-text class="width-100 overflow-ellipsis line-height-22">
                    Create {{title | lowercase | rtrim: 's'}}: {{searchControl.value}}</ion-text>
            </ion-button>
        </ng-container>
    </div>

    <div class="display-flex z-1000 border-top-light-med pt-4 pb-4 pr-14">
        <ion-button *ngIf="allowClear && !searchControl.value" (click)="clear()" [disabled]="inverseSelectionMode ? checkedCount === state.options.length : !checkedCount" fill="clear" class="ml-6 button-34 button-padding-20"
                    data-testid="multi.select.popover.clear">
            {{clearLabel}}
        </ion-button>
        <div class="margin-left-auto">
            <ion-button (click)="cancel()" fill="clear" class="mr-8 button-34 button-padding-20" data-testid="multi.select.popover.cancel">
                Cancel
            </ion-button>
            <ion-button (click)="finish()" class="ml-6 button-34 button-padding-20" data-testid="multi.select.popover.done">Done</ion-button>
        </div>
    </div>
</ng-container>
