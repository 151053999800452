import { Pipe, PipeTransform } from '@angular/core';
import {
    getListItemAccessDetails,
    ItemAccessDetails,
    ItemPermission,
} from '@insite-group-ltd/insite-teams-model';

@Pipe({ name: 'itemAccessDetail' })
export class ItemAccessDetail implements PipeTransform {
    transform(itemAccess: ItemPermission): ItemAccessDetails {
        return getListItemAccessDetails(itemAccess);
    }
}
