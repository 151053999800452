<div *ngIf="planOption$ | async as planOption" class="modal-content overflow-scroll-y">
    <ion-button class="modal-close-button hide-desktop" fill="clear" (click)="close()">
        <ion-icon name="close" color="admin-dark"></ion-icon>
    </ion-button>
    <ion-grid class="padding-10">
        <ion-row>
            <ion-col sizeLg="8" sizeXs="12">
                <div *ngIf="isDowngrade$ | async" class="alert-header info-alert mb-10 pr-10">
                    <div class="display-flex-column flex-row-small">
                        <div class="vertical-align-content">
                            <ion-icon name="information-circle-outline"
                                      color="secondary"
                                      class="alert-icon"></ion-icon>
                            <ion-text color="secondary" class="hide-small ion-margin-start">
                                <p class="font-15 font-weight-500 mt-0 mb-0">
                                    You are downgrading your plan
                                </p>
                            </ion-text>
                        </div>
                        <div class="flex-grow-10">
                            <div style="max-width: 600px">
                                <ion-text color="secondary">
                                    <p class="font-15 font-weight-500 mb-4 mt-8 hide-mobile">You are downgrading your plan</p>
                                    <p class="font-14 mt-6" *ngIf="planOption.type === 'TEAM'" data-testid="downgrade.warning">Downgrading your plan to Starter will limit the number of users allowed on your projects to {{quantity$ | async}} and remove any external users.</p>
                                    <p class="font-14 mt-6" *ngIf="planOption.type === 'BASIC'" data-testid="downgrade.warning">Downgrading your plan to Personal will remove all other collaborators from your projects.</p>
                                </ion-text>
                            </div>
                        </div>
                    </div>
                </div>
                <ion-text color="admin-dark"><p class="font-20 mb-8 mt-0 ml-12"><b>Checkout</b></p></ion-text>
                <div *ngIf="showOwnerName" class="plan-details">
                    <ion-text color="admin-dark"><p class="font-18 mb-8 mt-0"><b>Plan details</b></p>
                    </ion-text>
                    <ion-text color="dark"><p class="font-12 ion-no-margin mt-10 mb-12">
                        This is an internal name and can be updated at an time under your plan
                        settings. It will not be shown on any invoices or billing information.
                    </p></ion-text>
                    <ion-label>Owner name</ion-label>
                    <ion-input [(ngModel)]="ownerName"
                               class="paddle-input mt-10 font-14"
                               name="ownerName"
                               autocapitalize="words"
                               placeholder="Company / Individual name"
                               required
                               data-testid="checkout.company.name"></ion-input>
                    <ng-container *ngIf="planOption.supportsQuantity">
                        <ion-label color="black"><p class="font-15 mb-8 mt-16">Number of {{ planOption.quantityUnit }}s</p></ion-label>
                        <app-plan-quantity [quantityUnit]="planOption.quantityUnit" [maxQuantity]="planOption.maxQuantity"></app-plan-quantity>
                    </ng-container>
                    <ng-container *ngIf="planOption.type !== 'ENTERPRISE' || planOption.withTrial">
                        <ion-label color="black"><p class="font-15 mb-8 mt-16">Terms & Conditions</p></ion-label>
                        <div class="vertical-align-content mt-16 ml-8 mb-16">
                            <ion-checkbox [(ngModel)]="acceptTerms" name="acceptTerms"
                                          data-testid="checkout.terms"
                                          class="mr-12 background-transparent"></ion-checkbox>
                            <p class="font-14 ion-no-margin">I accept the <span (click)="termsOfService()" class="hover-underline cursor-pointer font-14 color-primary">Terms and Conditions.</span></p>
                        </div>
                    </ng-container>
                    <ion-button (click)="checkout()"
                                [disabled]="!ownerName || ((planOption.type !== 'ENTERPRISE' || planOption.withTrial) && !acceptTerms)"
                                data-testid="checkout.proceed.payment">Continue to payment</ion-button>
                </div>

                <ng-container *ngIf="loading">
                    <ion-text color="dark">
                        <p class="font-12 ion-no-margin ion-text-center">
                            Please wait whilst we contact our payment provider, this can take up to 60 seconds.
                        </p>
                    </ion-text>
                    <ion-spinner class="load-spinner"
                                 color="secondary"
                                 name="crescent"></ion-spinner>
                </ng-container>

                <div [hidden]="showOwnerName || loading" class="checkout-container"></div>
            </ion-col>

            <ion-col sizeLg="4" sizeXs="12" class="plan-overview-container">
                <app-plan-overview [planOption]="planOption"
                                   [noShadow]="true"
                                   [wrapMedium]="true"></app-plan-overview>
                <div *ngIf="planOption?.supportsUpgradeTo; else padding" class="ion-text-center mt-4">
                    <span (click)="upgrade()" class="external-link hover-underline font-14">Upgrade to {{planOption?.supportsUpgradeTo}}</span>
                </div>
                <ng-template #padding>
                    <div class="width-100 ion-margin-bottom"></div>
                </ng-template>
            </ion-col>
        </ion-row>
    </ion-grid>
</div>
