import { Component, Input, NgZone, OnInit } from '@angular/core';
import { isPlanDowngrade } from '@insite-group-ltd/insite-teams-model';
import {
    PlanQuery,
    SaasAgreementPage,
    SaasAgreementPageInputs,
    toPromise,
    UtilService,
} from '@insite-group-ltd/insite-teams-shared';
import { ModalController } from '@ionic/angular';
import pluralize from 'pluralize';
import { combineLatest, firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PlanOptionQuery, PlanOptionService, PlanOptionStore } from '../state/plan-option';

@Component({
    selector: 'app-checkout',
    templateUrl: './checkout.page.html',
    styleUrls: ['./checkout.page.scss'],
})
export class CheckoutPage implements OnInit {
    @Input() planId: string;
    planOption$ = this.planOptionQuery.selectedPlanOption$;
    quantity$ = this.planOptionQuery.quantity$;
    isDowngrade$: Observable<boolean>;
    ownerName: string;
    showOwnerName = true;
    loading = false;
    acceptTerms: boolean;

    constructor(
        private planQuery: PlanQuery,
        private utilService: UtilService,
        private modalController: ModalController,
        private zone: NgZone,
        private planOptionService: PlanOptionService,
        private planOptionQuery: PlanOptionQuery,
        private planOptionStore: PlanOptionStore
    ) {}

    async ngOnInit() {
        if (this.planId) {
            this.ownerName = await toPromise(this.planQuery.getPlanCompany$(this.planId));
            this.isDowngrade$ = combineLatest([
                this.planQuery.getPlanType$(this.planId),
                this.planOptionQuery.selectedPlanOption$,
            ]).pipe(
                map(([planType, selectedPlanOption]) =>
                    isPlanDowngrade(planType, selectedPlanOption.type)
                )
            );
        }
    }

    async checkout() {
        if (this.planId) {
            const planOption = await firstValueFrom(this.planOptionQuery.selectedPlanOption$);
            if (planOption.id === 'starter') {
                const plan = await firstValueFrom(this.planQuery.selectEntity(this.planId));
                const quantity = await firstValueFrom(this.quantity$);
                const planUserCount = Object.keys(plan.users).length;
                if (planUserCount > quantity) {
                    this.utilService.errorAlert(
                        'Invalid user quantity',
                        `Your plan currently has ${planUserCount} ${pluralize(
                            'user',
                            planUserCount
                        )}. Please remove some users from your plan, or increase the quantity to continue.`
                    );
                    return;
                }
            }
        }
        this.showOwnerName = false;
        this.loading = true;
        this.planOptionService.checkout(
            this.ownerName,
            this.planId,
            (loading: boolean) => {
                this.zone.run(() => (this.loading = loading));
            },
            () => {
                this.zone.run(() => (this.loading = true));
            },
            () => {
                this.zone.run(() => this.modalController.dismiss());
            }
        );
    }

    upgrade() {
        this.planOptionStore.updatePlanType('starter');
    }

    close() {
        this.utilService.dismissModal();
    }

    termsOfService() {
        this.utilService.presentModal<SaasAgreementPageInputs>(
            SaasAgreementPage,
            { adminConsole: true },
            { cssClass: 'terms-modal' }
        );
    }
}
