import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { ConnectionStatus, Network } from '@capacitor/network';
import { Capacitor } from '@capacitor/core';

@Injectable({
    providedIn: 'root',
})
export class NetworkService implements OnDestroy {
    private _networkStatus$ = new BehaviorSubject<ConnectionStatus>({
        connected: true,
        connectionType: 'unknown',
    });

    constructor() {
        if (Capacitor.isNativePlatform()) {
            Network.getStatus().then((status) => this._networkStatus$.next(status));
            Network.addListener('networkStatusChange', (status) =>
                this._networkStatus$.next(status)
            );
        }
    }

    ngOnDestroy() {
        if (Capacitor.isNativePlatform()) {
            Network.removeAllListeners();
        }
    }

    get networkStatus$(): Observable<ConnectionStatus> {
        return this._networkStatus$.asObservable();
    }

    get networkStatus(): Promise<ConnectionStatus> {
        return this._networkStatus$.pipe(take(1)).toPromise();
    }

    get connected(): Promise<boolean> {
        if (!Capacitor.isNativePlatform()) {
            return Promise.resolve(true);
        }
        return Network.getStatus().then((networkStatus) => networkStatus.connected);
    }

    get connected$(): Observable<boolean> {
        if (!Capacitor.isNativePlatform()) {
            return of(true);
        } else {
            return this._networkStatus$.pipe(map((status) => status.connected));
        }
    }
}
