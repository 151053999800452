import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { getInsiteUserName, InsiteUser } from '@insite-group-ltd/insite-teams-model';

@Component({
    selector: 'lib-users-to-invite',
    templateUrl: './users-to-invite.component.html',
    styleUrls: ['./users-to-invite.component.scss'],
})
export class UsersToInviteComponent implements OnInit {
    @Input() invalidEmails: string[];
    @Input() existingUserCount: number;
    @Input() usersToAdd: InsiteUser[];
    @Input() usersToInvite: string[];
    @Input() type: 'PLAN' | 'PROJECT';
    @Output() private willInvite = new EventEmitter<void>();
    emailsToInviteAsString: string;
    invalidEmailsAsString: string;
    usersToAddAsString: string;

    ngOnInit() {
        if (this.usersToAdd && this.usersToAdd.length > 0) {
            this.usersToAddAsString = this.usersToAdd
                .map((user) => getInsiteUserName(user))
                .join(', ');
        }
        if (this.usersToInvite && this.usersToInvite.length > 0) {
            this.emailsToInviteAsString = this.usersToInvite.join(', ');
        }
        if (this.invalidEmails && this.invalidEmails.length > 0) {
            this.invalidEmailsAsString = this.invalidEmails.join(', ');
        }
    }

    confirm() {
        this.willInvite.emit();
    }
}
