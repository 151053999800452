import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { InsiteUser } from '@insite-group-ltd/insite-teams-model';
import { CollectionState } from 'akita-ng-fire';

export type InsiteUserState = CollectionState<InsiteUser>;

@Injectable({ providedIn: 'root' })
@StoreConfig({
    name: 'users',
    idKey: 'id',
})
export class InsiteUserStore extends EntityStore<InsiteUserState> {
    constructor() {
        super();
    }
}
