import merge from 'lodash/merge';
import { ListVisibility } from './list';
import { DefaultItemAccessSetting, PlanSettings } from './plan';
import { TemplateManagementOptions } from './settings';

export interface ProjectPermissions {
    templateManagement: TemplateManagementOptions;
    listPrivacy: ListVisibility;
    defaultItemAccess?: DefaultItemAccessSetting;
}

export const DEFAULT_PROJECT_PERMISSIONS: ProjectPermissions = {
    templateManagement: 'ADMINS_INTERNALS',
    listPrivacy: 'OPEN',
    defaultItemAccess: {
        ADMIN: 'FULL',
        INTERNAL: 'LIMITED',
        EXTERNAL: 'LIMITED',
        READ_ONLY: 'READ_ONLY',
    },
};

export function getProjectPermission<T extends keyof ProjectPermissions>(
    planSettings: PlanSettings,
    projectPermissions: ProjectPermissions,
    permission: T
): ProjectPermissions[T] {
    return merge(
        {},
        DEFAULT_PROJECT_PERMISSIONS,
        getPlanProjectPermissions(planSettings),
        projectPermissions
    )[permission];
}

export function getPlanProjectPermissions(planSettings: PlanSettings): Partial<ProjectPermissions> {
    return {
        templateManagement: planSettings?.templateManagement,
        listPrivacy: planSettings?.listPrivacy,
        defaultItemAccess: planSettings?.defaultItemAccess,
    };
}
