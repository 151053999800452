<div *ngIf="planOption"
     class="plan-card"
     [ngClass]="{ 'no-shadow': noShadow, 'is-highlighted': planOption.mostPopular }"
     data-testid="plan">
    <ion-badge color="internal-role" class="offer-badge no-pointer-events" *ngIf="planOption.discountPercentage">
        <span data-testid="plan.discount.percentage">{{ planOption.discountPercentage }}</span>% discount!
    </ion-badge>
    <ion-row>
        <ion-col class="pl-0 pb-0"
                 sizeXs="12"
                 sizeSm="6"
                 [sizeMd]="wrapMedium ? 6 : 12"
                 sizeLg="12">
            <div class="vertical-align-content">
                <ion-badge [color]="planOption.mostPopular ? 'primary' : 'light'"
                           class="mr-10 pt-5 pb-5 pr-10 pl-10">
                    <ion-label [color]="planOption.mostPopular ? 'white' : 'primary'"
                               class="font-14" data-testid="plan.name">{{ planOption.name }}</ion-label>
                </ion-badge>
            </div>

            <ng-container *ngIf="planOption.withTrial; else price">
                <div class="mt-10 ion-align-items-baseline">
                    <ion-text color="admin-dark"><p class="font-32 font-weight-600 ion-no-margin"
                                                    data-testid="plan.trial">{{ planOption.trialDays }}-day trial</p>
                    </ion-text>
                    <ion-text color="med-dark">
                        <p class="font-12 font-weight-500 mt-0 mb-6">
                            then,
                            <ng-container *ngIf="planOption.price; else bespoke">
                                <span data-testid="plan.price">{{planOption.price | currency: planOption.currency}}</span>&nbsp;<span
                                    data-testid="plan.billing.interval">{{planOption.rate}}</span>
                            </ng-container>
                            <ng-template #bespoke>
                                <span data-testid="plan.price">bespoke pricing</span>
                            </ng-template>
                        </p>
                    </ion-text>
                </div>
            </ng-container>

            <ng-template #price>
                <ng-container *ngIf="planOption.type !== 'ENTERPRISE'; else letsTalkMessage">
                    <div class="mt-10 ion-align-items-baseline">
                        <ion-text color="admin-dark"
                                  class="position-relative display-inline-flex ion-align-items-baseline">
                            <p class="font-32 font-weight-600 ion-no-margin"
                               data-testid="plan.price">{{ planOption.discountedPrice ?? planOption.price | currency: planOption.currency }}</p>
                            <p *ngIf="planOption.discountedPrice >= 0 && planOption.discountDuration !== 'lifetime'"
                               class="font-12 font-weight-500 mt-0 mb-0">
                                &nbsp;{{ planOption.billingInterval === 'monthly' ? 'for 1 month' : 'for 1 year' }}
                            </p>
                        </ion-text>
                        <ion-text color="med-dark">
                            <p class="font-12 font-weight-500 mt-0 mb-6">
                                <ng-container *ngIf="planOption.discountedPrice >= 0; else rate">
                                    <span data-testid="plan.billing.interval">{{
                                        planOption.discountDuration === 'lifetime'
                                            ? 'forever'
                                            : 'then, ' +
                                            (planOption.price | currency: planOption.currency) +
                                            ' ' +
                                            planOption.rate
                                        }}</span>
                                </ng-container>
                                <ng-template #rate><span
                                        data-testid="plan.billing.interval">{{ planOption.rate }}</span></ng-template>
                            </p>
                        </ion-text>
                    </div>
                </ng-container>
                <ng-template #letsTalkMessage>
                    <div class="mt-10">
                        <ion-text color="admin-dark"><p class="font-32 font-weight-600 ion-no-margin"
                                                        data-testid="plan.price">Let's talk</p></ion-text>
                        <ion-text color="med-dark">
                            <p class="font-12 font-weight-500 mt-0 mb-6 tippy-underline width-max-content"
                               tippy="Get in touch with us for the best price. We can provide a tailored cost proposal to suit your exact needs.">
                                contact us for a quote
                            </p>
                        </ion-text>
                    </div>
                </ng-template>
            </ng-template>
        </ion-col>

        <ion-col sizeXs="12"
                 sizeSm="6"
                 [sizeMd]="wrapMedium ? 6 : 12"
                 sizeLg="12"
                 class="pl-0 pr-0">
            <ion-text color="med-dark">
                <p class="font-12 ion-no-margin">{{ planOption.description }}</p>
            </ion-text>
            <div class="vertical-align-content ion-margin-top">
                <app-plan-quantity *ngIf="planOption.supportsQuantity && buyButton; else quantityInfo"
                                   [minQuantity]="minQuantity || 1"
                                   [maxQuantity]="planOption.maxQuantity"
                                   [sizeSmall]="true"
                                   [quantityUnit]="planOption.quantityUnit"
                                   class="width-100"></app-plan-quantity>
                <ng-template #quantityInfo>
                    <div class="quantity-information flex-grow-1">
                        <ion-text color="dark">
                            <p class="font-12 font-weight-500 mt-8 mb-8">
                                <ng-container *ngIf="planOption.supportsQuantity; else nonQuantityPlan">
                                    {{ ((quantity$ | async) + ' ' + planOption.quantityUnit | makePluralString: (quantity$ | async))}}
                                </ng-container>
                                <ng-template #nonQuantityPlan>
                                    {{ planOption.quantityInfo }}
                                </ng-template>
                            </p>
                        </ion-text>
                    </div>
                </ng-template>
            </div>
        </ion-col>
    </ion-row>

    <div class="ion-margin-bottom mt-10">
        <div class="line-break"></div>
    </div>

    <ion-row>
        <ion-text color="admin-dark" class="font-14 font-weight-500 mb-10">{{planOption.featureHeadline}}</ion-text>
    </ion-row>

    <ion-row>
        <ion-col class="ion-no-padding"
                 sizeXs="12"
                 sizeSm="6"
                 [sizeMd]="wrapMedium ? 6 : 12"
                 sizeLg="12">
            <div *ngFor="let feature of planOption.featureSet1" class="vertical-align-content mb-4">
                <ion-icon class="font-20 mr-10 flex-shrink-0"
                          color="primary"
                          name="checkmark-sharp"></ion-icon>
                <ion-text color="admin-dark"><span class="font-12">{{ feature }}</span></ion-text>
            </div>
        </ion-col>
        <ion-col class="ion-no-padding"
                 sizeXs="12"
                 sizeSm="6"
                 [sizeMd]="wrapMedium ? 6 : 12"
                 sizeLg="12">
            <div *ngFor="let feature of planOption.featureSet2" class="vertical-align-content mb-4">
                <ion-icon class="font-20 mr-10 flex-shrink-0"
                          color="primary"
                          name="checkmark-sharp"></ion-icon>
                <ion-text color="admin-dark"><span class="font-12">{{ feature }}</span></ion-text>
            </div>
        </ion-col>
    </ion-row>

    <div class="flex-fill"></div>

    <ion-button *ngIf="buyButton"
                (click)="choosePlan.emit()"
                size="block"
                class="ion-no-margin mt-12 button-border-1"
                [color]="planOption.mostPopular ? 'primary' : 'light-blue-bg'"
                data-testid="plan.buy">

        <ng-container *ngIf="planOption.type !== 'ENTERPRISE'; else enterpriseCta">
            <span *ngIf="planOption.withTrial; else noTrial">{{'Start ' + planOption.trialDays + '-day trial'}}</span>
            <ng-template #noTrial><span>Buy now</span></ng-template>
        </ng-container>

        <ng-template #enterpriseCta>
            <ng-container *ngIf="canUserTrial$ | async; else cantTrial">
                {{
                planOption.withTrial
                    ? 'Start ' + planOption.trialDays + '-day trial'
                    : 'Get started'
                }}
            </ng-container>

            <ng-template #cantTrial>
                {{
                allowBuyBespokePlans
                    ? 'Buy now'
                    : 'Contact us'
                }}
            </ng-template>
        </ng-template>
    </ion-button>
</div>
