import { Component, Input } from '@angular/core';
import { UtilService } from '@insite-group-ltd/insite-teams-shared';
import { PlanOptionQuery } from '../state/plan-option';

@Component({
    selector: 'app-enterprise-enquiry-modal',
    templateUrl: './enterprise-enquiry-modal.page.html',
    styleUrls: ['./enterprise-enquiry-modal.page.scss'],
})
export class EnterpriseEnquiryModalPage {
    @Input() showMobileCloseButton = true;
    @Input() isUpgrade = false;
    @Input() isResub = false;

    planOption$ = this.planOptionQuery.selectedPlanOption$;

    constructor(private utilService: UtilService, private planOptionQuery: PlanOptionQuery) {}

    close(filled: boolean, companyName?: string) {
        const data = { filled, companyName };
        this.utilService.dismissModal(data);
    }
}
