import { compareAsc, parseISO } from 'date-fns';
import startsWith from 'lodash/startsWith';
import { Item, ManageItem } from './item';
import { ItemFieldSet } from './item-field-set';
import { getItemPriorityType, ITEM_PRIORITY_ORDER } from './item-priority';
import { ProjectLocation, ProjectLocations } from './project';
import { sortByCustomIndex } from './sort';

export interface ItemSortFunction {
    readonly fun: (a: Item, b: Item, data?: any) => number;
}

export interface ItemSortOption {
    readonly code: string;
}

export type ItemSort = ItemSortOption & ItemSortFunction;

const MIN_SECONDS = -62135596800;

// copied implementation from firestore (https://github.com/firebase/firebase-js-sdk/pull/2662/files)
// as unable to share Timestamp type between frontend and backend
function valueOfTimestamp(timestamp: { seconds: number; nanoseconds: number }): string {
    const adjustedSeconds = timestamp.seconds - MIN_SECONDS;
    const formattedSeconds = String(adjustedSeconds).padStart(12, '0');
    const formattedNanoseconds = String(timestamp.nanoseconds).padStart(9, '0');
    return formattedSeconds + '.' + formattedNanoseconds;
}

export function sortItemCreatedAsc(a: Item | ManageItem, b: Item | ManageItem): number {
    if (!a.createdAt) {
        return 1;
    } else if (!b.createdAt) {
        return -1;
    }
    const at = valueOfTimestamp(a.createdAt);
    const bt = valueOfTimestamp(b.createdAt);
    if (at === bt) {
        return a.savedIndex < b.savedIndex ? -1 : 1;
    }
    return at < bt ? -1 : 1;
}

export function sortItemCreatedDesc(a: Item, b: Item): number {
    if (!a.createdAt) {
        return 1;
    } else if (!b.createdAt) {
        return -1;
    }
    const at = valueOfTimestamp(a.createdAt);
    const bt = valueOfTimestamp(b.createdAt);
    if (at === bt) {
        return a.savedIndex < b.savedIndex ? 1 : -1;
    }
    return at < bt ? 1 : -1;
}

export function sortItemUpdatedAsc(a: Item, b: Item): number {
    if (!a.lastUpdated) {
        return 1;
    } else if (!b.lastUpdated) {
        return -1;
    }
    const at = valueOfTimestamp(a.lastUpdated);
    const bt = valueOfTimestamp(b.lastUpdated);
    if (at === bt) {
        return 0;
    }
    return at < bt ? -1 : 1;
}

export function sortItemUpdatedDesc(a: Item, b: Item): number {
    if (!a.lastUpdated) {
        return 1;
    } else if (!b.lastUpdated) {
        return -1;
    }
    const at = valueOfTimestamp(a.lastUpdated);
    const bt = valueOfTimestamp(b.lastUpdated);
    if (at === bt) {
        return 0;
    }
    return at < bt ? 1 : -1;
}

export function sortItemAz(a: Item, b: Item): number {
    return sortAz(a.title, b.title);
}

export function sortDueDateAsc(a: Item, b: Item): number {
    if (!a.dueDate) {
        return 1;
    } else if (!b.dueDate) {
        return -1;
    }
    return compareAsc(parseISO(a.dueDate), parseISO(b.dueDate));
}

export function sortDueDateDesc(a: Item, b: Item): number {
    if (!a.dueDate) {
        return 1;
    } else if (!b.dueDate) {
        return -1;
    }
    return compareAsc(parseISO(b.dueDate), parseISO(a.dueDate));
}

export function sortLocationsAsc(a: Item, b: Item): number {
    let aLocations = a.locations;
    if (a.locations && a.locations.length > 0 && a.locationNames) {
        aLocations = a.locations
            .map((location) => a.locationNames[location])
            .filter((name) => !!name);
    }
    let bLocations = b.locations;
    if (b.locations && b.locations.length > 0 && b.locationNames) {
        bLocations = b.locations
            .map((location) => b.locationNames[location])
            .filter((name) => !!name);
    }
    return sortArray(aLocations, bLocations, sortAzHyphensLast);
}

export function sortLocationsCustom(a: Item, b: Item, projectLocations: ProjectLocations): number {
    if (!projectLocations) {
        return sortLocationsAsc(a, b);
    }
    const aLocations = a.locations
        .map((location) => projectLocations[location])
        .filter((projectLocation) => !!projectLocation);
    const bLocations = b.locations
        .map((location) => projectLocations[location])
        .filter((projectLocation) => !!projectLocation);
    return sortArray<ProjectLocation>(aLocations, bLocations, sortByCustomIndex);
}

export function sortAssigneesAsc(a: Item, b: Item): number {
    let aAssignees = a.assignees;
    if (a.assignees && a.assignees.length > 0 && a.assigneeNames) {
        aAssignees = a.assignees
            .map((assignee) => a.assigneeNames[assignee])
            .filter((name) => !!name);
    }
    let bAssignees = b.assignees;
    if (b.assignees && b.assignees.length > 0 && b.assigneeNames) {
        bAssignees = b.assignees
            .map((assignee) => b.assigneeNames[assignee])
            .filter((name) => !!name);
    }
    return sortArray(aAssignees, bAssignees, sortAzHyphensLast);
}

function sortArray<T>(a: T[], b: T[], sortFn: (a: T, b: T) => number): number {
    if (!a || a.length === 0) {
        return 1;
    } else if (!b || b.length === 0) {
        return -1;
    }
    const aSorted = a.sort(sortFn);
    const bSorted = b.sort(sortFn);
    if (aSorted === bSorted) {
        return 0;
    }
    return sortArrayInner(aSorted, bSorted, 0, sortFn);
}

function sortArrayInner<T>(a: T[], b: T[], index: number, sortFn: (a: T, b: T) => number): number {
    if (!a[index]) {
        return 1;
    } else if (!b[index]) {
        return -1;
    } else if (a[index] === b[index]) {
        return sortArrayInner(a, b, index + 1, sortFn);
    }
    return sortFn(a[index], b[index]);
}

function sortAz(a: string, b: string): number {
    if (!a) {
        return 1;
    } else if (!b) {
        return -1;
    } else if (a.toLowerCase() === b.toLowerCase()) {
        return 0;
    }
    return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
}

function sortAzHyphensLast(a: string, b: string): number {
    if (!a) {
        return 1;
    } else if (!b) {
        return -1;
    }
    const aStartsWithHyphen = startsWith(a, '-');
    const bStartsWithHyphen = startsWith(b, '-');
    if (aStartsWithHyphen && bStartsWithHyphen) {
        if (a.toLowerCase() === b.toLowerCase()) {
            return 0;
        }
        return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
    } else if (aStartsWithHyphen) {
        return 1;
    } else if (bStartsWithHyphen) {
        return -1;
    } else if (a.toLowerCase() === b.toLowerCase()) {
        return 0;
    }
    return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
}

export function sortPriorityDesc(a: Item, b: Item): number {
    return (
        ITEM_PRIORITY_ORDER.indexOf(getItemPriorityType(b)) -
        ITEM_PRIORITY_ORDER.indexOf(getItemPriorityType(a))
    );
}

export const defaultItemSortOption: ItemSort = {
    code: 'CREATED_ASC',
    fun: sortItemCreatedAsc,
};

export function getItemSortOptions(itemFieldSet: ItemFieldSet): ItemSort[] {
    const options = [];

    // created date
    options.push({
        code: 'CREATED_ASC',
        fun: sortItemCreatedAsc,
    });
    options.push({
        code: 'CREATED_DESC',
        fun: sortItemCreatedDesc,
    });

    // due date
    if (itemFieldSet.fields.DUE_DATE.enabled) {
        options.push({
            code: 'DUE_DATE_ASC',
            fun: sortDueDateAsc,
        });
        options.push({
            code: 'DUE_DATE_DESC',
            fun: sortDueDateDesc,
        });
    }

    // priority
    if (itemFieldSet.fields.PRIORITY.enabled) {
        options.push({
            code: 'PRIORITY_DESC',
            fun: sortPriorityDesc,
        });
    }

    // title
    options.push({
        code: 'A_Z',
        fun: sortItemAz,
    });

    // locations
    if (itemFieldSet.fields.LOCATIONS.enabled) {
        options.push({
            code: 'LOCATION_ASC',
            fun: sortLocationsAsc,
        });
        options.push({
            code: 'LOCATION_CUSTOM',
            fun: sortLocationsCustom,
        });
    }

    // assignees
    if (itemFieldSet.fields.ASSIGNEES.enabled) {
        options.push({
            code: 'ASSIGNEE_ASC',
            fun: sortAssigneesAsc,
        });
    }

    // updated date
    options.push({
        code: 'UPDATED_ASC',
        fun: sortItemUpdatedAsc,
    });
    options.push({
        code: 'UPDATED_DESC',
        fun: sortItemUpdatedDesc,
    });

    return options;
}

export const SORT_OPTIONS: ItemSort[] = [
    {
        code: 'CREATED_ASC',
        fun: sortItemCreatedAsc,
    },
    {
        code: 'CREATED_DESC',
        fun: sortItemCreatedDesc,
    },
    {
        code: 'DUE_DATE_ASC',
        fun: sortDueDateAsc,
    },
    {
        code: 'DUE_DATE_DESC',
        fun: sortDueDateDesc,
    },
    {
        code: 'PRIORITY_DESC',
        fun: sortPriorityDesc,
    },
    {
        code: 'A_Z',
        fun: sortItemAz,
    },
    {
        code: 'LOCATION_ASC',
        fun: sortLocationsAsc,
    },
    {
        code: 'LOCATION_CUSTOM',
        fun: sortLocationsCustom,
    },
    {
        code: 'ASSIGNEE_ASC',
        fun: sortAssigneesAsc,
    },
    {
        code: 'UPDATED_ASC',
        fun: sortItemUpdatedAsc,
    },
    {
        code: 'UPDATED_DESC',
        fun: sortItemUpdatedDesc,
    },
];

export function getSortOptionNoDefault(code: string): ItemSort | undefined {
    return SORT_OPTIONS.find((option) => option.code === code);
}

export function getSortOption(code: string): ItemSort {
    const sortOption = SORT_OPTIONS.find((option) => option.code === code);
    if (sortOption) {
        return sortOption;
    }
    return defaultItemSortOption;
}

export function getSortFunction(code: string) {
    return getSortOption(code).fun;
}
