import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import {
    authState,
    createUserWithEmailAndPassword,
    EmailAuthProvider,
    fetchSignInMethodsForEmail,
    getMultiFactorResolver,
    getRedirectResult,
    reauthenticateWithCredential,
    SAMLAuthProvider,
    sendEmailVerification,
    sendPasswordResetEmail,
    signInWithCredential,
    signInWithCustomToken,
    signInWithRedirect,
    TotpMultiFactorGenerator,
    updateEmail,
    updatePassword,
    user,
    User,
    UserCredential,
} from '@angular/fire/auth';
import {
    collection,
    doc,
    getDocs,
    limit,
    query,
    serverTimestamp,
    updateDoc,
    where,
} from '@angular/fire/firestore';
import { Capacitor } from '@capacitor/core';
import { filterNilValue } from '@datorama/akita';
import { GetAuthProviderResponseDto } from '@insite-group-ltd/insite-teams-api-model';
import {
    AuthProvider,
    defaultCompletedUserGuideSteps,
    defaultImageSettings,
    defaultReportSettings,
    EMAIL_PATTERN,
    InsiteUser,
    InsiteUserPrivate,
    PASSWORD_PATTERN,
    PhoneNumber,
} from '@insite-group-ltd/insite-teams-model';
import { NavController } from '@ionic/angular';
import * as Sentry from '@sentry/capacitor';
import { CollectionConfig, FireAuthService } from 'akita-ng-fire';
import head from 'lodash/head';
import includes from 'lodash/includes';
import { BehaviorSubject, firstValueFrom, Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { InAppBrowserService } from '../../services/in-app-browser/in-app-browser.service';
import { LoggerService } from '../../services/logger/logger.service';
import { SentryService } from '../../services/sentry/sentry.service';
import { UrlService } from '../../services/url/url.service';
import { UtilService } from '../../services/util/util.service';
import { InsiteUserPrivateService } from '../user/insite-user-private.service';
import { InsiteUserService } from '../user/insite-user.service';
import { AuthState, AuthStore } from './auth.store';

export interface CreateAccountRequest {
    email: string;
    pass: string;
    confirmPass?: string;
    acceptPrivacy: boolean;
    acceptTerms: boolean;
    subscribeToMailingList: boolean;
    firstName: string;
    lastName: string;
    company?: string;
    trialed?: boolean;
    phoneNumber?: PhoneNumber;
    pendingWelcomePopup?: boolean;
    hasConsented: boolean;
}

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'users', idKey: 'id' })
export class AuthService extends FireAuthService<AuthState> implements OnDestroy {
    private readonly _authSigningUp = new Subject<void>();
    private readonly _authSlideNo = new BehaviorSubject<number>(0);
    private readonly _tenantId = new BehaviorSubject<string>(null);
    private _authenticated = false;
    private uid: string;
    private subs = new SubSink();
    private _phoneNumber: PhoneNumber;
    private authState$ = authState(this.auth);

    constructor(
        store: AuthStore,
        private analyticsService: AnalyticsService,
        private utilService: UtilService,
        private navController: NavController,
        private sentryService: SentryService,
        private urlService: UrlService,
        private insiteUserService: InsiteUserService,
        private insiteUserPrivateService: InsiteUserPrivateService,
        private httpClient: HttpClient,
        @Inject('environment') private environment,
        private inAppBrowserService: InAppBrowserService,
        private navCtrl: NavController,
        private loggerService: LoggerService
    ) {
        super(store);
        this.listenForRedirectResult();
        this.subs.sink = this.authState$.subscribe((user) => {
            if (user) {
                this.uid = user.uid;
                this._tenantId.next(user.tenantId);
                this._authenticated = true;
                Sentry.configureScope((scope) => {
                    scope.setUser({
                        id: user.uid,
                        email: user.email,
                    });
                });
                this.analyticsService.setUserId(this.uid);
            } else {
                this._tenantId.next(null);
                this._authenticated = false;
                this.uid = null;
                Sentry.configureScope((scope) => {
                    scope.setUser(null);
                });
                this.analyticsService.setUserId(undefined);
            }
        });
    }

    get authenticated(): boolean {
        return this._authenticated;
    }

    get authenticated$(): Observable<boolean> {
        return this.authState$.pipe(map((user) => !!user));
    }

    get userId(): string {
        return this.uid;
    }

    get userId$(): Observable<string> {
        return this.authState$.pipe(
            filter((user) => !!user?.uid),
            map((user) => user.uid)
        );
    }

    get authSigningUp() {
        return this._authSigningUp;
    }

    get authSlideNo() {
        return this._authSlideNo;
    }

    get phoneNumber() {
        return this._phoneNumber;
    }

    get tenantId$() {
        return this._tenantId;
    }

    setPhoneNumber(phoneNumber: PhoneNumber) {
        this._phoneNumber = phoneNumber;
    }

    public async login(email: string, pass: string): Promise<boolean> {
        try {
            await this.signin(email, pass);
            return true;
        } catch (err) {
            if (err.code === 'auth/multi-factor-auth-required') {
                return await this.present2faChallenge(err);
            } else if (
                err?.code !== 'auth/wrong-password' &&
                err?.message !== 'The password is invalid or the user does not have a password.'
            ) {
                this.sentryService.captureException(err, {
                    extra: {
                        email,
                    },
                });
            } else if (err?.code === 'auth/too-many-requests') {
                await this.utilService.messageAlert(
                    'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.'
                );
            } else {
                await this.utilService.messageAlert('Incorrect email or password');
            }
        }
        return false;
    }

    public async hasAccount(email: string): Promise<boolean> {
        if (!EMAIL_PATTERN.test(email)) {
            await this.utilService.messageAlert(
                'Email invalid',
                'Please enter a valid email address'
            );
            throw Error(`${email} not a valid email address`);
        }
        try {
            const methods = await this.fetchSignInMethodsForEmail(email);
            return includes(methods, 'password');
        } catch (err) {
            this.sentryService.captureException(err, {
                extra: {
                    email,
                },
            });
            await this.utilService.messageAlert('Invalid email address');
            throw err;
        }
    }

    public forgotPassword(email: string) {
        this.utilService.confirmAlert(
            'Forgot your password?',
            'If you have forgotten your password then you can reset it via email.',
            'Send email',
            async () => {
                await sendPasswordResetEmail(this.auth, email);
                this.utilService.successToast(`Password reset email sent to: ${email}`);
            }
        );
    }

    public async createAccount(request: CreateAccountRequest): Promise<boolean> {
        if (!(await this.validatePassword(request.pass, request.confirmPass))) {
            return false;
        }
        if (!request.acceptPrivacy || !request.acceptTerms) {
            await this.utilService.messageAlert(
                'Privacy & terms not accepted',
                'You must accept both our Privacy Policy and Acceptable Use Policy in order to sign up'
            );
            return false;
        }
        try {
            const userCredential = await this.createUserWithEmailAndPassword(
                request.email,
                request.pass
            );
            const user = userCredential.user;
            await this.saveInsiteUser(
                user,
                {
                    firstName: request.firstName?.trim(),
                    lastName: request.lastName?.trim(),
                    company: request.company?.trim(),
                },
                {
                    termsAccepted: request.acceptTerms,
                    privacyAccepted: request.acceptPrivacy,
                    subscribeToMailingList: request.subscribeToMailingList,
                    phoneNumber: request.phoneNumber,
                    trialed: request.trialed,
                    pendingWelcomePopup: request.pendingWelcomePopup,
                    hasConsented: request.hasConsented,
                    completedUserGuideSteps: defaultCompletedUserGuideSteps,
                }
            );
            return true;
        } catch (err) {
            this.sentryService.captureException(err, {
                extra: {
                    email: request.email,
                },
            });
            await this.utilService.messageAlert(
                'Failed to create account',
                this.getFirebaseErrorMessage(err)
            );
        }
        return false;
    }

    private async saveInsiteUser(
        user: User,
        insiteUser: Partial<InsiteUser>,
        insiteUserPrivate: Partial<InsiteUserPrivate>
    ) {
        const batch = this.insiteUserService.batch();
        this.insiteUserService.add(
            {
                id: user.uid,
                uid: user.uid,
                email: user.email.toLowerCase(),
                privateMode: false,
                createdAt: serverTimestamp(),
                ...insiteUser,
            } as InsiteUser,
            this.insiteUserService.getWriteOptions({}, batch)
        );
        this.insiteUserPrivateService.add(
            {
                id: user.uid,
                imageSettings: defaultImageSettings,
                reportSettings: defaultReportSettings,
                walkthroughComplete: false,
                utmSource: this.urlService.utmSource,
                ...insiteUserPrivate,
            } as InsiteUserPrivate,
            this.insiteUserPrivateService.getWriteOptions({ userId: user.uid }, batch)
        );
        await batch.commit();
        this.analyticsService.event('create_user');
        await this.sendVerificationEmail();
        if (insiteUserPrivate.trialed) {
            this.urlService.trackConversion();
        }
    }

    public createUserWithEmailAndPassword(email: string, pass: string) {
        return createUserWithEmailAndPassword(this.auth, email, pass);
    }

    public fetchSignInMethodsForEmail(email: string) {
        return fetchSignInMethodsForEmail(this.auth, email);
    }

    public async sendPasswordResetEmail() {
        const user = this.user;
        await sendPasswordResetEmail(this.auth, user.email);
        this.analyticsService.event('send_password_reset');
    }

    public async changePassword(pass: string) {
        const user = this.user;
        await updatePassword(user, pass);
        this.analyticsService.event('update_password');
    }

    public async changeEmail(email: string) {
        const loading = await this.utilService.presentLoadingSpinner();
        try {
            const user = this.user;
            await updateEmail(user, email);
            await this.sendVerificationEmail();
            this.analyticsService.event('update_email');
            await this.signOutAndClearTenant();
            this.utilService.successToast('Login to verify your updated email address');
            this.navController.navigateRoot('auth', {
                queryParams: { email: encodeURIComponent(email) },
            });
        } catch (err) {
            this.sentryService.captureException(err);
            this.utilService.messageAlert(
                'Failed to change email',
                this.getFirebaseErrorMessage(err)
            );
        } finally {
            loading.dismiss();
        }
    }

    public async sendVerificationEmail() {
        await sendEmailVerification(this.user);
        this.analyticsService.event('send_verification_email');
    }

    public async reauthenticateWithCredential(pass: string) {
        const user = this.user;
        const credential = EmailAuthProvider.credential(user.email, pass);
        await reauthenticateWithCredential(user, credential);
        this.analyticsService.event('reauthenticate_with_credential');
    }

    public async signInWithCustomToken(customToken: string): Promise<boolean> {
        const credential: UserCredential = await signInWithCustomToken(this.auth, customToken);
        this.analyticsService.event('sign_in_with_custom_token');
        if (!credential.user.emailVerified) {
            await this.sendVerificationEmail();
        }
        return credential.user.emailVerified;
    }

    public async deleteUser() {
        const user = this.user;
        await user.delete();
        this.analyticsService.event('delete_user');
    }

    public async validatePassword(pass: string, confirmPass: string): Promise<boolean> {
        if (!PASSWORD_PATTERN.test(pass)) {
            await this.utilService.messageAlert(
                'Passwords does not meet the strength requirements',
                'Must be more than 8 characters long, containing a minimum of 1 uppercase, 1 lowercase and 1 number'
            );
            return false;
        }
        if (confirmPass && pass !== confirmPass) {
            await this.utilService.messageAlert('Passwords do not match');
            return false;
        }
        return true;
    }

    updateUserPrivate(user: Partial<InsiteUserPrivate>): Promise<void> {
        const userId = this.userId;
        const docRef = doc(this.db, `users/${userId}/private/${userId}`);
        return updateDoc(docRef, user);
    }

    async searchByEmail(emailQuery: string): Promise<InsiteUser> {
        this.analyticsService.event('search_user_by_email');
        const users = await getDocs(
            query(
                collection(this.db, 'users'),
                where('email', '==', emailQuery),
                where('privateMode', '==', false),
                limit(1)
            )
        );
        if (users.size > 0) {
            const userDoc = head(users.docs);
            return { id: userDoc.id, ...userDoc?.data() } as InsiteUser;
        } else {
            return null;
        }
    }

    logout() {
        this.utilService.confirmAlert(
            'Log out',
            'Are you sure you want to log out?',
            'Log out',
            async () => {
                const loading = await this.utilService.presentLoadingSpinner();
                try {
                    await this.signOutAndClearTenant();
                    await this.navController.navigateRoot('/auth', {
                        animated: true,
                        animationDirection: 'back',
                    });
                } finally {
                    await loading.dismiss();
                }
            },
            'Back'
        );
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    getAuthProvider(email: string): Promise<GetAuthProviderResponseDto> {
        return firstValueFrom(
            this.httpClient.post<GetAuthProviderResponseDto>(
                `${this.environment.apiBaseUrl}/auth/provider`,
                {
                    email,
                }
            )
        );
    }

    signInViaCustomMicrosoftProvider(email: string, scope: 'TEAMS' | 'ADMIN') {
        const validateAuthPath = `${this.environment.functionDomain}/authenticate${
            scope === 'TEAMS' ? 'Teams' : 'Admin'
        }?email=${encodeURIComponent(email)}`;
        if (Capacitor.isNativePlatform()) {
            this.inAppBrowserService.openInAppBrowser(validateAuthPath, async (data: any) => {
                await this.signInWithCustomToken(data.jwt);
                this.navCtrl.navigateRoot('/');
            });
        } else {
            this.window.location = validateAuthPath;
        }
    }

    signInViaSamlProvider(email: string, authProvider: AuthProvider) {
        if (Capacitor.isNativePlatform()) {
            this.inAppBrowserService.openInAppBrowser(
                `${this.environment.appUrl}/auth?email=${encodeURIComponent(email)}`,
                async (data: any) => {
                    await signInWithCredential(
                        this.auth,
                        SAMLAuthProvider.credentialFromJSON(data)
                    );
                    this.navCtrl.navigateRoot('/');
                }
            );
        } else {
            const samlProvider = new SAMLAuthProvider(authProvider.id).setCustomParameters({
                login_hint: email,
            });
            signInWithRedirect(this.auth, samlProvider);
        }
    }

    private async listenForRedirectResult() {
        try {
            const result = await getRedirectResult(this.auth);
            if (result && this.inAppBrowserService.isWithinInAppBrowser) {
                const credential = SAMLAuthProvider.credentialFromResult(result);
                await this.auth.signOut();
                this.inAppBrowserService.postMessage(credential.toJSON());
            }
        } catch (err) {
            this.loggerService.error('auth-service', err.message);
        }
    }

    async signOutAndClearTenant() {
        await this.signOut();
        this.auth.tenantId = null;
    }

    getFirebaseErrorMessage(error: { code: string }): string {
        let errorMessage = 'Please try again';
        const errorCode = error.code;
        if (errorCode === 'auth/email-already-in-use') {
            errorMessage = 'Email address already in use';
        } else if (errorCode === 'auth/invalid-email') {
            errorMessage = 'Invalid email address';
        } else if (errorCode === 'auth/weak-password') {
            errorMessage = 'Password is too weak';
        } else if (errorCode === 'auth/requires-recent-login') {
            errorMessage = 'Requires recent login.';
        }
        return errorMessage;
    }

    get canManageAccount$(): Observable<boolean> {
        return user(this.auth).pipe(
            filterNilValue(),
            map((user) => {
                return (
                    user?.providerData.length === 1 &&
                    user.providerData[0].providerId === 'password'
                );
            })
        );
    }

    private get window(): any {
        return window;
    }

    private present2faChallenge(err: any) {
        return new Promise<boolean>((resolve) => {
            this.utilService.presentAlert(
                'Two-factor authentication',
                'Enter the 6-digit code from your authenticator app<br><br>Unable to retrieve your code? <a target="_blank" href="https://insiteapp.zendesk.com/hc/en-gb/requests/new">Contact us</a>',
                [
                    {
                        text: 'Cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            resolve(false);
                        },
                    },
                    {
                        text: 'Verify code',
                        role: 'confirm',
                        handler: async (data: any) => {
                            try {
                                const resolver = getMultiFactorResolver(this.auth, err);
                                const multiFactorAssertion =
                                    TotpMultiFactorGenerator.assertionForSignIn(
                                        head(resolver.hints).uid,
                                        data.code
                                    );
                                await resolver.resolveSignIn(multiFactorAssertion);
                                resolve(true);
                            } catch (err) {
                                this.utilService.errorAlert(
                                    'Failed to verify code',
                                    'Please try again.'
                                );
                                resolve(false);
                            }
                        },
                    },
                ],
                [
                    {
                        name: 'code',
                        type: 'text',
                        label: 'Code',
                        attributes: {
                            name: 'code',
                            minLength: 6,
                            maxLength: 6,
                            inputmode: 'numeric',
                            autocomplete: 'one-time-code',
                        },
                    },
                ]
            );
        });
    }
}
