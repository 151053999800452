import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { EnquiryDto } from '@insite-group-ltd/insite-teams-api-model';
import { PlanOption } from '@insite-group-ltd/insite-teams-model';
import {
    ApiService,
    AuthQuery,
    AuthService,
    focusField,
    UtilService,
} from '@insite-group-ltd/insite-teams-shared';
import { IonInput } from '@ionic/angular';
import { PlanOptionQuery } from '../../state/plan-option';

@Component({
    selector: 'app-enterprise-enquiry',
    templateUrl: './enterprise-enquiry.component.html',
    styleUrls: ['./enterprise-enquiry.component.scss'],
})
export class EnterpriseEnquiryComponent implements OnInit {
    @ViewChild('inputToFocus', { static: false }) inputToFocus: IonInput;
    @Input() name: string;
    @Input() email: string;
    @Input() showHeader: boolean;
    @Input() company: string;
    @Input() planOption: PlanOption;
    @Output() private requestSent = new EventEmitter<string>();
    canUserTrial$ = this.planOptionQuery.canUserTrial$;
    smallProjects = false;
    mediumProjects = false;
    largeProjects = false;
    additionalDetails: string;

    constructor(
        private utilService: UtilService,
        private authQuery: AuthQuery,
        private authService: AuthService,
        private planOptionQuery: PlanOptionQuery,
        private apiService: ApiService
    ) {}

    async ngOnInit() {
        if (this.authService.authenticated && !this.email && !this.name) {
            this.name = await this.authQuery.insiteUserName;
            this.email = await this.authQuery.email;
        }
    }

    ionViewDidEnter() {
        focusField(this.inputToFocus);
    }

    async sendEnquiry() {
        if (!this.smallProjects && !this.mediumProjects && !this.largeProjects) {
            return this.utilService.messageAlert(
                'No project size indicated',
                'Please select some expected project sizes so that we can understand your usage.'
            );
        }
        try {
            await this.utilService.withLoadingSpinner(() => {
                return this.apiService.post<EnquiryDto>('/enquiry', {
                    type: this.planOption.id,
                    name: this.name,
                    email: this.email,
                    company: this.company,
                    smallProjects: this.smallProjects,
                    mediumProjects: this.mediumProjects,
                    largeProjects: this.largeProjects,
                    notes: this.additionalDetails || 'No notes',
                });
            });
            this.utilService.successToast(`Enquiry received - we'll be in touch!`);
            this.requestSent.emit(this.company);
        } catch (e) {
            this.utilService.errorToast(`Failed to send enquiry - please try again!`);
        }
    }
}
