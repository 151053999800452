import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TippyModule } from '@ngneat/helipopper';
import { StatusIndicatorComponent } from './status-indicator.component';

@NgModule({
    imports: [CommonModule, IonicModule, TippyModule],
    declarations: [StatusIndicatorComponent],
    exports: [StatusIndicatorComponent],
})
export class StatusIndicatorModule {}
