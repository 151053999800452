import { Audited } from '@insite-group-ltd/insite-teams-model';

type WithId = { id: string };

export function getId<T extends WithId>(newId: string, value?: T): string {
    let id = value.id;
    if (!id) {
        id = newId;
        value.id = id;
    }
    return id;
}

export function removeNestedProperty<T extends Audited>(
    objectValue: T,
    key: keyof T,
    id: string
): Partial<T> {
    // @ts-ignore
    const data: Partial<T> = {
        [key]: Object.assign({}, objectValue[key]),
    };
    delete data[key][id];
    return data;
}

export function updateNestedProperty<T extends Audited>(
    objectValue: T,
    key: keyof T,
    id: string,
    propertyValue: any
): Partial<T> {
    // @ts-ignore
    const data: Partial<T> = {
        [key]: Object.assign({}, objectValue[key]),
    };
    data[key][id] = propertyValue;
    return data;
}
