import { Component, Input } from '@angular/core';
import { ItemPermission } from '@insite-group-ltd/insite-teams-model';
import { RadioGroupChangeEventDetail } from '@ionic/core';
import { UtilService } from '../../services/util/util.service';

@Component({
    selector: 'app-select-default-item-access',
    templateUrl: './select-default-item-access.page.html',
    styleUrls: ['./select-default-item-access.page.scss'],
})
export class SelectDefaultItemAccessPage {
    @Input() currentAccess: ItemPermission;
    accessOptions: ItemPermission[] = ['FULL', 'LIMITED', 'READ_ONLY'];

    constructor(private utilService: UtilService) {}

    dismiss(event?: Event) {
        const updatedAccess = (event as CustomEvent<RadioGroupChangeEventDetail>)?.detail?.value;
        this.utilService.dismissModal({ updatedAccess });
    }
}
