import { asArray } from '@insite-group-ltd/utils';
import { ActionSheetController, ModalController, PopoverController } from '@ionic/angular';
import { ComponentProps, ComponentRef, ModalOptions, Mode } from '@ionic/core';
import { ActionSheetButton } from '@ionic/core/dist/types/components/action-sheet/action-sheet-interface';
import {
    GroupedPopoverComponent,
    PopoverOptionGroup,
} from '../../components/popovers/grouped-popover/grouped-popover.component';
import { InputPopoverComponent } from '../../components/popovers/input-popover/input-popover.component';
import {
    RadioGroup,
    RadioGroupPopoverComponent,
} from '../../components/popovers/radio-group-popover/radio-group-popover.component';
import {
    PopoverOption,
    SelectPopoverComponent,
} from '../../components/popovers/select-popover/select-popover.component';

export class ModalBuilder<U, R, T extends ComponentRef> {
    private readonly options: ModalOptions<T>;

    constructor(private modalController: ModalController, component: T, componentProps?: U) {
        this.options = {
            component,
            componentProps,
        };
    }

    componentProps(componentProps: U): this {
        this.options.componentProps = componentProps;
        return this;
    }

    cssClass(...cssClass: string[]): this {
        this.options.cssClass = cssClass;
        return this;
    }

    backdropDismiss(backdropDismiss: boolean): this {
        this.options.backdropDismiss = backdropDismiss;
        return this;
    }

    async present(): Promise<HTMLIonModalElement> {
        const modal = await this.modalController.create(this.options);
        await modal.present();
        return modal;
    }

    async presentAwaitData() {
        const modal = await this.present();
        const { data } = await modal.onDidDismiss<R>();
        return data;
    }
}

export type ActionSheetBuilderButton = ActionSheetButton & {
    selected?: boolean;
    includeButtonIf?: boolean;
};

export class ActionSheetBuilder {
    private buttons: (ActionSheetButton | string)[] = [];

    constructor(private actionSheetController: ActionSheetController) {}

    header(text: string, handler?: ActionSheetButton['handler']): this {
        this.buttons.push({
            text,
            cssClass: 'action-sheet-intermittent-header',
            handler,
        });
        return this;
    }

    button(button: ActionSheetBuilderButton | ActionSheetBuilderButton[]): this {
        asArray(button).forEach((btn) => {
            if (btn.selected) {
                btn.cssClass = 'action-sheet-button-selected';
            }
            if (btn.includeButtonIf !== false) {
                this.buttons.push(btn);
            }
        });
        return this;
    }

    async present(cssClass?: string | string[]) {
        const actionSheet = await this.actionSheetController.create({
            mode: 'md',
            buttons: this.buttons,
            cssClass,
        });
        await actionSheet.present();
        return actionSheet;
    }
}

abstract class BasePopoverBuilder<T> {
    private cssClasses: string[] = ['custom-popover'];
    private translate = false;
    private _mode: Mode = 'ios';

    constructor(
        private popoverController: PopoverController,
        private component: ComponentRef,
        private event: Event
    ) {}

    cssClass(cssClass: string | string[]): this {
        this.cssClasses.push(...asArray(cssClass));
        return this;
    }

    translateLabels(translate: boolean): this {
        this.translate = translate;
        return this;
    }

    mode(mode: Mode): this {
        this._mode = mode;
        return this;
    }

    protected abstract getComponentProps(): ComponentProps<T>;

    present() {
        return this.presentPopover(this.getComponentProps());
    }

    async presentAwaitData() {
        const popover = await this.present();
        const { data } = await popover.onDidDismiss();
        return data;
    }

    private async presentPopover(componentProps: ComponentProps<T>) {
        const popover = await this.popoverController.create({
            id: 'tag-colour-picker',
            component: this.component,
            componentProps: {
                ...componentProps,
                translateLabels: this.translate,
            },
            cssClass: this.cssClasses,
            event: this.event,
            translucent: true,
            mode: this._mode,
            animated: false,
        });
        await popover.present();
        return popover;
    }
}

export class RadioGroupPopoverBuilder extends BasePopoverBuilder<RadioGroupPopoverComponent> {
    private _radioGroups: RadioGroup[] = [];

    constructor(
        popoverController: PopoverController,
        event: Event,
        radioGroups?: RadioGroup[],
        cssClass?: string | string[]
    ) {
        super(popoverController, RadioGroupPopoverComponent, event);
        if (radioGroups?.length) {
            this._radioGroups.push(...radioGroups);
        }
        if (cssClass) {
            this.cssClass(cssClass);
        }
    }

    radioGroup(radioGroup: RadioGroup | RadioGroup[]): this {
        this._radioGroups.push(...asArray(radioGroup));
        return this;
    }

    protected getComponentProps(): ComponentProps<RadioGroupPopoverComponent> {
        return { radioGroups: this._radioGroups };
    }
}

export class GroupedPopoverBuilder extends BasePopoverBuilder<GroupedPopoverComponent> {
    private _popoverGroups: PopoverOptionGroup[] = [];

    constructor(
        popoverController: PopoverController,
        event: Event,
        popoverOptionGroups?: PopoverOptionGroup[],
        cssClass?: string | string[]
    ) {
        super(popoverController, GroupedPopoverComponent, event);
        if (popoverOptionGroups?.length) {
            this._popoverGroups.push(...popoverOptionGroups);
        }
        if (cssClass) {
            this.cssClass(cssClass);
        }
    }

    optionGroup(optionGroup: PopoverOptionGroup | PopoverOptionGroup[]): this {
        this._popoverGroups.push(...asArray(optionGroup));
        return this;
    }

    protected getComponentProps(): ComponentProps<RadioGroupPopoverComponent> {
        return { popoverGroups: this._popoverGroups };
    }
}

export class SelectPopoverBuilder extends BasePopoverBuilder<SelectPopoverComponent> {
    private _options: PopoverOption[] = [];
    private _title = '';
    private _selectedIcon: 'start' | 'end' = 'start';
    private _ctx: Record<string, unknown>;

    constructor(
        popoverController: PopoverController,
        event: Event,
        popoverOptions?: PopoverOption[],
        title?: string,
        cssClass?: string | string[]
    ) {
        super(popoverController, SelectPopoverComponent, event);
        if (popoverOptions?.length) {
            this._options.push(...popoverOptions);
        }
        if (title) {
            this.title(title);
        }
        if (cssClass) {
            this.cssClass(cssClass);
        }
    }

    title(title: string): this {
        this._title = title;
        return this;
    }

    option(option: PopoverOption | PopoverOption[]): this {
        asArray(option)
            .filter((opt) => opt.includeOptionIf !== false)
            .forEach((opt) => this._options.push(opt));
        return this;
    }

    selectedIcon(selectedIcon: 'start' | 'end' = 'start'): this {
        this._selectedIcon = selectedIcon;
        return this;
    }

    ctx(ctx: Record<string, unknown>): this {
        this._ctx = ctx;
        return this;
    }

    protected getComponentProps(): ComponentProps<RadioGroupPopoverComponent> {
        return {
            options: this._options,
            title: this._title,
            selectedIcon: this._selectedIcon,
            ctx: this._ctx,
        };
    }
}

export class InputPopoverBuilder extends BasePopoverBuilder<InputPopoverComponent> {
    private _value = '';
    private _title = '';
    private _placeholder = '';
    private _hint = '';

    constructor(
        popoverController: PopoverController,
        event: Event,
        value: string,
        title?: string,
        cssClass?: string | string[],
        placeholder?: string,
        hint?: string
    ) {
        super(popoverController, InputPopoverComponent, event);
        if (value?.length) {
            this._value = value;
        }
        if (title) {
            this.title(title);
        }
        if (cssClass) {
            this.cssClass(cssClass);
        }
        if (placeholder) {
            this.placeholder(placeholder);
        }
        if (hint) {
            this.hint(hint);
        }
    }

    title(title: string): this {
        this._title = title;
        return this;
    }

    placeholder(placeholder: string): this {
        this._placeholder = placeholder;
        return this;
    }

    hint(hint: string): this {
        this._hint = hint;
        return this;
    }

    protected getComponentProps(): ComponentProps<InputPopoverComponent> {
        return {
            value: this._value,
            title: this._title,
            placeholder: this._placeholder,
            hint: this._hint,
        };
    }
}

export class GenericPopoverBuilder<T extends ComponentRef> extends BasePopoverBuilder<T> {
    constructor(
        popoverController: PopoverController,
        component: T,
        private componentProps: ComponentProps<T>,
        event: Event,
        cssClass?: string | string[]
    ) {
        super(popoverController, component, event);
        if (cssClass) {
            this.cssClass(cssClass);
        }
    }

    protected getComponentProps(): ComponentProps<RadioGroupPopoverComponent> {
        return this.componentProps;
    }
}
