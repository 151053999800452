import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';

@Injectable({ providedIn: 'root' })
export class DeviceService {
    private _deviceId: string;

    async getDeviceId(): Promise<string> {
        if (!this._deviceId) {
            if (Capacitor.isNativePlatform()) {
                const { Device } = await import('@capacitor/device');
                const id = await Device.getId();
                this._deviceId = id.identifier;
            } else {
                this._deviceId = 'web';
            }
        }
        return this._deviceId;
    }
}
