import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { PlanTransactionState, PlanTransactionStore } from './plan-transaction.store';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PlanTransactionQuery extends QueryEntity<PlanTransactionState> {
    constructor(protected store: PlanTransactionStore) {
        super(store);
    }

    get totalTransactionCount$(): Observable<number> {
        return this.selectCount();
    }

    get successfulTransactionCount$(): Observable<number> {
        return this.selectCount(
            (transaction) => transaction.type === 'subscription_payment_succeeded'
        );
    }

    get failedTransactionCount$(): Observable<number> {
        return this.selectCount(
            (transaction) => transaction.type === 'subscription_payment_failed'
        );
    }
}
