<ion-list class="popover-list border-0 pl-6 pr-6 pb-4 pt-4 position-relative z-1000 overflow-hidden">
    <ion-list-header *ngIf="title" class="pb-0 pl-16 font-weight-500" mode="md">
        <ion-text color="primary">{{ title }}</ion-text>
    </ion-list-header>
    <ion-item class="rounded-item mb-0 textarea-container white ion-no-padding">
        <ion-textarea [(ngModel)]="value"
                      [autoGrow]="!!value"
                      [placeholder]="placeholder"
                      color="admin-dark"
                      libRemoveTabIndex
                      libTextAreaClearIcon
                      mode="md"
                      name="inputPopover"
                      rows="1"
                      type="text">
        </ion-textarea>
    </ion-item>
</ion-list>
<div class="background-white pr-6 pl-6 pb-2" [ngClass]="{'mt-neg-6': hint}">
    <ion-text *ngIf="hint" color="admin-dark" class="ml-8 font-12">{{hint}}</ion-text>
    <div class="display-flex ion-justify-content-between">
        <ion-button (click)="cancel()" fill="clear" class="button-34 button-padding-6">
            Cancel
        </ion-button>
        <ion-button (click)="save()" class="button-34">
            Done
        </ion-button>
    </div>
</div>
