export function getFileExtensionFromUrl(url: string) {
    if (url.startsWith('data:')) {
        return url.split(';')[0].split('/')[1];
    } else {
        return url.split(/[#?]/)[0].split('.').pop()?.trim();
    }
}

export const CAPACITOR_FILE_MARKER = '_capacitor_file_';

export function isCapacitorUrl(url: string) {
    return url?.indexOf('capacitor') !== -1 || url?.indexOf(CAPACITOR_FILE_MARKER) !== -1;
}

export function getFileUrlFromCapacitorUrl(url: string) {
    return (
        'file://' + url.substring(url.indexOf(CAPACITOR_FILE_MARKER) + CAPACITOR_FILE_MARKER.length)
    );
}
