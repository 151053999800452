import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TippyModule } from '@ngneat/helipopper';
import { TranslateModule } from '@ngx-translate/core';
import { NgArrayPipesModule, NgStringPipesModule } from 'ngx-pipes';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { SharedDirectivesModule } from '../directives/shared-directives.module';
import { SharedPipesModule } from '../pipes/shared-pipes.module';
import { CsvImporterComponent } from './csv-importer/csv-importer.component';
import { ImageUploaderComponent } from './image-uploader/image-uploader.component';
import { InfoAlertHeaderComponent } from './info-alert-header/info-alert-header.component';
import { GroupedPopoverComponent } from './popovers/grouped-popover/grouped-popover.component';
import { InputPopoverComponent } from './popovers/input-popover/input-popover.component';
import { RadioGroupPopoverComponent } from './popovers/radio-group-popover/radio-group-popover.component';
import { SelectPopoverComponent } from './popovers/select-popover/select-popover.component';
import { ProjectCardComponent } from './project-card/project-card.component';
import { SwitchPlanComponent } from './switch-plan/switch-plan.component';
import { TippyHelpComponent } from './tippy-help/tippy-help.component';
import { UsersToInviteComponent } from './users-to-invite/users-to-invite.component';
import { WarningAlertHeaderComponent } from './warning-alert-header/warning-alert-header.component';

@NgModule({
    declarations: [
        ImageUploaderComponent,
        SwitchPlanComponent,
        InfoAlertHeaderComponent,
        WarningAlertHeaderComponent,
        UsersToInviteComponent,
        TippyHelpComponent,
        ProjectCardComponent,
        SelectPopoverComponent,
        RadioGroupPopoverComponent,
        GroupedPopoverComponent,
        CsvImporterComponent,
        InputPopoverComponent,
    ],
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        NgArrayPipesModule,
        SharedDirectivesModule,
        NgStringPipesModule,
        SharedPipesModule,
        TranslateModule,
        TippyModule,
        VirtualScrollerModule,
    ],
    exports: [
        ImageUploaderComponent,
        SwitchPlanComponent,
        InfoAlertHeaderComponent,
        WarningAlertHeaderComponent,
        UsersToInviteComponent,
        TippyHelpComponent,
        ProjectCardComponent,
        SelectPopoverComponent,
        RadioGroupPopoverComponent,
        GroupedPopoverComponent,
        InputPopoverComponent,
        CsvImporterComponent,
    ],
})
export class SharedComponentsModule {}
