<ion-list class="plan-list position-relative z-1000 overflow-hidden">
    <ion-list-header class="pb-0 pl-16 font-weight-500" mode="md"
        ><ion-text color="primary">Plans</ion-text></ion-list-header
    >
    <ion-item
        (click)="selectPlan(plan.id)"
        *ngFor="let plan of plans$ | async; trackBy: trackByFn"
        button
        class="font-14 position-relative z-10"
        mode="md"
        >{{ plan.company }}</ion-item
    >
    <ion-item
        *ngIf="buyAnother"
        (click)="buyAnotherPlan()"
        button
        class="font-14 position-relative z-10"
        mode="md"
        >Buy another plan</ion-item
    >
</ion-list>
