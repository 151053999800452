<ion-header class="ion-no-border admin-modal-header">
    <ion-toolbar mode="md" color="white">
        <ion-title class="font-22 pl-0" color="admin-dark"><b>Item permissions</b></ion-title>
        <ion-buttons slot="end" class="mr-4">
            <ion-button (click)="dismiss()">
                <ion-icon color="admin-dark" name="close-sharp"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<div class="modal-content overflow-scroll-y height-100">
    <ion-list class="no-border-radius mb-0 mr-0 ml-0">
        <ion-radio-group [value]="currentAccess" (ionChange)="dismiss($event)">
            <ion-item *ngFor="let accessOption of accessOptions" lines="inset">
                <ng-container *ngIf="accessOption | itemAccessDetail as details">
                    <div>
                        <div>
                            <ion-badge
                                [color]="details.colour"
                                class="font-weight-500 font-14 inline-vertical-align ion-justify-content-center badge-bordered-contrast"
                            >
                                <ion-icon
                                    [name]="details.icon"
                                    class="font-16 mr-4 ml-2"
                                ></ion-icon>
                                {{details.label}}
                            </ion-badge>
                        </div>
                        <p class="ion-text-wrap font-13 color-dark-tint mt-4 pl-6 mb-0">
                            {{details.description}}
                        </p>
                    </div>
                    <ion-radio [value]="accessOption" slot="end" mode="md"></ion-radio>
                </ng-container>
            </ion-item>
        </ion-radio-group>
    </ion-list>
</div>
