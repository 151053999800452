<ion-app>
    <ion-split-pane contentId="main">
        <ng-container
            *ngIf="{
                planOverview: planOverview$ | async
            } as state"
        >
            <ion-menu
                [hidden]="!authService.authenticated"
                swipeGesture="false"
                class="ion-no-border admin-menu"
                contentId="main"
                type="push"
            >
                <div class="menu-logo-item">
                    <img
                        (click)="home()"
                        src="./assets/imgs/logos/insite-logo-2020-dark-admin.svg"
                    />
                </div>
                <ion-content forceOverscroll="false">
                    <div class="display-flex-column height-100">
                        <ion-list lines="none" class="menu-list">
                            <ng-container *ngFor="let p of adminPages">
                                <ion-menu-toggle autoHide="false">
                                    <ion-item
                                        [attr.data-testid]="'adminMenu.' + p.title"
                                        [routerLink]="[p.url]"
                                        detail="false"
                                        lines="none"
                                        routerDirection="root"
                                        routerLinkActive="active">
                                        <ion-label color="admin-dark" class="display-flex" [attr.data-testid]="'menu.' + p.testId">{{
                                            p.title
                                        }}</ion-label>
                                        <ion-icon
                                            *ngIf="
                                                p.title === 'Plan & Billing' &&
                                                state.planOverview?.billingWarning
                                            "
                                            color="danger"
                                            name="alert-circle-outline"
                                            class="warning-icon"
                                            size="small"
                                        ></ion-icon>
                                    </ion-item>
                                </ion-menu-toggle>
                            </ng-container>

                            <div class="menu-line-break"></div>

                            <ion-menu-toggle autoHide="false">
                                <ion-item
                                    data-testid="adminMenu.launchTeams"
                                    class="cursor-pointer"
                                    detail="false"
                                    href="https://teams.insiteapp.co.uk"
                                    target="_blank"
                                    rel="noopener"
                                >
                                    <ion-label color="admin-dark">
                                        Launch Insite
                                        <ion-icon
                                            color="admin-dark"
                                            class="mb-neg-2"
                                            name="open-outline"
                                            size="small"
                                        ></ion-icon>
                                    </ion-label>
                                </ion-item>
                            </ion-menu-toggle>
                        </ion-list>
                        <div *ngIf="state.planOverview?.status === 'trialing'" class="trial-card">
                            <ion-text color="admin-dark"
                                ><p class="font-14 mt-0 mb-0">
                                    Currently trialling <span data-testid="trial.plan.name">{{ state.planOverview?.name }}</span>
                                </p></ion-text
                            >
                            <ion-text (click)="trialLearnMore()" color="primary"
                                ><p class="font-14 mt-4 mb-0 hover-underline cursor-pointer">
                                    Learn more
                                </p></ion-text
                            >
                            <div class="trial-progress-bg mt-6">
                                <div
                                    [ngStyle]="{
                                        width: state.planOverview?.trialPercentageComplete + '%'
                                    }"
                                    class="trial-progress"
                                ></div>
                            </div>
                            <div class="vertical-align-content mt-8">
                                <ion-icon name="time-outline" color="medium"></ion-icon>
                                <ion-text color="medium"
                                    ><p class="font-14 mt-0 mb-0 ml-6">
                                        <span data-testid="trial.days.remaining">{{ state.planOverview?.remainingTrialDays }}</span> days remaining
                                    </p></ion-text
                                >
                            </div>
                        </div>
                        <div class="flex-fill"></div>
                        <div class="menu-support-container ml-32">
                            <img
                                class="ml-8 menu-support-img"
                                [ngClass]="
                                    state.planOverview?.status === 'trialing'
                                        ? 'with-trial'
                                        : 'without-trial'
                                "
                                src="./assets/imgs/illustrations/menu-help.svg"
                            />
                            <ion-text color="admin-dark">
                                <p class="font-14 mb-0 ml-8">Ask our support team</p>
                                <p class="font-14 mb-0 mt-0 ml-8">if you have any questions</p>
                            </ion-text>
                            <ion-button
                                (click)="contactUs()"
                                class="ion-no-margin ion-margin-top"
                                color="dark"
                                fill="outline"
                            >
                                <ion-icon
                                    src="assets/imgs/custom-icons/custom-chatbubbles.svg"
                                    slot="start"
                                ></ion-icon>
                                Contact us
                            </ion-button>
                        </div>
                    </div>
                </ion-content>
            </ion-menu>
            <ion-router-outlet [swipeGesture]="false" id="main"></ion-router-outlet>
        </ng-container>
    </ion-split-pane>
    <div (click)="openHelp()" class="help-widget cursor-pointer">
        <ion-text class="font-20 font-weight-500">Help</ion-text>
    </div>
</ion-app>
