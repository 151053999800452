import { Injectable } from '@angular/core';
import { CollectionGuard } from 'akita-ng-fire';
import { of } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { LoggerService } from '../../services/logger/logger.service';
import { UtilService } from '../../services/util/util.service';
import { catchGuardError } from '../../utils/guard';
import { AuthQuery } from '../auth/auth.query';
import { InsiteUserPrivateService } from './insite-user-private.service';
import { InsiteUserPrivateState } from './insite-user-private.store';

@Injectable({ providedIn: 'root' })
export class InsiteUserPrivateGuard extends CollectionGuard<InsiteUserPrivateState> {
    constructor(
        protected service: InsiteUserPrivateService,
        private authQuery: AuthQuery,
        private utilService: UtilService,
        private loggerService: LoggerService
    ) {
        super(service);
    }

    protected sync() {
        return this.authQuery.select('uid').pipe(
            distinctUntilChanged(),
            tap((userId$) => {
                if (userId$) {
                    this.loggerService.debug('user private guard', 'syncing user private', {
                        userId$,
                    });
                }
            }),
            switchMap((userId) =>
                userId
                    ? this.service.syncActive({ path: `users/${userId}/private/${userId}` })
                    : of(null)
            ),
            catchError((err) =>
                catchGuardError(
                    err,
                    `user/$userId/private/$userId`,
                    this.utilService,
                    this.loggerService
                )
            )
        );
    }
}
