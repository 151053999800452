<div class="enquiry-container">
    <h4 *ngIf="showHeader" class="ion-text-center mt-4">Enquire for {{planOption?.name}}</h4>
    <p
        *ngIf="(canUserTrial$ | async) === false; else trialMessage"
        class="ion-no-margin ion-text-center font-14"
    >
        Please provide us with some usage details to help us prepare you a quotation.
    </p>
    <ng-template #trialMessage>
        <p class="ion-no-margin ion-text-center font-14">
            Before you start your trial please provide us with some usage details to help us prepare
            you a quotation.
        </p>
    </ng-template>

    <form #enquiryForm="ngForm" (ngSubmit)="sendEnquiry()" class="width-100 mt-4" novalidate>
        <ion-item class="rounded-item light ion-no-padding">
            <ion-label position="stacked" color="auth-text">Your name
                <ion-text color="primary">*</ion-text>
            </ion-label>
            <ion-input
                [(ngModel)]="name"
                autocorrect="false"
                autocapitalize="words"
                clearInput="true"
                color="auth-text"
                mode="md"
                name="name"
                required
                type="text"
                placeholder="Enter name"
                libRemoveTabIndex
                #inputToFocus
                data-testid="enquiry.name"
            >
            </ion-input>
        </ion-item>
        <ion-item class="rounded-item light ion-no-padding">
            <ion-label position="stacked" color="auth-text"
                >Email
                <ion-text color="primary">*</ion-text>
            </ion-label>
            <ion-input
                [(ngModel)]="email"
                autocorrect="false"
                clearInput="true"
                color="auth-text"
                mode="md"
                name="email"
                required
                type="email"
                placeholder="example@insiteapp.co.uk"
                pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                libRemoveTabIndex
                data-testid="enquiry.email"
            >
            </ion-input>
        </ion-item>
        <ion-item class="rounded-item light ion-no-padding">
            <ion-label position="stacked" color="auth-text"
                >Company
                <ion-text color="primary">*</ion-text>
            </ion-label>
            <ion-input
                [(ngModel)]="company"
                autocorrect="false"
                autocapitalize="words"
                clearInput="true"
                color="auth-text"
                mode="md"
                name="password"
                required
                type="text"
                placeholder="Enter company"
                libRemoveTabIndex
                data-testid="enquiry.company"
            >
            </ion-input>
        </ion-item>

        <ion-text color="admin-dark">
            <p class="font-14 ml-10 mb-4 ion-margin-top">
                How many users do you expect to have on each project. You can select multiple.
                <ion-text color="primary">*</ion-text>
            </p>
        </ion-text>

        <ion-item class="checkbox-item">
            <span>
                <ion-checkbox
                    [(ngModel)]="smallProjects"
                    name="smallUsers"
                    slot="start"
                    class="mr-8"
                    data-testid="enquiry.small.projects"
                ></ion-checkbox>
            </span>
            <ion-label>
                <ion-text class="font-14">1 - 10 users</ion-text>
            </ion-label>
        </ion-item>
        <ion-item class="mt-12 checkbox-item">
            <span>
                <ion-checkbox
                    [(ngModel)]="mediumProjects"
                    name="smallUsers"
                    slot="start"
                    class="mr-8"
                    data-testid="enquiry.medium.projects"
                ></ion-checkbox>
            </span>
            <ion-label>
                <ion-text class="font-14">11 - 20 users</ion-text>
            </ion-label>
        </ion-item>
        <ion-item class="mt-12 checkbox-item">
            <span>
                <ion-checkbox
                    [(ngModel)]="largeProjects"
                    name="smallUsers"
                    slot="start"
                    class="mr-8"
                    data-testid="enquiry.large.projects"
                ></ion-checkbox>
            </span>
            <ion-label>
                <ion-text class="font-14">20 + users</ion-text>
            </ion-label>
        </ion-item>

        <ion-item class="rounded-item textarea-container light ion-no-padding">
            <ion-label position="stacked" color="auth-text">Additional details</ion-label>
            <ion-textarea
                [(ngModel)]="additionalDetails"
                color="auth-text"
                rows="3"
                mode="ios"
                name="additionalDeatils"
                type="text"
                placeholder="Comments"
                libRemoveTabIndex
                data-testid="enquiry.notes"
            >
            </ion-textarea>
        </ion-item>

        <div class="display-flex mt-12">
            <ion-button [disabled]="!enquiryForm.form.valid" class="width-100" type="submit" data-testid="enquiry.send">
                Send enquiry {{ (canUserTrial$ | async) ? '& continue to trial' : '' }}
            </ion-button>
        </div>
    </form>
</div>
