import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HomeProjectView } from '@insite-group-ltd/insite-teams-model';

@Component({
    selector: 'lib-project-card',
    templateUrl: './project-card.component.html',
    styleUrls: ['./project-card.component.scss'],
})
export class ProjectCardComponent {
    @Input() project: HomeProjectView;
    @Input() showBlankProjectNo: boolean;
    @Input() notificationCount: number;
    @Input() static: boolean;
    @Output() notificationClick = new EventEmitter<void>();
    imageUnavailable: boolean;
}
