import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';

type WithId = Record<'id', string>;

export function keyById<T extends WithId>(values?: T[]): Record<string, T> {
    if (!values) {
        return {};
    }
    return keyBy(values, 'id');
}

export function keyPropertyById<T extends WithId>(
    values: T[],
    prop: keyof T
): Record<string, keyof T> {
    if (!values) {
        return {};
    }
    // @ts-ignore
    return mapValues(keyBy(values, 'id'), prop);
}

export function findById<T extends WithId>(values: T[], id?: string): T | undefined {
    if (!values || !id) {
        return undefined;
    }
    return values.find((option) => option.id === id);
}
