import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { GpsCoordinates, Image } from '@insite-group-ltd/insite-teams-model';
import { IonSlides } from '@ionic/angular';
import { MapService } from '../../services/map/map.service';
import { UtilService } from '../../services/util/util.service';
import { ViewImage } from './view-image-modal';

@Component({
    selector: 'app-view-image-modal',
    templateUrl: './view-image-modal.page.html',
    styleUrls: ['./view-image-modal.page.scss'],
})
export class ViewImageModalPage implements OnInit {
    @ViewChild('sliderRef', { static: true }) slides: IonSlides;

    @Input() images: Image[] = [];
    @Input() index = 0;

    mappedImages: ViewImage[] = [];
    imagesHaveGps = false;
    options = {
        centeredSlides: true,
        passiveListeners: false,
        zoom: {
            enabled: true,
        },
    };

    constructor(private utilService: UtilService, private mapService: MapService) {}

    ngOnInit() {
        if (this.index !== 0) {
            this.slides.slideTo(this.index);
        }
        this.imagesHaveGps = this.images.some((image) => image.gpsCoordinates);
    }

    ionViewWillEnter() {
        this.slides.update();
    }

    close() {
        this.utilService.dismissModal();
    }

    openGpsCoordinates(gpsCoordinates: GpsCoordinates) {
        this.mapService.openGpsCoordinates(gpsCoordinates);
    }
}
