import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { filterNilValue } from '@datorama/akita';
import { PlanType } from '@insite-group-ltd/insite-teams-model';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AuthQuery } from '../state/auth/auth.query';
import { ProjectQuery } from '../state/projects/project.query';

@Injectable({
    providedIn: 'root',
})
export class ProjectPlanNotBasicGuard implements CanActivate {
    constructor(
        private projectQuery: ProjectQuery,
        private authQuery: AuthQuery,
        private router: Router
    ) {}

    canActivate(next: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
        const projectId = next.params.projectId;
        return this.authQuery.userId$.pipe(
            filterNilValue(),
            switchMap((userId) =>
                this.projectQuery.getProject$(projectId).pipe(
                    switchMap((project) => {
                        if (
                            project.users[userId] === 'EXTERNAL' ||
                            project.users[userId] === 'READ_ONLY'
                        ) {
                            return of(true);
                        } else {
                            return this.projectQuery.getProjectPlanType$(projectId).pipe(
                                map((type: PlanType) => {
                                    return type !== 'BASIC'
                                        ? true
                                        : this.router.createUrlTree([
                                              '/app',
                                              'project',
                                              projectId,
                                              'view',
                                              'lists',
                                          ]);
                                })
                            );
                        }
                    })
                )
            )
        );
    }
}
