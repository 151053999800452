import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import {
    initializeAnalytics,
    provideAnalytics,
    ScreenTrackingService,
    setAnalyticsCollectionEnabled,
    UserTrackingService,
} from '@angular/fire/analytics';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { initializeFirestore, provideFirestore } from '@angular/fire/firestore';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import {
    SharedGuardsModule,
    SharedServicesModule,
    ZendeskConfig,
} from '@insite-group-ltd/insite-teams-shared';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { popperVariation, TippyModule } from '@ngneat/helipopper';
import { HotToastModule } from '@ngneat/hot-toast';
import { TranslateModule } from '@ngx-translate/core';
import { createErrorHandler } from '@sentry/angular-ivy';
import { NgxZendeskWebwidgetModule } from 'ngx-zendesk-webwidget';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChangePlanPageModule } from './change-plan/change-plan.module';
import { CheckoutPageModule } from './checkout/checkout.module';
import { ComponentsModule } from './components/components.module';
import { EnterpriseEnquiryModalPageModule } from './enterprise-enquiry-modal/enterprise-enquiry-modal.module';
import { TrialLearnMorePageModule } from './trial-learn-more/trial-learn-more.module';
import { UsersToInviteModalPageModule } from './users-to-invite-modal/users-to-invite-modal.module';

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot({ animated: !(window as any)?.testMode }),
        AppRoutingModule,
        provideFirebaseApp(() =>
            initializeApp(environment.firebase, {
                automaticDataCollectionEnabled: environment.production,
            })
        ),
        provideFirestore(() =>
            initializeFirestore(getApp(), {
                ignoreUndefinedProperties: true,
            })
        ),
        provideStorage(() => {
            const storage = getStorage(getApp());
            storage.maxUploadRetryTime = 60_000;
            return storage;
        }),
        provideAuth(() => getAuth(getApp())),
        provideAnalytics(() => {
            const analytics = initializeAnalytics(getApp());
            setAnalyticsCollectionEnabled(analytics, environment.production);
            return analytics;
        }),
        environment.production ? [] : AkitaNgDevtools.forRoot(),
        AkitaNgRouterStoreModule,
        SharedServicesModule.forRoot(environment),
        SharedGuardsModule,
        ComponentsModule,
        CheckoutPageModule,
        ChangePlanPageModule,
        EnterpriseEnquiryModalPageModule,
        TrialLearnMorePageModule,
        UsersToInviteModalPageModule,
        NgxZendeskWebwidgetModule.forRoot(ZendeskConfig),
        HttpClientModule,
        HttpClientJsonpModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en',
        }),
        TippyModule.forRoot({
            interactive: true,
            maxWidth: '240px',
            defaultVariation: 'tooltip',
            variations: {
                tooltip: {
                    theme: null,
                    arrow: true,
                    animation: 'scale',
                    trigger: 'mouseenter',
                    offset: [0, 7],
                },
                popper: popperVariation,
            },
        }),
        HotToastModule.forRoot(),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ],
    providers: [
        { provide: ErrorHandler, useValue: createErrorHandler() },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        ScreenTrackingService,
        UserTrackingService,
        InAppBrowser,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
