import { Injectable, Injector, OnDestroy } from '@angular/core';
import {
    Analytics,
    CustomParams,
    isSupported,
    logEvent,
    setDefaultEventParameters,
    setUserId,
    setUserProperties,
} from '@angular/fire/analytics';
import { Capacitor } from '@capacitor/core';
import { SubSink } from 'subsink';
// noinspection ES6PreferShortImport
import assign from 'lodash/assign';
import { LoggerService } from '../logger/logger.service';
import { UrlService } from '../url/url.service';

import { App } from '@capacitor/app';

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService implements OnDestroy {
    private subs = new SubSink();
    private _analytics: Analytics;

    constructor(
        private injector: Injector,
        private loggerService: LoggerService,
        private urlService: UrlService
    ) {
        isSupported().then((isSupported: boolean) => {
            if (isSupported) {
                this._analytics = this.injector.get(Analytics);
                this.loggerService.info('analytics', 'init analytics');
                if (Capacitor.isPluginAvailable('Device')) {
                    App.getInfo().then((info) => {
                        if (info.version) {
                            setDefaultEventParameters({
                                APP_VERSION: info.version,
                            });
                            this.loggerService.info(
                                'analytics',
                                `set analytics app version ${info.version}`
                            );
                        }
                    });
                }
                this.urlService.trackConversion$.subscribe(() => {
                    this.event('trial_start');
                });
            } else {
                this.loggerService.warn('analytics', `analytics not supported`);
            }
        });
    }

    setUserId(userId: string | undefined): void {
        if (this.isAnalyticsSupported) {
            setUserId(this._analytics, userId);
        }
    }

    setUserProperties(properties: CustomParams): void {
        if (this.isAnalyticsSupported) {
            setUserProperties(this._analytics, properties);
        }
    }

    event(name: string, params: any = {}): void {
        if (this.isAnalyticsSupported) {
            logEvent(this._analytics, name, assign(params, this.urlService.utmParams));
        }
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    private get isAnalyticsSupported() {
        return !!this._analytics;
    }
}
