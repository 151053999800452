import { Injectable } from '@angular/core';
import saveAs from 'file-saver';
import { Papa, UnparseConfig } from 'ngx-papaparse';

@Injectable({
    providedIn: 'root',
})
export class CsvService {
    constructor(private papa: Papa) {}

    saveCsv(rows: any, filename: string, config: UnparseConfig = { header: true }) {
        const csv = this.papa.unparse(rows, config);
        const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(csvData, filename);
    }
}
