export interface ImageUploadTask {
    id: string;
    imageId: string;
    docPath: string;
    target: ImageUploadTarget;
    filePath: string;
    url?: string;
    status: ImageUploadTaskStatus;
    uploadAttempts: number;
    dataUpdateAttempts: number;
    createdAt: any;
    lastModified: any;
    userId: string;
    deviceId: string;
    pendingFileDeletion: boolean;
}

export type ImageUploadTaskStatus = 'PENDING' | 'UPLOADED' | 'COMPLETE' | 'DELETED';
export type ImageUploadTarget =
    | 'PROJECT'
    | 'PROJECT_DRAWING'
    | 'ITEM'
    | 'ITEM_IMAGE_EDIT'
    | 'USER_PROFILE'
    | 'USER_COMPANY_LOGO'
    | 'USER_SIGNATURE'
    | 'PLAN_COMPANY_LOGO'
    | 'LIST_SIGN_OFF_SIGNATURE'
    | 'ITEM_DRAWING_PIN';

export interface ImageType {
    target: ImageUploadTarget;
    directory: string;
}

export const USER_PROFILE_IMAGE: ImageType = {
    target: 'USER_PROFILE',
    directory: 'images/user/profile',
};

export const COMPANY_LOGO_IMAGE: ImageType = {
    target: 'USER_COMPANY_LOGO',
    directory: 'images/user/company-logo',
};

export const USER_SIGNATURE_IMAGE: ImageType = {
    target: 'USER_SIGNATURE',
    directory: 'images/user/signature',
};

export const ITEM_IMAGE: ImageType = {
    target: 'ITEM',
    directory: 'images/item',
};

export const ITEM_IMAGE_EDIT: ImageType = {
    target: 'ITEM_IMAGE_EDIT',
    directory: 'images/item',
};

export const ITEM_DRAWING_PIN: ImageType = {
    target: 'ITEM_DRAWING_PIN',
    directory: 'images/project-drawings',
};

export const PROJECT_IMAGE: ImageType = {
    target: 'PROJECT',
    directory: 'images/project',
};

export const PLAN_COMPANY_LOGO: ImageType = {
    target: 'PLAN_COMPANY_LOGO',
    directory: 'images/plan',
};

export const LIST_SIGN_OFF_SIGNATURE: ImageType = {
    target: 'LIST_SIGN_OFF_SIGNATURE',
    directory: 'images/list/sign-off-signature',
};

export const PROJECT_DRAWING: ImageType = {
    target: 'PROJECT_DRAWING',
    directory: 'images/project-drawings',
};
