import { Component } from '@angular/core';
import { PlanQuery, UtilService } from '@insite-group-ltd/insite-teams-shared';

@Component({
    selector: 'app-trial-learn-more',
    templateUrl: './trial-learn-more.page.html',
    styleUrls: ['./trial-learn-more.page.scss'],
})
export class TrialLearnMorePage {
    planOverview$ = this.planQuery.planOverview$;

    constructor(private planQuery: PlanQuery, private utilService: UtilService) {}

    close() {
        this.utilService.dismissModal();
    }
}
