import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Platform } from '@ionic/angular';
import { CollectionConfig, NoPathParams } from 'akita-ng-fire';
import { take } from 'rxjs/operators';
import { AuditedService } from '../../services/audited/audited.service';
import { TelemetryService } from '../../services/telemetry/telemetry.service';
import { UtilService } from '../../services/util/util.service';
import { BaseCollectionService } from '../collection-service/base-collection.service';
import { SystemQuery } from './system.query';
import { SystemState, SystemStore } from './system.store';

const COLLECTION_PATH = 'system';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: COLLECTION_PATH, idKey: 'id' })
export class SystemService extends BaseCollectionService<SystemState, SystemStore, NoPathParams> {
    constructor(
        store: SystemStore,
        private systemQuery: SystemQuery,
        private utilService: UtilService,
        private platform: Platform,
        auditedService: AuditedService,
        telemetryService: TelemetryService
    ) {
        super(COLLECTION_PATH, store, auditedService, telemetryService);
    }

    checkAppVersion(targetVersion: string) {
        this.systemQuery
            .isUpdateAvailable(targetVersion)
            .pipe(take(1))
            .subscribe((appVersion) => {
                let subMessage: string;
                if (Capacitor.isNativePlatform()) {
                    const isAndroid = this.platform.is('android');
                    subMessage = `<small><a target="_blank" href="${
                        this.systemQuery.appStoreUrl
                    }">Visit the ${
                        isAndroid ? 'Play Store' : 'App Store'
                    }</a> and update the app</small>`;
                } else {
                    subMessage = `<small>Hard refresh to get the latest version</small><br><br><small>Not sure how to do this? Find out how <a target="_blank" href="https://insiteapp.zendesk.com/hc/en-gb/articles/4402194802322">here</a></small>`;
                }
                this.utilService.warningToast(
                    `<b>New app version available - ${appVersion.teamsVersion}</b><br>${subMessage}`,
                    {
                        autoClose: false,
                    }
                );
            });
    }
}
