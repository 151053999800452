export function percentage(partialValue: number, totalValue: number, decimalPlaces = 0) {
    if (partialValue && totalValue) {
        return toFixedNumber((100 * partialValue) / totalValue, decimalPlaces);
    } else {
        return 0;
    }
}

export function minutesFromNow(minutes: number) {
    return Date.now() + 60 * minutes * 1000;
}

function toFixedNumber(number: number, decimalPlaces: number) {
    const pow = Math.pow(10, decimalPlaces);
    return Math.round(number * pow) / pow;
}

export function asArray<T>(value: T | T[]): T[] {
    return Array.isArray(value) ? value : [value];
}
