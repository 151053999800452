import head from 'lodash/head';
import includes from 'lodash/includes';
import { Audited } from './audited';
import { PdfFormData, PdfRawFormFields } from './pdf';
import { sortByCreatedDesc } from './sort';

export interface InsiteFile extends Audited {
    id: string;
    title?: string;
    reference?: string;
    versions: Record<string, InsiteFileVersion>;
}

export interface UnsavedInsiteFileVersion extends Audited {
    id: string;
    url: string;
    size: number;
    originalFilename: string;
    extension: string;
    type: string;
    name?: string;
    notes?: string;
    // with pdf's we support the user filling them in, this is that data
    formData: PdfFormData;
    formFields: PdfRawFormFields;
}

export interface SavedInsiteFileVersion extends UnsavedInsiteFileVersion {
    filename: string;
    directory: string;
    path: string;
    name: string;
}

export type InsiteFileVersion = UnsavedInsiteFileVersion | SavedInsiteFileVersion;

export function isSavedFileVersion(file: InsiteFileVersion): file is SavedInsiteFileVersion {
    return !isUnsavedFileVersion(file);
}

export function isUnsavedFileVersion(file: InsiteFileVersion): file is SavedInsiteFileVersion {
    return !!file && isBase64File(file);
}

export function isBase64File(file: InsiteFileVersion): boolean {
    return file?.url?.indexOf('data:') !== -1;
}

export function isPdfFile(file: InsiteFileVersion): boolean {
    return file?.extension === 'pdf';
}

export function getCurrentFileVersion(versions: InsiteFile['versions']) {
    return head(Object.values(versions).sort(sortByCreatedDesc)) as InsiteFileVersion;
}

export function getCurrentFileVersionOriginalFilename(file: InsiteFile) {
    return getCurrentFileVersion(file.versions)?.originalFilename;
}

const imageMimeTypes = [
    'image/apng',
    'image/avif',
    'image/gif',
    'image/jpeg',
    'image/png',
    'image/svg+xml',
    'image/webp',
];

export function getCurrentFileVersionType(currentFileVersion: InsiteFileVersion) {
    if (currentFileVersion.extension === 'pdf') {
        return 'pdf';
    } else if (includes(imageMimeTypes, currentFileVersion.type)) {
        return 'image';
    } else {
        return 'none';
    }
}
