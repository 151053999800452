<ng-container *ngIf="{
    availableInternalUsersToAdd: availableInternalUsersToAdd$ | async,
    windowSize: windowSize$ | async,
    planType: planType$ | async,
    isProjectPlanAdmin: isProjectPlanAdmin$ | async,
    currentNonReadOnlyProjectUserCount: currentNonReadOnlyProjectUserCount$ | async,
    maxSeatCount: maxSeatCount$ | async,
    maxSeatCountBreakdown: maxSeatCountBreakdown$ | async,
    currentAvailableInternalSeatCount: currentAvailableInternalSeatCount$ | async,
    currentAvailableSeatCount: (maxSeatCount$ | async) - (currentNonReadOnlyProjectUserCount$ | async),
    internalSeatsAvailable: (currentAvailableInternalSeatCount$ | async) - getCheckedInternalUsersToAdd().length,
    checkedInternalUsersToAdd: getCheckedInternalUsersToAdd().length,
    nonReadOnlySeatsAvailable: (maxSeatCount$ | async) - (currentNonReadOnlyProjectUserCount$ | async) - getNonReadOnlyUsersToAddOrInviteCount(),
    nonReadOnlySeatsAllocated: (currentNonReadOnlyProjectUserCount$ | async) + getNonReadOnlyUsersToAddOrInviteCount()
} as state">
    <ion-grid class="ion-no-padding ion-text-left height-100">
        <ion-row class="height-100">
            <ion-col size="12" class="display-flex-column ion-padding-horizontal max-height-100 users-col">
                <ng-container *ngIf="loaded; else loading">
                    <ng-container *ngIf="(!usersToAdd?.length && !emailsToInvite?.length && !invalidEmails?.length && !usersAlreadyOnProjectCount); else searchResults">
                        <ng-container *ngIf="state.planType === 'ENTERPRISE' || (state.planType === 'TEAM' && state.isProjectPlanAdmin)">
                            <ion-row class="ion-align-items-end mobile-padding-left">
                                <ion-text color="admin-dark"><h6>Search & invite users</h6></ion-text>
                                <ion-icon name="information-circle" color="primary" class="mb-11 ml-4" maxWidth="300px" libRemoveIoniconTitle
                                          tippy="Separate email addresses using commas or semi-colons. All existing Insite users will be added to this project. Any others will be sent an invitation by email and added to the project automatically after signing up."></ion-icon>
                            </ion-row>
                            <ion-item class="rounded-item white textarea-container ion-no-padding mt-neg-2 flex-shrink-0">
                                <ion-textarea [(ngModel)]="searchboxValue"
                                              class="overflow-scroll-y"
                                              auto-grow="true"
                                              spellcheck="false"
                                              appNoAutocorrect
                                              color="dark"
                                              mode="ios"
                                              name="multipleEmails"
                                              placeholder="e.g. hello@insiteapp.co.uk, example@gmail.com"
                                              rows="3"
                                              data-testid="add.or.invite.email.entry">
                                </ion-textarea>
                            </ion-item>
                        </ng-container>

                        <ng-container *ngIf="searchboxValue?.length > 0; else organisationList">
                            <ion-text color="admin-dark">
                                <p class="font-14 mt-4 mb-0 pl-12">
                                    Comma separate email addresses to search for and add multiple users from outside of your organisation.
                                </p>
                            </ion-text>
                            <div class="display-flex ion-justify-content-end mt-12">
                                <ion-button (click)="searchboxValue = ''"
                                            fill="clear"
                                            class="ion-no-margin button-34">
                                    Back
                                </ion-button>
                                <ion-button (click)="search()"
                                            [disabled]="!searchboxValue || searchboxValue.length === 0"
                                            class="ion-no-margin button-34 button-padding-32"
                                            data-testid="add.or.invite.email.search">
                                    <ng-container>Search</ng-container>
                                </ion-button>
                            </div>
                        </ng-container>

                        <ng-template #organisationList>
                            <ion-row class="display-flex ion-align-items-end mobile-padding-left">
                                <ion-text color="admin-dark"><h6 class="mr-4">From your organisation</h6></ion-text>
                                <div class="flex-fill"></div>
                                <ng-container *ngIf="state.maxSeatCountBreakdown?.internal || state.maxSeatCount as maxCount">
                                    <div *ngIf="state.availableInternalUsersToAdd?.length"
                                        class="display-flex mb-10 mr-8 font-13">
                                        <ion-text color="admin-dark">
                                                <p class="mt-0 mb-0">
                                                    {{state.maxSeatCountBreakdown?.internal ? maxCount - state.internalSeatsAvailable : state.nonReadOnlySeatsAllocated + state.checkedInternalUsersToAdd}}/{{maxCount}} <span *ngIf="state.maxSeatCountBreakdown?.internal">internal </span>seats allocated
                                                </p>
                                            </ion-text>
                                        <ng-container *ngIf="state.internalSeatsAvailable !== 0; else noSeatsAvailable">
                                            <ion-icon name="information-circle" color="primary" maxWidth="300px" class="font-16 ml-4" libRemoveIoniconTitle
                                                    [tippy]="'You can add up to ' + (state.currentAvailableInternalSeatCount) + '&nbsp;' + ('user' | makePluralString: (state.currentAvailableInternalSeatCount)) + ' based upon the current number of users on the project and the permitted maximum of ' + maxCount + '. Contact us to discuss increasing this limit.'"></ion-icon>
                                        </ng-container>
                                        <ng-template #noSeatsAvailable>
                                            <ion-icon name="checkmark-circle" color="primary" maxWidth="300px" class="font-16 ml-4" libRemoveIoniconTitle
                                                    [tippy]="'You have allocated the permitted maximum of ' + maxCount + ' seats on this project. Contact us to discuss increasing this limit.'"></ion-icon>
                                        </ng-template>
                                    </div>
                                </ng-container>
                            </ion-row>

                            <ng-container *ngIf="state.availableInternalUsersToAdd?.length; else noInternalUsers">
                                <ion-list class="overflow-scroll-y mr-0" [ngClass]="{'border-all ml-0 clickable-item-list': !state.windowSize.smallSize}">
                                    <ion-item class="checkbox-item internal-users-list-header" mode="md" lines="full">
                                        <ion-icon slot="start"
                                                  color="dark"
                                                  class="ml-2 font-20 mr-16"
                                                  name="person"></ion-icon>
                                        <ion-label class="font-weight-600 font-13" color="admin-dark">Users ({{state.availableInternalUsersToAdd.length}})</ion-label>
                                        <ion-label *ngIf="state.checkedInternalUsersToAdd > 0" slot="end" class="font-weight-500 font-13 pr-8 ion-text-end" color="admin-dark">Project role</ion-label>
                                    </ion-item>
                                    <ng-container *ngFor="let internalUser of state.availableInternalUsersToAdd; trackBy: trackByFnUser; index as i; let first = first">
                                        <ion-item class="border-999 checkbox-item internal-user ml-10" [ngClass]="{'maintain-border': state.windowSize.smallSize, 'first-user': first}" mode="md">
                                            <ion-checkbox (ionChange)="updateInternalUsersToAdd($event, internalUser, state.maxSeatCount, state.maxSeatCountBreakdown, state.internalSeatsAvailable !== 0    , internalUserCheckbox)"
                                                          [checked]="this.internalUsersToAdd[internalUser.user.id]?.checked"
                                                          #internalUserCheckbox
                                                          mode="ios" slot="start" class="mr-16 ml-2"></ion-checkbox>
                                            <ion-label>{{internalUser.user.firstName}} {{internalUser.user.lastName}}</ion-label>
                                            <ion-button *ngIf="internalUsersToAdd[internalUser.user.id]?.checked"
                                                        (click)="presentRoleOptions($event, state.windowSize, internalUsersToAdd[internalUser.user.id])"
                                                        class="ion-no-margin font-13 button-padding-10 z-1000 button-border-1"
                                                        slot="end"
                                                        [color]="(internalUsersToAdd[internalUser.user.id].role | projectRoleDetail).colour">
                                                {{(internalUsersToAdd[internalUser.user.id].role | projectRoleDetail).label}}
                                                <ion-icon slot="end" name="chevron-down-sharp"></ion-icon>
                                            </ion-button>
                                        </ion-item>
                                    </ng-container>
                                </ion-list>

                                <div class="display-flex ion-justify-content-end">
                                    <ion-button *ngIf="state.windowSize.smallSize"
                                                (click)="cancel()"
                                                fill="outline"
                                                class="ion-no-margin button-34 button-padding-32 mr-8">
                                        <ng-container>Cancel</ng-container>
                                    </ion-button>
                                    <ion-button (click)="addInternalUsers()"
                                                [disabled]="state.checkedInternalUsersToAdd === 0"
                                                class="ion-no-margin button-34 button-padding-32"
                                                data-testid="add.or.invite.add.users">
                                        <ng-container>Add users</ng-container>
                                    </ion-button>
                                </div>
                            </ng-container>

                            <ng-template #noInternalUsers>
                                <div class="flex-grow-1 font-14 mobile-padding-left">
                                    <div class="display-flex mt-2">
                                        <lib-status-indicator [disabled]="true" icon="information" colour="primary" padding="padding-1" fontSize="font-14" class="mt-2 mr-6"></lib-status-indicator>
                                        <ion-text color="admin-dark">
                                            <p class="mt-0 mb-0">
                                                Everyone from your organisation is already on this project!
                                                <ng-container *ngIf="state.planType === 'ENTERPRISE'; else starterPlanMessage">Use the search box above to find more users.</ng-container>
                                                <ng-template #starterPlanMessage>
                                                    See <span class="external-link" libOpenUrl="https://insiteapp.zendesk.com/hc/en-gb/articles/360022064319">Add plan members</span>
                                                </ng-template>
                                            </p>
                                        </ion-text>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-template>

                    </ng-container>

                    <ng-template #searchResults>
                        <ion-row class="display-flex ion-align-items-end mobile-padding-left">
                            <ion-text color="admin-dark"><h6 class="mr-4">Search results</h6></ion-text>
                            <div class="flex-fill"></div>
                            <ng-container *ngIf="state.maxSeatCount && (usersToAdd?.length || emailsToInvite?.length)">
                                <div class="display-flex mb-10 mr-8 font-13">
                                    <ng-container *ngIf="state.nonReadOnlySeatsAvailable > 0; else noNonReadOnlySeatsAvailable">
                                        <ion-text color="admin-dark">
                                            <p class="mt-0 mb-0">
                                                {{state.nonReadOnlySeatsAllocated}}/{{state.maxSeatCount}} seats allocated
                                            </p>
                                        </ion-text>
                                        <ion-icon name="information-circle" color="primary" maxWidth="300px" class="font-16 ml-4" libRemoveIoniconTitle
                                                  [tippy]="'You can add up to ' + (state.currentAvailableSeatCount) + '&nbsp;' + ('user' | makePluralString: (state.currentAvailableSeatCount)) + ' based upon the current number of users on the project and the permitted maximum of ' + state.maxSeatCount + '. Contact us to discuss increasing this limit.'"></ion-icon>
                                    </ng-container>
                                    <ng-template #noNonReadOnlySeatsAvailable>
                                        <ng-container *ngIf="state.nonReadOnlySeatsAvailable === 0; else limitExceeded">
                                            <ion-text color="admin-dark">
                                                <p class="mt-0 mb-0">
                                                    {{state.nonReadOnlySeatsAllocated}}/{{state.maxSeatCount}} seats allocated
                                                </p>
                                            </ion-text>
                                            <ion-icon name="checkmark-circle" color="primary" maxWidth="300px" class="font-16 ml-4" libRemoveIoniconTitle
                                                      [tippy]="'You have allocated the permitted maximum of ' + state.maxSeatCount + ' seats on this project. You can continue to add users with the Read only role. Or, contact us to discuss increasing this limit.'"></ion-icon>
                                        </ng-container>
                                        <ng-template #limitExceeded>
                                            <ion-text color="admin-dark">
                                                <p class="mt-0 mb-0">
                                                    Too many users!
                                                </p>
                                            </ion-text>
                                            <ion-icon name="alert-circle" color="danger" maxWidth="300px" class="font-16 ml-4" libRemoveIoniconTitle
                                                      [tippy]="'You are trying to add more than the permitted maximum of ' + state.maxSeatCount + ' users to your project with Admin, Internal or External role. Please remove some Admin or Internal users from the results below. Or, contact us to discuss increasing this limit.'"></ion-icon>
                                        </ng-template>
                                    </ng-template>
                                </div>
                            </ng-container>
                        </ion-row>

                        <div *ngIf="usersAlreadyOnProjectCount || invalidEmails?.length > 0" class="mobile-padding-left border-14 border-all background-white pt-14 pr-12 pl-12" [ngClass]="{'ion-margin-bottom': !state.windowSize.smallSize}">
                            <ion-text *ngIf="usersAlreadyOnProjectCount" color="admin-dark" class="font-13">
                                <p class="display-flex pt-2 mt-0">
                                    <lib-status-indicator [disabled]="true" icon="alert" colour="incomplete" padding="padding-1" fontSize="font-14" class="mt-neg-2 mr-6"></lib-status-indicator>
                                    {{usersAlreadyOnProjectCount}} {{'user' | makePluralString: usersAlreadyOnProjectCount}}/{{'email' | makePluralString: usersAlreadyOnProjectCount}} already on project will not be added.</p>
                            </ion-text>
                            <ion-text *ngIf="invalidEmails?.length > 0" color="admin-dark" class="font-13">
                                <p class="display-flex pt-2 mt-0 mb-0">
                                    <lib-status-indicator [disabled]="true" icon="alert" colour="danger" padding="padding-1" fontSize="font-14" class="mt-neg-2 mr-6"></lib-status-indicator>
                                    The following invalid emails were provided:</p>
                                <p class="ml-24 mt-2 line-height-13">
                                    <ng-container *ngFor="let invalidEmail of invalidEmails; let last = last">
                                        <b>{{invalidEmail}}</b><ng-container *ngIf="!last">, </ng-container>
                                    </ng-container>
                                </p>
                            </ion-text>
                        </div>

                        <ng-container *ngIf="usersToAdd?.length || emailsToInvite?.length; else noResultsToAdd">
                            <ion-list class="overflow-scroll-y mr-0 pl-8" [ngClass]="{'border-all ml-0 clickable-item-list': !state.windowSize.smallSize}">
                                <ion-item *ngIf="usersToAdd?.length" class="checkbox-item" mode="md" lines="full">
                                    <ion-icon slot="start"
                                              color="dark"
                                              class="ml-2 font-20 mr-16"
                                              name="person"></ion-icon>
                                    <ion-label class="font-weight-600 font-13" color="admin-dark">Existing users ({{usersToAdd?.length}})</ion-label>
                                    <ion-label slot="end" class="font-weight-500 font-13 pr-8 ion-text-end" color="admin-dark">Project role</ion-label>
                                </ion-item>
                                <ng-container *ngFor="let userToAdd of usersToAdd; index as i; let last = last; trackBy: trackByFnUser">
                                    <ion-item class="border-999 search-result checkbox-item" [lines]="last ? 'full' : 'inset'" [ngClass]="{'maintain-border': state.windowSize.smallSize}" mode="md">
                                        <ion-icon (click)="removeUserToAdd(i)" slot="start" name="close-sharp" class="mr-16"></ion-icon>
                                        <ion-label>{{userToAdd.user.firstName}} {{userToAdd.user.lastName}}</ion-label>
                                        <ion-button (click)="presentRoleOptions($event, state.windowSize, userToAdd, (state.nonReadOnlySeatsAvailable <= 0 && userToAdd.role === 'READ_ONLY'))"
                                                        class="ion-no-margin button-padding-10 z-1000 button-border-1"
                                                        slot="end"
                                                        [color]="(userToAdd.role | projectRoleDetail).colour">
                                            {{(userToAdd.role | projectRoleDetail).label}}
                                            <ion-icon slot="end" name="chevron-down-sharp"></ion-icon>
                                        </ion-button>
                                    </ion-item>
                                </ng-container>
                                <ion-item *ngIf="emailsToInvite?.length" class="checkbox-item" mode="md" lines="full">
                                    <ion-icon slot="start"
                                              color="dark"
                                              class="ml-2 font-20 mr-16"
                                              name="mail"></ion-icon>
                                    <ion-label class="font-weight-600 font-13" color="dark">Emails to invite ({{emailsToInvite?.length}})</ion-label>
                                    <ion-label slot="end" class="font-weight-500 font-13 pr-8 ion-text-end" color="dark">Project role</ion-label>
                                </ion-item>
                                <ng-container *ngFor="let emailToInvite of emailsToInvite; index as i; trackBy: trackByFnEmail">
                                    <ion-item class="border-999 search-result checkbox-item" [ngClass]="{'maintain-border': state.windowSize.smallSize}" mode="md">
                                        <ion-icon (click)="removeEmailToInvite(i)" slot="start" name="close-sharp" class="mr-16"></ion-icon>
                                        <ion-label>{{emailToInvite.email}}</ion-label>
                                        <ion-button (click)="presentRoleOptions($event, state.windowSize, emailToInvite, (state.nonReadOnlySeatsAvailable <= 0 && emailToInvite.role === 'READ_ONLY'))"
                                                    class="ion-no-margin button-padding-10 z-1000 button-border-1"
                                                    slot="end"
                                                    [color]="(emailToInvite.role | projectRoleDetail).colour">
                                            {{(emailToInvite.role | projectRoleDetail).label}}
                                            <ion-icon slot="end" name="chevron-down-sharp"></ion-icon>
                                        </ion-button>
                                    </ion-item>
                                </ng-container>
                            </ion-list>

                            <div class="display-flex ion-justify-content-end">
                                <ion-button (click)="back()"
                                            fill="clear"
                                            class="ion-no-margin button-34">
                                    Back
                                </ion-button>
                                <ion-button (click)="addOrInviteUsers()"
                                            [disabled]="!loaded || (emailsToInvite?.length + usersToAdd?.length === 0)"
                                            class="ion-no-margin button-34 button-padding-32" data-testid="add.or.invite.add.users">
                                    <ng-container *ngIf="!emailsToInvite?.length; else inviteOptions">Add users</ng-container>
                                    <ng-template #inviteOptions>
                                        <ng-container *ngIf="usersToAdd?.length === 0">Send {{'invite' | makePluralString: emailsToInvite?.length}}</ng-container>
                                        <ng-container *ngIf="usersToAdd?.length > 0">Add & invite</ng-container>
                                    </ng-template>
                                </ion-button>
                            </div>
                        </ng-container>


                        <ng-template #noResultsToAdd>
                            <div class="font-14 mobile-padding-left">
                                <ion-text color="dark">
                                    <p><b>No users to add</b></p>
                                    <p>Please make sure that the email addresses provided are valid, comma separated
                                        and that they are not already on the project before searching again.</p>
                                </ion-text>
                            </div>

                            <div class="display-flex ion-justify-content-end ion-margin-bottom">
                                <ion-button (click)="back()"
                                            class="ion-no-margin button-34 button-padding-32">
                                    Back
                                </ion-button>
                            </div>
                        </ng-template>

                    </ng-template>
                </ng-container>

                <ng-template #loading>
                    <div class="flex-grow-1">
                        <ion-spinner class="load-spinner" name="crescent" color="secondary"></ion-spinner>
                    </div>
                </ng-template>
            </ion-col>
        </ion-row>
    </ion-grid>
</ng-container>
