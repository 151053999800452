import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Auth, authState } from '@angular/fire/auth';
import { DocumentChange } from '@angular/fire/firestore';
import { TelemetryRequestDto } from '@insite-group-ltd/insite-teams-api-model';
import head from 'lodash/head';
import { firstValueFrom, interval } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { DeviceService } from '../device/device.service';
import { FeatureService } from '../feature/feature.service';
import { LoggerService } from '../logger/logger.service';
import { SentryService } from '../sentry/sentry.service';

@Injectable({
    providedIn: 'root',
})
export class TelemetryService implements OnDestroy {
    private subs = new SubSink();
    private _data: any[] = [];
    private _captureTelemetryEnabled = false;
    private authState$ = authState(this.auth);

    constructor(
        @Inject('environment') private environment,
        private httpClient: HttpClient,
        private auth: Auth,
        private deviceService: DeviceService,
        private featureService: FeatureService,
        private loggerService: LoggerService,
        private sentryService: SentryService
    ) {
        this.subs.sink = this.authState$.subscribe(() => {
            this._data = [];
        });
        this.subs.sink = this.featureService
            .isFeatureEnabled$('captureTelemetry')
            .pipe(
                tap((enabled) => (this._captureTelemetryEnabled = enabled)),
                filter((enabled) => {
                    if (enabled) {
                        this.loggerService.info('TelemetryService', 'telemetry enabled');
                    } else {
                        this.loggerService.info('TelemetryService', 'telemetry disabled');
                    }
                    return enabled;
                }),
                switchMap(() =>
                    this.featureService.getFeatureConfig$('flushTelemetryIntervalSeconds')
                ),
                switchMap((intervalSeconds) => {
                    return interval(intervalSeconds * 1000).pipe(
                        takeUntil(
                            this.featureService
                                .isFeatureEnabled$('captureTelemetry')
                                .pipe(filter((val) => !val))
                        )
                    );
                })
            )
            .subscribe(() => {
                this.flushTelemetry();
            });
    }

    public addFirestoreReadCollectionTelemetryData(documentChangeAction: DocumentChange<any>[]) {
        if (this._captureTelemetryEnabled && !!this.auth.currentUser) {
            const readDocs = documentChangeAction.filter(
                (action) => !action.doc.metadata.fromCache
            );
            const readCount = readDocs?.length;
            if (readCount) {
                const firstDoc = head(readDocs);
                const fullPath = firstDoc?.doc?.ref?.path;
                if (fullPath) {
                    const collectionPath = fullPath.substring(0, fullPath.lastIndexOf('/'));
                    this._data.push({
                        type: 'firestore/read_collection',
                        collectionPath,
                        readCount,
                    });
                    this.loggerService.info(
                        'TelemetryService',
                        `collection '${collectionPath}' - read count '${readCount}'`
                    );
                }
            }
        }
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    private async flushTelemetry() {
        try {
            if (this._captureTelemetryEnabled && !!this.auth.currentUser && this._data.length) {
                const bearerToken = await this.auth.currentUser?.getIdToken();
                if (bearerToken) {
                    const dataInFlush = this._data;
                    this._data = [];
                    const body: TelemetryRequestDto = {
                        data: dataInFlush,
                        deviceId: await this.deviceService.getDeviceId(),
                    };
                    return firstValueFrom(
                        this.httpClient.post(
                            `${this.environment.apiBaseUrl}/telemetry`,
                            body,
                            this.getHttpOptions(bearerToken)
                        )
                    );
                }
            } else {
                this.loggerService.info('TelemetryService', 'skipping telemetry flush - no data');
            }
        } catch (err) {
            this.loggerService.error(
                'TelemetryService',
                'failed to flush telemetry: ' + err.message
            );
            this.sentryService.captureException(err);
        }
    }

    private getHttpOptions(bearerToken: string) {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${bearerToken}`,
                'X-Insite-User': this.auth.currentUser.uid || '',
            }),
        };
    }
}
