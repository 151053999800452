import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Capacitor } from '@capacitor/core';
import {
    fastSortByCustomIndex,
    SelectOption,
    SortableSelectOption,
} from '@insite-group-ltd/insite-teams-model';
import { IonInput, ModalController } from '@ionic/angular';
import includes from 'lodash/includes';
import isString from 'lodash/isString';
import { nanoid } from 'nanoid';
import { combineLatest, Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { focusField } from '../../../utils/focus';
import { SelectPopoverOption, SelectPopoverResultValidationFunction } from './select-popover';

@Component({
    selector: 'lib-select-popover',
    templateUrl: './select-popover.page.html',
    styleUrls: ['./select-popover.page.scss'],
})
export class SelectPopoverPage implements OnInit {
    @ViewChild('filterInput', { static: false }) filterInput: IonInput;

    @Input() title: string;
    @Input() entityName: string;
    @Input() options: SortableSelectOption[] = [];
    @Input() value: string | SortableSelectOption;
    @Input() allowAdd = false;
    @Input() showNoSelectionOption = false;
    @Input() autoCapitalizeInput = 'sentences';
    @Input() scope: 'project' | 'list' = 'project';
    @Input() validationFn: SelectPopoverResultValidationFunction = () => true;

    valueId = '';
    searchControl = new FormControl();
    options$: Observable<SelectPopoverOption[]> = of([]);

    constructor(private modalController: ModalController) {}

    ngOnInit() {
        if (isString(this.value)) {
            this.valueId = this.value;
        } else if (this.value) {
            this.valueId = this.value.id;
        }
        if (this.showNoSelectionOption) {
            this.options.push({
                id: 'NO_SELECTION',
                name: 'No ' + this.entityName,
                sortIndex: -1,
            });
        }
        this.options$ = combineLatest([
            of(this.options),
            this.searchControl.valueChanges.pipe(
                startWith(''),
                map((searchValue) => searchValue.toLowerCase())
            ),
        ]).pipe(
            map(([options, searchValue]) => {
                if (searchValue) {
                    return options.filter((o) => includes(o.name.toLowerCase(), searchValue));
                }
                return options;
            }),
            map((options) => fastSortByCustomIndex(options))
        );
    }

    ionViewDidEnter() {
        if (!Capacitor.isNativePlatform()) {
            focusField(this.filterInput);
        }
    }

    cancel() {
        this.dismiss();
    }

    async finish() {
        const result =
            this.valueId === 'NO_SELECTION'
                ? null
                : this.options.find((opt) => opt.id === this.valueId);
        if (await this.validationFn(result)) {
            this.dismiss(result);
        }
    }

    add() {
        const searchValue = this.searchControl.value;
        if (!this.options.some((opt) => opt.name.toLowerCase() === searchValue.toLowerCase())) {
            const optionId = nanoid();
            this.options.push({ id: optionId, name: searchValue });
            this.valueId = optionId;
        }
        this.searchControl.setValue('');
    }

    compareById(value: SelectPopoverOption, other: SelectPopoverOption) {
        return value?.id === other?.id;
    }

    private dismiss(result?: SelectOption | null) {
        this.modalController.dismiss(result);
    }
}
