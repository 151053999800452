import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
    selector: '[libRemoveIoniconTitle]',
})
export class RemoveIoniconTitleDirective implements OnInit {
    constructor(private el: ElementRef) {}

    ngOnInit(): void {
        const removeTitle = () => {
            if (
                this.el.nativeElement &&
                this.el.nativeElement.shadowRoot &&
                this.el.nativeElement.shadowRoot.querySelector('.icon-inner svg title')
            ) {
                this.el.nativeElement.shadowRoot.querySelector('.icon-inner svg title').innerHTML =
                    '';
            } else {
                setTimeout(() => {
                    removeTitle();
                }, 500);
            }
        };
        removeTitle();
    }
}
