import { Injectable } from '@angular/core';
import { CollectionConfig, NoPathParams } from 'akita-ng-fire';
import { AuditedService } from '../../../services/audited/audited.service';
import { TelemetryService } from '../../../services/telemetry/telemetry.service';
import { BaseCollectionService } from '../../collection-service/base-collection.service';
import { PlanFeaturesState, PlanFeaturesStore } from './plan-features.store';

const COLLECTION_PATH = 'plan-features';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: COLLECTION_PATH, idKey: 'id' })
export class PlanFeaturesService extends BaseCollectionService<
    PlanFeaturesState,
    PlanFeaturesStore,
    NoPathParams
> {
    constructor(
        store: PlanFeaturesStore,
        protected auditedService: AuditedService,
        protected telemetryService: TelemetryService
    ) {
        super(COLLECTION_PATH, store, auditedService, telemetryService);
    }
}
