import { Injectable } from '@angular/core';
import { increment } from '@angular/fire/firestore';
import { DictionaryPhrase } from '@insite-group-ltd/insite-teams-model';
import { CollectionConfig } from 'akita-ng-fire';
import { AuditedService } from '../../../services/audited/audited.service';
import { TelemetryService } from '../../../services/telemetry/telemetry.service';
import {
    BaseCollectionService,
    PlanIdPathParam,
} from '../../collection-service/base-collection.service';
import { PlanQuery } from '../plan.query';
import {
    PlanDictionaryPhrasesState,
    PlanDictionaryPhrasesStore,
} from './plan-dictionary-phrases.store';

const COLLECTION_PATH = 'plans/:planId/dictionary-phrases';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: COLLECTION_PATH, idKey: 'id' })
export class PlanDictionaryPhrasesService extends BaseCollectionService<
    PlanDictionaryPhrasesState,
    PlanDictionaryPhrasesStore,
    PlanIdPathParam
> {
    constructor(
        store: PlanDictionaryPhrasesStore,
        auditedService: AuditedService,
        telemetryService: TelemetryService,
        private planQuery: PlanQuery
    ) {
        super(COLLECTION_PATH, store, auditedService, telemetryService);
    }

    get path() {
        const planId = this.planQuery.getActiveId();
        return `plans/${planId}/dictionary-phrases`;
    }

    async updatePhraseAvailability(
        checked: boolean,
        selectedPhraseIds: string[],
        field: 'title' | 'description'
    ) {
        let batch = this.batch();
        let batchSize = 0;
        for (const selectedPhraseId of selectedPhraseIds) {
            if (batchSize >= 500) {
                await batch.commit();
                batch = this.batch();
                batchSize = 0;
            }
            await this.update(
                selectedPhraseId,
                { [field]: checked },
                this.getWriteOptions({ planId: this.planQuery.getActiveId() }, batch)
            );
            batchSize++;
        }
        await batch.commit();
    }

    updatePhraseUseCount(
        planId: string,
        phraseId: string,
        field: 'useCountTitle' | 'useCountDescription'
    ) {
        return this.update(phraseId, { [field]: increment(1) }, this.getWriteOptions({ planId }));
    }

    importPhrases(phrases: Partial<DictionaryPhrase>[]) {
        const batch = this.batch();
        this.add(phrases, this.getWriteOptions({ planId: this.planQuery.getActiveId() }, batch));
        return batch.commit();
    }

    removePhrases(phrases: string[]) {
        const batch = this.batch();
        this.remove(phrases, this.getWriteOptions({ planId: this.planQuery.getActiveId() }, batch));
        return batch.commit();
    }
}
