import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { PlanOverview } from '@insite-group-ltd/insite-teams-model';
import {
    AuthService,
    PlanQuery,
    TranslationService,
    UtilService,
    ZendeskService,
} from '@insite-group-ltd/insite-teams-shared';
import { MenuController, NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { TrialLearnMorePageInputs } from './trial-learn-more/trial-learn-more';
import { TrialLearnMorePage } from './trial-learn-more/trial-learn-more.page';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    adminPages = [];
    planOverview$: Observable<PlanOverview>;
    private subs = new SubSink();

    constructor(
        public authService: AuthService,
        private utilService: UtilService,
        private navCtrl: NavController,
        private planQuery: PlanQuery,
        private router: Router,
        private zendeskService: ZendeskService,
        private menuController: MenuController,
        private translationService: TranslationService
    ) {}

    ngOnInit() {
        this.translationService.init();
        this.planOverview$ = this.planQuery.planOverview$;
        this.subs.sink = this.authService.sync().subscribe();
        this.subs.sink = this.router.events
            .pipe(filter((event) => event instanceof NavigationStart))
            .subscribe((event: NavigationStart) => {
                if (event.url.startsWith('/auth')) {
                    this.menuController.enable(false);
                } else {
                    this.menuController.enable(true);
                }
            });
        this.subs.sink = this.planQuery.selectActive().subscribe((plan) => {
            if (plan) {
                this.adminPages = [
                    {
                        title: 'Projects',
                        url: '/app/projects',
                        testId: 'projects',
                    },
                    plan.type !== 'BASIC'
                        ? {
                              title: 'Members',
                              url: '/app/members',
                              testId: 'members',
                          }
                        : null,
                    {
                        title: 'Plan & Billing',
                        url: '/app/manage',
                        testId: 'manage',
                    },
                    {
                        title: 'Settings',
                        url: '/app/settings',
                        testId: 'settings',
                    },
                    {
                        title: 'Resources',
                        url: '/app/resources',
                        testId: 'resources',
                    },
                ].filter((page) => !!page);
            } else {
                this.adminPages = [
                    {
                        title: 'Get started',
                        url: '/app/get-started',
                        testId: 'get.started',
                    },
                ];
            }
        });
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    home() {
        this.navCtrl.navigateRoot('/app/projects', {
            animated: true,
            animationDirection: 'back',
        });
    }

    contactUs() {
        this.zendeskService.contactSupport();
    }

    openHelp() {
        this.zendeskService.openHelp();
    }

    trialLearnMore() {
        this.utilService.presentModal<TrialLearnMorePageInputs>(
            TrialLearnMorePage,
            {},
            { cssClass: 'trail-learn-more-modal' }
        );
    }
}
