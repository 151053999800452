import { Component, Input } from '@angular/core';
import { SelectOption } from '@insite-group-ltd/insite-teams-model';
import { PopoverController } from '@ionic/angular';
import { RadioGroupChangeEventDetail } from '@ionic/core';

export interface RadioGroup {
    header: string;
    values: SelectOption[];
    currentValue: string;
    handler: RadioGroupCallback;
    informationMessage?: string;
    guideLink?: string;
}

export type RadioGroupCallback = (valueId: string) => void | Promise<void>;

@Component({
    selector: 'lib-radio-group-popover',
    templateUrl: './radio-group-popover.component.html',
    styleUrls: ['./radio-group-popover.component.scss'],
})
export class RadioGroupPopoverComponent {
    @Input() radioGroups: RadioGroup[];
    @Input() translateLabels: boolean;

    constructor(private popoverController: PopoverController) {}

    selectGroupValue(event: Event, handler: RadioGroupCallback) {
        const valueId = (event as CustomEvent<RadioGroupChangeEventDetail>).detail.value;
        handler(valueId);
        this.popoverController.dismiss();
    }
}
