import { Injectable } from '@angular/core';
import { CollectionConfig } from 'akita-ng-fire';
import { AuditedService } from '../../../services/audited/audited.service';
import { TelemetryService } from '../../../services/telemetry/telemetry.service';
import {
    BaseCollectionService,
    PlanIdPathParam,
} from '../../collection-service/base-collection.service';
import { PlanDetailsState, PlanDetailsStore } from './plan-details.store';

const COLLECTION_PATH = 'plans/:planId/details';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: COLLECTION_PATH, idKey: 'id' })
export class PlanDetailsService extends BaseCollectionService<
    PlanDetailsState,
    PlanDetailsStore,
    PlanIdPathParam
> {
    constructor(
        store: PlanDetailsStore,
        auditedService: AuditedService,
        telemetryService: TelemetryService
    ) {
        super(COLLECTION_PATH, store, auditedService, telemetryService);
    }
}
