import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import includes from 'lodash/includes';
import { UtilService } from '../../services/util/util.service';

@Directive({
    selector: '[libDropzone]',
})
export class DropzoneDirective {
    @Output() dropped = new EventEmitter<FileList>();
    @Output() hovered = new EventEmitter<boolean>();
    @Input() multipleDropAllowed: boolean;
    @Input() acceptedType: string;
    @Input() maxSizeInMB?: number;

    constructor(private utilService: UtilService) {}

    @HostListener('drop', ['$event'])
    async onDrop($event) {
        $event.preventDefault();
        const files = $event.dataTransfer.files;
        if (this.acceptedType) {
            for (const file of files) {
                if (!includes(this.acceptedType, file.type)) {
                    await this.utilService.messageAlert(
                        'Unsupported file type!',
                        'Please drop a valid file type.'
                    );
                    this.hovered.emit(false);
                    return;
                }
            }
        }
        for (const file of files) {
            if (this.maxSizeInMB && file.size > this.maxSizeInMB * 1024 * 1024) {
                await this.utilService.messageAlert(
                    'File size exceeds maximum!',
                    `Files have a maximum size of ${this.maxSizeInMB}MB`
                );
                this.hovered.emit(false);
                return;
            }
        }

        if (this.multipleDropAllowed) {
            this.dropped.emit(files);
        }
        if (!this.multipleDropAllowed) {
            if (files.length > 1) {
                await this.utilService.messageAlert(
                    'Multiple files dropped!',
                    'Only one file can be dropped. Please try again.'
                );
            } else if (files.length === 1) {
                this.dropped.emit(files);
            }
        }
        this.hovered.emit(false);
    }

    @HostListener('dragover', ['$event'])
    onDragOver($event) {
        $event.preventDefault();
        this.hovered.emit(true);
    }

    @HostListener('dragleave', ['$event'])
    onDragLeave($event) {
        $event.preventDefault();
        this.hovered.emit(false);
    }
}
