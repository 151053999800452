import { Injectable } from '@angular/core';
import {
    canAccessFeature,
    DEFAULT_FEATURE_ACCESS,
    DEFAULT_FEATURE_CONFIG,
    DEFAULT_FEATURE_FLAGS,
    FeatureAccess,
    FeatureConfig,
    FeatureFlags,
} from '@insite-group-ltd/insite-teams-model';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { PlanFeaturesQuery } from '../../state/plan/plan-features/plan-features.query';
import { ProjectQuery } from '../../state/projects/project.query';
import { SystemQuery } from '../../state/system/system.query';
import { InsiteUserPrivateQuery } from '../../state/user/insite-user-private.query';
import { toPromise } from '../../utils/pipe';

@Injectable({
    providedIn: 'root',
})
export class FeatureService {
    constructor(
        private systemQuery: SystemQuery,
        private projectQuery: ProjectQuery,
        private planFeaturesQuery: PlanFeaturesQuery,
        private insiteUserPrivateQuery: InsiteUserPrivateQuery
    ) {}

    get featureFlags$(): Observable<FeatureFlags> {
        return combineLatest([
            this.systemQuery.featureFlags$,
            this.planFeaturesQuery.activePlanFeatureFlags$,
            this.insiteUserPrivateQuery.featureFlags$,
        ]).pipe(
            map(([systemFeatureFlags, planFeatureFlags, userFeatureFlags]) =>
                Object.assign(
                    {},
                    DEFAULT_FEATURE_FLAGS,
                    systemFeatureFlags,
                    planFeatureFlags,
                    userFeatureFlags
                )
            )
        );
    }

    isFeatureEnabled$(feature: keyof FeatureFlags): Observable<boolean> {
        return this.featureFlags$.pipe(
            map((featureFlags) => featureFlags?.[feature]),
            distinctUntilChanged()
        );
    }

    isFeatureEnabled(feature: keyof FeatureFlags): Promise<boolean> {
        return toPromise(this.isFeatureEnabled$(feature));
    }

    getFeatureConfig$<T extends keyof FeatureConfig>(configKey: T): Observable<FeatureConfig[T]> {
        return combineLatest([
            this.systemQuery.featureConfig$,
            this.planFeaturesQuery.activePlanFeatureConfig$,
            this.insiteUserPrivateQuery.featureConfig$,
        ]).pipe(
            map(
                ([systemFeatureConfig, planFeatureConfig, userFeatureConfig]) =>
                    Object.assign(
                        {},
                        DEFAULT_FEATURE_CONFIG,
                        systemFeatureConfig,
                        planFeatureConfig,
                        userFeatureConfig
                    )?.[configKey]
            ),
            distinctUntilChanged()
        );
    }

    getFeatureConfig<T extends keyof FeatureConfig>(configKey: T): Promise<FeatureConfig[T]> {
        return toPromise(this.getFeatureConfig$(configKey));
    }

    get featureAccess$(): Observable<FeatureAccess> {
        return combineLatest([
            this.systemQuery.featureAccess$,
            this.planFeaturesQuery.activePlanFeatureAccess$,
            this.insiteUserPrivateQuery.featureAccess$,
        ]).pipe(
            map(([systemFeatureAccess, planFeatureAccess, userFeatureAccess]) =>
                Object.assign(
                    {},
                    DEFAULT_FEATURE_ACCESS,
                    systemFeatureAccess,
                    planFeatureAccess,
                    userFeatureAccess
                )
            )
        );
    }

    canAccessFeature$<T extends keyof FeatureAccess>(featureKey: T): Observable<boolean> {
        return combineLatest([this.featureAccess$, this.projectQuery.activeProjectPlanType$]).pipe(
            map(([featureAccess, planType]) =>
                canAccessFeature(featureAccess, featureKey, planType)
            )
        );
    }

    canAccessFeature<T extends keyof FeatureAccess>(featureKey: T): Promise<boolean> {
        return toPromise(this.canAccessFeature$(featureKey));
    }
}
