import { sort } from 'fast-sort';
import isNil from 'lodash/isNil';
import startsWith from 'lodash/startsWith';
import { SortableSelectOption } from './select-option';

export interface SortingBy {
    description: string;
    fn: (a: any, b: any) => number;
    code: string;
}

export interface WithSortIndex {
    sortIndex?: number;
}

export const sortingByName: SortingBy = {
    description: 'Sorted by: A-Z',
    fn: sortByName,
    code: 'A_Z',
};

export const sortingByCreatedDesc: SortingBy = {
    description: 'Sorted by: Newest first',
    fn: sortByCreatedDesc,
    code: 'CREATED_DESC',
};

export const sortingByCreatedAsc: SortingBy = {
    description: 'Sorted by: Newest last',
    fn: sortByCreatedAsc,
    code: 'CREATED_ASC',
};

export const sortingByCustomIndex: SortingBy = {
    description: 'Sorted by: Custom order',
    fn: sortByCustomIndex,
    code: 'CUSTOM',
};

export function sortByName(a: { name: string } | undefined, b: { name: string } | undefined) {
    return sortByAlpha(a?.name, b?.name);
}

export function sortByLocationName(a: { locationName: string }, b: { locationName: string }) {
    return sortByAlpha(a.locationName, b.locationName);
}

// DEPRECATED, should use fast sort below
// same with all legacy sort functions
export function sortByCustomIndex(
    a: WithSortIndex & { name: string },
    b: WithSortIndex & { name: string }
) {
    if (!isNil(a.sortIndex) && !isNil(b.sortIndex)) {
        return a.sortIndex > b.sortIndex ? 1 : -1;
    } else {
        return sortByAlpha(a.name, b.name);
    }
}

export function fastSortByCustomIndex(values: SortableSelectOption[]) {
    return sort(values).asc([(u) => u.sortIndex, (u) => u.name]);
}

export function sortByNameHyphensLast(a: { name: string }, b: { name: string }) {
    if (!a.name) {
        return 1;
    } else if (!b.name) {
        return -1;
    }
    const aStartsWithHyphen = startsWith(a.name, '-');
    const bStartsWithHyphen = startsWith(b.name, '-');
    if (aStartsWithHyphen && bStartsWithHyphen) {
        if (a.name.toLowerCase() === b.name.toLowerCase()) {
            return 0;
        }
        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
    } else if (aStartsWithHyphen) {
        return 1;
    } else if (bStartsWithHyphen) {
        return -1;
    } else if (a.name.toLowerCase() === b.name.toLowerCase()) {
        return 0;
    }
    return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
}

export function sortByAlpha(a: string | undefined, b: string | undefined) {
    if (!a) {
        return 1;
    } else if (!b) {
        return -1;
    } else if (a.toLowerCase() === b.toLowerCase()) {
        return 0;
    }
    return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
}

export function sortByCreatedDesc(a: { createdAt: string }, b: { createdAt: string }) {
    return a.createdAt < b.createdAt ? 1 : -1;
}

export function sortByCreatedAsc(a: { createdAt: string }, b: { createdAt: string }) {
    return a.createdAt > b.createdAt ? 1 : -1;
}

export function sortByGeneratedDesc(a: { generatedDate: string }, b: { generatedDate: string }) {
    return a.generatedDate < b.generatedDate ? 1 : -1;
}

export const sortOptions = [
    sortingByName,
    sortingByCreatedAsc,
    sortingByCreatedDesc,
    sortingByCustomIndex,
];

export function getSortByCode(code: string): SortingBy {
    return sortOptions.find((sortingBy) => sortingBy.code === code) || sortingByCreatedDesc;
}
