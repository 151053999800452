import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { BillingInterval, PlanOption, PlanTypeId } from '@insite-group-ltd/insite-teams-model';
import isFinite from 'lodash/isFinite';
import isString from 'lodash/isString';
import toNumber from 'lodash/toNumber';

export interface PlanOptionState extends EntityState<PlanOption, string> {
    planType?: PlanTypeId;
    quantity: number;
    billingInterval: BillingInterval;
    coupon?: string;
    couponPlans: number[];
    couponDiscount?: number;
    couponDuration: 'single' | 'lifetime';
}

const initialState: PlanOptionState = {
    planType: undefined,
    quantity: 1,
    billingInterval: 'monthly',
    coupon: undefined,
    couponPlans: [],
    couponDiscount: undefined,
    couponDuration: 'single',
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'plan-option' })
export class PlanOptionStore extends EntityStore<PlanOptionState> {
    constructor() {
        super(initialState);
    }

    resetStore() {
        this.update(initialState);
    }

    updateStoreFromQueryParams(queryParams: ParamMap) {
        const plan = queryParams.get('plan');
        if (plan) {
            const quantity = queryParams.get('quantity');
            this.updateCoupon(queryParams.get('coupon'));
            this.updateCouponDiscount(queryParams.get('couponDiscount'));
            this.updateCouponPlans(queryParams.getAll('couponPlan'));
            this.updateCouponDuration(
                queryParams.get('couponDuration') as PlanOptionState['couponDuration']
            );
            this.updatePlanType(plan as PlanOptionState['planType']);
            this.updateBillingInterval(queryParams.get('billingInterval') as BillingInterval);
            if (plan === 'starter' && quantity) {
                this.updateQuantity(quantity);
            }
        }
    }

    updateCoupon(coupon: string) {
        this.update({ coupon });
    }

    updateBillingInterval(billingInterval: BillingInterval) {
        if (billingInterval && (billingInterval === 'monthly' || billingInterval === 'annual')) {
            this.update({ billingInterval: billingInterval });
        }
    }

    updatePlanType(planType: PlanTypeId) {
        if (planType) {
            this.update({ planType: planType });
        }
    }

    updateQuantity(quantity: number | string) {
        const quantityNumber = this.convertToNumber(quantity);
        if (quantityNumber && quantityNumber > 0) {
            this.update({ quantity: quantityNumber });
        }
    }

    incrementQuantityToMinimum(minimumQuantity: number) {
        const { quantity } = this.getValue();
        if (quantity < minimumQuantity) {
            this.updateQuantity(minimumQuantity);
        }
    }

    incrementQuantity() {
        this.updateQuantity(this.getValue().quantity + 1);
    }

    decrementQuantity() {
        this.updateQuantity(this.getValue().quantity - 1);
    }

    private updateCouponDuration(couponDuration: PlanOptionState['couponDuration']) {
        if (couponDuration && (couponDuration === 'single' || couponDuration === 'lifetime')) {
            this.update({ couponDuration: couponDuration });
        }
    }

    private updateCouponDiscount(couponDiscount: number | string) {
        const couponDiscountNumber = this.convertToNumber(couponDiscount);
        if (couponDiscountNumber && couponDiscountNumber >= 0 && couponDiscountNumber <= 100) {
            this.update({ couponDiscount: couponDiscountNumber });
        }
    }

    private updateCouponPlans(couponPlans: string[]) {
        const couponPlansNumber = couponPlans
            .map((couponPlan) => this.convertToNumber(couponPlan))
            .filter((couponPlan) => !!couponPlan);
        this.update({ couponPlans: couponPlansNumber || [] });
    }

    private convertToNumber(value: number | string): number | undefined {
        if (isString(value)) {
            const asNumber = toNumber(value);
            if (isFinite(asNumber)) {
                return asNumber;
            } else {
                return undefined;
            }
        } else {
            return value;
        }
    }
}
