import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Project } from '@insite-group-ltd/insite-teams-model';
import { CollectionGuard } from 'akita-ng-fire';
import { catchError, tap } from 'rxjs/operators';
import { LoggerService } from '../../services/logger/logger.service';
import { TranslationService } from '../../services/translation/translation.service';
import { UtilService } from '../../services/util/util.service';
import { ProjectService } from '../../state/projects/project.service';
import { catchGuardError } from '../../utils/guard';
import { ProjectState } from './project.store';

@Injectable({ providedIn: 'root' })
export class ActiveProjectGuard extends CollectionGuard<ProjectState> {
    constructor(
        protected service: ProjectService,
        private utilService: UtilService,
        private translationService: TranslationService,
        private loggerService: LoggerService
    ) {
        super(service);
    }

    protected sync(next: ActivatedRouteSnapshot) {
        this.loggerService.debug('active project guard', 'syncing active project');
        return this.service.syncActive({ id: next.params.projectId }).pipe(
            tap((project: Project) =>
                this.translationService.setProjectTranslations(project?.translations)
            ),
            catchError((err) =>
                catchGuardError(
                    err,
                    `projects/${next.params.projectId}`,
                    this.utilService,
                    this.loggerService
                )
            )
        );
    }
}
