import { Injectable } from '@angular/core';
import { getDownloadURL, ref, Storage, UploadMetadata, uploadString } from '@angular/fire/storage';
import {
    isUnsavedPdf,
    Pdf,
    SavedInsiteFileVersion,
    UnsavedInsiteFileVersion,
} from '@insite-group-ltd/insite-teams-model';
import { v4 as uuidv4 } from 'uuid';
import { AuthService } from '../../state/auth/auth.service';
import { ProjectQuery } from '../../state/projects/project.query';
import { AnalyticsService } from '../analytics/analytics.service';

export class UploadedFile {
    downloadUrl: string;
    filename: string;
    path: string;
}

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    constructor(
        private storage: Storage,
        private analyticsService: AnalyticsService,
        private projectQuery: ProjectQuery,
        private authService: AuthService
    ) {}

    public async uploadPdf(
        documentPath: string,
        pdf: Pdf,
        projectId: string,
        downloadFilename?: string
    ): Promise<Pdf> {
        if (!isUnsavedPdf(pdf)) {
            return pdf;
        }
        let base64 = pdf.url;
        const filename = `${uuidv4()}.pdf`;
        const metadata: UploadMetadata = {
            contentDisposition: downloadFilename
                ? `attachment; filename="${downloadFilename}"`
                : undefined,
            customMetadata: this.getFileCustomMetadata(documentPath, projectId),
        };
        const path = `${documentPath}/${filename}`;
        if (!base64.startsWith('data:application/pdf;base64,')) {
            base64 = `data:application/pdf;base64,${base64}`;
        }
        const url = await this.uploadDataUrl(path, base64, metadata);
        this.analyticsService.event('upload_to_storage', path);
        return Object.assign(pdf, {
            url,
            filename,
            path,
            directory: documentPath,
        });
    }

    public async uploadDataUrl(path: string, dataUrl: string, metadata: UploadMetadata = {}) {
        const storageRef = ref(this.storage, path);
        const uploadResult = await uploadString(storageRef, dataUrl, 'data_url', {
            cacheControl: 'public,max-age=31536000',
            ...metadata,
        });
        return await getDownloadURL(uploadResult.ref);
    }

    public async uploadFile(
        documentPath: string,
        file: UnsavedInsiteFileVersion,
        projectId: string
    ): Promise<SavedInsiteFileVersion> {
        const filename = `${uuidv4()}.${file.extension}`;
        const metadata: UploadMetadata = {
            cacheControl: 'public,max-age=31536000',
            contentDisposition: `attachment; filename="${file.originalFilename}"`,
            customMetadata: this.getFileCustomMetadata(documentPath, projectId),
        };
        const path = `${documentPath}/${filename}`;
        const url = await this.uploadDataUrl(path, file.url, metadata);
        this.analyticsService.event('upload_to_storage', path);
        return Object.assign({}, file, {
            url,
            filename,
            path,
            directory: documentPath,
        }) as SavedInsiteFileVersion;
    }

    public getDownloadUrl(path: string) {
        return getDownloadURL(ref(this.storage, path));
    }

    private getFileCustomMetadata(documentPath: string, projectId: string) {
        return {
            documentPath,
            projectId,
            planId: this.projectQuery.getProjectPlanId(projectId),
            userId: this.authService.userId,
        };
    }
}
