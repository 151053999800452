<ion-badge data-testid="project.card.notifications"
           *ngIf="notificationCount"
           class="notification-icon cursor-pointer z-900"
           color="danger"
           (click)="$event.stopPropagation(); notificationClick.emit()">{{ notificationCount }}</ion-badge>
<div class="project-card display-flex-column" [ngClass]="{ static: static }">
    <div class="image-wrapper">
        <ion-img #img
                 [src]="project.image | resolveUnsanitizedImageUrl: '/assets/imgs/placeholders/no-project-image.svg'"
                 (ionError)="img.src = '../../../../assets/imgs/placeholders/image-unavailable.svg'; imageUnavailable = true"
                 [ngClass]="{'has-image': project.image, 'project-image-unavailable': imageUnavailable && (project.image | isLocalImage)}">
        </ion-img>
    </div>
    <div class="pt-4 ion-margin-horizontal mb-10">
        <ion-text data-testid="project.card.name" [color]="project.name ? 'admin-dark' : 'medium'">
            <h6 class="overflow-ellipsis mb-8 mt-6">
                {{ project.name || 'Enter name' }}
            </h6>
        </ion-text>
        <div class="vertical-align-content">
            <ion-badge *ngIf="project.projectNumber || showBlankProjectNo"
                       class="overflow-ellipsis"
                       color="tertiary">
                {{showBlankProjectNo ? project.projectNumber || 'No number' : project.projectNumber}}
            </ion-badge>
        </div>
    </div>
    <div class="flex-fill"></div>
    <div class="display-flex pb-10">
        <div class="ion-text-center project-stat border-right">
            <ion-text color="admin-dark">
                <h5 class="ion-no-margin"><b>{{ project.listCount || '0' }}</b></h5>
            </ion-text>
            <ion-text color="dark">
                <p class="font-12 mb-0 mt-4">{{ 'List' | makePluralString: project.listCount }}</p>
            </ion-text>
        </div>
        <div class="ion-text-center project-stat">
            <ion-text color="admin-dark"><h5 class="ion-no-margin">
                <b>{{ project.userCount || '0' }}</b>
            </h5></ion-text>
            <ion-text color="dark">
                <p class="font-12 mb-0 mt-4">{{ 'User' | makePluralString: project.userCount }}</p>
            </ion-text>
        </div>
    </div>
</div>
