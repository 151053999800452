import { Pipe, PipeTransform } from '@angular/core';
import { Image } from '@insite-group-ltd/insite-teams-model';

@Pipe({ name: 'resolveUnsanitizedImageUrl' })
export class ResolveUnsanitizedImageUrl implements PipeTransform {
    transform(image: Image, defaultImage?: string): string {
        if (!image) {
            return defaultImage;
        } else {
            return image.thumbUrl || image.url;
        }
    }
}
