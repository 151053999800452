import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Image, isBase64Image, isLocalImage } from '@insite-group-ltd/insite-teams-model';
import isString from 'lodash/isString';

@Pipe({ name: 'resolveImageUrl' })
export class ResolveImageUrl implements PipeTransform {
    constructor(private domSanitizer: DomSanitizer) {}

    transform(image: Image | string, defaultImage?: string): SafeUrl | string {
        if (!image) {
            return defaultImage;
        } else if (isString(image)) {
            return image;
        } else if (image.thumbUrl) {
            return image.thumbUrl;
        } else if (isBase64Image(image) || isLocalImage(image)) {
            return this.domSanitizer.bypassSecurityTrustUrl(image.url);
        } else {
            return image.url;
        }
    }
}
