import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Capacitor } from '@capacitor/core';
import {
    SelectOption,
    getRandomOptionColour,
    sortByNameHyphensLast,
} from '@insite-group-ltd/insite-teams-model';
import { IonInput, ModalController } from '@ionic/angular';
import cloneDeep from 'lodash/cloneDeep';
import includes from 'lodash/includes';
import isEqual from 'lodash/isEqual';
import isString from 'lodash/isString';
import omit from 'lodash/omit';
import { Observable, combineLatest, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { focusField } from '../../../utils/focus';
import {
    MultiSelectPopoverOption,
    MultiSelectPopoverPageResult,
    MultiSelectPopoverResultValidationFunction,
} from './multi-select-popover';

@Component({
    selector: 'lib-multi-select-popover',
    templateUrl: './multi-select-popover.page.html',
    styleUrls: ['./multi-select-popover.page.scss'],
})
export class MultiSelectPopoverPage<T extends SelectOption> implements OnInit {
    @ViewChild('filterInput', { static: false }) filterInput: IonInput;

    @Input() title: string;
    @Input() options: T[] = [];
    @Input() values: string[] | T[] = [];
    @Input() withColours = false;
    @Input() allowAdd = true;
    @Input() allowClear = false;
    @Input() clearLabel = 'Clear selection';
    @Input() inverseSelectionMode = false;
    @Input() autoCapitalizeInput = 'sentences';
    @Input() endSlotTemplateRef: TemplateRef<any>;
    @Input() sortOptions = true;
    @Input() validationFn: MultiSelectPopoverResultValidationFunction<T> = () => true;
    searchControl = new FormControl();
    options$: Observable<MultiSelectPopoverOption[]> = of([]);
    private opts: MultiSelectPopoverOption[] = [];

    constructor(private modalController: ModalController) {}

    ngOnInit() {
        // add from available and check those selected
        this.opts = cloneDeep(this.options || []).map((option) => {
            return {
                ...option,
                checked: (this.values || []).some((selected: string | T) => {
                    if (isString(selected)) {
                        return selected === option.id;
                    } else if (!selected.id && !option.id) {
                        return isEqual(selected.name, option.name);
                    } else {
                        return selected.id === option.id;
                    }
                }),
            };
        });
        this.options$ = combineLatest([
            of(this.opts),
            this.searchControl.valueChanges.pipe(
                startWith(''),
                map((searchValue) => searchValue.toLowerCase())
            ),
        ]).pipe(
            map(([options, searchValue]) => {
                if (searchValue) {
                    return options.filter((o) => includes(o.name.toLowerCase(), searchValue));
                }
                return options;
            }),
            map((options) => (this.sortOptions ? options.sort(sortByNameHyphensLast) : options))
        );
    }

    get checkedCount() {
        return this.opts.filter((opt) => opt.checked).length;
    }

    ionViewDidEnter() {
        if (!Capacitor.isNativePlatform()) {
            focusField(this.filterInput);
        }
    }

    cancel() {
        this.dismiss();
    }

    clear() {
        for (const opt of this.opts) {
            opt.checked = this.inverseSelectionMode;
        }
    }

    async finish() {
        const selected: T[] = this.opts
            .filter((option) => option.checked)
            .map((option) => cloneDeep(omit(option, 'checked')) as T);
        const selectedOptions = this.sortOptions ? selected.sort(sortByNameHyphensLast) : selected;
        // create a new set of options from the original list, plus any new ones
        const completeOptions = [
            ...(this.options || []),
            ...selectedOptions.filter(
                (result) => !this.options.some((tag) => result.name === tag.name)
            ),
        ];
        const result: MultiSelectPopoverPageResult<T> = {
            values: selectedOptions,
            options: completeOptions,
        };
        if (await this.validationFn(result)) {
            this.dismiss(result);
        }
    }

    add() {
        const searchValue = this.searchControl.value;
        if (!this.opts.some((opt) => opt.name.toLowerCase() === searchValue.toLowerCase())) {
            if (this.withColours) {
                this.opts.push({
                    id: null,
                    name: searchValue,
                    checked: true,
                    colour: getRandomOptionColour(this.opts),
                });
            } else {
                this.opts.push({ id: null, name: searchValue, checked: true });
            }
        }
        this.searchControl.setValue('');
    }

    private dismiss(result?: MultiSelectPopoverPageResult<T>) {
        this.modalController.dismiss(result);
    }
}
