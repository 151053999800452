import { Injectable } from '@angular/core';
import { Project } from '@insite-group-ltd/insite-teams-model';
import { CollectionConfig, NoPathParams } from 'akita-ng-fire';
import { AuditedService } from '../../services/audited/audited.service';
import { TelemetryService } from '../../services/telemetry/telemetry.service';
import { BaseCollectionService } from '../collection-service/base-collection.service';
import { InsiteUserState, InsiteUserStore } from './insite-user.store';

const COLLECTION_PATH = 'users';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: COLLECTION_PATH, idKey: 'id' })
export class InsiteUserService extends BaseCollectionService<
    InsiteUserState,
    InsiteUserStore,
    NoPathParams
> {
    constructor(
        store: InsiteUserStore,
        auditedService: AuditedService,
        telemetryService: TelemetryService
    ) {
        super(COLLECTION_PATH, store, auditedService, telemetryService);
    }

    syncProjectUsers(project: Project) {
        return this.syncManyDocs(Object.keys(project.users));
    }
}
