import { Directive, HostListener, Input } from '@angular/core';
import { UtilService } from '../../services/util/util.service';

@Directive({
    selector: '[libOpenUrl]',
})
export class OpenUrlDirective {
    @Input('libOpenUrl') url: string;

    constructor(private utilService: UtilService) {}

    @HostListener('click', ['$event'])
    onClick(event: any) {
        event.stopPropagation();
        if (this.url) {
            this.utilService.openUrl(this.url);
        }
    }
}
