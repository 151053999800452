import { Injectable } from '@angular/core';
import { AppLauncher } from '@capacitor/app-launcher';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import {
    getGpsCoordinatesGoogleMapsUrl,
    getGpsCoordinatesLabel,
    GpsCoordinates,
} from '@insite-group-ltd/insite-teams-model';
import { Platform } from '@ionic/angular';
import { isTestMode } from '../../utils/test';
import { LoggerService } from '../logger/logger.service';
import { SentryService } from '../sentry/sentry.service';
import { UtilService } from '../util/util.service';

const LOGGER_NAME = 'map-service';

@Injectable({
    providedIn: 'root',
})
export class MapService {
    constructor(
        private platform: Platform,
        private utilService: UtilService,
        private loggerService: LoggerService,
        private sentryService: SentryService
    ) {}

    public async openGpsCoordinates(gpsCoordinates: GpsCoordinates) {
        try {
            if (Capacitor.isNativePlatform() && Capacitor.isPluginAvailable('Browser')) {
                const platformUrl = this.getPlatformGpsCoordinatesUrl(gpsCoordinates);
                if (platformUrl && Capacitor.isPluginAvailable('AppLauncher')) {
                    const successfullyOpened = await AppLauncher.openUrl({
                        url: platformUrl,
                    });
                    if (successfullyOpened) {
                        this.loggerService.info(
                            LOGGER_NAME,
                            `successfully opened platform gps coordinates url: ${platformUrl}`
                        );
                        return;
                    }
                }
                await Browser.open({ url: getGpsCoordinatesGoogleMapsUrl(gpsCoordinates) });
            } else {
                window.open(
                    getGpsCoordinatesGoogleMapsUrl(gpsCoordinates),
                    isTestMode() ? '_self' : '_blank'
                );
            }
        } catch (err) {
            this.loggerService.error(
                LOGGER_NAME,
                `failed to open map with gps coordinates: ${JSON.stringify(
                    gpsCoordinates
                )}, error: ${err.message}`
            );
            this.sentryService.captureException(err, (scope) => {
                scope.setTag('service', LOGGER_NAME);
                scope.setExtra('gpsCoordinates', gpsCoordinates);
                return scope;
            });
            this.utilService.errorAlert('Failed to open map with gps coordinates');
        }
    }

    private getPlatformGpsCoordinatesUrl(gpsCoordinates: GpsCoordinates) {
        const gpsCoordinatesLabel = getGpsCoordinatesLabel(gpsCoordinates);
        const latLong = `${gpsCoordinates.latitude},${gpsCoordinates.longitude}`;
        if (this.platform.is('ios')) {
            return `maps:ll=${latLong}&q=${encodeURIComponent(gpsCoordinatesLabel)}`;
        } else if (this.platform.is('android')) {
            return `geo:${latLong}?q=${encodeURIComponent(
                `${latLong}(${gpsCoordinatesLabel})`
            )}&z=18`;
        } else {
            return null;
        }
    }
}
