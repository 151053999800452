import { Injectable } from '@angular/core';
import { filterNilValue, QueryEntity } from '@datorama/akita';
import {
    getInsiteUserName,
    InsiteUser,
    Plan,
    PlanOverview,
    PlanOwner,
    PlanRole,
    PlanSettings,
    PlanType,
    sortInsiteUserByName,
    UserInPlan,
} from '@insite-group-ltd/insite-teams-model';
import { differenceInDays, startOfDay } from 'date-fns';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import toNumber from 'lodash/toNumber';
import { combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AuthQuery } from '../auth/auth.query';
import { SystemQuery } from '../system/system.query';
import { InsiteUserQuery } from '../user/insite-user.query';
import { PlanState, PlanStore } from './plan.store';

@Injectable({ providedIn: 'root' })
export class PlanQuery extends QueryEntity<PlanState> {
    constructor(
        protected store: PlanStore,
        private authQuery: AuthQuery,
        private userQuery: InsiteUserQuery,
        private systemQuery: SystemQuery
    ) {
        super(store);
    }

    get isPlanAdmin$(): Observable<boolean> {
        return this.currentPlanRole$.pipe(map((planRole) => planRole === 'ADMIN'));
    }

    get customTerms$(): Observable<any> {
        return this.selectActive().pipe(
            filterNilValue(),
            map((plan) => plan?.settings?.translations)
        );
    }

    get currentPlanId$(): Observable<string> {
        return this.selectActiveId().pipe(filterNilValue(), distinctUntilChanged());
    }

    get isCurrentUserAdminOfAnyPlan$() {
        return this.currentUserPlansIdsAsAdmin$.pipe(
            distinctUntilChanged(isEqual),
            map((planIds) => !!planIds?.length)
        );
    }

    get currentUserPlansAsAdmin$() {
        return this.authQuery.userId$.pipe(
            switchMap((userId) =>
                this.selectAll({
                    filterBy: (plan) => get(plan, `users.${userId}`) === 'ADMIN',
                })
            )
        );
    }

    get currentUserPlansIdsAsAdmin$(): Observable<string[]> {
        return this.currentUserPlansAsAdmin$.pipe(
            map((plans) => plans.map((plan) => plan.id)),
            distinctUntilChanged(isEqual)
        );
    }

    get isUserOnAnyPlan$(): Observable<boolean> {
        return this.selectCount().pipe(map((count) => count > 0));
    }

    getIsUserOnAnyPlanOfType$(type: PlanType): Observable<boolean> {
        return this.selectCount((plan) => plan.type === type).pipe(map((count) => count > 0));
    }

    get currentUserNotDeletedPlansAsAdmin$(): Observable<Plan[]> {
        return this.authQuery.userId$.pipe(
            switchMap((userId) =>
                this.selectAll({
                    filterBy: (plan) =>
                        get(plan, `users.${userId}`) === 'ADMIN' && plan?.status !== 'deleted',
                })
            )
        );
    }

    get currentPlanCompany$(): Observable<string> {
        return this.selectActive((plan) => plan?.company);
    }

    get currentPlanSettings$(): Observable<PlanSettings> {
        return this.selectActive((plan) => plan?.settings);
    }

    get currentPlanUsers$(): Observable<Plan['users']> {
        return this.selectActive((plan) => plan?.users);
    }

    get currentPlanUserIds$(): Observable<string[]> {
        return this.selectActive((plan) => Object.keys(plan?.users || {})).pipe(
            filterNilValue(),
            distinctUntilChanged(isEqual)
        );
    }

    get currentPlanOwnerId$(): Observable<string> {
        return this.selectActive((plan) => plan?.createdBy).pipe(
            filterNilValue(),
            distinctUntilChanged()
        );
    }

    get currentPlanOwner$(): Observable<PlanOwner> {
        return this.selectActive((plan) => plan?.createdBy).pipe(
            switchMap((createdBy) => this.userQuery.selectEntity(createdBy)),
            map((owner: InsiteUser) => {
                if (owner) {
                    return { name: getInsiteUserName(owner), email: owner.email };
                } else {
                    return { name: '', email: '' };
                }
            })
        );
    }

    get currentPlanMaxProjectCount$(): Observable<number> {
        return this.selectActive((plan) => plan?.maxProjectCount);
    }

    get planUserCount$(): Observable<number> {
        return this.selectActive((plan) => plan).pipe(
            filterNilValue(),
            map((plan) => Object.keys(plan.users || {}).length + (plan.invitedUsers || []).length)
        );
    }

    get planAdminUserCount$(): Observable<number> {
        return this.selectActive((plan) => plan?.users).pipe(
            filterNilValue(),
            map((users) => Object.values(users).filter((role) => role === 'ADMIN').length)
        );
    }

    get planInternalUserCount$(): Observable<number> {
        return this.selectActive((plan) => plan?.users).pipe(
            filterNilValue(),
            map((users) => Object.values(users).filter((role) => role === 'INTERNAL').length)
        );
    }

    get planInvitedUserCount$(): Observable<number> {
        return this.selectActive((plan) => plan?.invitedUsers || []).pipe(
            map((invitedUsers) => invitedUsers?.length || 0)
        );
    }

    get currentPlanInvitedUsers$(): Observable<string[]> {
        return this.selectActive((plan) => plan?.invitedUsers || []);
    }

    get usersInPlan$(): Observable<UserInPlan[]> {
        return this.selectActive((plan) => plan?.users).pipe(
            switchMap((planUsers) => this.mapUsersInPlan(planUsers))
        );
    }

    getUsersInPlan$(planId: string): Observable<UserInPlan[]> {
        return this.selectEntity(planId, (plan) => plan?.users).pipe(
            switchMap((planUsers) => this.mapUsersInPlan(planUsers))
        );
    }

    getPlanType$(planId: string): Observable<PlanType> {
        return this.selectEntity(planId, (plan) => plan?.type);
    }

    getPlanCompany$(planId: string): Observable<string> {
        return this.selectEntity(planId, (plan) => plan?.company);
    }

    private mapUsersInPlan(planUsers: Plan['users']) {
        if (!planUsers) {
            return of([]);
        }
        return this.userQuery.selectMany(Object.keys(planUsers)).pipe(
            map((users) => {
                const usersInPlan = users.map((insiteUser) => {
                    return {
                        userId: insiteUser.id,
                        role: planUsers[insiteUser.id],
                        insiteUser,
                    };
                });
                return sortInsiteUserByName(usersInPlan);
            })
        );
    }

    get currentPlanSubscriptionName$() {
        return this.selectActive().pipe(
            withLatestFrom(this.systemQuery.allPlans$),
            map(([plan, allPlans]) => {
                if (plan) {
                    const subscriptionPlanId = plan.subscription_plan_id;
                    if (plan.subscriptionNameOverride) {
                        return plan.subscriptionNameOverride;
                    } else if (subscriptionPlanId) {
                        const subscriptionPlan = allPlans.find(
                            (plan) => plan.id === toNumber(subscriptionPlanId)
                        );
                        return subscriptionPlan?.name;
                    } else if (plan.status === 'trialing' || plan.type === 'ENTERPRISE') {
                        return 'Premium';
                    } else if (plan.type === 'TEAM') {
                        return 'Starter';
                    } else if (plan.type === 'BASIC') {
                        return 'Personal';
                    }
                }
                return undefined;
            })
        );
    }

    get currentPlanQuantityUnit$() {
        return this.selectActive().pipe(
            withLatestFrom(this.systemQuery.allPlans$),
            map(([plan, allPlans]) => {
                if (plan) {
                    const subscriptionPlanId = plan.subscription_plan_id;
                    const subscriptionPlan = allPlans.find(
                        (plan) => plan.id === toNumber(subscriptionPlanId)
                    );
                    return subscriptionPlan?.quantityUnit;
                } else {
                    return undefined;
                }
            })
        );
    }

    get planOverview$(): Observable<PlanOverview> {
        return combineLatest([this.selectActive(), this.currentPlanSubscriptionName$]).pipe(
            map(([plan, currentPlanSubscriptionName]) => {
                if (!plan) {
                    return {} as PlanOverview;
                }
                const remainingTrialDays = plan.trialEndDate
                    ? differenceInDays(
                          startOfDay(plan.trialEndDate.toDate()),
                          startOfDay(new Date())
                      )
                    : 0;
                const totalTrialDays =
                    plan?.trialEndDate?.toDate && plan?.createdAt?.toDate
                        ? differenceInDays(
                              startOfDay(plan.trialEndDate.toDate()),
                              startOfDay((plan.createdAt || new Date()).toDate())
                          )
                        : 0;
                const featureSet1 = [];
                const featureSet2 = [];
                const mobileAndDesktopAppsLabel = 'Mobile & desktop apps';
                if (plan.type === 'BASIC') {
                    featureSet1.push('Unlimited cloud storage', mobileAndDesktopAppsLabel);
                    featureSet2.push('Markup Tools', 'Unlimited projects');
                } else if (plan.type === 'TEAM') {
                    featureSet1.push('Collaboration', '5 users per project');
                    featureSet2.push('Unlimited cloud storage', mobileAndDesktopAppsLabel);
                } else if (plan.type === 'ENTERPRISE') {
                    featureSet1.push('Collaboration', 'External project users');
                    featureSet2.push('Unlimited users per project', mobileAndDesktopAppsLabel);
                }
                return {
                    name: currentPlanSubscriptionName,
                    type: plan.type,
                    status: plan.status,
                    billingWarning: plan.status === 'deleted' || plan.status === 'past_due',
                    remainingTrialDays,
                    trialPercentageComplete: plan.trialEndDate
                        ? 100 - (remainingTrialDays / totalTrialDays) * 100
                        : 100,
                    trialEndDate: plan.trialEndDate,
                    createdAt: plan.createdAt,
                    featureSet1,
                    featureSet2,
                };
            })
        );
    }

    get plansWithLogos$() {
        return this.selectAll({ filterBy: (plan) => !!plan.settings?.companyLogo });
    }

    get planIds$() {
        return this.selectAll().pipe(map((plans) => (plans || []).map((plan) => plan.id)));
    }

    private get currentPlanRole$(): Observable<PlanRole> {
        return combineLatest([this.authQuery.userId$, this.selectActive()]).pipe(
            map(([userId, plan]) => {
                if (plan) {
                    return plan.users[userId];
                } else {
                    return null;
                }
            })
        );
    }
}
