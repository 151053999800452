import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class AlertHeaderService {
    public minimised = false;

    toggleMinimised() {
        this.minimised = !this.minimised;
    }
}
