import { Component, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
    selector: 'lib-input-popover',
    templateUrl: './input-popover.component.html',
    styleUrls: ['./input-popover.component.scss'],
})
export class InputPopoverComponent {
    @Input() value: string;
    @Input() title: string;
    @Input() placeholder?: string;
    @Input() hint?: string;

    constructor(private popoverController: PopoverController) {}

    save() {
        this.popoverController.dismiss(this.value);
    }

    cancel() {
        this.popoverController.dismiss();
    }
}
