import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Component({
    selector: 'lib-status-indicator',
    templateUrl: './status-indicator.component.html',
    styleUrls: ['./status-indicator.component.scss'],
})
export class StatusIndicatorComponent {
    @Output() clicked = new EventEmitter<MouseEvent>();
    @Input() icon: string;
    @Input() colour: string;
    @Input() disabled: boolean;
    @Input() transparent = false;
    @Input() solid = false;
    @Input() padding = 'padding-3';
    @Input() fontSize = 'font-18';
    @Input() opacity = 'opacity-1';
    @Input() tippyContent: string | TemplateRef<any>;
    @Input() tippyTouch = true;
}
