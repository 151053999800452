import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { ImageUploadTask } from '@insite-group-ltd/insite-teams-model';
import { CollectionState } from 'akita-ng-fire';

export type ImageUploadTaskState = CollectionState<ImageUploadTask>;

@Injectable({ providedIn: 'root' })
@StoreConfig({
    name: 'image-uploads',
    idKey: 'id',
})
export class ImageUploadStore extends EntityStore<ImageUploadTaskState> {
    constructor() {
        super();
    }
}
