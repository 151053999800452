import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { InsiteUserPrivate } from '@insite-group-ltd/insite-teams-model';
import { CollectionState } from 'akita-ng-fire';

export type InsiteUserPrivateState = CollectionState<InsiteUserPrivate>;

@Injectable({ providedIn: 'root' })
@StoreConfig({
    name: 'users-private',
    idKey: 'id',
})
export class InsiteUserPrivateStore extends EntityStore<InsiteUserPrivateState> {
    constructor() {
        super();
    }
}
