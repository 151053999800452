import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { CollectionState } from 'akita-ng-fire';

export interface UserDevice {
    id: string;
    name: string;
    model: string;
    operatingSystem: string;
    osVersion: string;
    manufacturer: string;
    appVersion: string;
}

export type UserDeviceState = CollectionState<UserDevice>;

@Injectable({ providedIn: 'root' })
@StoreConfig({
    name: 'user-device',
    idKey: 'id',
})
export class UserDeviceStore extends EntityStore<UserDeviceState> {
    constructor() {
        super();
    }
}
