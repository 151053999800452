import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ChangePlanPage } from './change-plan.page';
import { RouterModule, Routes } from '@angular/router';
import { ComponentsModule } from '../components/components.module';

const routes: Routes = [
    {
        path: '',
        component: ChangePlanPage,
    },
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        ComponentsModule,
    ],
    declarations: [ChangePlanPage],
})
export class ChangePlanPageModule {}
