import forOwn from 'lodash/forOwn';
import pick from 'lodash/pick';
import { sortByNameHyphensLast, WithSortIndex } from './sort';
import { findById } from './util';

export type WithSelectOptions = Record<string, SelectOption[]>;

export type SortableSelectOption = SelectOption & WithSortIndex;

export interface SelectOption {
    id: string;
    name: string;
    colour?: string;
    icon?: string;
}

export interface SelectOptionColour {
    id: string;
    name: string;
    argbColour: string;
    pdfColour: string;
}

export type SelectOptions = SelectOption[];

type SelectOptionArrayOrObject<T extends SelectOption> = T[] | Record<string, T>;

export const NOOP_SORT = () => 0;

export function getSelectOptionIds<T extends SelectOption>(
    options: SelectOptionArrayOrObject<T>,
    sortFn?: (a: T, b: T) => number
) {
    return mapToSelectOptionArray(options, sortFn).map((option) => option.id);
}

export function getSelectOptionNames<T extends SelectOption>(
    options: SelectOptionArrayOrObject<T>
) {
    return mapToSelectOptionArray(options).map((option) => option.name);
}

export function getSelectOptionLabel<T extends SelectOption>(
    id: string,
    options: SelectOptionArrayOrObject<T>
) {
    return getSelectOptionById(id, options)?.name || '';
}

export function getSelectOptionById<T extends SelectOption>(
    id: string,
    options: SelectOptionArrayOrObject<T>
): T | undefined {
    return findById<T>(mapToSelectOptionArray<T>(options), id);
}

export function getSelectOptionByIdOrName<T extends SelectOption>(
    idOrName: string,
    options: SelectOptionArrayOrObject<T>
): T | undefined {
    return mapToSelectOptionArray<T>(options).find(
        (option) =>
            option.id === idOrName || option.name.trim().toLowerCase() === idOrName?.toLowerCase()
    );
}

export function mapToSelectOptionArray<T extends SelectOption>(
    object: SelectOptionArrayOrObject<T>,
    sortFn?: (a: T, b: T) => number
) {
    if (!object) {
        return [];
    } else if (Array.isArray(object)) {
        return object.sort(sortFn || sortByNameHyphensLast);
    } else {
        const mapped: T[] = [];
        forOwn(object, (value, key) => {
            mapped.push(mapToType<T>(key, value));
        });
        return mapped.sort(sortFn || sortByNameHyphensLast);
    }
}

export function mapArrayToSelectOptionObject<T extends SortableSelectOption>(
    selectOptions: T[]
): Record<string, T> {
    if (!selectOptions?.length) {
        return {};
    }
    const selectOptionObject: Record<string, T> = {};
    selectOptions.forEach((option: T, index: number) => {
        selectOptionObject[option.id] = {
            ...option,
            sortIndex: index,
        };
    });
    return selectOptionObject;
}

export function mapArrayToSortableSelectOptionObject<T extends SortableSelectOption>(
    selectOptions: T[]
): Record<string, T> {
    if (!selectOptions?.length) {
        return {};
    }
    const selectOptionObject: Record<string, T> = {};
    selectOptions.forEach((option: T, index: number) => {
        option.sortIndex = index;
        selectOptionObject[option.id] = option;
    });
    return selectOptionObject;
}

export function mapIdsToSelectOptions<T extends SelectOption>(
    ids: string[],
    selectOptions: T[]
): T[] {
    if (ids?.length && selectOptions?.length) {
        return ids
            .map((id) => selectOptions.find((option) => option.id === id) as T)
            .filter((option) => !!option)
            .sort(sortByNameHyphensLast);
    }
    return [];
}

function mapToType<T>(id: string, data: any): T {
    return { id, ...data };
}

export const YES_NO = {
    N: {
        id: 'N',
        name: 'No',
    },
    Y: {
        id: 'Y',
        name: 'Yes',
    },
};

export function asSelectOption<T extends SelectOption>(option: T) {
    return pick(option, ['id', 'name']);
}
