import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AuthService } from '../../state/auth/auth.service';

@Injectable({ providedIn: 'root' })
export class ApiService {
    constructor(
        @Inject('environment') private environment,
        private httpClient: HttpClient,
        private authService: AuthService
    ) {}

    public async post<T, R = unknown>(path: string, body: T): Promise<R> {
        return this.httpClient
            .post<R>(`${this.environment.apiBaseUrl}${path}`, body, {
                ...(await this.getHttpOptions()),
            })
            .toPromise();
    }

    private async getHttpOptions() {
        const user = this.authService.user;
        const token = await user.getIdToken();
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }),
        };
    }
}
