import { Injectable } from '@angular/core';
import { filterNilValue, QueryEntity } from '@datorama/akita';
import {
    BaseList,
    CurrentUsersProjectRole,
    DEFAULT_ITEM_FIELD_SET,
    DEFAULT_OPEN_LIST_ROLE_ITEM_ACCESS,
    DEFAULT_PROJECT_PERMISSIONS,
    DefaultItemAccessSetting,
    getCompanyLogoSource,
    getInsiteUserName,
    getItemFieldSet,
    getMergedItemFieldSet,
    getPlanProjectPermissions,
    getProjectInvitedUsers,
    getProjectPermission,
    getReportSettings,
    ItemFieldSet,
    keyPropertyById,
    ListVisibility,
    mapArrayToSelectOptionObject,
    mapToSelectOptionArray,
    Plan,
    PlanStatus,
    PlanType,
    Project,
    ProjectAssignee,
    ProjectLocation,
    ProjectLocations,
    ProjectPermissions,
    ProjectTag,
    SelectOption,
    sortByCustomIndex,
    sortInsiteUserByName,
    StatusGroup,
    UserInPlan,
    UserInProject,
} from '@insite-group-ltd/insite-teams-model';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import keys from 'lodash/keys';
import max from 'lodash/max';
import merge from 'lodash/merge';
import pickBy from 'lodash/pickBy';
import { combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { TranslationService } from '../../services/translation/translation.service';
import { AuthQuery } from '../auth/auth.query';
import { PlanQuery } from '../plan/plan.query';
import { InsiteUserPrivateQuery } from '../user/insite-user-private.query';
import { InsiteUserQuery } from '../user/insite-user.query';
import { ProjectState, ProjectStore } from './project.store';

@Injectable({ providedIn: 'root' })
export class ProjectQuery extends QueryEntity<ProjectState> {
    constructor(
        protected store: ProjectStore,
        private authQuery: AuthQuery,
        private planQuery: PlanQuery,
        private userQuery: InsiteUserQuery,
        private userPrivateQuery: InsiteUserPrivateQuery,
        private translationService: TranslationService
    ) {
        super(store);
    }

    get activeProjectBasicDetails$() {
        return this.selectActive().pipe(
            map((project) => {
                if (!project) {
                    return null;
                }
                return {
                    id: project.id,
                    name: project.name,
                };
            }),
            distinctUntilChanged(isEqual)
        );
    }

    isProjectActive$(projectId: string): Observable<boolean> {
        return this.getProject$(projectId).pipe(
            filterNilValue(),
            map((project) => project.status !== 'ARCHIVED')
        );
    }

    isProjectArchived$(projectId: string): Observable<boolean> {
        return this.getProject$(projectId).pipe(
            filterNilValue(),
            map((project) => project.status === 'ARCHIVED')
        );
    }

    isProjectPlanAdmin$(projectId: string): Observable<boolean> {
        return combineLatest([this.getProject$(projectId), this.authQuery.userId$]).pipe(
            filter(([project, userId]) => !!project && !!userId),
            switchMap(([project, userId]) =>
                this.planQuery
                    .selectEntity(project.planId)
                    .pipe(map((plan) => plan && plan.users[userId] === 'ADMIN'))
            )
        );
    }

    getProjectPlanStatus$(projectId: string): Observable<PlanStatus> {
        return this.getProject$(projectId).pipe(
            filterNilValue(),
            switchMap((project) => this.planQuery.selectEntity(project.planId)),
            map((plan) => plan?.status)
        );
    }

    canUserSeeAnyProjectAlertHeader$(projectId: string): Observable<boolean> {
        return combineLatest([
            this.isProjectArchived$(projectId),
            this.canUserSeeProjectPlanActionRequired$(projectId),
        ]).pipe(
            map(
                ([isProjectArchived, canUserSeeProjectPlanActionRequired]) =>
                    isProjectArchived || canUserSeeProjectPlanActionRequired
            )
        );
    }

    canUserSeeProjectPlanActionRequired$(projectId: string): Observable<boolean> {
        return combineLatest([
            this.getProjectPlanStatus$(projectId),
            this.getCurrentUsersProjectRole$(projectId),
        ]).pipe(
            map(
                ([planStatus, currentUserProjectRole]) =>
                    (planStatus === 'deleted' || planStatus === 'past_due') &&
                    currentUserProjectRole.isOnPlan
            )
        );
    }

    getProjectPlanId(projectId: string): Project['planId'] {
        return this.getEntity(projectId).planId;
    }

    selectProjectPlanId$(projectId: string): Observable<Project['planId']> {
        return this.selectEntity(projectId, (project) => project.planId);
    }

    isUserOnProjectPlan$(projectId: string): Observable<boolean> {
        return this.getCurrentUsersProjectRole$(projectId).pipe(
            map((role) => role.isOnPlan),
            distinctUntilChanged(isEqual)
        );
    }

    get activeProjectPlanType$(): Observable<PlanType> {
        return this.selectActiveId().pipe(
            switchMap((projectId) => {
                if (projectId) {
                    return this.getProjectPlanType$(projectId);
                } else {
                    return this.planQuery.selectActive((plan) => plan.type).pipe(filterNilValue());
                }
            })
        );
    }

    getProjectPlanType$(projectId: string): Observable<PlanType> {
        return this.isUserOnProjectPlan$(projectId).pipe(
            switchMap((isUserOnProjectPlan) => {
                if (isUserOnProjectPlan) {
                    return this.getProjectPlanId$(projectId).pipe(
                        switchMap((planId) => this.planQuery.getPlanType$(planId))
                    );
                } else {
                    return of('ENTERPRISE' as PlanType);
                }
            }),
            distinctUntilChanged(isEqual)
        );
    }

    getProjectPlanDefaultItemAccessSettings$(
        projectId: string
    ): Observable<DefaultItemAccessSetting> {
        return this.isUserOnProjectPlan$(projectId).pipe(
            switchMap((isUserOnProjectPlan) => {
                if (isUserOnProjectPlan) {
                    return this.getProject$(projectId).pipe(
                        switchMap((project) =>
                            this.getProjectPlan$(projectId).pipe(
                                map((plan) =>
                                    getProjectPermission(
                                        plan.settings,
                                        project.permissions,
                                        'defaultItemAccess'
                                    )
                                )
                            )
                        )
                    );
                } else {
                    return of(DEFAULT_OPEN_LIST_ROLE_ITEM_ACCESS);
                }
            }),

            distinctUntilChanged(isEqual)
        );
    }

    getProjectPlanListPrivacy$(projectId: string): Observable<ListVisibility> {
        return this.isUserOnProjectPlan$(projectId).pipe(
            switchMap((isUserOnProjectPlan) => {
                if (isUserOnProjectPlan) {
                    return this.getProject$(projectId).pipe(
                        switchMap((project) =>
                            this.getProjectPlan$(projectId).pipe(
                                map((plan) =>
                                    getProjectPermission(
                                        plan.settings,
                                        project.permissions,
                                        'listPrivacy'
                                    )
                                )
                            )
                        )
                    );
                } else {
                    return of('OPEN');
                }
            }),
            distinctUntilChanged(isEqual)
        );
    }

    getProjectPlanId$(projectId: string): Observable<string> {
        return this.getProject$(projectId).pipe(
            filterNilValue(),
            map((project) => project.planId),
            filterNilValue(),
            distinctUntilChanged()
        );
    }

    getProjectPlan$(projectId: string): Observable<Plan> {
        return this.getProject$(projectId).pipe(
            filterNilValue(),
            switchMap((project) => this.planQuery.selectEntity(project.planId)),
            filterNilValue()
        );
    }

    getProjectPlanMaxUserCount$(projectId: string): Observable<Plan['maxUserCount']> {
        return this.getProjectPlan$(projectId).pipe(map((plan) => plan?.maxUserCount));
    }

    getProjectPlanMaxUserCountBreakdown$(
        projectId: string
    ): Observable<Plan['maxUserCountBreakdown']> {
        return this.getProjectPlan$(projectId).pipe(map((plan) => plan?.maxUserCountBreakdown));
    }

    getUsersInProjectPlan$(projectId: string): Observable<UserInPlan[]> {
        return this.getProject$(projectId).pipe(
            filterNilValue(),
            switchMap((project) => this.planQuery.getUsersInPlan$(project.planId)),
            filterNilValue()
        );
    }

    getActiveProjectName$(projectId: string): Observable<string> {
        return this.selectActiveId().pipe(
            filter((activeId) => activeId === projectId),
            switchMap(() => this.selectActive((project) => project.name))
        );
    }

    getProjectName$(projectId: string): Observable<string> {
        return this.selectEntity(projectId, (project) => project.name);
    }

    getProjectNames$(projectIds: string[]): Observable<string[]> {
        return this.selectMany(projectIds).pipe(
            map((projects) => projects.map((project) => project.name))
        );
    }

    getProjectNameOptions$(projectIds: string[]): Observable<SelectOption[]> {
        return this.selectMany(projectIds).pipe(
            map((projects) =>
                projects.map((project) => {
                    return {
                        id: project.id,
                        name: project.name,
                    };
                })
            )
        );
    }

    getCurrentUsersProjectRole$(projectId: string): Observable<CurrentUsersProjectRole> {
        return this.authQuery.userId$.pipe(
            switchMap((userId) =>
                this.selectActiveId().pipe(
                    filter((activeId) => activeId === projectId),
                    switchMap(() => this.selectActive()),
                    filterNilValue(),
                    map((project) => new CurrentUsersProjectRole(userId, project))
                )
            ),
            distinctUntilChanged(isEqual)
        );
    }

    isProjectAdmin$(projectId: string): Observable<boolean> {
        return this.getCurrentUsersProjectRole$(projectId).pipe(
            map((projectRole) => projectRole.isAdmin)
        );
    }

    isProjectAdmin(projectId: string): Promise<boolean> {
        return this.getCurrentUsersProjectRole$(projectId)
            .pipe(
                map((projectRole) => projectRole.isAdmin),
                take(1)
            )
            .toPromise();
    }

    getAssignees$(projectId: string): Observable<ProjectAssignee[]> {
        return this.getProject$(projectId).pipe(
            filterNilValue(),
            map((project) => mapToSelectOptionArray<ProjectAssignee>(project.assignees)),
            distinctUntilChanged(isEqual)
        );
    }

    getAssigneeNamesById$(
        projectId: string
    ): Observable<Record<ProjectAssignee['id'], ProjectAssignee['name']>> {
        return this.getAssignees$(projectId).pipe(map((lists) => keyPropertyById(lists, 'name')));
    }

    getTranslations$(projectId: string): Observable<any> {
        return this.getProject$(projectId).pipe(
            filterNilValue(),
            map((project) => project.translations)
        );
    }

    getLocations$(projectId: string): Observable<ProjectLocation[]> {
        return this.getProject$(projectId).pipe(
            filterNilValue(),
            map((project) => mapToSelectOptionArray<ProjectLocation>(cloneDeep(project.locations))),
            distinctUntilChanged(isEqual)
        );
    }

    getLocationsWithSortIndex$(projectId: string): Observable<ProjectLocations> {
        return this.getLocations$(projectId).pipe(
            map((projectLocations) => {
                projectLocations.sort(sortByCustomIndex);
                projectLocations.forEach((location, index) => (location.sortIndex = index));
                return mapArrayToSelectOptionObject(projectLocations);
            })
        );
    }

    getLocationsNextSortIndex$(projectId: string): Observable<number | undefined> {
        return this.getLocations$(projectId).pipe(
            map((locations) => locations?.map((location) => location.sortIndex) || []),
            map((locationSortIndexes) =>
                max(locationSortIndexes.filter((sortIndex) => !isNil(sortIndex)))
            ),
            map((currentLocationSortIndex) =>
                !isNil(currentLocationSortIndex) ? currentLocationSortIndex + 1 : undefined
            )
        );
    }

    getTags$(projectId: string): Observable<ProjectTag[]> {
        return this.getProject$(projectId).pipe(
            filterNilValue(),
            map((project) => mapToSelectOptionArray<ProjectTag>(project.tags)),
            distinctUntilChanged(isEqual)
        );
    }

    getUsersInProject$(projectId: string): Observable<UserInProject[]> {
        return this.getProject$(projectId).pipe(
            filterNilValue(),
            map((project) => project.users),
            switchMap((projectUsers) => {
                if (!projectUsers) {
                    return of([]);
                }
                const projectUserIds = Object.keys(projectUsers);
                return this.userQuery.selectMany(projectUserIds).pipe(
                    filter((users) => users.length === projectUserIds.length),
                    map((users) => {
                        const usersInProject = users.map((insiteUser) => {
                            return {
                                userId: insiteUser.id,
                                role: projectUsers[insiteUser.id],
                                insiteUser,
                            };
                        });
                        return sortInsiteUserByName(usersInProject);
                    })
                );
            })
        );
    }

    getProjectAdminCount$(projectId: string) {
        return this.getUsersInProject$(projectId).pipe(
            map((users) => users.filter((projectUser) => projectUser.role === 'ADMIN').length)
        );
    }

    isUserOnProject$(projectId: string, userId: string): Observable<boolean> {
        return this.getUsersInProject$(projectId).pipe(
            map((projectUsers) => projectUsers.some((projectUser) => projectUser.userId === userId))
        );
    }

    getUsersInProjectAsSelectOption$(projectId: string): Observable<SelectOption[]> {
        return this.getUsersInProject$(projectId).pipe(
            map((users) => {
                return users.map((user) => {
                    return {
                        id: user.userId,
                        name: getInsiteUserName(user.insiteUser),
                    };
                });
            })
        );
    }

    getUserInProject$(projectId: string, userId: string): Observable<UserInProject> {
        return this.getProject$(projectId).pipe(
            filterNilValue(),
            map((project) => project.users[userId]),
            switchMap((userProjectRole) => {
                return this.userQuery.selectEntity(userId).pipe(
                    filterNilValue(),
                    map((insiteUser) => {
                        return {
                            userId: insiteUser.id,
                            role: userProjectRole,
                            insiteUser,
                        };
                    })
                );
            })
        );
    }

    getProjectUserCount$(projectId: string): Observable<number> {
        return this.getProject$(projectId).pipe(
            filterNilValue(),
            map((project) => Object.keys(project.users).length)
        );
    }

    getProject$(projectId: string): Observable<Project> {
        return this.selectActiveId().pipe(
            filter((activeId) => activeId === projectId),
            switchMap(() => this.selectActive()),
            filterNilValue(),
            map((project) => cloneDeep(project))
        );
    }

    getProjectPermissions$(projectId: string): Observable<ProjectPermissions> {
        return combineLatest([this.getProject$(projectId), this.getProjectPlan$(projectId)]).pipe(
            map(([project, plan]) =>
                merge(
                    {},
                    DEFAULT_PROJECT_PERMISSIONS,
                    getPlanProjectPermissions(plan?.settings),
                    project?.permissions
                )
            )
        );
    }

    getInvitedProjectUsers$(projectId: string): Observable<string[]> {
        return this.selectActiveId().pipe(
            filter((activeId) => activeId === projectId),
            switchMap(() => this.selectActive((project) => getProjectInvitedUsers(project)))
        );
    }

    getLocation$(projectId: string, locationId: string): Observable<ProjectLocation> {
        return this.selectActiveId().pipe(
            filter((activeId) => activeId === projectId),
            switchMap(() => this.selectActive((project) => project.locations)),
            filterNilValue(),
            map((locations) => cloneDeep(locations[locationId]))
        );
    }

    getAssignee$(projectId: string, assigneesId: string): Observable<ProjectAssignee> {
        return this.selectActiveId().pipe(
            filter((activeId) => activeId === projectId),
            switchMap(() => this.selectActive((project) => project.assignees)),
            filterNilValue(),
            map((assignees) => cloneDeep(assignees[assigneesId]))
        );
    }

    getStatusGroups$(projectId: string): Observable<StatusGroup[]> {
        return this.selectActiveId().pipe(
            filter((activeId) => activeId === projectId),
            switchMap(() => this.selectActive((project) => project.statusGroups)),
            map((statusGroups) => cloneDeep(statusGroups || []))
        );
    }

    get selectAllProjectsWithTemplates(): Observable<Project[]> {
        return this.selectAll().pipe(
            map((projects) => projects.filter((project) => project.listTemplateCount))
        );
    }

    getProjectReportColour$(projectId: string): Observable<string> {
        return this.isUserOnProjectPlan$(projectId).pipe(
            withLatestFrom(this.userPrivateQuery.userPrivate$),
            switchMap(([isUserOnPlan, userPrivate]) => {
                if (isUserOnPlan) {
                    return this.getProjectPlan$(projectId).pipe(
                        map((plan) => getReportSettings(userPrivate, isUserOnPlan, plan))
                    );
                } else {
                    return of(getReportSettings(userPrivate, isUserOnPlan));
                }
            }),
            map((mergedReportSettings) => mergedReportSettings.reportColour)
        );
    }

    getProjectCompanyLogoUrl$(projectId: string): Observable<string> {
        return this.isUserOnProjectPlan$(projectId).pipe(
            withLatestFrom(this.userPrivateQuery.userPrivate$),
            switchMap(([isUserOnPlan, userPrivate]) => {
                if (isUserOnPlan) {
                    return this.getProjectPlan$(projectId).pipe(
                        map((plan) => getCompanyLogoSource(userPrivate, isUserOnPlan, plan))
                    );
                } else {
                    return of(getCompanyLogoSource(userPrivate, isUserOnPlan));
                }
            }),
            map((companyLogo) => companyLogo?.url)
        );
    }

    getNonReadOnlyProjectUserCount$(projectId: string) {
        return this.getProject$(projectId).pipe(
            map((project) => keys(pickBy(project.users, (value) => value !== 'READ_ONLY')).length)
        );
    }

    getInternalProjectUserCount$(projectId: string) {
        return this.getProject$(projectId).pipe(
            map(
                (project) =>
                    keys(
                        pickBy(project.users, (value) => value === 'ADMIN' || value === 'INTERNAL')
                    ).length
            )
        );
    }

    getExternalProjectUserCount$(projectId: string) {
        return this.getProject$(projectId).pipe(
            map((project) => keys(pickBy(project.users, (value) => value === 'EXTERNAL')).length)
        );
    }

    getItemFieldSets$(projectId: string): Observable<Record<string, ItemFieldSet>> {
        return this.getProject$(projectId).pipe(
            map((project) => {
                const itemFieldSets = project.itemFieldSets || {};
                return {
                    [DEFAULT_ITEM_FIELD_SET.id]: DEFAULT_ITEM_FIELD_SET,
                    ...itemFieldSets,
                };
            }),
            distinctUntilChanged(isEqual)
        );
    }

    getItemFieldSetsArray$(projectId: string): Observable<ItemFieldSet[]> {
        return this.getProject$(projectId).pipe(
            map((project) => mapToSelectOptionArray(project.itemFieldSets)),
            map((itemFieldSets) => {
                const customItemFieldSets = (itemFieldSets || []).map((itemFieldSet) =>
                    merge(cloneDeep(DEFAULT_ITEM_FIELD_SET), itemFieldSet || {})
                );
                return [DEFAULT_ITEM_FIELD_SET, ...customItemFieldSets];
            })
        );
    }

    getItemFieldSet$(projectId: string, list: BaseList): Observable<ItemFieldSet> {
        return this.getProject$(projectId).pipe(map((project) => getItemFieldSet(project, list)));
    }

    getMergedItemFieldSet$(projectId: string, lists: BaseList[]): Observable<ItemFieldSet> {
        return this.getProject$(projectId).pipe(
            map((project) => getMergedItemFieldSet(project, lists))
        );
    }

    get projectTree$() {
        return this.selectActiveId().pipe(
            switchMap((projectId) => {
                return this.getCurrentUsersProjectRole$(projectId).pipe(
                    switchMap((currentUsersProjectRole) => {
                        if (!currentUsersProjectRole.isOnPlan) {
                            return of([
                                {
                                    title: 'Lists',
                                    url: '/list',
                                },
                                {
                                    title: 'Collaborators',
                                    url: '/users',
                                },
                                {
                                    title: this.translationService.get('item.assignees'),
                                    url: '/assignees',
                                },
                                {
                                    title: this.translationService.get('item.locations'),
                                    url: '/locations',
                                },
                            ]);
                        } else {
                            return this.getProjectPlanType$(projectId).pipe(
                                map((planType) => {
                                    return [
                                        {
                                            title: 'Lists',
                                            url: '/list',
                                        },
                                        {
                                            title: 'Templates',
                                            url: '/template',
                                        },
                                        planType !== 'BASIC'
                                            ? {
                                                  title: 'Collaborators',
                                                  url: '/users',
                                              }
                                            : null,
                                        {
                                            title: this.translationService.get('item.assignees'),
                                            url: '/assignees',
                                        },
                                        {
                                            title: this.translationService.get('item.locations'),
                                            url: '/locations',
                                        },
                                        {
                                            title: 'Project settings',
                                            url: '/settings',
                                        },
                                    ].filter((page) => !!page);
                                })
                            );
                        }
                    })
                );
            })
        );
    }
}
