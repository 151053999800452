import { Capacitor } from '@capacitor/core';
import { IonInput, IonSearchbar } from '@ionic/angular';
import { isTestMode } from './test';

export function focusField(inputToFocus: IonInput | IonSearchbar, allowMobile = false) {
    if (isTestMode()) {
        // eslint-disable-next-line no-console
        console.info(`skipping input focus as app running in test mode`);
        return;
    }
    if (inputToFocus && (allowMobile || !Capacitor.isNativePlatform())) {
        try {
            setTimeout(() => inputToFocus.setFocus(), 100);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.warn('failed to focus input', inputToFocus);
        }
    }
}
