import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { arrayUnion } from '@angular/fire/firestore';
import { filterNilValue } from '@datorama/akita';
import {
    FeatureConfig,
    InsiteUserPrivate,
    InsiteUserWarningMessage,
    ItemFilterProfile,
    PinturaEditorState,
    SystemWhatsNewDetail,
} from '@insite-group-ltd/insite-teams-model';
import { CollectionConfig } from 'akita-ng-fire';
import isError from 'lodash/isError';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { gt } from 'semver';
import { AuditedService } from '../../services/audited/audited.service';
import { FeatureService } from '../../services/feature/feature.service';
import { SentryService } from '../../services/sentry/sentry.service';
import { TelemetryService } from '../../services/telemetry/telemetry.service';
import {
    BaseCollectionService,
    UserIdPathParam,
} from '../collection-service/base-collection.service';
import { InsiteUserPrivateQuery } from './insite-user-private.query';
import { InsiteUserPrivateState, InsiteUserPrivateStore } from './insite-user-private.store';

const COLLECTION_PATH = 'users/:userId/private';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: COLLECTION_PATH, idKey: 'id' })
export class InsiteUserPrivateService extends BaseCollectionService<
    InsiteUserPrivateState,
    InsiteUserPrivateStore,
    UserIdPathParam
> {
    constructor(
        store: InsiteUserPrivateStore,
        private insiteUserPrivateQuery: InsiteUserPrivateQuery,
        protected auditedService: AuditedService,
        protected telemetryService: TelemetryService,
        private httpClient: HttpClient,
        private sentryService: SentryService,
        private featureService: FeatureService
    ) {
        super(COLLECTION_PATH, store, auditedService, telemetryService);
        this.resolveUserCountryCode();
    }

    get path() {
        const userId = this.insiteUserPrivateQuery.getActiveId();
        return `users/${userId}/private`;
    }

    get userPrivateFromServer$(): Observable<InsiteUserPrivate> {
        return this.insiteUserPrivateQuery.selectActiveId().pipe(
            filterNilValue(),
            switchMap((userId) => this.getDocDataFromServer(`users/${userId}/private/${userId}`)),
            filterNilValue()
        );
    }

    async resolveUserCountryCode() {
        const resolveUserCountryCode = await this.featureService.isFeatureEnabled(
            'resolveUserCountryCode'
        );
        if (resolveUserCountryCode) {
            this.insiteUserPrivateQuery.userPrivateFromId$
                .pipe(filter((insiteUserPrivate) => !insiteUserPrivate.countryCode))
                .subscribe(() => {
                    this.httpClient
                        .get('https://ipapi.co/country/', { responseType: 'text' })
                        .subscribe(
                            (countryCode) => {
                                this.update(
                                    this.insiteUserPrivateQuery.getActiveId(),
                                    {
                                        countryCode,
                                    },
                                    this.getWriteOptions({
                                        userId: this.insiteUserPrivateQuery.getActiveId(),
                                    })
                                );
                            },
                            (err: HttpErrorResponse) => {
                                this.sentryService.captureException(
                                    isError(err.error)
                                        ? err.error
                                        : new Error(
                                              `status code ${err.status}, body: ${err.error}`
                                          ),
                                    {
                                        extra: {
                                            userId: this.insiteUserPrivateQuery.getActiveId(),
                                        },
                                    }
                                );
                            }
                        );
                });
        }
    }

    saveItemFilterProfile(listId: string, itemFilterProfile: ItemFilterProfile) {
        return this.update(
            this.insiteUserPrivateQuery.getActiveId(),
            {
                [`savedListFilters.${listId}`]: itemFilterProfile,
            },
            this.getWriteOptions({ userId: this.insiteUserPrivateQuery.getActiveId() })
        );
    }

    clearItemFilterProfile(listId: string) {
        return this.removeField(
            this.insiteUserPrivateQuery.getActiveId(),
            `savedListFilters.${listId}`,
            this.getWriteOptions({ userId: this.insiteUserPrivateQuery.getActiveId() })
        );
    }

    completeUserGuideStep(stepId: string) {
        return this.update(
            this.insiteUserPrivateQuery.getActiveId(),
            {
                completedUserGuideSteps: arrayUnion(stepId),
            } as any,
            this.getWriteOptions({ userId: this.insiteUserPrivateQuery.getActiveId() })
        );
    }

    hideWarningMessage(warningMessage: InsiteUserWarningMessage) {
        return this.update(
            this.insiteUserPrivateQuery.getActiveId(),
            {
                [`warningMessage.${warningMessage}`]: true,
            },
            this.getWriteOptions({ userId: this.insiteUserPrivateQuery.getActiveId() })
        );
    }

    updatePinturaImageEditorState(pinturaImageEditorState: PinturaEditorState) {
        return this.update(
            this.insiteUserPrivateQuery.getActiveId(),
            {
                pinturaImageEditorState,
            },
            this.getWriteOptions({ userId: this.insiteUserPrivateQuery.getActiveId() })
        );
    }

    async updateViewedWhatsNewVersion(whatsNewDetail: SystemWhatsNewDetail) {
        if (whatsNewDetail) {
            const { viewedWhatsNewVersion } = await this.insiteUserPrivateQuery.userPrivate;
            if (!viewedWhatsNewVersion || gt(whatsNewDetail.version, viewedWhatsNewVersion)) {
                this.updateField(
                    this.insiteUserPrivateQuery.getActiveId(),
                    'viewedWhatsNewVersion',
                    whatsNewDetail.version,
                    this.getWriteOptions({ userId: this.insiteUserPrivateQuery.getActiveId() })
                );
            }
        }
    }

    updateFeatureConfig<T extends keyof FeatureConfig>(configKey: T, value: FeatureConfig[T]) {
        return this.update(
            this.insiteUserPrivateQuery.getActiveId(),
            {
                featureConfig: {
                    [configKey]: value,
                },
            } as any,
            this.getWriteOptions({ userId: this.insiteUserPrivateQuery.getActiveId() })
        );
    }
}
