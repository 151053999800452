import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { FeatureConfig, FeatureFlags, Features } from '@insite-group-ltd/insite-teams-model';
import isEqual from 'lodash/isEqual';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { ProjectQuery } from '../../projects/project.query';
import { PlanQuery } from '../plan.query';
import { PlanFeaturesState, PlanFeaturesStore } from './plan-features.store';

@Injectable({ providedIn: 'root' })
export class PlanFeaturesQuery extends QueryEntity<PlanFeaturesState> {
    constructor(
        protected store: PlanFeaturesStore,
        private projectQuery: ProjectQuery,
        private planQuery: PlanQuery
    ) {
        super(store);
    }

    get activePlanFeatureFlags$(): Observable<FeatureFlags> {
        return this.activePlanFeatures$
            .pipe(map((planFeatures) => planFeatures?.featureFlags || {}))
            .pipe(distinctUntilChanged(isEqual));
    }

    get activePlanFeatureAccess$(): Observable<Features['featureAccess']> {
        return this.activePlanFeatures$
            .pipe(map((planFeatures) => planFeatures?.featureAccess || {}))
            .pipe(distinctUntilChanged(isEqual));
    }

    get activePlanFeatureConfig$(): Observable<FeatureConfig> {
        return this.activePlanFeatures$
            .pipe(map((planFeatures) => planFeatures?.featureConfig || {}))
            .pipe(distinctUntilChanged(isEqual));
    }

    private get activePlanFeatures$(): Observable<Features> {
        return this.projectQuery
            .selectActive((project) => project?.planId)
            .pipe(
                switchMap((planId) => (planId ? of(planId) : this.planQuery.selectActiveId())),
                distinctUntilChanged(),
                switchMap((planId) => (planId ? this.selectEntity(planId) : of(null)))
            );
    }
}
