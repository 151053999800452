import { Component, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

export interface PopoverOption {
    text: string;
    handler?: (ctx?: any) => void;
    icon?: string;
    selected?: boolean;
    disabled?: boolean;
    subtitle?: string;
    includeOptionIf?: boolean;
}

@Component({
    selector: 'lib-select-popover',
    templateUrl: './select-popover.component.html',
    styleUrls: ['./select-popover.component.scss'],
})
export class SelectPopoverComponent {
    @Input() options: PopoverOption[];
    @Input() title: string;
    @Input() translateLabels: boolean;
    @Input() selectedIcon: 'start' | 'end' = 'start';
    @Input() ctx: Record<string, unknown>;

    constructor(private popoverController: PopoverController) {}

    optionFunction(fn: (ctx?) => void) {
        fn(this.ctx);
        this.popoverController.dismiss();
    }
}
