import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { filterNilValue, QueryEntity } from '@datorama/akita';
import {
    AppVersion,
    FeatureAccess,
    FeatureConfig,
    FeatureFlags,
    FileBlacklistConfig,
    NotificationOption,
    NotificationOptionGroup,
    NotificationOptions,
    SaasAgreement,
    SelectOption,
    SubscriptionPlans,
    SystemWhatsNew,
    SystemWhatsNewDetail,
    Terms,
    Trial,
} from '@insite-group-ltd/insite-teams-model';
import { Platform } from '@ionic/angular';
import { sort } from 'fast-sort';
import flatten from 'lodash/flatten';
import isEqual from 'lodash/isEqual';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { gt, satisfies } from 'semver';
import { SystemState, SystemStore } from './system.store';

const ANDROID_APP_URL = 'https://play.google.com/store/apps/details?id=uk.co.insite.team';
const IOS_APP_URL = 'https://apps.apple.com/is/app/insite-teams/id1492151560';

@Injectable({ providedIn: 'root' })
export class SystemQuery extends QueryEntity<SystemState> {
    constructor(protected store: SystemStore, private platform: Platform) {
        super(store);
    }

    get terms(): Observable<string> {
        return this.selectEntity('terms').pipe(
            filterNilValue(),
            map((data) => (data as Terms).data)
        );
    }

    get saasAgreement(): Observable<string> {
        return this.selectEntity('saasAgreement').pipe(
            filterNilValue(),
            map((data) => (data as SaasAgreement).data)
        );
    }

    get appVersion(): Observable<AppVersion> {
        return this.selectEntity('version').pipe(
            filterNilValue(),
            map((data) => data as AppVersion)
        );
    }

    get notifications(): Observable<NotificationOptionGroup[]> {
        return this.selectEntity('notifications').pipe(
            filterNilValue(),
            map((data) => (data as NotificationOptions).data)
        );
    }

    get notificationTypeOptions$(): Observable<SelectOption[]> {
        return this.selectEntity('notifications').pipe(
            filterNilValue(),
            map((data) => (data as NotificationOptions).data.map((groups) => groups.options)),
            map((options: NotificationOption[][]) => flatten(options)),
            map((options: NotificationOption[]) =>
                options.map((option) => {
                    return { id: option.type, name: option.description };
                })
            )
        );
    }

    get trialLength$(): Observable<number> {
        return this.selectEntity('trial').pipe(
            filterNilValue(),
            map((data) => (data as Trial).trialLength)
        );
    }

    get activePlans$(): Observable<SubscriptionPlans['activePlans']> {
        return this.selectEntity('plans').pipe(
            filterNilValue(),
            map((data) => (data as SubscriptionPlans)?.activePlans)
        );
    }

    get allPlans$(): Observable<SubscriptionPlans['allPlans']> {
        return this.selectEntity('plans').pipe(
            filterNilValue(),
            map((data) => (data as SubscriptionPlans)?.allPlans)
        );
    }

    get featureFlags$(): Observable<FeatureFlags> {
        return this.selectEntity('featureFlags').pipe(
            map((featureFlags) => featureFlags || {}),
            distinctUntilChanged(isEqual)
        ) as Observable<FeatureFlags>;
    }

    get featureAccess$(): Observable<FeatureAccess> {
        return this.selectEntity('featureAccess').pipe(
            map((featureAccess) => featureAccess || {}),
            distinctUntilChanged(isEqual)
        ) as Observable<FeatureAccess>;
    }

    get featureConfig$(): Observable<FeatureConfig> {
        return this.selectEntity('featureConfig').pipe(
            map((featureConfig) => featureConfig || {}),
            distinctUntilChanged(isEqual)
        ) as Observable<FeatureConfig>;
    }

    get appStoreUrl() {
        if (Capacitor.isNativePlatform()) {
            const isAndroid = this.platform.is('android');
            if (isAndroid) {
                return ANDROID_APP_URL;
            } else {
                return IOS_APP_URL;
            }
        } else {
            return null;
        }
    }

    get whatsNewDetails$(): Observable<SystemWhatsNewDetail[]> {
        return this.selectEntity('whatsNew').pipe(
            filterNilValue(),
            map((data) => (data as SystemWhatsNew)?.data || []),
            map((details) => sort(details).desc('version'))
        );
    }

    get fileBlacklistPattern(): Observable<string> {
        return this.selectEntity('fileBlacklist').pipe(
            filterNilValue(),
            map((data) => (data as FileBlacklistConfig).blacklistPattern)
        );
    }

    getWhatsNewDetail$(version: string): Observable<SystemWhatsNewDetail> {
        return this.whatsNewDetails$.pipe(
            map((details) => details.find((detail) => satisfies(version, detail.versionMatcher)))
        );
    }

    isUpdateAvailable(targetVersion: string, versionKey: keyof AppVersion = 'teamsVersion') {
        return this.appVersion.pipe(
            filter(
                (appVersion) => appVersion[versionKey] && gt(appVersion[versionKey], targetVersion)
            )
        );
    }
}
