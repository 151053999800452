import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { Image } from '@insite-group-ltd/insite-teams-model';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { FeatureService } from '../../services/feature/feature.service';
import { ImageService } from '../../services/image/image.service';
import { UtilService } from '../../services/util/util.service';

@Component({
    selector: 'lib-image-uploader',
    templateUrl: './image-uploader.component.html',
    styleUrls: ['./image-uploader.component.scss'],
})
export class ImageUploaderComponent implements OnInit {
    @Input() readOnly = false;
    @Input() multipleAllowed: boolean;
    @Input() round: boolean;
    @Input() width: string;
    @Input() height: string;
    @Input() borderRadius: string;
    @Output() uploadedImages = new EventEmitter<Image[]>();
    @ViewChild('fileInput') fileInput: ElementRef;
    maxImageUploadSizeInMB: number;
    isHovering: boolean;
    files: File[] = [];
    result: string;
    acceptedType = 'image/png,image/jpg,image/jpeg';

    constructor(
        private utilService: UtilService,
        private analyticsService: AnalyticsService,
        private imageService: ImageService,
        private featureService: FeatureService
    ) {}

    async ngOnInit() {
        this.maxImageUploadSizeInMB = await this.featureService.getFeatureConfig(
            'maxImageUploadSizeInMB'
        );
    }

    toggleHover(event: boolean) {
        this.isHovering = event;
    }

    async onDrop(files: FileList) {
        this.uploadedImages.emit(await this.imageService.fromFileList(files));
        this.analyticsService.event('uploader_on_drop', { fileCount: files ? files.length : 0 });
    }

    async chooseDesktopImages(event) {
        for (const file of event.target.files) {
            if (file.size > this.maxImageUploadSizeInMB * 1024 * 1024) {
                await this.utilService.messageAlert(
                    'Image size exceeds maximum!',
                    `Images have a maximum size of ${this.maxImageUploadSizeInMB}MB`
                );
                return;
            }
        }
        this.uploadedImages.emit(await this.imageService.fromFileList(event.target.files));
        this.analyticsService.event('uploader_on_choose', {
            fileCount: event?.target?.files ? event.target.files.length : 0,
        });
        event.target.value = null;
    }

    public openFileUploader() {
        this.fileInput.nativeElement.click();
    }
}
