<div class="modal-content overflow-scroll-y">
    <ion-grid class="padding-10">
        <ion-row>
            <ion-col sizeMd="" sizeXs="12" class="display-flex ion-justify-content-center">
                <div class="form-container">
                    <app-enterprise-enquiry
                        (requestSent)="close(true, $event)"
                        [showHeader]="true"
                        [planOption]="planOption$ | async"
                    ></app-enterprise-enquiry>
                </div>
            </ion-col>
            <ion-col sizeMd="5" sizeXs="12" class="show-tablet plan-overview-container">
                <app-plan-overview
                    [wrapMedium]="false"
                    [noShadow]="true"
                    [planOption]="planOption$ | async"
                ></app-plan-overview>
            </ion-col>
        </ion-row>
    </ion-grid>
    <ion-button
        *ngIf="showMobileCloseButton"
        (click)="close(false)"
        class="close-modal"
        fill="clear">
        <ion-icon name="close-sharp" color="admin-dark" slot="icon-only"></ion-icon>
    </ion-button>
</div>
