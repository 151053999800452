import { Pipe, PipeTransform } from '@angular/core';
import {
    getProjectRoleDetails,
    ProjectRole,
    ProjectRoleDetails,
} from '@insite-group-ltd/insite-teams-model';

@Pipe({ name: 'projectRoleDetail' })
export class ProjectRoleDetail implements PipeTransform {
    transform(projectRole: ProjectRole): ProjectRoleDetails {
        return getProjectRoleDetails(projectRole);
    }
}
