import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
    SharedComponentsModule,
    SharedDirectivesModule,
    SharedPipesModule,
} from '@insite-group-ltd/insite-teams-shared';
import { IonicModule } from '@ionic/angular';
import { TippyModule } from '@ngneat/helipopper';
import { NgStringPipesModule } from 'ngx-pipes';
import { ActionRequiredComponent } from './action-required/action-required.component';
import { AdminHeaderComponent } from './admin-header/admin-header.component';
import { AvatarDropdownComponent } from './avatar-dropdown/avatar-dropdown.component';
import { EnterpriseEnquiryComponent } from './enterprise-enquiry/enterprise-enquiry.component';
import { ManageProjectImageComponent } from './manage-project-image/manage-project-image.component';
import { PlanOverviewComponent } from './plan-overview/plan-overview.component';
import { PlanQuantityComponent } from './plan-quantity/plan-quantity.component';
import { ThemeColourComponent } from './theme-colour/theme-colour.component';

@NgModule({
    declarations: [
        AdminHeaderComponent,
        PlanOverviewComponent,
        EnterpriseEnquiryComponent,
        PlanQuantityComponent,
        ActionRequiredComponent,
        ThemeColourComponent,
        AvatarDropdownComponent,
        ManageProjectImageComponent,
    ],
    imports: [
        CommonModule,
        IonicModule,
        SharedPipesModule,
        SharedDirectivesModule,
        FormsModule,
        SharedComponentsModule,
        TippyModule,
        NgStringPipesModule,
    ],
    exports: [
        AdminHeaderComponent,
        PlanOverviewComponent,
        EnterpriseEnquiryComponent,
        PlanQuantityComponent,
        ActionRequiredComponent,
        ThemeColourComponent,
        AvatarDropdownComponent,
        ManageProjectImageComponent,
    ],
})
export class ComponentsModule {}
