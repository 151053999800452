<ion-header *ngIf="adminConsole; else appHeader" class="ion-no-border admin-modal-header">
    <ion-toolbar mode="md" color="white">
        <ion-title class="font-22" color="admin-dark"><b>Insite - Terms & Conditions</b></ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="close()">
                <ion-icon color="admin-dark" name="close-sharp"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ng-template #appHeader>
    <ion-header  class="ion-no-border">
        <ion-toolbar color="primary">
            <ion-title>Insite - Terms & Conditions</ion-title>
            <ion-buttons slot="end">
                <ion-button (click)="close()">
                    <ion-icon name="close-sharp"></ion-icon>
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>
</ng-template>

<ng-container *ngIf="loaded; else loading;">
    <ion-content class="ignore-desktop-padding" [ngClass]="{'outer-content': !adminConsole}">
        <div *ngIf="saasAgreement" [innerHTML]="saasAgreement" class="size-sm-padding-horizontal ion-margin-horizontal color-admin-dark"></div>
    </ion-content>
</ng-container>
<ng-template #loading>
    <ion-content class="ignore-desktop-padding">
        <ion-spinner class="load-spinner" color="secondary" name="crescent"></ion-spinner>
    </ion-content>
</ng-template>
