import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import {
    AppVersion,
    FeatureAccess,
    FeatureConfig,
    FeatureFlags,
    FileBlacklistConfig,
    NotificationOptions,
    SaasAgreement,
    SubscriptionPlans,
    SystemWhatsNew,
    Terms,
    Trial,
} from '@insite-group-ltd/insite-teams-model';
import { CollectionState } from 'akita-ng-fire';

export type SystemState = CollectionState<
    | Terms
    | NotificationOptions
    | AppVersion
    | Trial
    | SubscriptionPlans
    | FeatureAccess
    | FeatureFlags
    | FeatureConfig
    | FeatureAccess
    | SystemWhatsNew
    | FileBlacklistConfig
    | SaasAgreement
>;

@Injectable({ providedIn: 'root' })
@StoreConfig({
    name: 'system',
    idKey: 'id',
})
export class SystemStore extends EntityStore<SystemState> {
    constructor() {
        super();
    }
}
