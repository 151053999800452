<ng-container *ngIf="readOnly; else editable">
    <div
        [class.round]="round"
        [ngStyle]="{ height: height, width: width, 'border-radius': borderRadius }"
        class="dropzone">
        <ion-text class="ion-text-center" color="dark">
            <p class="mt-6 mb-0">No image</p>
        </ion-text>
    </div>
</ng-container>
<ng-template #editable>
    <div
        (dropped)="onDrop($event)"
        (hovered)="toggleHover($event)"
        [acceptedType]="acceptedType"
        [maxSizeInMB]="maxImageUploadSizeInMB"
        [class.hovering]="isHovering"
        [class.round]="round"
        [multipleDropAllowed]="multipleAllowed"
        [ngStyle]="{ height: height, width: width, 'border-radius': borderRadius }"
        class="dropzone"
        libDropzone>
        <ion-icon
            (click)="fileInput.click()"
            class="cursor-pointer"
            color="admin-dark"
            name="camera"
            size="large"
        ></ion-icon>

        <ion-text [color]="isHovering ? 'medium' : 'dark'" class="ion-text-center">
            <p class="mb-0 mt-0 font-16">
                <b
                    ><span (click)="fileInput.click()" class="external-link hover-underline"
                        >Choose</span
                    ></b
                >
                or<br />drop here
            </p>
        </ion-text>

        <input
            #fileInput
            (change)="chooseDesktopImages($event)"
            [accept]="acceptedType"
            [attr.multiple]="multipleAllowed ? 'multiple' : null"
            style="display: none"
            type="file"
            data-testid="image.uploader.file"
        />
    </div>
</ng-template>
