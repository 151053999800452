import { Audited } from './audited';
import { Image } from './image';
import { InsiteUser } from './insite-user';
import { isListItemFieldEnabled, ItemFieldSet } from './item-field-set';
import { List } from './list';
import { ProjectPermissions } from './project-permissions';
import { SelectOption } from './select-option';
import { WithSortIndex } from './sort';
import { DEFAULT_STATUS_GROUPS, StatusGroup, StatusOption } from './status';
import { Translations } from './translations';

export const EMPTY_TRANSLATIONS = {
    project: {},
    drawing: {},
    list: {},
    item: {},
    report: {},
};

export const PROJECT_ROLES: ProjectRole[] = ['ADMIN', 'INTERNAL', 'EXTERNAL', 'READ_ONLY'];

export type ProjectStatus = 'ACTIVE' | 'ARCHIVED';

export type ProjectRole = 'ADMIN' | 'INTERNAL' | 'EXTERNAL' | 'READ_ONLY';

export interface ProjectRoleDetails {
    label: string;
    icon: string;
    description: string;
    colour: string;
}

export type ProjectLocations = Record<string, ProjectLocation>;

export type ProjectAssignees = Record<string, ProjectAssignee>;

export interface Project extends Audited {
    id: string;
    name: string;
    projectNumber: string;
    client: string;
    architect: string;
    location: string;
    image?: Image;
    listCount: number;
    listTemplateCount: number;
    reportCount: number;
    users: Record<string, ProjectRole>;
    invitedUsers: string[];
    invitedUsersWithRoles?: Record<ProjectRole, string[]>;
    assignees: ProjectAssignees;
    locations: ProjectLocations;
    planId: string;
    translations?: Translations;
    status: ProjectStatus;
    tags: Record<string, ProjectTag>;
    statusGroups: StatusGroup[];
    itemFieldSets?: Record<string, ItemFieldSet>;
    permissions?: ProjectPermissions;
}

export class CurrentUsersProjectRole {
    readonly role?: ProjectRole;
    readonly userId?: string;

    constructor(userId?: string, project?: Project) {
        if (userId && project) {
            this.role = project.users[userId];
        }
        this.userId = userId;
    }

    get isAdmin(): boolean {
        return this.role === 'ADMIN';
    }

    get isInternal(): boolean {
        return this.role === 'INTERNAL';
    }

    get isOnPlan(): boolean {
        return this.isAdmin || this.isInternal;
    }

    get isExternal(): boolean {
        return this.role === 'EXTERNAL';
    }

    get isReadOnly(): boolean {
        return this.role === 'READ_ONLY';
    }

    get hasRole(): boolean {
        return !!this.role;
    }

    static none() {
        return new CurrentUsersProjectRole(undefined, undefined);
    }
}

export function getProjectRoleDetails(role: ProjectRole): ProjectRoleDetails {
    switch (role) {
        case 'ADMIN':
            return {
                label: 'Admin',
                icon: 'shield',
                description: `Full access to project. Can manage collaborators, settings and project details.`,
                colour: 'light-blue',
            };
        case 'INTERNAL':
            return {
                label: 'Internal',
                icon: 'person',
                description: `Moderate access to project. Can create lists and manage tags.`,
                colour: 'internal-role',
            };
        case 'EXTERNAL':
            return {
                label: 'External',
                icon: 'log-out',
                description: `Limited access to project. Cannot create lists. Can create & contribute to items.`,
                colour: 'external-role',
            };
        case 'READ_ONLY':
            return {
                label: 'Read only',
                icon: 'eye',
                description: `Minimal access to project. Cannot create lists or update items.`,
                colour: 'light-med',
            };
    }
}

export interface UserInProject {
    userId: string;
    role: ProjectRole;
    insiteUser: InsiteUser;
}

export interface UserToAddWithRole {
    user: InsiteUser;
    roleOptions: ProjectRole[];
    role: ProjectRole;
    checked: boolean;
}

export interface EmailToInviteWithRole {
    email: string;
    roleOptions: ProjectRole[];
    role: ProjectRole;
}

export interface ProjectLocation extends SelectOption, WithSortIndex {
    drawingId?: string;
}

export interface ProjectAssignee extends SelectOption {
    users?: string[];
}

export type ProjectTag = SelectOption;

export class ProjectOverview {
    totalLists: number;
    totalItems = 0;
    totalReports = 0;
    totalComplete = 0;
    percentageComplete = 0;

    constructor(project: Project, lists: List[]) {
        this.totalLists = lists.length;
        for (const list of lists) {
            this.totalItems += list.itemCount || 0;
            this.totalReports += list.reportCount || 0;
            if (isListItemFieldEnabled(project, list, 'STATUS')) {
                this.totalComplete += list.completeCount || 0;
            } else {
                this.totalComplete += list.itemCount || 0;
            }
        }
        if (this.totalItems > 0 && this.totalComplete > 0) {
            this.percentageComplete = Math.round((this.totalComplete / this.totalItems) * 100);
        } else {
            this.percentageComplete = 0;
        }
    }
}

export interface HomeProjectView {
    id: string;
    name: string;
    projectNumber: string;
    image: Image;
    listCount: number;
    userCount: number;
    status: ProjectStatus;
}

export function getAvailableStatusGroups(project: Project): StatusGroup[] {
    if (project?.statusGroups?.length) {
        return [...DEFAULT_STATUS_GROUPS, ...project.statusGroups];
    }
    return DEFAULT_STATUS_GROUPS;
}

export function getAvailableStatusGroupOptions(project: Project): StatusOption[] {
    let options: StatusOption[] = [];
    getAvailableStatusGroups(project).forEach(
        (statusGroup) => (options = options.concat(statusGroup.options))
    );
    return options;
}

export function getProjectInvitedUsers(project: Project) {
    return (project?.invitedUsers || []).concat(
        project?.invitedUsersWithRoles?.ADMIN || [],
        project?.invitedUsersWithRoles?.INTERNAL || [],
        project?.invitedUsersWithRoles?.EXTERNAL || [],
        project?.invitedUsersWithRoles?.READ_ONLY || []
    );
}

export function isMemberOfProject(userId: string, project: Project): boolean {
    return new CurrentUsersProjectRole(userId, project).hasRole;
}

export function getAssigneeIdByName(assigneeName: string, project: Project): string | undefined {
    for (const [assigneeId, assignee] of Object.entries(project.assignees || {})) {
        if (assignee.name === assigneeName) {
            return assigneeId;
        }
    }
    return undefined;
}

export function getLocationIdByName(locationName: string, project: Project): string | undefined {
    for (const [locationId, location] of Object.entries(project.locations || {})) {
        if (location.name === locationName) {
            return locationId;
        }
    }
    return undefined;
}
