import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PlanOption } from '@insite-group-ltd/insite-teams-model';
import { PlanOptionQuery } from '../../state/plan-option';

@Component({
    selector: 'app-plan-overview',
    templateUrl: './plan-overview.component.html',
    styleUrls: ['./plan-overview.component.scss'],
})
export class PlanOverviewComponent {
    @Input() planOption: PlanOption;
    @Input() wrapMedium: boolean;
    @Input() buyButton: boolean;
    @Input() minQuantity: number;
    @Input() noShadow: boolean;
    @Input() allowBuyBespokePlans: boolean;
    @Output() choosePlan = new EventEmitter<void>();

    quantity$ = this.planOptionQuery.quantity$;
    canUserTrial$ = this.planOptionQuery.canUserTrial$;

    constructor(private planOptionQuery: PlanOptionQuery) {}
}
