import { Injectable, OnDestroy } from '@angular/core';
import { Auth, authState } from '@angular/fire/auth';
import { Capacitor } from '@capacitor/core';
import { CollectionConfig } from 'akita-ng-fire';
import { SubSink } from 'subsink';
import {
    BaseCollectionService,
    UserIdPathParam,
} from '../../state/collection-service/base-collection.service';
import { AuditedService } from '../audited/audited.service';
import { DeviceService } from '../device/device.service';
import { TelemetryService } from '../telemetry/telemetry.service';
import { UserDevice, UserDeviceState, UserDeviceStore } from './user-device.store';

const COLLECTION_PATH = 'users/:userId/devices';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: COLLECTION_PATH, idKey: 'id' })
export class UserDeviceService
    extends BaseCollectionService<UserDeviceState, UserDeviceStore, UserIdPathParam>
    implements OnDestroy
{
    private authState$ = authState(this.auth);
    private subs = new SubSink();

    constructor(
        store: UserDeviceStore,
        auditedService: AuditedService,
        telemetryService: TelemetryService,
        private auth: Auth,
        private deviceService: DeviceService
    ) {
        super(COLLECTION_PATH, store, auditedService, telemetryService);
    }

    get path() {
        return `users/${this.auth.currentUser.uid}/devices`;
    }

    init() {
        if (Capacitor.isNativePlatform()) {
            this.subs.sink = this.authState$.subscribe(async (authState) => {
                if (authState) {
                    const { Device } = await import('@capacitor/device');
                    const { App } = await import('@capacitor/app');
                    const info = await Device.getInfo();
                    const appInfo = await App.getInfo();
                    const id = await this.deviceService.getDeviceId();
                    const userDevice: UserDevice = {
                        appVersion: appInfo.version,
                        id,
                        manufacturer: info.manufacturer,
                        model: info.model,
                        name: info.name,
                        operatingSystem: info.operatingSystem,
                        osVersion: info.osVersion,
                    };
                    this.setDoc(`users/${authState.uid}/devices/${id}`, userDevice);
                }
            });
        }
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}
