import { Injectable } from '@angular/core';
import { CollectionGuard } from 'akita-ng-fire';
import { of } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { LoggerService } from '../../../services/logger/logger.service';
import { UtilService } from '../../../services/util/util.service';
import { catchGuardError } from '../../../utils/guard';
import { ProjectQuery } from '../../projects/project.query';
import { PlanQuery } from '../plan.query';
import { PlanFeaturesService } from './plan-features.service';
import { PlanFeaturesState } from './plan-features.store';

@Injectable({ providedIn: 'root' })
export class ActivePlanFeaturesGuard extends CollectionGuard<PlanFeaturesState> {
    constructor(
        protected service: PlanFeaturesService,
        private projectQuery: ProjectQuery,
        private planQuery: PlanQuery,
        private utilService: UtilService,
        private loggerService: LoggerService
    ) {
        super(service);
    }

    protected sync() {
        return this.projectQuery
            .selectActive((project) => project?.planId)
            .pipe(
                switchMap((planId) => (planId ? of(planId) : this.planQuery.selectActiveId())),
                distinctUntilChanged(),
                switchMap((activePlanId) =>
                    activePlanId ? this.service.syncDoc({ id: activePlanId }) : of(null)
                ),
                catchError((err) =>
                    catchGuardError(err, 'plan-features', this.utilService, this.loggerService)
                )
            );
    }
}
