import { Injectable } from '@angular/core';
import { CollectionGuard } from 'akita-ng-fire';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { PlanQuery } from './plan.query';
import { PlanService } from './plan.service';
// noinspection ES6PreferShortImport
import { LoggerService } from '../../services/logger/logger.service';
import { UtilService } from '../../services/util/util.service';
import { catchGuardError } from '../../utils/guard';
import { InsiteUserService } from '../user/insite-user.service';
import { PlanState } from './plan.store';

@Injectable({ providedIn: 'root' })
export class ActivePlanUsersGuard extends CollectionGuard<PlanState> {
    constructor(
        protected service: PlanService,
        private planQuery: PlanQuery,
        private insiteUserService: InsiteUserService,
        private utilService: UtilService,
        private loggerService: LoggerService
    ) {
        super(service);
    }

    protected sync() {
        return this.planQuery.currentPlanUserIds$.pipe(
            tap((currentPlanUserIds$) =>
                this.loggerService.debug('active plan users guard', 'syncing active plan users', {
                    currentPlanUserIds$,
                })
            ),
            switchMap((currentPlanUserIds) =>
                this.insiteUserService.syncManyDocs(currentPlanUserIds)
            ),
            catchError((err) => catchGuardError(err, 'users', this.utilService, this.loggerService))
        );
    }
}
