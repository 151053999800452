import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AbilityModule } from '@casl/angular';
import { IonicModule } from '@ionic/angular';
import { NgStringPipesModule } from 'ngx-pipes';
import { SharedPipesModule } from '../../pipes/shared-pipes.module';
import { StatusIndicatorModule } from '../status-indicator/status-indicator.module';
import { ManageProjectUserModalPage } from './manage-project-user-modal.page';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        NgStringPipesModule,
        SharedPipesModule,
        AbilityModule,
        StatusIndicatorModule,
    ],
    declarations: [ManageProjectUserModalPage],
})
export class ManageProjectUserModalPageModule {}
