import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SharedPipesModule } from '../../pipes/shared-pipes.module';
import { SelectDefaultItemAccessPage } from './select-default-item-access.page';

@NgModule({
    imports: [CommonModule, FormsModule, IonicModule, SharedPipesModule],
    declarations: [SelectDefaultItemAccessPage],
})
export class SelectDefaultItemAccessPageModule {}
