import camelCase from 'lodash/camelCase';
import cloneDeep from 'lodash/cloneDeep';
import forOwn from 'lodash/forOwn';
import set from 'lodash/set';
import { ItemFieldSet } from './item-field-set';

export function fixTranslations(translations: any): any {
    forOwn(translations, (_, rootKey) => {
        forOwn(translations[rootKey], (__, valueKey) => {
            if (!translations[rootKey][valueKey]) {
                delete translations[rootKey][valueKey];
            } else if (
                rootKey === 'project' ||
                rootKey === 'list' ||
                rootKey === 'item' ||
                rootKey === 'report'
            ) {
                const str = translations[rootKey][valueKey];
                translations[rootKey][valueKey] = str.charAt(0).toUpperCase() + str.slice(1);
            }
        });
    });
    return translations;
}

export function mergeItemFieldSetTranslations(
    translations: Translations,
    itemFieldSet: ItemFieldSet
) {
    if (itemFieldSet) {
        const merged = cloneDeep(translations);
        Object.values(itemFieldSet.fields)
            .filter((field) => field.enabled && field.label)
            .forEach((field) => set(merged, `item.${camelCase(field.id)}`, field.label));
        return merged;
    } else {
        return translations;
    }
}

export interface Translations {
    project: ProjectTranslations;
    list: ListTranslations;
    item: ItemTranslations;
    report: ReportTranslations;
    drawing: DrawingTranslations;
    itemSort: ItemSortTranslations;
}

export interface ItemSortTranslations {
    [key: string]: {
        label: string;
        description: string;
    };
}

export interface DrawingTranslations {
    drawings: string;
    title: string;
    reference: string;
    revision: string;
}

export interface ItemTranslations {
    images: string;
    title: string;
    description: string;
    dueDate: string;
    assignees: string;
    locations: string;
    priority: string;
    status: string;
    statusScore: string;
    comments: string;
    files: string;
}

export interface ListTranslations {
    name: string;
    reference: string;
}

export interface ProjectTranslations {
    name: string;
    number: string;
    client: string;
    architect: string;
    location: string;
    tags: string;
}

export interface ReportTranslations {
    revision: string;
    project: string;
    list: string;
    items: string;
    summary: string;
}

export const enTranslations: Translations = {
    project: {
        name: 'Name',
        number: 'Number',
        client: 'Client',
        architect: 'Architect',
        location: 'Location',
        tags: 'Tags',
    },
    list: {
        name: 'Title',
        reference: 'Reference',
    },
    item: {
        images: 'Images',
        title: 'Title',
        description: 'Description',
        dueDate: 'Due date',
        assignees: 'Assignees',
        locations: 'Locations',
        priority: 'Priority',
        status: 'Status',
        statusScore: 'Score',
        comments: 'Comments',
        files: 'Files',
    },
    report: {
        revision: 'Revision',
        project: 'Project',
        list: 'List',
        items: 'Items',
        summary: 'Summary',
    },
    drawing: {
        drawings: 'Drawings',
        title: 'Title',
        reference: 'Reference',
        revision: 'Revision',
    },
    itemSort: {
        CREATED_ASC: {
            label: 'Newest last',
            description: 'Sorted by: Newest last',
        },
        CREATED_DESC: {
            label: 'Newest first',
            description: 'Sorted by: Newest first',
        },
        DUE_DATE_ASC: {
            label: '{{dueDate}} ascending',
            description: 'Sorted by: {{dueDate}} ascending',
        },
        DUE_DATE_DESC: {
            label: '{{dueDate}} descending',
            description: 'Sorted by: {{dueDate}} descending',
        },
        A_Z: {
            label: 'A-Z by {{title}}',
            description: 'Sorted by: {{title}}',
        },
        LOCATION_ASC: {
            label: 'A-Z by {{locations}}',
            description: 'Sorted by: {{locations}}',
        },
        LOCATION_CUSTOM: {
            label: '{{locations}} custom order',
            description: 'Sorted by: {{locations}} custom order',
        },
        ASSIGNEE_ASC: {
            label: 'A-Z by {{assignees}}',
            description: 'Sorted by: {{assignees}}',
        },
        UPDATED_ASC: {
            label: 'Updated most recently last',
            description: 'Sorted by: Updated most recently last',
        },
        UPDATED_DESC: {
            label: 'Updated most recently first',
            description: 'Sorted by: Updated most recently first',
        },
        PRIORITY_DESC: {
            label: 'Priority High to Low',
            description: 'Sorted by: Priority High to Low',
        },
    },
} as const;
