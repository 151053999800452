<div class="ion-padding-horizontal">
    <ion-text *ngIf="usersToAdd?.length">
        <p class="ml-10 mb-10 mt-10 font-weight-500">
            {{ usersToAdd?.length }} existing Insite
            {{ 'user' | makePluralString: usersToAdd?.length }} to be added to
            {{ type | lowercase }}:
        </p>
        <div class="valid-emails mt-10 mb-12">
            <ion-text color="admin-dark">
                <p class="ml-10 mt-2 mb-4 font-14">{{ usersToAddAsString }}</p>
            </ion-text>
        </div>
    </ion-text>

    <ion-text *ngIf="usersToInvite?.length">
        <p class="ml-10 mb-10 font-weight-500">
            {{ usersToInvite?.length }} email
            {{ 'address' | makePluralString: usersToInvite?.length }} to be invited to join
            {{ type | lowercase }}:
        </p>
        <div class="valid-emails mt-10 mb-12">
            <ion-text color="admin-dark">
                <p class="ml-10 mt-2 mb-4 font-14">{{ emailsToInviteAsString }}</p>
            </ion-text>
        </div>
    </ion-text>

    <ion-text *ngIf="!usersToAdd?.length && !usersToInvite?.length">
        <h3 class="vertical-align-content ion-justify-content-center mb-10 font-weight-500">
            <ion-icon
                class="font-22 ml-4 mr-8"
                name="alert-circle-outline"
                color="danger"
            ></ion-icon>
            No users to add or invite!
        </h3>
    </ion-text>

    <div class="display-flex ion-justify-content-end ion-margin-bottom">
        <ion-button *ngIf="usersToAdd?.length && usersToInvite?.length === 0" (click)="confirm()">
            Add
        </ion-button>
        <ion-button *ngIf="usersToInvite?.length && usersToAdd?.length === 0" (click)="confirm()">
            Send invite
        </ion-button>
        <ion-button *ngIf="usersToInvite?.length && usersToAdd?.length" (click)="confirm()">
            Add & invite
        </ion-button>
    </div>

    <ion-text *ngIf="existingUserCount && existingUserCount > 0">
        <p class="display-flex font-weight-500 mb-32 mt-26">
            <ion-icon
                class="font-22 mr-4 flex-shrink-0"
                name="information-circle-outline"
                color="primary"
            ></ion-icon>
            {{ existingUserCount }}&nbsp;{{ existingUserCount === 1 ? 'user is' : 'users are' }}
            already part of this plan and will not be added.
        </p>
    </ion-text>

    <ng-container *ngIf="invalidEmails?.length">
        <ion-text>
            <p class="display-flex mb-10 font-weight-500">
                <ion-icon
                    class="font-22 mr-4 flex-shrink-0"
                    name="alert-circle-outline"
                    color="danger"
                ></ion-icon>
                The following invalid email addresses will not be invited:
            </p>
        </ion-text>
        <div class="invalid-emails mt-10 mb-12">
            <ion-text color="admin-dark"
                ><p class="ml-10 mt-2 mb-4 font-14">
                    {{ invalidEmailsAsString }}
                </p></ion-text
            >
        </div>
        <ion-text
            ><p class="ml-8 font-14 mb-32">
                Please check the email addresses and make sure that they are either semi-colon or
                comma separated.
            </p></ion-text
        >
    </ng-container>
</div>
