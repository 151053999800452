import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { InsiteUser } from '@insite-group-ltd/insite-teams-model';
import { FireAuthState } from 'akita-ng-fire';

export type AuthState = FireAuthState<InsiteUser>;

@Injectable({ providedIn: 'root' })
@StoreConfig({
    name: 'auth',
    idKey: 'id',
})
export class AuthStore extends EntityStore<AuthState> {
    constructor() {
        super();
    }
}
