import { Injectable } from '@angular/core';
import { CollectionGuard } from 'akita-ng-fire';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { LoggerService } from '../../../services/logger/logger.service';
import { UtilService } from '../../../services/util/util.service';
import { catchGuardError } from '../../../utils/guard';
import { PlanQuery } from '../plan.query';
import { PlanDetailsService } from './plan-details.service';
import { PlanDetailsState } from './plan-details.store';

@Injectable({ providedIn: 'root' })
export class ActivePlanDetailsGuard extends CollectionGuard<PlanDetailsState> {
    constructor(
        protected service: PlanDetailsService,
        private planQuery: PlanQuery,
        private utilService: UtilService,
        private loggerService: LoggerService
    ) {
        super(service);
    }

    protected sync() {
        return this.planQuery.currentPlanId$.pipe(
            tap((currentPlanId$) =>
                this.loggerService.debug(
                    'active plan details guard',
                    'syncing active plan details',
                    { currentPlanId$ }
                )
            ),
            switchMap((planId) =>
                this.service.syncActive({ path: `plans/${planId}/details/${planId}` })
            ),
            catchError((err) =>
                catchGuardError(
                    err,
                    `plans/$planId/details/$planId`,
                    this.utilService,
                    this.loggerService
                )
            )
        );
    }
}
