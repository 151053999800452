import { Injectable } from '@angular/core';
import { where } from '@angular/fire/firestore';
import { CollectionGuard, CollectionGuardConfig } from 'akita-ng-fire';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { LoggerService } from '../../services/logger/logger.service';
import { UtilService } from '../../services/util/util.service';
import { catchGuardError } from '../../utils/guard';
import { AuthQuery } from '../auth/auth.query';
import { PlanService } from './plan.service';
import { PlanState, PlanStore } from './plan.store';

@Injectable({ providedIn: 'root' })
@CollectionGuardConfig({ awaitSync: true })
export class AdminPlansGuard extends CollectionGuard<PlanState> {
    constructor(
        protected service: PlanService,
        private planStore: PlanStore,
        private authQuery: AuthQuery,
        private utilService: UtilService,
        private loggerService: LoggerService
    ) {
        super(service);
    }

    protected sync() {
        return this.authQuery.userId$.pipe(
            tap((userId$) =>
                this.loggerService.debug('admin plans guard', 'syncing admin plans', { userId$ })
            ),
            tap(() => this.planStore.reset()),
            switchMap((userId) => {
                return this.service.syncCollection(
                    [where(`users.${userId}`, 'in', ['ADMIN', 'INTERNAL'])],
                    {
                        loading: true,
                    }
                );
            }),
            catchError((err) =>
                catchGuardError(err, `users.$userId`, this.utilService, this.loggerService)
            )
        );
    }
}
