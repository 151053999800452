import flatten from 'lodash/flatten';

export interface GuideStep {
    id: string;
    title: string;
    text: string;
    buttons: { classes: string; text: string }[];
    attachTo?: { element: string; on: string };
    modalOverlayOpeningRadius?: number;
    modalOverlayOpeningPadding?: number;
}

export interface Guide {
    type: 'app' | 'update';
    steps: GuideStep[];
    config?: {
        minSize?: 'smallSize' | 'tabletSize' | 'desktopSize';
    };
}

const GUIDE_STEP_NEXT_BUTTON = {
    classes: 'shepherd-button-primary',
    text: 'Next&nbsp;&nbsp;&#10095;',
};

const GUIDE_STEP_DONE_BUTTON = {
    classes: 'shepherd-button-primary',
    text: 'Done',
};

export type GuideId =
    | 'app-item-filter-sort'
    | 'update-desktop-navbar'
    | 'update-pintura-editor'
    | 'app-item-navigation'
    | 'update-fab-desktop-migration'
    | 'app-file-viewer'
    | 'app-pdf-form';

export type Guides = Record<GuideId, Guide>;

export const APP_ITEM_FILTER_SORT_GUIDE: Guide = {
    type: 'app',
    steps: [
        {
            id: 'app-item-filter-sort-quick-filters',
            attachTo: { element: '.quick-filters-container', on: 'bottom' },
            title: '⚡&nbsp; Use quick filters',
            text: 'Use these quick action buttons as shortcuts to some common filters.',
            buttons: [GUIDE_STEP_NEXT_BUTTON],
        },
        {
            id: 'app-item-filter-sort-save-filters',
            attachTo: {
                element: '.save-filters-container',
                on: 'top-end',
            },
            title: '💾&nbsp; Save filters',
            text: 'Toggle this on to save the selected filters against this list so that they are set the next time you return.',
            buttons: [GUIDE_STEP_DONE_BUTTON],
        },
    ],
};

export const UPDATE_DESKTOP_NAVBAR_GUIDE: Guide = {
    type: 'update',
    config: { minSize: 'desktopSize' },
    steps: [
        {
            id: 'update-desktop-navbar-notifications',
            attachTo: { element: '.navbar-notifications-container', on: 'bottom' },
            title: `➡️&nbsp; I've moved!`,
            text: 'Notifications can now be accessed from here on larger screens.',
            buttons: [GUIDE_STEP_NEXT_BUTTON],
            modalOverlayOpeningRadius: 14,
            modalOverlayOpeningPadding: 2,
        },
        {
            id: 'update-desktop-navbar-profile',
            attachTo: { element: '.navbar-avatar', on: 'bottom' },
            title: '👤&nbsp; Profile and settings',
            text: 'Click on your avatar to access your profile and settings. You can log out from here too.',
            buttons: [GUIDE_STEP_DONE_BUTTON],
            modalOverlayOpeningRadius: 24,
            modalOverlayOpeningPadding: 2,
        },
    ],
};

export const UPDATE_PINTURA_EDITOR_GUIDE: Guide = {
    type: 'update',
    steps: [
        {
            id: 'update-pintura-editor',
            title: `📸️&nbsp; New image editor!`,
            text: `A new and improved editor is here! <br><br>Note: The original editing tool will still be used for any images that have previously been edited.`,
            buttons: [GUIDE_STEP_DONE_BUTTON],
        },
    ],
};

export const APP_ITEM_NAVIGATION: Guide = {
    type: 'app',
    steps: [
        {
            id: 'app-item-navigation-next',
            title: `➡️&nbsp; Navigate between items!`,
            attachTo: { element: `[data-userguidestep="manage.item.next"]`, on: 'top-start' },
            text: `Use the next and previous buttons to quickly navigate through the items in your list.`,
            buttons: [GUIDE_STEP_NEXT_BUTTON],
        },
        {
            id: 'app-item-navigation-previous',
            title: `⬅️&nbsp; Use this to go back`,
            attachTo: { element: `[data-userguidestep="manage.item.previous"]`, on: 'top-end' },
            text: `After you've made changes to any items, use the save button to update them all at once.`,
            buttons: [GUIDE_STEP_DONE_BUTTON],
        },
    ],
};

export const UPDATE_FAB_MIGRATION: Guide = {
    type: 'update',
    config: { minSize: 'desktopSize' },
    steps: [
        {
            id: 'update-fab-desktop-migration',
            title: `➡️&nbsp; I've moved!`,
            attachTo: { element: `[data-userguidestep="fab.desktop.migration"]`, on: 'bottom' },
            text: `On tablet and desktop, you'll find the buttons to create and add up here in the toolbar instead of at the bottom of the page.`,
            buttons: [GUIDE_STEP_DONE_BUTTON],
            modalOverlayOpeningRadius: 20,
            modalOverlayOpeningPadding: 2,
        },
    ],
};

export const APP_FILE_VIEWER: Guide = {
    type: 'app',
    steps: [
        {
            id: 'app-file-viewer-details',
            title: `ℹ️&nbsp; Details and actions`,
            attachTo: { element: `[data-userguidestep="file.viewer.details"]`, on: 'bottom' },
            text: `Use this to view the details and versions of this file, as well as to carry out any actions.`,
            buttons: [GUIDE_STEP_DONE_BUTTON],
            modalOverlayOpeningRadius: 20,
            modalOverlayOpeningPadding: 2,
        },
    ],
};

export const APP_PDF_FORM: Guide = {
    type: 'app',
    steps: [
        {
            id: 'app-file-viewer-details',
            title: `ℹ️&nbsp; Details and actions`,
            attachTo: { element: `[data-userguidestep="file.viewer.details"]`, on: 'bottom' },
            text: `Use this to view the details and versions of this file, as well as to carry out any actions.`,
            buttons: [GUIDE_STEP_NEXT_BUTTON],
            modalOverlayOpeningRadius: 20,
            modalOverlayOpeningPadding: 2,
        },
        {
            id: 'app-file-viewer-form',
            title: `📝&nbsp; Edit the form`,
            attachTo: { element: `[data-userguidestep="file.viewer.form"]`, on: 'auto' },
            text: `For interactive forms, you can either edit the PDF file directly or you can use the form editor.`,
            buttons: [GUIDE_STEP_DONE_BUTTON],
            modalOverlayOpeningRadius: 20,
            modalOverlayOpeningPadding: 2,
        },
    ],
};

export const GUIDES: Guides = {
    'app-item-filter-sort': APP_ITEM_FILTER_SORT_GUIDE,
    'update-desktop-navbar': UPDATE_DESKTOP_NAVBAR_GUIDE,
    'update-pintura-editor': UPDATE_PINTURA_EDITOR_GUIDE,
    'app-item-navigation': APP_ITEM_NAVIGATION,
    'update-fab-desktop-migration': UPDATE_FAB_MIGRATION,
    'app-file-viewer': APP_FILE_VIEWER,
    'app-pdf-form': APP_PDF_FORM,
};

export function getUpdateGuideStepIds(): string[] {
    return flatten(
        Object.values(GUIDES)
            .filter((guide) => guide.type === 'update')
            .map((guide) => guide.steps)
            .map((steps) => steps.map((step) => step.id))
    );
}
