import isMatch from 'lodash/isMatch';
import { BillingInterval, PlanType } from './plan';

export interface SubscriptionPlan {
    id: number;
    typeId: PlanTypeId;
    name: string;
    type: PlanType;
    billingInterval: BillingInterval;
    trial: boolean;
    quantityUnit?: SubscriptionQuantityUnit;
}

export type SubscriptionQuantityUnit = 'project' | 'user';

export interface SubscriptionPlans {
    activePlans: SubscriptionPlan[];
    allPlans: SubscriptionPlan[];
}

export type PlanTypeId = 'basic' | 'personal' | 'team' | 'starter' | 'premium' | 'enterprise';

export function getSubscriptionPlanIdsForPlanTypeIdAndInterval(
    subscriptionPlans: SubscriptionPlan[],
    typeId: PlanTypeId,
    billingInterval: BillingInterval
): number[] {
    return subscriptionPlans
        .filter((plan) => plan.typeId === typeId && plan.billingInterval === billingInterval)
        .map((plan) => plan.id);
}

export function getSubscriptionPlanId(
    subscriptionPlans: SubscriptionPlan[],
    typeId: PlanTypeId,
    trial: boolean,
    billingInterval: BillingInterval
) {
    const matchingPlan = subscriptionPlans.find((subscriptionPlan) =>
        isMatch(subscriptionPlan, { typeId, trial, billingInterval })
    );
    if (!matchingPlan) {
        throw Error(
            `unknown subscription plan: typeId='${typeId}' trial='${trial}' billingInterval='${billingInterval}'`
        );
    }
    return matchingPlan.id;
}
