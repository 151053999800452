import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import set from 'lodash/set';
import { DocumentActivityDetail } from './activity';
import { Audited } from './audited';
import { Image } from './image';
import { DEFAULT_ITEM_FIELD_SET, getItemFieldSet, ItemFieldSet } from './item-field-set';
import { ItemSections } from './item-section';
import { DefaultItemAccessSetting, PlanType } from './plan';
import { Project, ProjectRole, ProjectTag } from './project';
import { getSelectOptionNames, SelectOption, WithSelectOptions } from './select-option';
import { WithSortIndex } from './sort';
import { DEFAULT_STATUS_GROUP, StatusGroup } from './status';
import { findById, keyById } from './util';

export interface ListActivityDetail extends DocumentActivityDetail {
    field: keyof List;
}

export type ListStatusId = 'ACTIVE' | 'SIGNED_OFF' | 'ARCHIVED';

export interface ListStatus extends SelectOption, WithSortIndex {
    id: ListStatusId;
    readOnly: boolean;
    requiresSignature: boolean;
    comment?: string;
    signature?: Image;
    lastUpdated?: any;
    lastUpdatedBy?: any;
}

export const ACTIVE_LIST_STATUS: ListStatus = {
    id: 'ACTIVE',
    name: 'Active',
    readOnly: false,
    requiresSignature: false,
    sortIndex: 0,
};

export const SIGNED_OFF_LIST_STATUS: ListStatus = {
    id: 'SIGNED_OFF',
    name: 'Signed off',
    readOnly: true,
    requiresSignature: true,
    sortIndex: 1,
};

export const ARCHIVED_LIST_STATUS: ListStatus = {
    id: 'ARCHIVED',
    name: 'Archived',
    readOnly: true,
    requiresSignature: false,
    sortIndex: 2,
};

export const DEFAULT_LIST_STATUS_OPTIONS: ListStatus[] = [
    ACTIVE_LIST_STATUS,
    SIGNED_OFF_LIST_STATUS,
    ARCHIVED_LIST_STATUS,
];

export function getListStatusOptionName(id?: string) {
    return findById(DEFAULT_LIST_STATUS_OPTIONS, id)?.name;
}

export type ListVisibility = 'PRIVATE' | 'INTERNAL' | 'OPEN' | 'CUSTOM';

export interface ListSettings {
    itemImagesMustHaveGpsCoordinates: boolean;
}

export const DEFAULT_LIST_SETTINGS: ListSettings = {
    itemImagesMustHaveGpsCoordinates: false,
};

export interface List extends BaseList {
    ref: string;
    visibility: ListVisibility;
    visibilityCustomUsers: string[];
    completeCount: number;
    reportCount: number;
    listTemplate?: ListTemplate;
    listTemplateId?: string;
    status: ListStatus;
    userItemPermissions: UserItemPermissions;
}

export interface ManageList extends Omit<List, 'tags'> {
    tags: ProjectTag[];
    usersWithAccess: Record<string, boolean>;
}

export const DEFAULT_MANAGE_LIST: ManageList = {
    statusGroup: DEFAULT_STATUS_GROUP,
    visibility: 'OPEN',
    usersWithAccess: {},
    settings: DEFAULT_LIST_SETTINGS,
    itemFieldSet: {
        id: DEFAULT_ITEM_FIELD_SET.id,
    },
} as ManageList;

export interface ManageListOptions extends WithSelectOptions {
    tags: ProjectTag[];
    itemFieldSets: ItemFieldSet[];
}

export const EMPTY_MANAGE_LIST_OPTIONS: ManageListOptions = { tags: [], itemFieldSets: [] };

export function getListPrivacyLabel(listPrivacy: string): string {
    switch (listPrivacy) {
        case 'PRIVATE':
            return 'Private';
        case 'INTERNAL':
            return 'Internal collaborators only';
        case 'OPEN':
            return 'Available to all project collaborators';
        case 'CUSTOM':
            return 'Specified project collaborators';
    }
    return '';
}

export const listContentViewSettingsField1 = ['assigneesLabel', 'locationsLabel'] as const;
export const listContentViewSettingsField2 = ['dueDate', 'locationsLabel'] as const;

export interface ListContentViewSettings {
    field1?: (typeof listContentViewSettingsField1)[number];
    field2?: (typeof listContentViewSettingsField2)[number];
}

export type ListGroupByProperty = 'STATUS' | 'TAGS' | 'TEMPLATE';

export interface ListGroupBy {
    property: ListGroupByProperty;
}

export const defaultListContentViewSettings: ListContentViewSettings = {
    field1: 'assigneesLabel',
    field2: 'dueDate',
};

export interface BaseList extends Audited {
    id: string;
    name: string;
    itemCount: number;
    tags: Record<string, ProjectTag>;
    statusGroup: StatusGroup;
    settings: ListSettings;
    itemSections?: ItemSections;
    itemFieldSet?: {
        id: string;
    };
}

export interface ListTemplate extends BaseList, SelectOption {
    description: string;
}

export interface ManageListTemplate extends Omit<ListTemplate, 'tags'> {
    tags: ProjectTag[];
}

export const DEFAULT_MANAGE_LIST_TEMPLATE = {
    statusGroup: DEFAULT_STATUS_GROUP,
    settings: DEFAULT_LIST_SETTINGS,
    itemFieldSet: {
        id: DEFAULT_ITEM_FIELD_SET.id,
    },
} as ManageListTemplate;

export function getTemplateNameFromId(id: string, templates: ListTemplate[]) {
    const found = findById(templates, id);
    return found ? found.name : '';
}

export function getListTranslations(project: Project, list?: List) {
    const listTranslations = {
        itemStatus: keyById(list?.statusGroup?.options),
    };
    if (list) {
        const itemFieldSet = getItemFieldSet(project, list);
        Object.values(itemFieldSet.fields)
            .filter((field) => field.enabled && field.label)
            .forEach((field) => {
                set(listTranslations, `item.${camelCase(field.id)}`, field.label);
            });
    }
    return listTranslations;
}

export type ItemPermission = 'FULL' | 'LIMITED' | 'READ_ONLY' | 'NONE';

export interface ItemAccessDetails {
    label: string;
    icon: string;
    description: string;
    colour: string;
}

export type UserItemPermissions = Record<string, ItemPermission>;

export type ProjectRoleItemAccess = Record<ProjectRole, ItemPermission>;

export function getUserItemPermissions(
    userId: string,
    planType: PlanType,
    defaultItemAccessPlanSettings: DefaultItemAccessSetting,
    list: List,
    userProjectRole: ProjectRole
): ItemPermission {
    if (get(list, 'status.id', 'ACTIVE') !== 'ACTIVE') {
        return 'READ_ONLY';
    } else if (list?.userItemPermissions && list.userItemPermissions[userId]) {
        return list.userItemPermissions[userId];
    } else {
        return getDefaultUserItemPermission(
            planType,
            defaultItemAccessPlanSettings,
            list.visibility,
            userProjectRole,
            list.createdBy,
            userId
        );
    }
}

export function getDefaultUserItemPermission(
    planType: PlanType,
    defaultItemAccessPlanSettings: DefaultItemAccessSetting,
    listVisibility: ListVisibility,
    projectRole: ProjectRole,
    createdBy: string,
    userId: string
) {
    if (
        planType === 'TEAM' ||
        planType === 'BASIC' ||
        listVisibility === 'PRIVATE' ||
        createdBy === userId
    ) {
        return 'FULL';
    }
    if (listVisibility === 'CUSTOM' || listVisibility === 'OPEN' || listVisibility === 'INTERNAL') {
        return (
            defaultItemAccessPlanSettings?.[projectRole] ||
            DEFAULT_OPEN_LIST_ROLE_ITEM_ACCESS[projectRole]
        );
    } else {
        return 'READ_ONLY';
    }
}

export const DEFAULT_OPEN_LIST_ROLE_ITEM_ACCESS: ProjectRoleItemAccess = {
    ADMIN: 'FULL',
    INTERNAL: 'LIMITED',
    EXTERNAL: 'LIMITED',
    READ_ONLY: 'READ_ONLY',
};

export function getListItemAccessDetails(itemAccess: ItemPermission): ItemAccessDetails {
    switch (itemAccess) {
        case 'FULL':
            return {
                label: 'Full access',
                icon: 'shield',
                description: `Can edit details on all items. Including updating status and approving and rejecting status requests. Unable to make status requests.`,
                colour: 'light-blue',
            };
        case 'LIMITED':
            return {
                label: 'Limited',
                icon: 'hand-right',
                description: `Full access and management only on items they have created. Otherwise, they can add photos, comments and make status requests.`,
                colour: 'external-role',
            };
        case 'READ_ONLY':
            return {
                label: 'Read only',
                icon: 'eye',
                description: `Can only view items on the list. Unable to make any changes. Cannot create items.`,
                colour: 'light-med',
            };
        case 'NONE':
            return {
                label: 'None',
                icon: 'ban',
                description: `No permissions on list.`,
                colour: 'light-med',
            };
    }
}

export function canUserSeeList(list: List, userId: string, userProjectRole: ProjectRole): boolean {
    if (list?.visibility === 'PRIVATE' && list?.createdBy === userId) {
        return true;
    } else if (
        list?.visibility === 'INTERNAL' &&
        (userProjectRole === 'ADMIN' || userProjectRole === 'INTERNAL')
    ) {
        return true;
    } else if (list?.visibility === 'OPEN' && !!userProjectRole) {
        return true;
    } else {
        return list?.visibility === 'CUSTOM' && list?.visibilityCustomUsers.indexOf(userId) > -1;
    }
}

export interface ListsFromTemplate {
    template: ListTemplate;
    lists: ListFromTemplate[];
}

export interface ListFromTemplate {
    name: string;
    reference?: string;
    tags?: string[];
    settings?: ListSettings;
    statusGroup?: string;
    itemFieldSet?: string;
}

export function toListFromTemplate(listTemplate: ListTemplate): ListFromTemplate {
    return {
        name: listTemplate.name,
        settings: listTemplate.settings || DEFAULT_LIST_SETTINGS,
        tags: getSelectOptionNames(listTemplate.tags),
        itemFieldSet: listTemplate.itemFieldSet?.id || DEFAULT_ITEM_FIELD_SET.id,
    };
}

export function getListStatusGroup(list: BaseList | undefined) {
    return list?.statusGroup || DEFAULT_STATUS_GROUP;
}

export function getListStatusGroupOptions(list: BaseList | undefined) {
    return getListStatusGroup(list).options;
}
